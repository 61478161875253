import React from "react";
import { MdClose, MdAdd } from "react-icons/md";
import RenderDependentPipelines from "./RenderDependentPipelines";
import {
  PipelineContainer,
  SegmentContainer,
  SegmentAddControlContainer,
  SegmentAdd,
  Segment,
  SegmentHeader,
  SegmentBody,
  SegmentAddRight,
  SegmentAddControlContainerBottom,
  SegmentAddBottom,
} from "./CreateFormStyles";
import ActionSelector from "./Actions/ActionsSelector";

const RenderSegment = ({
  dependencies,
  segment,
  dispatch,
  isLast,
  stateSources,
  stateTransformations,
  originNode,
}) => {
  return (
    <>
      <PipelineContainer>
        {segment?.pipelineDependencies?.length ? (
          <RenderDependentPipelines
            dependencies={dependencies}
            pipelines={segment.pipelineDependencies}
            dispatch={dispatch}
            stateSources={stateSources}
            stateTransformations={stateTransformations}
            type="ETL_PIPELINE"
          />
        ) : null}

        {segment?.dataSourceDependencies?.length ? (
          <RenderDependentPipelines
            dependencies={dependencies}
            pipelines={segment.dataSourceDependencies.map((d) => d?.id ?? d)}
            dispatch={dispatch}
            stateSources={stateSources}
            stateTransformations={stateTransformations}
            type="DATA_SOURCE"
          />
        ) : null}

        {segment?.transformationDependencies?.length ? (
          <RenderDependentPipelines
            dependencies={dependencies}
            pipelines={segment.transformationDependencies.map(
              (t) => t?.id ?? t
            )}
            dispatch={dispatch}
            stateSources={stateSources}
            stateTransformations={stateTransformations}
            type="TRANSFORMATION"
          />
        ) : null}
      </PipelineContainer>
      <SegmentContainer>
        <SegmentAddControlContainer>
          <SegmentAdd
            onClick={() =>
              dispatch({
                type: "PREP_INSERT_SEGMENT",
                payload: { segment, insertType: "parent" },
              })
            }
          >
            <MdAdd />
          </SegmentAdd>
        </SegmentAddControlContainer>
        <Segment>
          <SegmentHeader>
            <div style={{ flex: 1 }}></div>
            {dependencies?.length === 1 ? null : (
              <div
                title={"Remove Segment"}
                style={{ marginLeft: "auto", cursor: "pointer" }}
                onClick={() =>
                  dispatch({
                    type: "REMOVE_SEGMENT",
                    payload: segment,
                  })
                }
              >
                <MdClose />
              </div>
            )}
          </SegmentHeader>
          <SegmentBody>
            <ActionSelector
              key={segment?.uid}
              dispatch={dispatch}
              segment={segment}
              dependencies={dependencies}
              stateSources={stateSources}
              stateTransformations={stateTransformations}
            />
          </SegmentBody>

          {isLast || originNode ? (
            <SegmentAddRight
              onClick={() =>
                dispatch({
                  type: "PREP_INSERT_SEGMENT",
                  payload: { segment, insertType: "link" },
                })
              }
            >
              <MdAdd />
            </SegmentAddRight>
          ) : null}
        </Segment>

        <SegmentAddControlContainerBottom>
          <SegmentAddBottom
            onClick={() =>
              dispatch({
                type: "PREP_INSERT_SEGMENT",
                payload: { segment, insertType: "child" },
              })
            }
          >
            <MdAdd />
          </SegmentAddBottom>
        </SegmentAddControlContainerBottom>
      </SegmentContainer>
    </>
  );
};

export default RenderSegment;
