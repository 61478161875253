import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableSortLabel,
} from "@mui/material";
import { useApi } from "../../../api/useApi";
import { availableDataSourcesBare } from "../../../api/dataSourceQueries";
import Loading from "./Loading";
import Error from "./Error";
import { Link } from "react-router-dom";
import { formatTime } from "../../../common/formats";

const DataSourceList = ({ data }) => {
  const [{ loading, errors, data: dataSourcesAvail }, fetchSources] = useApi();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(3);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("score");

  let sourcesData = dataSourcesAvail?.availableDataSources?.edges ?? [];

  const handleRequestSort = (e, property) => {
    e.stopPropagation();
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = sourcesData.sort((a, b) => {
    const scoreA = a.node.latestReport?.qualityScore?.score ?? 0;
    const scoreB = b.node.latestReport?.qualityScore?.score ?? 0;
    if (order === "asc") {
      return scoreA - scoreB;
    } else {
      return scoreB - scoreA;
    }
  });

  useEffect(() => {
    if (data?.sources?.length) {
      fetchSources({
        query: availableDataSourcesBare,
        variables: {
          first: 9999,
          where: {
            enabled: { eq: true },
            id: { in: data?.sources },
          },
          tagFilter: { exactMatch: false, tagIds: [] },
        },
      });
    }
  }, [fetchSources, data?.sources]);

  const handlePageChange = (event, newPage) => {
    setPageIndex(newPage);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageIndex(0); // Reset to first page on page size change
  };

  if (errors && data) {
    return (
      <Box
        sx={{
          marginTop: "2rem",
          padding: 2,
          border: "1px solid #ccc",
          borderRadius: 1,
        }}
      >
        <Error message="There was an error loading the data." />
      </Box>
    );
  }

  if (!dataSourcesAvail) {
    return (
      <Box
        sx={{
          marginTop: "2rem",
          padding: 2,
          border: "1px solid #ccc",
          borderRadius: 1,
        }}
      >
        <Loading />
      </Box>
    );
  }

  // Calculate the total number of pages
  const totalPages = Math.ceil(sourcesData.length / pageSize);

  // Slice the data for the current page
  const currentPageData = sortedData.slice(
    pageIndex * pageSize,
    pageIndex * pageSize + pageSize
  );

  return (
    <>
      <Typography
        variant="h5"
        gutterBottom
        sx={{ zIndex: 2, position: "relative", top: "-10px" }}
      >
        {data?.widgetName}
      </Typography>

      <TableContainer
        component={Paper}
        sx={{ position: "relative", marginTop: "1rem" }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Data Source</TableCell>
              <TableCell sortDirection={orderBy === "score" ? order : false}>
                <TableSortLabel
                  active={orderBy === "score"}
                  direction={orderBy === "score" ? order : "asc"}
                  onClick={(e) => handleRequestSort(e, "score")}
                >
                  Score
                </TableSortLabel>
              </TableCell>
              <TableCell>Report Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentPageData.length ? (
              currentPageData.map((source) => (
                <TableRow key={source.node.id}>
                  <TableCell>
                    <Typography
                      variant="body2"
                      color="primary"
                      component={Link}
                      to={`/sources/${source.node.id}`}
                    >
                      {source.node.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {source.node.latestReport?.qualityScore?.score}
                  </TableCell>
                  <TableCell>
                    {source.node.latestReport?.timestamp
                      ? formatTime(
                          new Date(source.node.latestReport?.timestamp)
                        )
                      : null}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={2} align="center">
                  <Typography variant="body2" color="textSecondary">
                    No data available
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        page={pageIndex}
        onPageChange={handlePageChange}
        rowsPerPage={pageSize}
        onRowsPerPageChange={handlePageSizeChange}
        rowsPerPageOptions={[3]}
        count={sourcesData.length}
        labelDisplayedRows={({ page }) => `Page ${page + 1} of ${totalPages}`}
        labelRowsPerPage="Items per page:"
      />
    </>
  );
};

export default DataSourceList;
