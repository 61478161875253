// widgetConfigurations/RecordFinderConfig.js
import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Button,
  Modal,
  Typography,
  Slider,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useApi } from "../../../api/useApi";
import { allRecordLevelReportConfigurationSummaries } from "../../../api/reportQueries";

const OversightConfig = ({
  data,
  onSubmit,
  onDelete,
  isEditing,
  workspace,
}) => {
  const [{ loading, errors, data: dynamicViewsData }] = useApi(
    allRecordLevelReportConfigurationSummaries,
    {
      first: 9999,
      where: {
        enabled: { eq: true },
      },
    }
  );

  const dynamicViews =
    dynamicViewsData?.allRecordLevelReportConfigurationSummaries?.nodes ?? [];

  const dynamicViewsOptions =
    dynamicViews?.map((dv) => {
      return { label: dv?.name, value: dv?.id };
    }) ?? [];

  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      ...data,
      minWidth: 4,
      maxWidth: 6,
      minHeight: 13,
      maxHeight: 13,
      defaultWidth: 4,
      defaultHeight: 13,
      dynamicView: data?.dynamicView || [], // Set as an array for multiple selections
    },
  });

  useEffect(() => {
    if (data) {
      Object.entries(data).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [data, setValue]);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [sections, setSections] = useState([]);

  useEffect(() => {
    // Fetch available sections for the specific workspace
    const fetchSections = async () => {
      if (workspace?.configuration?.sections) {
        setSections(workspace.configuration.sections);
      }
    };

    fetchSections();
  }, [workspace]);

  const handleClearSection = () => {
    setValue("sectionId", ""); // Clear the sectionId field
  };

  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);
  const handleConfirmDelete = () => {
    handleCloseDeleteModal();
    onDelete();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mb={2}>
        <Controller
          name="dynamicView"
          control={control}
          defaultValue={[]}
          render={({ field }) => (
            <TextField
              {...field}
              label="Select Dynamic Views"
              select
              fullWidth
              SelectProps={{
                multiple: true, // Enable multiple selections
                value: field.value || [], // Ensure the field is treated as an array
                onChange: (event) => {
                  field.onChange(event.target.value);
                },
                renderValue: (selected) =>
                  selected
                    .map((value) => {
                      const selectedItem = dynamicViewsOptions.find(
                        (option) => option.value === value
                      );
                      return selectedItem ? selectedItem.label : "";
                    })
                    .join(", "), // Display selected items as a comma-separated list
              }}
            >
              {dynamicViewsOptions?.map((dvo) => {
                return (
                  <MenuItem key={dvo.value} value={dvo.value}>
                    <Checkbox checked={field.value.includes(dvo.value)} />
                    {dvo.label}
                  </MenuItem>
                );
              })}
            </TextField>
          )}
        />
      </Box>
      <Box mb={2}>
        <Controller
          name="widgetName"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField {...field} label="Widget Name" fullWidth />
          )}
        />
      </Box>
      <Box mb={2}>
        <Controller
          name="reportLabel"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField {...field} label="Report Label" fullWidth />
          )}
        />
      </Box>
      <Box mb={2}>
        <Controller
          name="pageSize"
          control={control}
          defaultValue={20}
          render={({ field }) => (
            <TextField
              {...field}
              label="Table Page Size"
              fullWidth
              type="number"
            />
          )}
        />
      </Box>
      <Box mb={2}>
        <Controller
          name="failureThreshold"
          control={control}
          defaultValue={1}
          render={({ field }) => (
            <TextField
              {...field}
              label="Failure Threshold"
              fullWidth
              type="number"
            />
          )}
        />
        <p style={{ padding: ".5rem" }}>
          The widget will indicate failure counts that meet or exceed your
          failure threshold by highlighting them in red.
        </p>
      </Box>

      {sections.length > 0 && (
        <Box mb={2}>
          <Controller
            name="sectionId"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField {...field} label="Select Section" select fullWidth>
                {sections.map((section) => (
                  <MenuItem key={section.id} value={section.id}>
                    {section.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Button
            variant="contained"
            onClick={handleClearSection}
            sx={{
              backgroundColor: "rgb(18, 17, 53)",
              "&:hover": {
                backgroundColor: "rgba(18, 17, 53, 0.8)",
              },
            }}
          >
            Clear Section
          </Button>
        </Box>
      )}

      <Button
        variant="contained"
        type="submit"
        sx={{
          backgroundColor: "rgb(18, 17, 53)",
          "&:hover": {
            backgroundColor: "rgba(18, 17, 53, 0.8)",
          },
        }}
      >
        {data && isEditing ? "Save Widget" : "Update Preview"}
      </Button>

      {onDelete && (
        <>
          <Button
            variant="contained"
            type="button"
            onClick={handleOpenDeleteModal}
          >
            Remove Widget
          </Button>

          <Modal
            open={openDeleteModal}
            onClose={handleCloseDeleteModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              width={400}
              bgcolor="background.paper"
              boxShadow={24}
              p={4}
            >
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Confirm Delete
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Are you sure you want to delete this widget?
              </Typography>
              <Box mt={4} display="flex" justifyContent="space-between">
                <Button
                  onClick={handleConfirmDelete}
                  variant="contained"
                  color="secondary"
                  sx={{
                    backgroundColor: "rgb(18, 17, 53)",
                    "&:hover": {
                      backgroundColor: "rgba(18, 17, 53, 0.8)",
                    },
                  }}
                >
                  Confirm
                </Button>
                <Button onClick={handleCloseDeleteModal} variant="outlined">
                  Cancel
                </Button>
              </Box>
            </Box>
          </Modal>
        </>
      )}
    </form>
  );
};

export default OversightConfig;
