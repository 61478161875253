import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableSortLabel,
} from "@mui/material";
import { useApi } from "../../../api/useApi";
import { availableDataSourcesBare } from "../../../api/dataSourceQueries";
import Loading from "./Loading";
import Error from "./Error";
import DataQualityScoreMinWidget from "../../../components/Widgets/DataSourceWidgets/DataQualityScoreMinWidget";
import { Link } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";

const SourceScoreBarChart = ({ data }) => {
  const getColorForScore = (score) => {
    const thresholdScores = [
      { min: 0, max: 39, name: "Critical", color: "#f87e7e" },
      { min: 40, max: 69, name: "Low", color: "#E76E3C" },
      { min: 70, max: 94, name: "Medium", color: "#F6C600" },
      { min: 95, max: 100, name: "High", color: "#40806A" },
    ];

    for (const threshold of thresholdScores) {
      if (score >= threshold.min && score <= threshold.max) {
        return threshold.color;
      }
    }
    return "#000000"; // Default color if no match found
  };

  const chartData = {
    labels: data.map((source) => source.node.name),
    datasets: [
      {
        label: "Source Score",
        data: data.map(
          (source) => source.node.latestReport?.qualityScore?.score ?? 0
        ),
        backgroundColor: data.map((source) =>
          getColorForScore(source.node.latestReport?.qualityScore?.score ?? 0)
        ),
        borderColor: data.map((source) =>
          getColorForScore(source.node.latestReport?.qualityScore?.score ?? 0)
        ),
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div style={{ width: "100%", height: "400px" }}>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export function findThresholdScore(grade) {
  let scoreObject;

  const thresholdScores = [
    {
      min: 0,
      max: 39,
      name: "Critical",
      color: "#f87e7e",
    },
    {
      min: 40,
      max: 69,
      name: "Low",
      color: "#E76E3C",
    },
    {
      min: 70,
      max: 94,
      name: "Medium",
      color: "#F6C600",
    },
    {
      min: 95,
      max: 100,
      name: "High",
      color: "#40806A",
    },
  ];
  // Pin grade to critical if the score is negative
  if (grade <= 0) {
    scoreObject = thresholdScores[0];
  }
  thresholdScores.forEach((s, i) => {
    if (grade >= s.min && grade <= s.max) {
      scoreObject = thresholdScores[i];
    }
  });
  return scoreObject;
}

const DataSourceScore = ({ data }) => {
  const [{ loading, errors, data: dataSourcesAvail }, fetchSources] = useApi();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(3);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("score");

  let sourcesData = dataSourcesAvail?.availableDataSources?.edges ?? [];

  const handleRequestSort = (e, property) => {
    e.stopPropagation();
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = sourcesData.sort((a, b) => {
    const scoreA = a.node.latestReport?.qualityScore?.score ?? 0;
    const scoreB = b.node.latestReport?.qualityScore?.score ?? 0;
    if (order === "asc") {
      return scoreA - scoreB;
    } else {
      return scoreB - scoreA;
    }
  });

  useEffect(() => {
    if (data?.sources?.length) {
      fetchSources({
        query: availableDataSourcesBare,
        variables: {
          first: 9999,
          where: {
            enabled: { eq: true },
            id: { in: data?.sources },
          },
          tagFilter: { exactMatch: false, tagIds: [] },
        },
      });
    }
  }, [fetchSources, data?.sources]);

  const handlePageChange = (event, newPage) => {
    setPageIndex(newPage);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageIndex(0); // Reset to first page on page size change
  };

  if (errors && data) {
    return (
      <Box
        sx={{
          marginTop: "2rem",
          padding: 2,
          border: "1px solid #ccc",
          borderRadius: 1,
        }}
      >
        <Error message="There was an error loading the data." />
      </Box>
    );
  }

  if (!dataSourcesAvail) {
    return (
      <Box
        sx={{
          marginTop: "2rem",
          padding: 2,
          border: "1px solid #ccc",
          borderRadius: 1,
        }}
      >
        <Loading />
      </Box>
    );
  }

  // Calculate the total number of pages
  const totalPages = Math.ceil(sourcesData.length / pageSize);

  // Slice the data for the current page
  const currentPageData = sortedData.slice(
    pageIndex * pageSize,
    pageIndex * pageSize + pageSize
  );

  return (
    <>
      <Typography
        variant="h5"
        gutterBottom
        sx={{ zIndex: 2, position: "relative", top: "-10px" }}
      >
        {data?.widgetName}
      </Typography>

      {!data?.widgetType || data?.widgetType === "cards" ? (
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          {sortedData?.map((source) => {
            const qualityScore = source?.node?.latestReport?.qualityScore;
            const sourceInfo = source?.node;
            return (
              <Grid item xs={12} sm={6} md={4} lg={3} key={sourceInfo?.id}>
                <Box
                  component={Link}
                  to={`/sources/${sourceInfo?.id}`}
                  sx={{ textDecoration: "none", color: "inherit" }}
                >
                  <Paper
                    sx={{
                      padding: 2,
                      backgroundColor: "#fff",
                      borderRadius: 2,
                      boxShadow: 1,
                      color: "inherit",
                      position: "relative",
                      display: "inline-block",
                      textDecoration: "none",
                      color: "inherit",
                      maxWidth: "150px",
                      minWidth: "150px", // Ensure it takes up the full width of the grid item
                      boxSizing: "border-box", // Include padding and border in the element's total width and height
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="primary"
                      component={Link}
                      to={`/sources/${sourceInfo?.id}`}
                    >
                      🗄️{sourceInfo?.name}
                    </Typography>

                    {data?.showQualityScoreLabel && (
                      <Typography
                        variant="body3"
                        component="div"
                        sx={{
                          marginTop: "1rem",
                          textAlign: "center",
                          color: "#000",
                        }}
                      >
                        QUALITY SCORE
                      </Typography>
                    )}

                    <DataQualityScoreMinWidget
                      loading={loading}
                      data={qualityScore}
                    />
                  </Paper>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      ) : null}

      {data?.widgetType === "table" && (
        <>
          <TableContainer
            component={Paper}
            sx={{ position: "relative", marginTop: "1rem" }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Data Source</TableCell>
                  <TableCell
                    sortDirection={orderBy === "score" ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === "score"}
                      direction={orderBy === "score" ? order : "asc"}
                      onClick={(e) => handleRequestSort(e, "score")}
                    >
                      Score
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentPageData.length ? (
                  currentPageData.map((source) => (
                    <TableRow key={source.node.id}>
                      <TableCell>
                        <Typography
                          variant="body2"
                          color="primary"
                          component={Link}
                          to={`/sources/${source.node.id}`}
                        >
                          {source.node.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {source.node.latestReport?.qualityScore?.score}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={2} align="center">
                      <Typography variant="body2" color="textSecondary">
                        No data available
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            page={pageIndex}
            onPageChange={handlePageChange}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handlePageSizeChange}
            rowsPerPageOptions={[3]}
            count={sourcesData.length}
            labelDisplayedRows={({ page }) =>
              `Page ${page + 1} of ${totalPages}`
            }
            labelRowsPerPage="Items per page:"
          />
        </>
      )}

      {data?.widgetType === "bar" && (
        <>
          <Paper
            sx={{
              padding: 2,
              backgroundColor: "#fff",
              borderRadius: 2,
              boxShadow: 1,
              color: "inherit",
              position: "relative",
              display: "inline-block",
              textDecoration: "none",
              color: "inherit",
              width: "100%",
              boxSizing: "border-box", // Include padding and border in the element's total width and height
            }}
          >
            <SourceScoreBarChart data={sourcesData} />
          </Paper>
        </>
      )}
    </>
  );
};

export default DataSourceScore;
