import { useState, useEffect } from "react";
import UseDataSourcePercentage from "../../../Hooks/useDataSourcePercentage";
const Processing = ({ sourceId, inline }) => {
  const { dataSourcePercentageComplete, setDataSourcePercentageComplete } =
    UseDataSourcePercentage();
  const [complete, setComplete] = useState("");

  useEffect(() => {
    if (dataSourcePercentageComplete?.payload?.SourceId === sourceId) {
      console.log(dataSourcePercentageComplete?.payload);
      if (dataSourcePercentageComplete?.payload?.PercentageComplete === 100) {
        setDataSourcePercentageComplete(null);
        setComplete("Finalizing");
      } else if (dataSourcePercentageComplete?.payload?.SimpleMetrics) {
        // let counts = dataSourcePercentageComplete?.payload?.SimpleMetrics.map(sm => )
        const sm = dataSourcePercentageComplete?.payload?.SimpleMetrics;
        // console.log(sm)
        const logs = Object.entries(sm).map(v => `DS${v[0]}: { metrics sent: ${v[1].Item1}, metrics processed: ${v[1].Item2} }`);
        setComplete(logs.join("\n"));
      } else {
        setComplete(
          inline
            ? ` ` +
                dataSourcePercentageComplete?.payload?.PercentageComplete +
                `%`
            : `Processing ` +
                dataSourcePercentageComplete?.payload?.PercentageComplete +
                `%`
        );
      }
    }
  }, [
    inline,
    dataSourcePercentageComplete,
    sourceId,
    setDataSourcePercentageComplete,
  ]);

  useEffect(() => {
    if (complete === "Finalizing") {
      setTimeout(() => {
        setComplete("");
      }, 3000);
    }
  }, [complete]);

  return complete;
};

export default Processing;
