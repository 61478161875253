import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
  useContext,
} from "react";
import debounce from "lodash.debounce";
import { StyledInput, FormControl } from "../../components/Form/FormControls";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import {
  MdOutlineRocketLaunch,
  MdEditDocument,
  MdCalculate,
  MdPolicy,
} from "react-icons/md";
import Modal from "../../components/Modal";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link,
  Grid,
  Alert,
  Button,
  ButtonGroup,
  CircularProgress,
  Backdrop,
  Box,
  Autocomplete,
  TextField,
  Checkbox,
  FormControlLabel,
  IconButton,
  Collapse,
  Typography,
} from "@mui/material";
import { AuthContext } from "../../contexts/AuthContext";
import {
  availableDataSourcesForBP,
  dataSourceForCrosstable,
} from "../../api/dataSourceQueries";
import {
  createRuleStandard,
  createNewRuleInstance,
} from "../../api/ruleMutations";
import { useApi } from "../../api/useApi";
import { allMappedInstancesForBP } from "../../api/ruleQueries";
import SourceBuilder from "./bpmanager/SourceBuilder";
import ViewStandard from "../../views/RuleStandardPage/ViewStandard";
import { Scrollbars } from "react-custom-scrollbars";
import Permissions from "../../components/Form/PermissionsWithoutFormik";
import { formatTime } from "../../common/formats";

const AccessPoint = () => {
  const [myPatToken, setMyPatToken] = useState("");
  const [inputValue, setInputValue] = useState("");

  const debouncedSave = useMemo(
    () => debounce((newValue) => setMyPatToken(newValue), 1000),
    []
  );

  const updateValue = (newValue) => {
    setInputValue(newValue);
    debouncedSave(newValue);
  };

  return (
    <>
      <h3>Enter Your Personal Access Token to Access Rule Packs</h3>
      <p>
        <i>Keep this token secure, do not share this token with anyone!</i>
      </p>
      <FormControl>
        <StyledInput
          type="password"
          name="pta"
          label="Access Token"
          value={inputValue}
          placeholder={`Enter Your Personal Access Token`}
          onChange={(e) => updateValue(e?.target?.value)}
        />
      </FormControl>

      {myPatToken ? <ImportForm myPatToken={myPatToken} /> : null}
    </>
  );
};

const DeployAll = ({ blueprint, setMode }) => {
  return <div>All</div>;
};

///

const DeployPolicies = ({ blueprint, setMode }) => {
  const [selectedPolicies, setSelectedPolicies] = useState([]);
  const [view, setView] = useState();
  const [deploy, setDeploy] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState({}); // To track loading status
  const [completedPolicies, setCompletedPolicies] = useState([]);
  const [failedPolicies, setFailedPolicies] = useState([]);
  const [currentPolicyIndex, setCurrentPolicyIndex] = useState(0); // Track the current policy being processed
  const isProcessingRef = useRef(false); // Ref to track if a policy is being processed
  const [{ errors: createdErrors, data: created }, create, clearFields] =
    useApi();

  const uniquePolicies = blueprint?.sourceProfiles
    ?.flatMap((profile) => profile?.policyAndMappings)
    ?.reduce((acc, policy) => {
      if (!acc.some((p) => p.ruleStandardId === policy.ruleStandardId)) {
        acc.push(policy);
      }
      return acc;
    }, []);

  // Handle checkbox toggle for individual rows
  const handleToggle = (ruleStandardId) => {
    setSelectedPolicies((prev) =>
      prev.includes(ruleStandardId)
        ? prev.filter((id) => id !== ruleStandardId)
        : [...prev, ruleStandardId]
    );
  };

  // Handle Select All toggle
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allIds = uniquePolicies.map((policy) => policy.ruleStandardId);
      setSelectedPolicies(allIds);
    } else {
      setSelectedPolicies([]);
    }
  };

  // Function to kick off the policy creation
  const createPolicy = (policy) => {
    setLoadingStatus((prev) => ({
      ...prev,
      [policy.ruleStandardId]: "loading",
    }));

    const variables = {
      newStandard: {
        name: policy?.ruleStandardName,
        standardType: "RULE",
        description: policy?.ruleDescription,
        versions: [
          {
            fragments: policy?.policyStandardVersionTemplate?.fragments.map(
              ({ id, standardVersionId, ...fragment }) => ({
                ...fragment,
                typeInformation: fragment?.typeInformation.map(
                  ({ fragmentId, ...typeInfo }) => typeInfo
                ),
              })
            ),
          },
        ],
        permissions: permissions,
        tagInstances: null,
      },
    };

    create({ query: createRuleStandard, variables });
  };

  // Effect to handle policy creation process
  useEffect(() => {
    if (deploy && !isProcessingRef.current && permissions.length) {
      const policy = selectedPolicies[currentPolicyIndex]
        ? uniquePolicies.find(
            (p) => p.ruleStandardId === selectedPolicies[currentPolicyIndex]
          )
        : null;

      if (policy) {
        createPolicy(policy); // Begin creating the current policy
        isProcessingRef.current = true; // Set flag to avoid triggering effect again
      }
    }
  }, [deploy, currentPolicyIndex, selectedPolicies, uniquePolicies]);

  // Effect to handle response from the API (either success or failure)
  useEffect(() => {
    if (created || createdErrors) {
      const policy = selectedPolicies[currentPolicyIndex]
        ? uniquePolicies.find(
            (p) => p.ruleStandardId === selectedPolicies[currentPolicyIndex]
          )
        : null;

      if (created && created?.createNewRuleInstance?.errors) {
      }

      if (created && policy) {
        setCompletedPolicies((prev) => {
          const updatedCompleted = [...prev, policy.ruleStandardId];
          if (
            updatedCompleted.length + failedPolicies.length ===
            selectedPolicies.length
          ) {
            setDeploy(false); // All policies processed, stop deployment
          }
          return updatedCompleted;
        });
        setLoadingStatus((prev) => ({
          ...prev,
          [policy.ruleStandardId]: "completed",
        }));
        clearFields();
      }

      if (createdErrors && policy) {
        setFailedPolicies((prev) => {
          const updatedFailed = [...prev, policy.ruleStandardId];
          if (
            completedPolicies.length + updatedFailed.length ===
            selectedPolicies.length
          ) {
            setDeploy(false); // All policies processed, stop deployment
          }
          return updatedFailed;
        });
        setLoadingStatus((prev) => ({
          ...prev,
          [policy.ruleStandardId]: {
            status: "failed",
            errors: createdErrors.map((ce) => ce.message),
          },
        }));
        clearFields();
      }

      // Reset processing flag and move to the next policy
      isProcessingRef.current = false;

      if (currentPolicyIndex + 1 < selectedPolicies.length) {
        setCurrentPolicyIndex((prevIndex) => prevIndex + 1);
      }
    }
  }, [created, createdErrors, deploy]);

  // Kickoff the deployment process
  const kickoffPolicyDeployment = () => {
    setCurrentPolicyIndex(0); // Start from the first selected policy
    setDeploy(true); // Trigger the deployment process
  };

  const allSelected =
    uniquePolicies.length > 0 &&
    selectedPolicies.length === uniquePolicies.length;
  const isIndeterminate =
    selectedPolicies.length > 0 &&
    selectedPolicies.length < uniquePolicies.length;

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#f5f5f5", // Light background to make it stand out
          padding: "16px",
          borderRadius: "8px", // Rounded corners
          border: "1px solid #ddd", // Subtle border
        }}
        mb={2}
      >
        <Typography
          variant="body1"
          sx={{
            fontWeight: "bold", // Bold font for emphasis
            color: "#333", // Darker text color for readability
            fontSize: "1rem", // Adjust font size as needed
          }}
        >
          Ensure you have deployed these policies to the environment for which
          you wish to map within, if you attempt to map before, im not sure what
          happens, but good luck. If it already exists, and you need to update
          to latest, try removing the existing policy. If that doesn't work -
          It's Broken.
          <Typography component="span" color="error" fontWeight="bold">
            The Warning! ☢️
          </Typography>
        </Typography>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={isIndeterminate}
                  checked={allSelected}
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell>Policy Name</TableCell>
              <TableCell>Deployment Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {uniquePolicies?.map((policy) => (
              <TableRow key={policy.ruleStandardId}>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedPolicies.includes(policy.ruleStandardId)}
                    onChange={() => handleToggle(policy.ruleStandardId)}
                  />
                </TableCell>
                <TableCell>{policy.ruleStandardName}</TableCell>
                <TableCell>
                  {loadingStatus[policy.ruleStandardId] === "loading" &&
                    "Loading"}
                  {loadingStatus[policy.ruleStandardId] === "completed" &&
                    "Success"}
                  {loadingStatus[policy.ruleStandardId]?.status ===
                    "failed" && (
                    <div>
                      <p>Errors:</p>
                      <ul>
                        {loadingStatus[policy.ruleStandardId].errors.map(
                          (error, index) => (
                            <li key={index}>{error}</li>
                          )
                        )}
                      </ul>
                    </div>
                  )}
                </TableCell>

                <TableCell>
                  {/* {loadingStatus[policy.ruleStandardId]?.status ===
                    "failed" && (
                    <Button
                      onClick={() => redeployPolicy(policy.ruleStandardId)}
                    >
                      Retry
                    </Button>
                  )} */}
                  <Button onClick={() => setView(policy)}>View Policy</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div style={{ marginTop: "1rem" }}>
        <h4>Select Permissions</h4>
        <Permissions callFn={setPermissions} permissions={permissions} />
      </div>

      {/* Centered Deploy Button */}
      <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
        <Button
          onClick={() => kickoffPolicyDeployment()}
          variant="contained"
          size="large"
          disabled={deploy || !selectedPolicies?.length || !permissions?.length} // Disable button when loading
        >
          {deploy ? "Deploying..." : "Deploy Selected Policies"}
        </Button>
      </Box>

      {deploy && (
        <img
          src="https://i.giphy.com/media/JIX9t2j0ZTN9S/giphy.webp"
          alt={"We're working on it!"}
        />
      )}

      {view && (
        <Modal title={view?.ruleStandardName} hide={() => setView()}>
          <Scrollbars autoHeightMax={window.innerHeight * 0.7} autoHeight>
            <ViewStandard standard={view?.policyStandardVersionTemplate} />
          </Scrollbars>
        </Modal>
      )}
    </>
  );
};

//

const DeployMappings = ({ blueprint, setMode }) => {
  const mappings =
    blueprint?.sourceProfiles?.flatMap(
      (profile) => profile.policyAndMappings
    ) ?? [];

  const ruleStandardNames = [
    ...new Set(
      blueprint?.sourceProfiles?.flatMap((profile) =>
        profile.policyAndMappings?.map((mapping) => mapping.ruleStandardName)
      ) ?? []
    ),
  ];

  const [view, setView] = useState();
  const [selectedPolicyMappings, setSelectedPolicyMappings] = useState([]);
  const [deploy, setDeploy] = useState(false);
  const [sourceOptions, setSourceOptions] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState({}); // To track loading status
  const [completedPolicies, setCompletedPolicies] = useState([]);
  const [failedPolicies, setFailedPolicies] = useState([]);
  const [currentPolicyIndex, setCurrentPolicyIndex] = useState(0); // Track the current policy being processed
  const isProcessingRef = useRef(false); // Ref to track if a policy is being processed
  const [pairings, setPairings] = useState();
  const [processingQueue, setProcessingQueue] = useState([]);
  const [crossTableResults, setCrossTableResults] = useState([]);
  const [startProcessing, setStartProcessing] = useState(false);
  const [oldColumns, setOldColumns] = useState([]);
  const [instanceConfigs, setInstanceConfigs] = useState();
  const [notFoundStandards, setNotFoundStandards] = useState([]);

  const addUniqueStandard = (newStandard) => {
    setNotFoundStandards((prev) => {
      // Check if the new standard already exists in the array
      const isStandardExists = prev?.some(
        (standard) => standard?.name === newStandard?.name
      );

      // If it doesn't exist, add the new standard, otherwise return the previous state
      if (!isStandardExists) {
        return [...prev, newStandard];
      }

      return prev; // Return the previous state if the entry is not unique
    });
  };

  const handleNewSourceChange = (index, event, newValue) => {
    const updatedPairings = [...pairings];
    const selectedOption = newValue || {}; // If no value is selected

    // Update the new source for the selected pairing
    updatedPairings[index].newSource = {
      name: selectedOption.label || "",
      sourceId: selectedOption.value || "",
    };

    setPairings(updatedPairings);
  };

  const [{ errors: createdErrors, data: created }, create, clearFields] =
    useApi();
  const [{ errors: errorsAllSources, data: availableSources }] = useApi(
    availableDataSourcesForBP,
    {
      first: 9999,
      where: {
        enabled: { eq: true },
      },
      tagFilter: { exactMatch: false, tagIds: [] },
    }
  );

  const [
    { loading: loadingPolicies, errors: policyErrors, data: policiesData },
    getPolicies,
  ] = useApi();

  useEffect(() => {
    getPolicies({
      query: allMappedInstancesForBP,
      variables: {
        standardType: "RULE",
        // first 9999 also fixes 3767 due to HC change of defaults
        first: 9999, //added as a hack to enable filtering until we get case-insens filtering
        where: {
          enabled: { eq: true },
          name: { in: ruleStandardNames },
        },
      },
    });
  }, [getPolicies]);

  const sourcesAvail = availableSources?.availableDataSources?.edges ?? [];

  const allNewColumns =
    sourcesAvail?.map((sac) => sac?.node?.columns ?? []).flat() ?? [];

  const [
    { loading: loadingSource, data: dataSourceData },
    getDataSourceColumns,
  ] = useApi();

  useEffect(() => {
    if (pairings?.length) {
      setProcessingQueue(
        pairings
          ?.filter((p) => p?.newSource?.sourceId) // Filter pairings with valid newSource.sourceId
          .map((p) => p.newSource.sourceId) // Map to extract sourceId
      );
      setStartProcessing(true);
    }
  }, [pairings]);

  useEffect(() => {
    if (processingQueue?.length > 0 && !loadingSource) {
      const nextSourceId = processingQueue[0];

      // Prevent unnecessary calls by checking if loadingSource is false
      getDataSourceColumns({
        query: dataSourceForCrosstable,
        variables: {
          id: Number(nextSourceId),
          where: {
            secondaryDataSources: { enabled: true },
          },
        },
      });
    } else {
      setStartProcessing(false);
    }
  }, [processingQueue]); // Remove `loadingSource` as a dependency to avoid triggering too many queries

  // Effect to post-process after data source API response
  useEffect(() => {
    if (dataSourceData) {
      postProcessSourcesForCrossTable();
    }
  }, [dataSourceData]);

  // Post-process after API calls are completed
  const postProcessSourcesForCrossTable = () => {
    if (dataSourceData && !loadingSource) {
      const newPrimaryId =
        dataSourceData?.dataSourceForCrosstable?.primaryDataSource?.id;

      setCrossTableResults((prev) => {
        // Check if the id already exists in the previous results
        const isExisting = prev.some(
          (item) => item.primaryDataSource.id === newPrimaryId
        );

        // Only update state if the new primaryDataSource doesn't already exist
        if (!isExisting) {
          return [...prev, dataSourceData?.dataSourceForCrosstable];
        }
        // Return the previous state unchanged if the id exists
        return prev;
      });

      // Remove the processed source from the queue
      const remainingQueue = processingQueue.filter(
        (sourceId) => sourceId !== newPrimaryId
      );
      setProcessingQueue(remainingQueue);
    }
  };
  useEffect(() => {
    if (
      !startProcessing &&
      availableSources?.availableDataSources?.edges?.length &&
      policiesData?.availableBusinessRuleStandards?.edges?.length
    ) {
      // Mappings
      const mappings =
        blueprint?.sourceProfiles?.flatMap(
          (profile) => profile.policyAndMappings
        ) ?? [];

      // // Find Initial Source Selection
      let mappingConfigs = [];

      function lookUpMappingsForCrossTable(fragments, sourceId, columns) {
        let isCross = false;
        if (sourceId) {
          if (fragments?.length) {
            fragments?.forEach((f) => {
              const columnId = f?.columnId;
              const foundColumn = columns.find((c) => c?.id === columnId);
              if (foundColumn) {
                if (foundColumn?.dataSourceId !== Number(sourceId)) {
                  isCross = true;
                }
              }
            });
          } else {
            return false;
          }

          return isCross;
        } else {
          return false;
        }
      }

      mappings?.forEach((m) => {
        // New Policies
        const policies =
          policiesData?.availableBusinessRuleStandards?.edges?.map(
            (e) => e?.node
          ) ?? [];

        // Get the Original Source ID, map lookup the pairing to see which
        // should be the new sourceid :: ogSourceId

        // Find the Old and the new Policy ID / Standard ID
        // use m?.ruleStandardName to find against new policy name its gatta match here

        // find the latest version (largest id, pass that into get the is recon)

        const newSourcePairing = pairings.find(
          (p) => p?.ogSource?.sourceId === m?.ogSourceId
        );

        const foundNewSource = sourcesAvail.find(
          (s) => s?.node?.name === newSourcePairing?.newSource?.name
        );

        const newStandard = policies?.find(
          (p) => p.name === m?.ruleStandardName
        );
        if (newStandard) {
          //found a matching standard
          const standard = policies
            ?.find((p) => p.name === m?.ruleStandardName)
            ?.versions?.reduce(
              (prev, current) => (current.id > prev.id ? current : prev),
              { id: -Infinity }
            );
          if (standard) {
            //found a latest version of said standard
            const businessRules =
              standard?.fragments
                .filter((frag) => frag.typeInformation[0].typeValue === 3) // First Fragment of an Operation has a typeValue = 3
                .map(
                  (operationFrag) => operationFrag.typeInformation[1].typeValue
                ) ?? []; // Second Fragment of an Operation's typeValue is the businessRule

            const isAReconciliationRule =
              businessRules.includes(14) || businessRules.includes(15);

            // Find whatever NEW source was selected
            // use that as the column selection

            const allColumns =
              blueprint?.sourceProfiles?.flatMap((sp) => sp.ogColumns) || [];

            const cleanFragments = m?.mappingData?.mappings?.map((map) => {
              //go find the execution order of the new mapping, use that fragment id

              const isSystem = m?.ruleStandardId <= 0;
              const fullyQualifiedName = isSystem
                ? allColumns.find((c) => c?.id === map?.columnId)
                    ?.fullyQualifiedName ?? "|"
                : map?.column?.fullyQualifiedName ?? "|";

              // Split the string by the pipe character
              const [OgSourceName, OgSourceColumnName] =
                fullyQualifiedName?.split("|");

              // now that we have the OG sourcename, and the column name
              // we can go look up the pairings and based on the select of og to NEW source
              // we can get the correct column ID from the new sources

              const ogSourceForColumn = pairings.find(
                (p) => p?.ogSource?.name === OgSourceName
              );
              const newSourceForColumn = sourcesAvail.find(
                (s) => s?.node?.name === ogSourceForColumn?.newSource?.name
              );
              const newSourceColumns = newSourceForColumn?.node?.columns ?? [];

              return {
                businessRuleFragmentId: standard?.fragments?.find(
                  (f) =>
                    f.executionOrder ===
                    map?.businessRuleFragment?.executionOrder
                )?.id,
                columnId:
                  newSourceColumns?.find((c) => c?.name === OgSourceColumnName)
                    ?.id ?? 0,
              };
            });

            const calcMappings = m?.mappingData?.calculationMappings?.map(
              (cmap) => {
                //go find the execution order of the new mapping, use that fragment id
                // we have to do the lookup for the frag id because its always null on the calc column
                const oldColumnInfo = oldColumns.find(
                  (oc) => oc?.id === cmap?.columnId
                );

                const isSystem = m?.ruleStandardId <= 0;
                const fullyQualifiedName = isSystem
                  ? allColumns?.find((c) => c?.id === cmap?.columnId)
                      ?.fullyQualifiedName ?? "|"
                  : oldColumnInfo?.fullyQualifiedName ?? "|";

                // Split the string by the pipe character
                const [OgSourceName, OgSourceColumnName] =
                  fullyQualifiedName?.split("|");

                // now that we have the OG sourcename, and the column name
                // we can go look up the pairings and based on the select of og to NEW source
                // we can get the correct column ID from the new sources

                const ogSourceForColumn = pairings.find(
                  (p) => p?.ogSource?.name === OgSourceName
                );
                const newSourceForColumn = sourcesAvail.find(
                  (s) => s?.node?.name === ogSourceForColumn?.newSource?.name
                );
                const newSourceColumns =
                  newSourceForColumn?.node?.columns ?? [];

                return {
                  businessRuleFragmentId: standard?.fragments?.find(
                    (f) =>
                      Number(f.executionOrder) ===
                      m?.policyStandardVersionTemplate?.fragments?.find(
                        (f) =>
                          Number(f.id) === Number(cmap?.businessRuleFragmentId)
                      )?.executionOrder
                  )?.id,
                  columnId:
                    newSourceColumns?.find(
                      (c) => c?.name === OgSourceColumnName
                    )?.id ?? 0,
                  argument: cmap?.argument,
                  order: cmap?.order,
                };
              }
            );

            // need to fetch EXISTING matching cols and push the new ones in - just incase someone went and mapped something else

            function getNewColumnId(oldSourceId, oldColumnName) {
              const pairing = pairings.find(
                (p) => p?.ogSource?.sourceId === oldSourceId
              );

              const newColummnId = sourcesAvail
                .find((s) => s?.node?.name === pairing?.newSource?.name)
                ?.node?.columns?.find((c) => c?.name === oldColumnName)?.id;

              return newColummnId;
            }

            const ogSourceForColumn = pairings.find(
              (p) => p?.ogSource?.name === m?.ogSourceName
            );

            const existingMatchingColumns =
              crossTableResults
                .find(
                  (ct) =>
                    ct.primaryDataSource?.id ===
                    ogSourceForColumn?.ogSource?.sourceId
                )
                ?.matchingColumns?.map((mc) => {
                  return {
                    dataSourceColumn1: mc?.dataSourceColumn1?.id,
                    dataSourceColumn2: mc?.dataSourceColumn2?.id,
                  };
                }) ?? [];

            const oldMatchingColumns =
              blueprint?.sourceProfiles?.find(
                (sp) => sp?.ogId === ogSourceForColumn?.ogSource?.sourceId
              )?.ogMatchingColumns ?? [];

            const newMatchingColumns =
              oldMatchingColumns?.map((omc) => {
                return {
                  dataSourceColumn1: getNewColumnId(
                    omc?.dataSourceColumn1?.dataSourceId,
                    omc?.dataSourceColumn1?.name
                  ),
                  dataSourceColumn2: getNewColumnId(
                    omc?.dataSourceColumn2?.dataSourceId,
                    omc?.dataSourceColumn2?.name
                  ),
                };
              }) ?? [];

            const filterDuplicateMatchingColumns = (
              existingMatchingColumns,
              newMatchingColumns
            ) => {
              // Combine existing and new columns
              const combinedColumns = [
                ...existingMatchingColumns,
                ...newMatchingColumns,
              ];

              // Use a Set to store unique pairs as strings
              const uniqueColumns = new Set();

              // Filter to ensure only one instance of each unique column pair is returned
              const filteredColumns = combinedColumns?.filter((col) => {
                const key = `${col.dataSourceColumn1}-${col.dataSourceColumn2}`;
                if (!uniqueColumns.has(key)) {
                  uniqueColumns.add(key);
                  return true; // Keep this unique column
                }
                return false; // Skip duplicates
              });

              // Map to the desired structure
              return filteredColumns?.map((col) => ({
                dataSourceColumnId1: col.dataSourceColumn1,
                dataSourceColumnId2: col.dataSourceColumn2,
              }));
            };

            function getUniqueSourceIds(pairings) {
              // Extract unique sourceIds from pairings
              return Array.from(
                new Set(pairings.map((pair) => pair.newSource.sourceId))
              );
            }

            function findAndMergeColumns(sourcesAvail, pairings) {
              // Get unique sourceIds from pairings
              const uniqueSourceIds = getUniqueSourceIds(pairings);

              // Initialize an array to store merged columns
              const mergedColumns = [];

              // Loop through each unique sourceId and add their columns
              uniqueSourceIds.forEach((sourceId) => {
                // Find the source in sourcesAvail by sourceId
                const source = sourcesAvail.find(
                  (item) => item.node.id === sourceId
                );

                if (source) {
                  // Directly push columns into mergedColumns
                  mergedColumns.push(...source.node.columns);
                }
              });

              return mergedColumns;
            }

            // Example usage:
            const mergedColumnsResult = findAndMergeColumns(
              sourcesAvail,
              pairings
            );

            const isDuplicateRecordRule = standard?.id === -3;
            const isDuplicateColumnRule = standard?.id === -2;
            const base = {
              key: m?.ruleInstanceVersionId,
              query: {
                dataSourceId: newSourcePairing?.newSource?.sourceId,
                instance: {
                  standardId: newStandard?.id,
                  isAReconciliationRuleInstance: isAReconciliationRule,
                  isACrosstableRuleInstance: lookUpMappingsForCrossTable(
                    [...cleanFragments, ...calcMappings],
                    newSourcePairing?.newSource?.sourceId,
                    mergedColumnsResult
                  ),
                  title: m?.ruleInstanceName,
                  versions: [
                    {
                      standardVersionId: standard?.id,
                      mappings:
                        !isDuplicateColumnRule && !isDuplicateRecordRule
                          ? cleanFragments
                          : [],
                      calculationMappings: !isDuplicateRecordRule
                        ? calcMappings
                        : [],
                      matchingColumns: filterDuplicateMatchingColumns(
                        existingMatchingColumns,
                        newMatchingColumns
                      ),
                    },
                  ],
                },
                saveAsDraft: false, // hardcoded to not save as draft
              },
            };

            mappingConfigs.push(base);
          } else {
            addUniqueStandard({
              message: `No Policy Version Found ${m?.ruleStandardName}`,
              name: m?.ruleStandardName,
            });
          }
        } else {
          addUniqueStandard({
            message: `No Policy Found ${m?.ruleStandardName}`,
            name: m?.ruleStandardName,
          });
        }
      });

      setInstanceConfigs(mappingConfigs);
    }
  }, [oldColumns, crossTableResults, pairings]);

  useEffect(() => {
    if (
      availableSources?.availableDataSources?.edges?.length &&
      policiesData?.availableBusinessRuleStandards?.edges?.length
    ) {
      // configure Mapping Instances

      // NEW Sources
      const sourcesAvail = availableSources?.availableDataSources?.edges ?? [];

      // Establish X Table Sources
      const ogSources = blueprint?.sourceProfiles ?? [];

      // Source Pairings?
      let oldToNewSourcePairings = [];
      let oldColumns = [];

      ogSources?.forEach((ogs) => {
        // Attempt to find new Source in system
        const foundNewSource = sourcesAvail.find(
          (s) => s?.node?.name === ogs.ogName
        );
        oldColumns.push(...ogs.ogColumns);
        // Lookup any other Sources for X Table
        oldToNewSourcePairings.push({
          ogSource: { name: ogs.ogName, sourceId: ogs.ogId },
          newSource: {
            name: foundNewSource?.node?.name,
            sourceId: foundNewSource?.node?.id,
          },
        });

        ogs?.ogCrossTableReq?.forEach((xt) => {
          // Attempt to find if cross-table source ID is in ogSources
          const isXtInOgSources = ogSources?.some(
            (source) => source.ogId === xt.id
          );

          // If not found in ogSources, push its columns to oldColumns
          if (!isXtInOgSources) {
            oldColumns.push(...xt.columns);
          }

          // Attempt to find new Source in system
          const foundNewSourceForXt = sourcesAvail?.find(
            (s) => s?.node?.name === xt.name
          );

          // Lookup any other Sources for X Table
          oldToNewSourcePairings.push({
            ogSource: { name: xt.name, sourceId: xt.id },
            newSource: {
              name: foundNewSourceForXt?.node?.name,
              sourceId: foundNewSourceForXt?.node?.id,
            },
          });
        });
      });

      // Log old to new pairings

      setPairings(oldToNewSourcePairings);
      setOldColumns(oldColumns);

      setSourceOptions(
        sourcesAvail?.map((s) => {
          return {
            label: s.node?.name,
            value: s.node?.id,
          };
        })
      );
    }
  }, [availableSources, blueprint, policiesData]);

  const uniquePolicies = blueprint?.sourceProfiles
    .flatMap((profile) => profile.policyAndMappings)
    ?.reduce((acc, policy) => {
      if (!acc.some((p) => p.ruleStandardId === policy.ruleStandardId)) {
        acc.push(policy);
      }
      return acc;
    }, []);

  // Handle checkbox toggle for individual rows
  const handleToggle = (ruleStandardId) => {
    // Reset states when toggling a policy
    setSelectedPolicyMappings((prev) =>
      prev.includes(ruleStandardId)
        ? prev.filter((id) => id !== ruleStandardId)
        : [...prev, ruleStandardId]
    );

    // Reset loading status and other states for the toggled policy
    setLoadingStatus((prev) => ({
      ...prev,
      [ruleStandardId]: undefined, // Reset the loading status for this policy
    }));

    // If restarting after a previous deploy, reset relevant states
    setCurrentPolicyIndex(0);
    setCompletedPolicies([]);
    setFailedPolicies([]);
    isProcessingRef.current = false;
  };

  // Handle Select All toggle
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allIds = mappings
        .filter((m) =>
          notFoundStandards?.find((s) => s?.name !== m?.ruleStandardName)
        )
        .map((policy) => policy.ruleInstanceVersionId);
      setSelectedPolicyMappings(allIds);
    } else {
      setSelectedPolicyMappings([]);
    }
  };
  //TODO
  // Function to kick off the policy creation
  const createPolicy = (policy) => {
    setLoadingStatus((prev) => ({
      ...prev,
      [policy]: "loading",
    }));

    create({
      query: createNewRuleInstance,
      variables: instanceConfigs.find((ic) => ic?.key === policy)?.query,
    });
  };

  // Effect to handle policy creation process
  useEffect(() => {
    if (deploy && !isProcessingRef.current) {
      const policy = selectedPolicyMappings[currentPolicyIndex]
        ? selectedPolicyMappings.find(
            (p) => p === selectedPolicyMappings[currentPolicyIndex]
          )
        : null;

      if (policy) {
        createPolicy(policy); // Begin creating the current policy
        isProcessingRef.current = true; // Set flag to avoid triggering effect again
      }
    }
  }, [deploy, currentPolicyIndex, selectedPolicyMappings, uniquePolicies]);

  // Effect to handle response from the API (either success or failure)
  useEffect(() => {
    if (created || createdErrors) {
      const policy = selectedPolicyMappings[currentPolicyIndex]
        ? selectedPolicyMappings.find(
            (p) => p === selectedPolicyMappings[currentPolicyIndex]
          )
        : null;

      if (created && policy) {
        setCompletedPolicies((prev) => {
          const updatedCompleted = [...prev, policy];
          if (
            updatedCompleted.length + failedPolicies.length ===
            selectedPolicyMappings.length
          ) {
            setDeploy(false); // All policies processed, stop deployment

            toast.success(
              <div>
                <p>Mapping Deployment finished!</p>
                <img
                  src="https://i.giphy.com/media/vnnoqBjIrJ73y/giphy.webp"
                  alt="All Set!"
                  style={{ width: "220px" }} // adjust the width as needed
                />
              </div>
            );
          }
          return updatedCompleted;
        });
        setLoadingStatus((prev) => ({
          ...prev,
          [policy]: "completed",
        }));
        clearFields();
      }

      if (createdErrors && policy) {
        setFailedPolicies((prev) => {
          const updatedFailed = [...prev, policy];
          if (
            completedPolicies.length + updatedFailed.length ===
            selectedPolicyMappings.length
          ) {
            setDeploy(false); // All policies processed, stop deployment
            toast.error(`A Mapping Failed!`);
          }
          return updatedFailed;
        });
        setLoadingStatus((prev) => ({
          ...prev,
          [policy]: {
            status: "failed",
            errors: createdErrors.map((ce) => ce.message),
          },
        }));
        clearFields();
      }

      // Reset processing flag and move to the next policy
      isProcessingRef.current = false;

      if (currentPolicyIndex + 1 < selectedPolicyMappings.length) {
        setCurrentPolicyIndex((prevIndex) => prevIndex + 1);
      }
    }
  }, [created, createdErrors, deploy]);

  // Kickoff the deployment process
  const kickoffPolicyDeployment = () => {
    setCurrentPolicyIndex(0); // Start from the first selected policy
    setCompletedPolicies([]); // Reset completed policies
    setFailedPolicies([]); // Reset failed policies
    isProcessingRef.current = false; // Reset the processing flag
    setDeploy(true); // Trigger the deployment process
  };

  const allSelected =
    mappings.filter((m) =>
      notFoundStandards?.find((s) => s?.name !== m?.ruleStandardName)
    ).length > 0 &&
    selectedPolicyMappings.length ===
      mappings.filter((m) =>
        notFoundStandards?.find((s) => s?.name !== m?.ruleStandardName)
      ).length;

  const isIndeterminate =
    selectedPolicyMappings.length > 0 &&
    selectedPolicyMappings.length < mappings.length;

  const uniqueStandards = blueprint?.sourceProfiles
    ?.flatMap((profile) => profile.policyAndMappings)
    ?.reduce((acc, policy) => {
      if (!acc.some((p) => p.ruleStandardId === policy.ruleStandardId)) {
        acc.push(policy);
      }
      return acc;
    }, []);

  return (
    <>
      {/* Overlay with CircularProgress */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!pairings?.length || loadingSource}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {pairings?.length ? (
        <>
          <h3>PK Pairing Config</h3>
          <Box
            sx={{
              backgroundColor: "#f5f5f5", // Light background to make it stand out
              padding: "16px",
              borderRadius: "8px", // Rounded corners
              border: "1px solid #ddd", // Subtle border
            }}
            mb={2}
          >
            <Typography
              variant="body1"
              sx={{
                fontWeight: "bold", // Bold font for emphasis
                color: "#333", // Darker text color for readability
                fontSize: "1rem", // Adjust font size as needed
              }}
            >
              Ensure if you are not using Exact Source name matching to OG, that
              the COLUMNS match exactly by name.
              <Typography component="span" color="error" fontWeight="bold">
                You've been warned! ☢️
              </Typography>
            </Typography>
          </Box>
          <Grid
            container
            mt={2}
            spacing={2}
            sx={{
              backgroundColor: "#f5f5f5",
              padding: 2,
              borderRadius: 1,
            }}
          >
            {pairings?.map((p, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Box
                  sx={{
                    backgroundColor: "#ffffff",
                    padding: 2,
                    boxShadow: 1,
                    borderRadius: 1,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box mb={2} mt={2}>
                    <strong>Og Mapping:</strong> {p?.ogSource?.name}
                  </Box>
                  <Box>
                    <Autocomplete
                      options={sourceOptions}
                      getOptionLabel={(option) => option?.label || ""}
                      value={
                        sourceOptions.find(
                          (option) => option.value === p?.newSource?.sourceId
                        ) || null
                      }
                      onChange={(event, newValue) =>
                        handleNewSourceChange(index, event, newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`Select Source for ${p.ogSource.name}`}
                          placeholder="Type to search..."
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props}>{option.label}</li>
                      )}
                    />
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </>
      ) : null}

      {notFoundStandards?.length > 0 ? (
        <Grid container spacing={2} mt={2} mb={2}>
          <Grid item xs={12}>
            <Alert severity="warning">
              {notFoundStandards.map((sfs, index) => (
                <Box key={index} mb={1}>
                  <Typography variant="body2">{sfs?.message}</Typography>
                </Box>
              ))}
            </Alert>
          </Grid>
        </Grid>
      ) : null}

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={isIndeterminate}
                  checked={allSelected}
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell>Mapping Instance Name</TableCell>
              <TableCell>OG Source</TableCell>
              <TableCell>Deployment Status</TableCell>
              <TableCell>New Columns</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mappings?.map((mapping) => (
              <TableRow key={mapping.ruleInstanceVersionId}>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedPolicyMappings.includes(
                      mapping.ruleInstanceVersionId
                    )}
                    onChange={() => handleToggle(mapping.ruleInstanceVersionId)}
                    disabled={notFoundStandards?.find(
                      (s) => s?.name === mapping?.ruleStandardName
                    )}
                  />
                </TableCell>
                <TableCell>{mapping.ruleInstanceName}</TableCell>
                <TableCell>{mapping.ogSourceName}</TableCell>
                <TableCell>
                  {loadingStatus[mapping.ruleInstanceVersionId] === "loading" &&
                    "Loading"}
                  {loadingStatus[mapping.ruleInstanceVersionId] ===
                    "completed" && "Success"}
                  {loadingStatus[mapping.ruleInstanceVersionId]?.status ===
                    "failed" && (
                    <div>
                      <p>Errors:</p>
                      <ul>
                        {loadingStatus[
                          mapping.ruleInstanceVersionId
                        ].errors.map((error, index) => (
                          <li key={index}>{error}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </TableCell>
                <TableCell>
                  {instanceConfigs
                    ?.find((ic) => ic.key === mapping?.ruleInstanceVersionId)
                    ?.query?.instance?.versions[0]?.mappings?.map((m) => {
                      return (
                        <Box
                          sx={{
                            display: "inline-flex", // Use flexbox
                            flexDirection: "row", // Align vertically
                            alignItems: "center", // Center items
                            padding: "4px 8px",
                            backgroundColor: "#1976d2", // Change this to your desired color
                            color: "white",
                            borderRadius: "16px",
                            fontSize: "12px",
                            fontWeight: "bold",
                            textAlign: "center",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {" "}
                          <MdPolicy size={24} />
                          {
                            allNewColumns?.find((ac) => ac?.id === m?.columnId)
                              ?.fullyQualifiedName
                          }
                        </Box>
                      );
                    })}
                  {instanceConfigs
                    ?.find((ic) => ic.key === mapping?.ruleInstanceVersionId)
                    ?.query?.instance?.versions[0]?.calculationMappings?.map(
                      (m) => {
                        return (
                          <Box
                            sx={{
                              display: "inline-flex", // Use flexbox
                              flexDirection: "row", // Align vertically
                              alignItems: "center", // Center items
                              padding: "4px 8px",
                              backgroundColor: "#1286a2", // Change this to your desired color
                              color: "white",
                              borderRadius: "16px",
                              fontSize: "12px",
                              fontWeight: "bold",
                              textAlign: "center",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <MdCalculate size={24} />
                            {
                              allNewColumns?.find(
                                (ac) => ac?.id === m?.columnId
                              )?.fullyQualifiedName
                            }
                          </Box>
                        );
                      }
                    )}
                </TableCell>
                <TableCell>
                  <Button
                    onClick={() =>
                      setView(
                        uniqueStandards.find(
                          (p) => p?.ruleStandardId === mapping?.ruleStandardId
                        )
                      )
                    }
                  >
                    View Policy
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Centered Deploy Button */}
      <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
        <Button
          onClick={() => kickoffPolicyDeployment()}
          variant="contained"
          size="large"
          disabled={deploy || !selectedPolicyMappings?.length} // Disable button when loading
        >
          {deploy ? "Deploying..." : "Deploy Selected Mappings"}
        </Button>
      </Box>

      {deploy && (
        <img
          src="https://i.giphy.com/media/JIX9t2j0ZTN9S/giphy.webp"
          alt={"We're working on it!"}
        />
      )}

      {view && (
        <Modal title={view?.ruleStandardName} hide={() => setView()}>
          <Scrollbars autoHeightMax={window.innerHeight * 0.7} autoHeight>
            <ViewStandard standard={view?.policyStandardVersionTemplate} />
          </Scrollbars>
        </Modal>
      )}
    </>
  );
};

const BluePrintDeployment = ({
  blueprint,
  onSubmit,
  setSelectedBlueprint,
  setIsDeploying,
}) => {
  const [mode, setMode] = useState(2);
  return (
    <>
      <button
        onClick={() => {
          setSelectedBlueprint(null);
          setIsDeploying(false);
        }}
      >
        Back
      </button>
      <div>
        <h3>{blueprint?.name}</h3>
        <div>
          <p>Description: {blueprint?.description}</p>
        </div>
        <div>
          Edited Date: {formatTime(new Date(blueprint?.editedDate)) ?? "N/A"}
        </div>
      </div>

      {/* <Button onClick={() => setMode(1)}>Deploy All</Button> */}
      <Box display="flex" justifyContent="center" sx={{ marginBottom: 2 }}>
        <ButtonGroup variant="outlined">
          {/* Use outlined variant for tab-like appearance */}
          <Button
            onClick={() => setMode(2)}
            variant={mode === 2 ? "contained" : "outlined"} // Highlight the active tab
          >
            Deploy Policy Templates
          </Button>
          <Button
            onClick={() => setMode(3)}
            variant={mode === 3 ? "contained" : "outlined"} // Highlight the active tab
          >
            Deploy Mappings
          </Button>
        </ButtonGroup>
      </Box>
      {/* <Button onClick={() => setMode(4)}>Configure Custom Reports</Button> */}
      {/* {mode === 1 && <DeployAll blueprint={blueprint} setMode={setMode} />} */}
      {mode === 2 && <DeployPolicies blueprint={blueprint} setMode={setMode} />}
      {mode === 3 && <DeployMappings blueprint={blueprint} setMode={setMode} />}
      {/* {mode === 4 && (
        <DeployCustomReports blueprint={blueprint} setMode={setMode} />
      )} */}
    </>
  );
};

const BluePrintBuilder = ({
  blueprint,
  onSubmit,
  setSelectedBlueprint,
  setIsDeploying,
}) => {
  const { control, register, handleSubmit, watch, setValue, reset } = useForm({
    defaultValues: {
      sourceProfiles: blueprint?.sourceProfiles ?? [],
    },
  });
  const { user } = useContext(AuthContext);
  const sourceProfiles = watch("sourceProfiles");

  const isEditing = blueprint?.name; // Check if the blueprint is not empty

  useEffect(() => {
    if (isEditing) {
      Object.entries(blueprint)?.forEach(([key, value]) => {
        if (key === "sourceProfiles") {
          setValue("sourceProfiles", value); // Ensure value structure is correct here
        } else {
          setValue(key, value); // Set other fields if needed
        }
      });
    }
  }, [blueprint, setValue, isEditing]);

  const handleFormSubmit = (data) => {
    const updatedData = {
      ...data,
      editedDate: new Date().toISOString(),
      editedBy: user?.emailAddress,
    };

    onSubmit(updatedData); // Pass the data to handleBlueprintSubmit
  };

  const processSourceProfiles = (selectedSources) => {
    setValue("sourceProfiles", selectedSources);
  };

  return (
    <>
      <h3>{isEditing ? "Edit" : "Create"} Blueprint</h3>{" "}
      <button
        onClick={() => {
          setSelectedBlueprint(null);
          setIsDeploying(false);
        }}
      >
        Back
      </button>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        {isEditing ? (
          <Box mb={2} mt={2}>
            <h3>{blueprint?.name}</h3>
          </Box>
        ) : (
          <Box mb={2}>
            <label>Name</label>
            <input {...register("name")} type="text" />
          </Box>
        )}
        <Box mb={2}>
          <label>Description</label>
          <input {...register("description")} type="text" />
        </Box>

        <SourceBuilder
          sourceProfiles={sourceProfiles}
          processSourceProfiles={processSourceProfiles}
        />

        {/* Centered Deploy Button */}
        <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
          <Button type="submit" variant="contained" size="large">
            Save Blueprint
          </Button>
        </Box>
      </form>
    </>
  );
};

const BlueprintTable = ({
  myPatToken,
  onBlueprintLoad,
  onCreateNew,
  setIsDeploying,
}) => {
  const [blueprints, setBlueprints] = useState([]);

  // Remove BluePrint

  const handleRemoveBlueprintClick = (blueprint) => {
    if (
      window.confirm(
        `Are you sure you want to delete the blueprint "${blueprint.name}"?`
      )
    ) {
      removeBlueprintFromGit(blueprint.path);
    }
  };

  // Function to fetch the latest commit ID
  const fetchLatestCommitId = async () => {
    const refUrl = `https://dev.azure.com/basecapanalytics/Testing%20Tools/_apis/git/repositories/Testing%20Tools/refs?filter=heads/master&api-version=6.0`;

    const response = await fetch(refUrl, {
      method: "GET",
      headers: {
        Authorization: `Basic ${btoa(`:${myPatToken}`)}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(
        `Failed to fetch latest commit ID: ${response.statusText}`
      );
    }

    const data = await response.json();
    const latestCommitId = data.value[0]?.objectId;
    return latestCommitId;
  };

  const removeBlueprintFromGit = async (blueprintPath) => {
    try {
      const latestCommitId = await fetchLatestCommitId();

      const changes = [
        {
          changeType: "delete",
          item: { path: blueprintPath },
        },
      ];

      const requestBody = {
        refUpdates: [
          { name: "refs/heads/master", oldObjectId: latestCommitId },
        ],
        commits: [{ comment: `Deleted blueprint: ${blueprintPath}`, changes }],
      };

      const gitCommitURL = `https://dev.azure.com/basecapanalytics/Testing%20Tools/_apis/git/repositories/Testing%20Tools/pushes?api-version=6.0`;

      const response = await fetch(gitCommitURL, {
        method: "POST",
        headers: {
          Authorization: `Basic ${btoa(`:${myPatToken}`)}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`Failed to delete blueprint: ${response.statusText}`);
      }

      toast.success(
        <div>
          <p>Blueprint {blueprintPath} deleted successfully!</p>
          <img
            src="https://i.giphy.com/media/vnnoqBjIrJ73y/giphy.webp"
            alt="All Set!"
            style={{ width: "220px" }} // adjust the width as needed
          />
        </div>
      );

      fetchBlueprints(); // Refresh the list after deletion
    } catch (error) {
      console.error("Error deleting blueprint:", error);
      toast.error("Failed to delete the blueprint.");
    }
  };

  const fetchBlueprints = useCallback(() => {
    const listBlueprintsURL =
      "https://dev.azure.com/basecapanalytics/Testing%20Tools/_apis/git/repositories/Testing%20Tools/items?scopePath=%2Fblueprints&recursionLevel=full&versionDescriptor.version=master&api-version=6.0";

    fetch(listBlueprintsURL, {
      method: "GET",
      withCredentials: true,
      crossDomain: true,
      headers: {
        Authorization: "Basic " + btoa(":" + myPatToken),
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        const blueprintFiles = data.value
          .filter((file) => file.path.endsWith(".json"))
          .map((file) => ({
            name: file.path.split("/").pop(),
            path: file.path,
          }));
        setBlueprints(blueprintFiles);
      })
      .catch((error) => {
        console.error("Error fetching blueprint files:", error);
      });
  }, [myPatToken]);

  useEffect(() => {
    fetchBlueprints();
  }, [fetchBlueprints]);

  const handleBlueprintClick = (blueprintPath) => {
    const blueprintURL = `https://dev.azure.com/basecapanalytics/Testing%20Tools/_apis/git/repositories/Testing%20Tools/items?path=${encodeURIComponent(
      blueprintPath
    )}&api-version=6.0`;

    fetch(blueprintURL, {
      method: "GET",
      headers: {
        Authorization: `Basic ${btoa(`:${myPatToken}`)}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        onBlueprintLoad(data);
      })
      .catch((error) => {
        console.error("Error loading blueprint JSON:", error);
      });
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={onCreateNew}>
        Create New Blueprint
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Blueprint Name</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {blueprints.map((blueprint) => (
              <TableRow key={blueprint.name}>
                <TableCell>{blueprint.name}</TableCell>
                <TableCell>
                  <Box
                    sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}
                  >
                    <IconButton
                      title="Stage Deployment"
                      onClick={() => {
                        handleBlueprintClick(blueprint.path);
                        setIsDeploying(true);
                      }}
                      sx={{ fontSize: 32 }}
                    >
                      <MdOutlineRocketLaunch />
                    </IconButton>
                    <IconButton
                      title=""
                      onClick={() => handleBlueprintClick(blueprint.path)}
                      sx={{ fontSize: 32 }}
                    >
                      <MdEditDocument />
                    </IconButton>
                    <IconButton
                      title="Remove Blueprint"
                      onClick={() => handleRemoveBlueprintClick(blueprint)}
                      sx={{ fontSize: 32, color: "red" }}
                    >
                      🗑️
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const ImportForm = ({ myPatToken }) => {
  const [selectedBlueprint, setSelectedBlueprint] = useState(null);
  const [isDeploying, setIsDeploying] = useState();

  const handleBlueprintLoaded = (blueprintData) => {
    setSelectedBlueprint(blueprintData);
  };

  const handleCreateNewBlueprint = () => {
    setSelectedBlueprint({
      name: "",
    }); // Reset the form for a new blueprint
  };
  // Function to fetch the latest commit ID
  const fetchLatestCommitId = async () => {
    const refUrl = `https://dev.azure.com/basecapanalytics/Testing%20Tools/_apis/git/repositories/Testing%20Tools/refs?filter=heads/master&api-version=6.0`;

    const response = await fetch(refUrl, {
      method: "GET",
      headers: {
        Authorization: `Basic ${btoa(`:${myPatToken}`)}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(
        `Failed to fetch latest commit ID: ${response.statusText}`
      );
    }

    const data = await response.json();
    const latestCommitId = data.value[0]?.objectId;
    return latestCommitId;
  };

  // Function to check if a file exists
  const checkIfFileExists = async (filePath) => {
    const fileUrl = `https://dev.azure.com/basecapanalytics/Testing%20Tools/_apis/git/repositories/Testing%20Tools/items?path=${encodeURIComponent(
      filePath
    )}&api-version=6.0`;

    const response = await fetch(fileUrl, {
      method: "GET",
      headers: {
        Authorization: `Basic ${btoa(`:${myPatToken}`)}`,
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      return true;
    } else if (response.status === 404) {
      return false;
    } else {
      throw new Error(`Error checking if file exists: ${response.statusText}`);
    }
  };
  const handleBlueprintSubmit = async (data) => {
    try {
      const latestCommitId = await fetchLatestCommitId();

      // Determine if we're editing or adding a new blueprint
      const isEditing = selectedBlueprint?.name;

      // Set the file path based on whether we're editing or creating new
      const filePath = isEditing
        ? `/blueprints/${selectedBlueprint.name}.json`
        : data.name
        ? `/blueprints/${data.name}.json`
        : "/blueprints/unnamed.json";

      const fileContent = JSON.stringify(data, null, 2);

      let changes = [];

      if (isEditing) {
        // Perform an edit operation
        changes.push({
          changeType: "edit",
          item: { path: filePath },
          newContent: { content: fileContent, contentType: "rawtext" },
        });
      } else {
        // Adding a new blueprint

        const fileExists = await checkIfFileExists(filePath);
        if (fileExists) {
          throw new Error(`File ${filePath} already exists.`);
        }

        changes.push({
          changeType: "add",
          item: { path: filePath },
          newContent: { content: fileContent, contentType: "rawtext" },
        });
      }

      const commitMessage = isEditing
        ? `Updated blueprint: ${selectedBlueprint.name}`
        : `Created new blueprint: ${data.name}`;

      // Prepare the request body for the Git commit
      const requestBody = {
        refUpdates: [
          { name: "refs/heads/master", oldObjectId: latestCommitId },
        ],
        commits: [
          {
            comment: commitMessage,
            changes: changes,
          },
        ],
      };

      const gitCommitURL = `https://dev.azure.com/basecapanalytics/Testing%20Tools/_apis/git/repositories/Testing%20Tools/pushes?api-version=6.0`;

      // Send the request to commit the blueprint
      const response = await fetch(gitCommitURL, {
        method: "POST",
        headers: {
          Authorization: `Basic ${btoa(`:${myPatToken}`)}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `HTTP error! status: ${response.status} - ${errorText}`
        );
      }

      toast.success(
        `Blueprint "${isEditing ? selectedBlueprint.name : data.name}" ${
          isEditing ? "updated" : "created"
        } and committed!`
      );
      setSelectedBlueprint(null);
    } catch (error) {
      toast.error("Error saving blueprint.");
    }
  };

  return (
    <div>
      {selectedBlueprint !== null ? (
        isDeploying ? (
          <BluePrintDeployment
            blueprint={selectedBlueprint}
            onSubmit={handleBlueprintSubmit}
            setSelectedBlueprint={setSelectedBlueprint}
            setIsDeploying={setIsDeploying}
          />
        ) : (
          <BluePrintBuilder
            blueprint={selectedBlueprint}
            onSubmit={handleBlueprintSubmit}
            setSelectedBlueprint={setSelectedBlueprint}
            setIsDeploying={setIsDeploying}
          />
        )
      ) : (
        <BlueprintTable
          myPatToken={myPatToken}
          onBlueprintLoad={handleBlueprintLoaded}
          onCreateNew={handleCreateNewBlueprint}
          setIsDeploying={setIsDeploying}
        />
      )}
    </div>
  );
};

const BPManager = () => {
  return <AccessPoint />;
};

export default BPManager;
