//   Creates or Updates the Workspace
export const updateWorkspace = /* GraphQL */ `
  mutation ($workSpaceModel: WorkspaceInputModelInput!) {
    updateWorkspace(workSpaceModel: $workSpaceModel) {
      id
      name
      description
      configuration
      permissions {
        accessingWorkGroupId
        permissionCode
      }
      ownerId
      makeFav
      enabled
    }
  }
`;

// Updates the Workspace Enabled
export const updateWorkspaceEnabled = /* GraphQL */ `
  mutation ($workSpaceId: Int!, $enabled: Boolean!) {
    updateWorkspaceEnabled(workSpaceId: $workSpaceId, enabled: $enabled) {
      id
    }
  }
`;

// Updates the Workspace Favorite
export const updateWorkspaceFavorite = /* GraphQL */ `
  mutation ($workSpaceId: Int!, $favorite: Boolean!) {
    updateWorkspaceFavorite(workSpaceId: $workSpaceId, favorite: $favorite) {
      id
    }
  }
`;
