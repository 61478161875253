import React from "react";
import { useApi } from "../../../api/useApi";
import { reportHistory, basicInfo } from "../../../api/dataSourceQueries";
import PagedTable from "../../../components/Table/PagedTable";
import { useParams } from "react-router-dom";
import Card from "../../../components/Card";
import StyledLink from "../../../components/StyledLink";
import { dataSourceReport } from "../../../common/paths";
import SplashLoader from "../../../components/Loaders/SplashLoader";
import { formatTime } from "../../../common/formats";
import ActionWrapper from "../../../components/ActionsWrapper";
import { FaDatabase } from "react-icons/fa";
import ErrorMessages from "../../../components/Notifications/ErrorMessages";
import { failureEnums } from "../../../common/failureEnums";

const Reports = () => {
  let params = useParams();
  const sourceId = Number(params?.sourceId);

  //Available Users Count
  const [{ loading: detailsLoading, data: details }] = useApi(basicInfo, {
    id: sourceId,
  });

  const dataSourceName = details?.dataSource?.name;

  //Body Section of Widget
  function Body() {
    const { href: url } = window.location;
    // eslint-disable-next-line
    const [subDomain] = /:\/\/([^\/]+)/.exec(url)?.[1]?.split(".") ?? [""];
    let highlightP2Diff = false;
    if (["localhost:5000", "test", "test2", "test3", "test4", "staging", "performance"].includes(subDomain.toLowerCase())) {
      highlightP2Diff = true;
    }
    const columnsData = [
      {
        Header: "Date",
        id: "createdOn",
        accessor: (d) => d?.node?.createdOn,
        Cell: ({ row: { original } }) => {
          return (
            <StyledLink
              to={dataSourceReport(
                original?.node?.dataSourceId,
                original?.node?.id
              )}
            >
              {formatTime(new Date(original?.node?.createdOn))}
            </StyledLink>
          );
        },
      },
      {
        Header: "Quality Score",
        id: "qualityScore",
        Cell: ({ row: { original } }) => {
          // Score = (1 - (failure count / opportunities)) * 100

          const totalRulesFailed = original?.node?.metadataMetrics.find(
            (metric) => metric.metricType === "TOTAL_RULES_FAILED"
          )?.metricValue;

          const totalRows = original?.node?.metadataMetrics.find(
            (metric) => metric.metricType === "ROWS_MEASURED"
          )?.metricValue;

          const ruleInstancesRun = original?.node?.metadataMetrics.find(
            (metric) => metric.metricType === "RULE_INSTANCES_RUN"
          )?.metricValue;

          // Opportunities for failure = number of rows * number of rules
          const totalOpportunities = ruleInstancesRun * totalRows;

          let score = 0;
          if (totalOpportunities === 0) {
            score = 100;
          } else {
            score = Math.floor(
              (1 - totalRulesFailed / totalOpportunities) * 100
            );
          }

          return isNaN(score) ? "" : score;
        },
      },
      {
        Header: "Batch Name",
        id: "batchName",
        accessor: (d) => d.node.batchName,
      },
      {
        Header: "Rows Measured",
        id: "rowsmeasured",
        accessor: (d) =>
          d?.node?.metadataMetrics.find(
            (metric) => metric.metricType === "ROWS_MEASURED"
          )?.metricValue,
      },
      {
        Header: "Failure Opportunities",
        id: "failureOpportunities",
        accessor: (d) => {
          const rowsMeasured = d?.node?.metadataMetrics.find(
            (metric) => metric.metricType === "ROWS_MEASURED"
          )?.metricValue;
          const instancesRun = d?.node?.metadataMetrics.find(
            (metric) => metric.metricType === "RULE_INSTANCES_RUN"
          )?.metricValue;

          const opps = rowsMeasured * instancesRun;
          return isNaN(opps) ? "" : opps;
        }
      },
      {
        Header: "Total Failures",
        id: "totalFailures",
        accessor: (d) =>
          d?.node?.metadataMetrics.find(
            (metric) => metric.metricType === "TOTAL_RULES_FAILED"
          )?.metricValue,
      },
      {
        Header: "Report State",
        id: "reportState",
        accessor: (d) => {
          let rState = failureEnums.find(
            (resultState) => resultState.value.toLowerCase() === d?.node?.resultState.toLowerCase()
          )?.label
          if (highlightP2Diff && d?.node?.initializingProcess?.toLowerCase().includes("platform2")) {
            rState = `${rState} (p2)`;
          }
          // don't need to check highlight for debug states as these are properly filtered before the UI
          if (d?.node?.initializingProcess?.toLowerCase().includes("data_quality_debug")) {
            rState = `${rState} {DQ_DEBUG}`;
          }
          if (d?.node?.initializingProcess?.toLowerCase().includes("performance_debug")) {
            rState = `${rState} {PERF_DEBUG}`;
          }
          return rState;
        }
      },
    ];

    //Init Data Fetch
    const [{ errors, loading, data: apiData }, doFetch] = useApi();

    const reports = apiData?.dataSource?.reportHistory?.edges ?? [];
    const totalCount = apiData?.dataSource?.reportHistory?.totalCount;
    const pageInfo = apiData?.dataSource?.reportHistory?.pageInfo;
    //Fetch for Table Paged
    const fetchData = React.useCallback(
      ({ pageSize, cursor }) => {
        doFetch({
          query: reportHistory,
          variables: {
            first: pageSize,
            after: cursor,
            id: sourceId,
          },
        });
      },
      [doFetch]
    );

    if (errors) return <ErrorMessages errors={errors} />;
    //If We're loading Count, Don't render table yet,
    // don't render table if we don't have a count
    if (detailsLoading) return <SplashLoader text="Loading Results" />;

    return (
      <>
        <PagedTable
          fetchData={fetchData}
          pageInfo={pageInfo}
          totalCount={totalCount}
          loading={loading}
          data={reports}
          columns={columnsData}
        />
      </>
    );
  }

  //Actions Section of Widget
  const Actions = () => {
    return (
      <>
        <ActionWrapper>
          <StyledLink to={`/sources/${sourceId}`} title="Latest Report">
            <FaDatabase />
          </StyledLink>
        </ActionWrapper>
      </>
    );
  };

  return (
    <Card
      title={dataSourceName}
      titleDescription={"Report History"}
      body={Body}
      actions={Actions}
    />
  );
};

export default Reports;
