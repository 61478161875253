import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components/macro";
import Button from "../../../components/Button";
import {
  customReportDataSourcesByRuleIds,
  customReportDataSourcesByPolicyGroup,
  customReportById,
} from "../../../api/dataSourceQueries";
import { businessRuleInstancesAttachedToSource } from "../../../api/ruleQueries";
import {
  createCustomReport,
  updateCustomReport,
} from "../../../api/dataSourceMutations";
import {
  FormControl,
  StyledInput,
  StyledSelect,
  Label,
} from "../../../components/Form/FormControls";
import StyledLink from "../../../components/StyledLink";
import { MdClose } from "react-icons/md";
import ActionWrapper from "../../../components/ActionsWrapper";
import { useApi } from "../../../api/useApi";
import SortTable from "../../../components/Table/SortTable";
import { useParams } from "react-router-dom";
import ErrorMessages from "../../../components/Notifications/ErrorMessages";
import SplashLoader from "../../../components/Loaders/SplashLoader";
import Spinner from "../../../components/Loaders/Spinner";
import { useHistory } from "react-router-dom";
import { approvalStates } from "../../../common/formOptions";
const TableHeader = styled.div`
  display: flex;
  align-items: center;
`;
const TableHeaderActions = styled.div`
  flex: 1;
  display: flex;
`;

const TableHeaderActionItems = styled.div`
  margin-left: auto;
`;

const SelectedPolicies = ({
  policies,
  setSelectedPolicies,
  selectedPolicies,
}) => {
  const updateLocally = useCallback(
    (node) => {
      const foundInternally = selectedPolicies.find(
        (ds) => ds === node?.latestVersion?.id
      );

      if (foundInternally) {
        setSelectedPolicies((prevState) => {
          return prevState.filter((s) => s !== node?.latestVersion?.id);
        });
      } else {
        setSelectedPolicies((prevState) => [
          ...prevState,
          node?.latestVersion?.id,
        ]);
      }
    },
    [setSelectedPolicies, selectedPolicies]
  );

  const columnsData = [
    {
      Header: "Name",
      id: "Name",
      width: 220,
      accessor: "name",
      Cell: ({ row: { original } }) => {
        const foundInternally = selectedPolicies.find(
          (ds) => ds === original?.latestVersion?.id
        );
        const isSelected = foundInternally;

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              type="checkbox"
              name={`datasources.${original?.latestVersion?.id}`}
              label="Use Data Source in Cross-table"
              checked={isSelected}
              onChange={() => updateLocally(original)}
            />
            <div style={{ marginLeft: "1rem" }}>{original?.title}</div>
          </div>
        );
      },
    },
    {
      Header: "Approval State",
      id: "approvalState",
      width: 220,
      accessor: "approvalState",
      Cell: ({ row: { original } }) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginLeft: "1rem" }}>
              {
                approvalStates.find(
                  (apprv) => apprv.value === original?.approvalState
                )?.label
              }
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <h4>Select Policies</h4>
      <SortTable data={policies} columns={columnsData} />
    </div>
  );
};

const ColumnsByDataSource = ({
  sourceId,
  policyOptions,
  policies,
  setColumns,
  setCurrentStep,
  currentColumns,
  selectedPolicies,
}) => {
  const [selectedColumns, setSelectedColumns] = useState(currentColumns ?? []);
  const [selectAllColumnsEnable, setSelectAllColumnsEnable] = useState([]);
  // policyOptions = 0 : All
  // policyOptions = 1 : Only High
  // policyOptions = 2 : Other - All Except High
  // policyOptions = 3 : Specific

  let filteredPolicies = [];

  if (policyOptions === "ALL") {
    filteredPolicies = policies.map((sp) => sp?.latestVersion?.id);
  } else if (policyOptions === "ALL_HIGH_PRIORITY") {
    filteredPolicies = policies
      .filter((sp) => sp?.priority === "HIGH")
      .map((sp) => sp?.latestVersion?.id);
  } else if (policyOptions === "ALL_OTHER_PRIORITY") {
    filteredPolicies = policies
      .filter((sp) => sp?.priority !== "HIGH")
      .map((sp) => sp?.latestVersion?.id);
  } else if (policyOptions === "USER_SPECIFIED") {
    filteredPolicies = selectedPolicies;
  }

  const [{ loading, data }] = useApi(
    policyOptions === "USER_SPECIFIED"
      ? customReportDataSourcesByRuleIds
      : customReportDataSourcesByPolicyGroup,
    policyOptions === "USER_SPECIFIED"
      ? {
          dataSourceId: sourceId,
          instanceVersionIds: filteredPolicies,
        }
      : {
          dataSourceId: sourceId,
          policyGroup: policyOptions,
        }
  );

  const dataSourceColumns =
    policyOptions === "USER_SPECIFIED"
      ? data?.customReportDataSourcesByRuleIds
      : data?.customReportDataSourcesByPolicyGroup;

  useEffect(() => {
    if (dataSourceColumns) {
      setColumns((prevState) => {
        let cleanColumns = [];

        dataSourceColumns.forEach((ds) => {
          const columns = prevState.filter((c) => c.dataSourceId === ds?.id);

          cleanColumns.push(...columns);
        });

        return cleanColumns;
      });
      setSelectedColumns((prevState) => {
        let cleanColumns = [];

        dataSourceColumns.forEach((ds) => {
          const columns = prevState.filter((c) => c.dataSourceId === ds?.id);

          cleanColumns.push(...columns);
        });

        return cleanColumns;
      });
    }
  }, [dataSourceColumns, setColumns, setSelectedColumns]);

  const updateLocally = useCallback(
    (node) => {
      const foundInternally = selectedColumns.find((ds) => ds?.id === node?.id);

      if (foundInternally) {
        setSelectedColumns((prevState) => {
          return prevState.filter((s) => s?.id !== node?.id);
        });
      } else {
        setSelectedColumns((prevState) => [...prevState, node]);
      }
    },
    [setSelectedColumns, selectedColumns]
  );

  const selectAllColumns = (columns, sourceId) => {
    const alreadyEnabled = selectAllColumnsEnable.find((id) => id === sourceId)
      ? true
      : false;

    if (alreadyEnabled) {
      setSelectAllColumnsEnable(
        selectAllColumnsEnable.filter((ds) => ds !== sourceId)
      );

      const cleanedColumns = selectedColumns?.filter(
        (c) => c?.dataSourceId !== sourceId
      );
      setSelectedColumns(cleanedColumns);
    } else {
      setSelectedColumns((prevState) => [...prevState, ...columns]);
      setSelectAllColumnsEnable([...selectAllColumnsEnable, sourceId]);
    }
  };

  const columnsData = [
    {
      Header: "Name",
      id: "Name",
      width: 220,
      accessor: "name",
      Cell: ({ row: { original } }) => {
        const foundInternally = selectedColumns.find(
          (ds) => ds?.id === original?.id
        );
        const isSelected = foundInternally;

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              type="checkbox"
              name={`columns.${original?.id}`}
              label="Use Data Source in Cross-table"
              checked={isSelected}
              onChange={() => updateLocally(original)}
            />
            <div style={{ marginLeft: "1rem" }}>{original?.name}</div>
          </div>
        );
      },
    },
  ];

  if (loading) return <div>Loading</div>;

  return (
    <div>
      {dataSourceColumns?.map((dsc) => {
        const sid = dsc?.id;
        const columns = dsc?.columns;

        const isFound = selectAllColumnsEnable.find((id) => id === sid);
        return (
          <div>
            <h4>{dsc?.name}</h4>
            <Button
              onClick={() => {
                selectAllColumns(columns, sid);
              }}
            >
              {isFound ? "Deselect All" : "Select All"}
            </Button>
            <SortTable data={dsc?.columns ?? []} columns={columnsData} />
          </div>
        );
      })}
      <div style={{ position: "absolute", right: "2rem", bottom: "2rem" }}>
        <Button
          onClick={() => {
            setColumns(selectedColumns);
            setCurrentStep((prev) => prev + 1);
          }}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

const GatherData = ({ sourceId, edit }) => {
  const [{ errors, loading, data }] = useApi(
    businessRuleInstancesAttachedToSource,
    {
      first: 9999, // (4352) hacky fix - TODO: implement proper pagination w/ case-insensitive matching
      sourceId: sourceId,
      enabledStates: "PUBLISHED",
      order: {
        title: "ASC",
      },
    }
  );

  const [
    {
      loading: customReportLoading,
      data: customReportData,
      errors: customReportErrors,
    },
    doFetch,
  ] = useApi();

  let params = useParams();

  useEffect(() => {
    if (edit && params?.customReportId) {
      doFetch({
        query: customReportById,
        variables: { reportId: Number(params?.customReportId) },
      });
    }
  }, [edit, doFetch, params]);

  const policies =
    data?.businessRuleInstancesAttachedToSource?.edges?.map((instance) => {
      return instance?.node;
    }) ?? [];

  if (customReportLoading || loading) {
    return <SplashLoader text="Loading Data" />;
  }
  const customReportDataParse = customReportData?.customReportById;
  return (
    <>
      {customReportErrors ? (
        <ErrorMessages errors={customReportErrors} />
      ) : null}
      {errors ? <ErrorMessages errors={errors} /> : null}

      <NewReportForm
        sourceId={sourceId}
        policies={policies}
        edit={edit}
        customReportData={customReportDataParse}
      />
    </>
  );
};

const NewReportForm = ({ sourceId, policies, edit, customReportData }) => {
  const [currentStep, setCurrentStep] = useState(0);

  const [policyOptions, setPolicyOptions] = useState(
    customReportData?.policyGroup ?? "ALL"
  );
  const [exportEnabled, setExportEnabled] = useState(
    customReportData?.exportReport ?? false
  );
  const [selectedPolicies, setSelectedPolicies] = useState(
    customReportData?.instanceVersions?.filter((iv) => iv?.instance?.enabledState === "PUBLISHED")
       .map((iv) => iv?.id) ?? []
  );

  const [columns, setColumns] = useState(customReportData?.columns ?? []);
  const [reportType, setReportType] = useState(customReportData?.type ?? "ALL");
  const [reportName, setReportName] = useState(customReportData?.name ?? "");
  let history = useHistory();
  const hasHigh = policies.filter((f) => f?.priority === "HIGH");
  const hasNonHigh = policies.filter((f) => f?.priority !== "HIGH");

  let stepName = "Select Report Type";
  if (currentStep === 0) {
    stepName = "Report Type";
  } else if (currentStep === 1) {
    stepName = "Select Columns";
  } else if (currentStep === 2) {
    stepName = "Select Report Type";
  }

  let PolicyOptionsArr = [
    {
      label: "All Policies",
      value: "ALL",
      isDisabled: false,
    },
    {
      label: "Specific Policies",
      value: "USER_SPECIFIED",
      isDisabled: false,
    },
  ];

  const [
    { loading: submitLoading, errors: submitErrors, data: submitData },
    doSubmit,
  ] = useApi();

  useEffect(() => {
    if (submitData && !submitErrors?.length) {
      history.push(`/sources/${sourceId}/manage/reporting`);
    }
  }, [submitData, submitErrors, history, sourceId]);

  const submit = () => {
    let uniqueObjArray = [
      ...new Map(columns.map((item) => [item["id"], item])).values(),
    ];

    if (customReportData) {
      doSubmit({
        query: updateCustomReport,
        variables: {
          input: {
            exportEnabled: exportEnabled,
            columnIds: uniqueObjArray?.map((c) => c?.id),
            instanceVersionIds:
              policyOptions === "USER_SPECIFIED" ? selectedPolicies : null,
            name: reportName,
            policyGroup: policyOptions,
            primaryDataSourceId: sourceId,
            reportType: reportType,
          },
          reportId: customReportData?.id,
        },
      });
    } else {
      doSubmit({
        query: createCustomReport,
        variables: {
          input: {
            exportEnabled: exportEnabled,
            columnIds: uniqueObjArray?.map((c) => c?.id),
            instanceVersionIds:
              policyOptions === "USER_SPECIFIED" ? selectedPolicies : null,
            name: reportName,
            policyGroup: policyOptions,
            primaryDataSourceId: sourceId,
            reportType: reportType,
          },
        },
      });
    }
  };

  let ReportTypes = [
    { label: "All", value: "ALL" },
    { label: "All Failures", value: "ALL_FAILURES" },
    { label: "All Successes", value: "ALL_SUCCESSES" },
    { label: "Current Data CSV", value: "CLONE_DATA_SOURCE" },
    { label: "Incorrect Value Failures", value: "INCORRECT_VALUE_FAILURE" },
    { label: "Missing Value Failures", value: "NULL_FAILURE" },
    { label: "No Matching Row Failures", value: "NO_MATCHING_ROW" },
  ];

  if (currentStep === 2) {
    const { href: url } = window.location;
    // eslint-disable-next-line
    const [subDomain] = /:\/\/([^\/]+)/.exec(url)?.[1]?.split(".") ?? [""];
    if (
      ["localhost:5000", "naf", "test", "test2", "test3", "test4", "staging", "performance"].includes(
        subDomain.toLowerCase()
      ) &&
      policyOptions === "ALL"
    ) {
      ReportTypes.push({
        label: "High Priority Failures",
        value: "HIGH_PRIORITY_FAILURES",
        isDisabled: hasHigh?.length ? false : true,
      });
      ReportTypes.push({
        label: "Other Priority Failures",
        value: "OTHER_PRIORITY_FAILURES",
        isDisabled: hasNonHigh?.length ? false : true,
      });
    }

    const selectedReportType = ReportTypes.find((p) => p.value === reportType);
    if (!selectedReportType) {
      setReportType("ALL");
    }
  }

  return (
    <div>
      <TableHeader>
        <h3>Reporting - {edit ? "Edit" : "New"}</h3>
        <TableHeaderActions>
          <TableHeaderActionItems>
            <ActionWrapper>
              <StyledLink
                to={`/sources/${sourceId}/manage/reporting`}
                title="Cancel Create New"
              >
                <MdClose />
              </StyledLink>
            </ActionWrapper>
          </TableHeaderActionItems>
        </TableHeaderActions>
      </TableHeader>

      <div>Step {currentStep + 1} of 3</div>
      <div style={{ fontWeight: "bold", marginTop: "1rem" }}>{stepName}</div>

      <div style={{ marginBottom: "1rem", marginTop: "1rem" }}>
        {currentStep === 0 ? (
          <>
            <FormControl>
              <StyledInput
                type="text"
                name="name"
                label="Name"
                value={reportName}
                placeholder={`Name`}
                onChange={(e) => setReportName(e.target.value)}
              />
            </FormControl>

            <FormControl>
              <Label>Select Report Policies</Label>
              <StyledSelect
                className={`react-select-container`}
                classNamePrefix={`react-select`}
                name={`reportPolicy`}
                id={`reportPolicy`}
                inputId={`reportPolicy-input`}
                instanceId={`reportPolicy-instance`}
                label="Select Policies"
                options={PolicyOptionsArr}
                placeholder={`Select Policies`}
                value={PolicyOptionsArr.find((p) => p.value === policyOptions)}
                menuPortalTarget={document.body}
                menuPlacement="auto"
                onChange={(e) => setPolicyOptions(e?.value)}
              />
            </FormControl>
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <FormControl>
                <label style={{ cursor: "pointer" }}>
                  <input
                    style={{ cursor: "pointer" }}
                    type="checkbox"
                    name={`Enabled Export`}
                    label="Enabled Export"
                    checked={exportEnabled}
                    onChange={() => setExportEnabled((prev) => !prev)}
                  />
                  Enable Export
                </label>
              </FormControl>
              <div style={{ marginLeft: "1rem" }}>
                (
                <StyledLink to={`/sources/${sourceId}/manage/configuration`}>
                  Manage Export Path
                </StyledLink>
                )
              </div>
            </div>
          </>
        ) : null}

        {policyOptions === "USER_SPECIFIED" && currentStep === 0 ? (
          <SelectedPolicies
            policies={policies}
            selectedPolicies={selectedPolicies}
            setSelectedPolicies={setSelectedPolicies}
          />
        ) : null}
        {currentStep === 2 ? (
          <FormControl>
            <Label>Report Type</Label>
            <StyledSelect
              className={`react-select-container`}
              classNamePrefix={`react-select`}
              name={`reportType`}
              id={`reportType`}
              inputId={`reportType-input`}
              instanceId={`reportType-instance`}
              label="Report Type"
              options={ReportTypes}
              placeholder={`Select Type`}
              value={ReportTypes.find((p) => p.value === reportType)}
              menuPortalTarget={document.body}
              menuPlacement="auto"
              onChange={(e) => setReportType(e?.value)}
            />
          </FormControl>
        ) : null}
        {currentStep === 1 ? (
          <ColumnsByDataSource
            sourceId={sourceId}
            policyOptions={policyOptions}
            policies={policies}
            setColumns={setColumns}
            setCurrentStep={setCurrentStep}
            currentColumns={columns}
            selectedPolicies={selectedPolicies}
          />
        ) : null}
      </div>

      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>
          {currentStep !== 0 ? (
            <Button
              onClick={() => setCurrentStep((prev) => (prev ? prev - 1 : 0))}
            >
              Back
            </Button>
          ) : null}
        </div>
        <div>
          {currentStep !== 2 && currentStep !== 1 ? (
            <Button onClick={() => setCurrentStep((prev) => prev + 1)}>
              Next
            </Button>
          ) : null}
          {currentStep === 2 ? (
            <Button onClick={() => submit()}>
              {submitLoading ? <Spinner /> : "Complete"}
            </Button>
          ) : null}
        </div>
      </div>

      {submitErrors ? <ErrorMessages errors={submitErrors} /> : null}
    </div>
  );
};

export default GatherData;
