const fragments_fragment = /* GraphQL */ `
    fragments {
      executionOrder
      id
      standardVersionId
      typeInformation{
        fragmentId
        fragmentValue
        typeHierarchyLevel
        typeValue
      }
    }
  `;

// // List of Rule Standards
export const standardsList = /* GraphQL */ `
  query (
    $first: Int
    $after: String
    $tagFilter: CustomTagFilterInput!
    $standardType: BusinessRuleStandardTypes
    $where: BusinessRuleStandardFilterInput
    $order: [BusinessRuleStandardSortInput!]
    $customSort: [CustomSortInputModelInput!]
  ) {
    availableBusinessRuleStandards(
      first: $first
      after: $after
      tagFilter: $tagFilter
      standardType: $standardType
      where: $where
      order: $order
      customSort: $customSort
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          name
          description
          enabled
          instances {
            enabledState
            id
            title
            dataSource {
              enabled
              id
              name
            }
          }
          tagInstances {
            id
            remoteObjectId
            tag {
              name
              description
              id
            }
            tagId
            type
          }
        }
      }
    }
  }
`;

// List of Rule Standards
export const simpleStandardsList = /* GraphQL */ `
  query (
    $first: Int
    $after: String
    $standardType: BusinessRuleStandardTypes
    $where: BusinessRuleStandardFilterInput
    $order: [BusinessRuleStandardSortInput!]
    $customSort: [CustomSortInputModelInput!]
  ) {
    availableBusinessRuleStandards(
      first: $first
      after: $after
      standardType: $standardType
      where: $where
      order: $order
      customSort: $customSort
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          name
          description
          enabled
        }
      }
    }
  }
`;

// Get Available Rule Standards
export const availableBusinessRuleStandards = /* GraphQL */ `
  query($ruleType: BusinessRuleStandardTypes!, $cursor: CursorInput!) {
      availableBusinessRuleStandards(ruleType: $ruleType, cursor: $cursor) {
        id
        name
        description
        versions {
          id
          createdOn
          ${fragments_fragment}
        }
      }
  }
`;

// Get Available Rule standards for edit

export const businessRuleStandard = /* GraphQL */ `
  query($standardId: Int!) {
      businessRuleStandardById(standardId: $standardId) {
        id
        name
        description
        tagInstances {
        id
        remoteObjectId
        tag {
          name
          description
          id
        }
        tagId
        type
      }
        permissions {
          accessingWorkGroupId
          grantedOn
          grantingUserId
          permissionCode
        }
        versions {
          createdOn
          id
          ${fragments_fragment}
          standard {
                enabled
                id
                name
                versions {
                  id
                  createdOn
                }
              }
        }
      }
  }
`;

// Get Available Rule standards for edit

export const businessRuleStandardTags = /* GraphQL */ `
  query ($standardId: Int!) {
    businessRuleStandardById(standardId: $standardId) {
      id
      enabled
      tagInstances {
        id
        remoteObjectId
        tag {
          name
          description
          id
        }
        tagId
        type
      }
    }
  }
`;

// Get Available Rule standards for by Id

export const businessRuleStandardById = /* GraphQL */ `
  query ($standardId: Int!) {
    businessRuleStandardById(standardId: $standardId) {
      id
      name
      description
      instances {
        id
        title
        enabledState
        latestVersion {
          id
          standardVersionId
          standardVersion {
            standard {
              id
              latestVersion {
                id
              }
            }
          }
        }
        dataSource {
          name
          id
          friendlyName
          enabled
          latestReport {
            refreshSummaryId
            timestamp
            qualityScore {
              score
            }
            ruleFailures {
              failures {
                failureCount
                successCount
                failedRuleStandardId
                failedRuleInstanceId
              }
            }
          }
        }
      }
    }
  }
`;

// Test Business Rule Instance
export const testBusinessRuleInstance = /* GraphQL */ `
  query (
    $inputInstance: BusinessRuleInstanceInputModelInput!
    $inputStandard: BusinessRuleStandardInputModelInput!
    $strDataRow: String!
    $strPrevRow: String!
  ) {
    testBusinessRuleInstance(
      inputInstance: $inputInstance
      inputStandard: $inputStandard
      strDataRow: $strDataRow
      strPrevRow: $strPrevRow
    ) {
      failedConditional
      failureCode
      failureFragmentMappingId
      failureRuleInstanceVersionId
    }
  }
`;

export const getRuleInstanceVersionsFromList = /* GraphQL */ `
  query ($ids: LongListInputModelInput!) {
    ruleInstanceVersionsFromList(inputList: $ids) {
      id
      standardVersionId
      instanceId
      createdOn
      mappings {
        id
        businessRuleFragmentId
        columnId
        businessRuleFragment {
          id
          executionOrder
        }
      }
      calculationMappings {
        id
        businessRuleFragmentId
        columnId
        businessRuleFragment {
          id
          executionOrder
        }
      }
    }
  }
`;

// abused query to get all mapped instances

export const allMappedInstances = /* GraphQL */ `
  query (
    $first: Int
    $after: String
    $where: BusinessRuleStandardFilterInput!
    $order: [BusinessRuleStandardSortInput!]
    $customSort: [CustomSortInputModelInput!]
  ) {
    availableBusinessRuleStandards(
      first: $first
      after: $after
      where: $where
      order: $order
      customSort: $customSort
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          name
          description
          enabled
          tagInstances {
            id
            remoteObjectId
            tag {
              name
              description
              id
            }
            tagId
            type
          }
          latestVersion {
            id
          }
          instances {
            enabledState
            approvalState
            id
            title
            latestVersion {
              id
              standardVersionId
            }
            dataSource {
              enabled
              id
              name
              friendlyName
              latestReport {
                refreshSummaryId
                timestamp
                qualityScore {
                  score
                }
                refreshSummary {
                  createdOn
                  batchName
                }
                columnProfiles {
                  profileResults {
                    columnId
                    value
                    valueSubType
                    valueType
                  }
                }
                failureDetails {
                  dataSourceMetrics {
                    totalFailuresCount
                    totalSuccessfulRowsCount
                    totalHighPriorityFailuresCount
                    totalOtherPriorityFailuresCount
                  }
                  columnEntries {
                    columnId
                    details {
                      failureCount
                      percentFailureCount
                      ruleStandardInstancedName
                      standardColumnName
                    }
                    failureCount
                    failureCountChange
                    name
                    previouslyTopOrder
                  }
                  ruleStandardEntries {
                    details {
                      columnName
                      failureCount
                      percentFailureCount
                      standardColumnName
                    }
                    failureCount
                    failureCountChange
                    name
                    previouslyTopOrder
                    rulesStandardId
                  }
                }
                ruleFailures {
                  failures {
                    failedRuleInstanceId
                    failedRuleInstanceVersionId
                    failedRuleStandardId
                    failedRuleInstanceName
                    failedRuleStandardName
                    failedRuleStandardVersionId
                    successCount
                    failureCount
                    failurePercentage
                    incorrectValueFailureCount
                    incorrectValueFailurePercentage
                    noMatchingRowFailureCount
                    noMatchingRowFailurePercentage
                    nullFailureCount
                    nullValueFailurePercentage
                    successCount
                    failedBusinessRuleInstancePriority
                    errorCount
                    errorPercentage
                  }
                  sourceName
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const allMappedInstancesForBP = /* GraphQL */ `
  query (
    $first: Int
    $after: String
    $where: BusinessRuleStandardFilterInput!
    $order: [BusinessRuleStandardSortInput!]
    $customSort: [CustomSortInputModelInput!]
  ) {
    availableBusinessRuleStandards(
      first: $first
      after: $after
      where: $where
      order: $order
      customSort: $customSort
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          name
          description
          enabled
          versions {
            id
            ${fragments_fragment}
          }
          instances {
            id
            versions {
              id
              mappings {
                column {
                dataSourceId
                fullyQualifiedName
                dataType
                enabled
                id
                name
                flags
                ordinal
              }
                columnId
                businessRuleFragmentId
                businessRuleFragment {
                executionOrder
                id
                standardVersion {
                  id
                  standardId
                }
              }
              }
              calculationMappings {
                column {
                dataSourceId
                fullyQualifiedName
                dataType
                enabled
                id
                name
                flags
                ordinal
              }
                order
                argument
                columnId
                businessRuleFragmentId
                businessRuleFragment {
                executionOrder
                id
                standardVersion {
                  id
                  standardId
                }
              }
              }
            }
          }
        }
      }
    }
  }
`;

// businessRuleInstancesAttachedToSource
// GetBusinessRuleInstancesAttachedToSource

export const businessRuleInstancesAttachedToSource = /* GraphQL */ `
  query (
    $sourceId: Int!
    $enabledStates: [BusinessRuleInstanceState!]!
    $first: Int
    $after: String
  ) {
    businessRuleInstancesAttachedToSource(
      sourceId: $sourceId
      enabledStates: $enabledStates
      first: $first
      after: $after
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          dataSourceId
          id
          standardId
          priority
          approvalState
          dataSourceId
          enabledState
          id
          latestVersion {
            id
            standardVersion {
              id
              standard {
                id
                enabled
                latestVersion {
                  id
                }
              }
            }
            standardVersionId
          }
          title
        }
      }
    }
  }
`;

export const businessRuleInstanceVersionsAttachedToSource = /* GraphQL */ `
  query ($sourceId: Int!, $enabledStates: [BusinessRuleInstanceState!]!) {
    businessRuleInstanceVersionsAttachedToSource(
      sourceId: $sourceId
      enabledStates: $enabledStates
    ) {
      sourceId
      versions {
        id
        standardVersionId
        instanceId
        createdOn
        mappings {
                column {
                dataSourceId
                fullyQualifiedName
                dataType
                enabled
                id
                name
                flags
                ordinal
              }
                columnId
                businessRuleFragmentId
                businessRuleFragment {
                executionOrder
                id
                standardVersion {
                  id
                  standardId
                }
              }
              }
              calculationMappings {
                column {
                dataSourceId
                fullyQualifiedName
                dataType
                enabled
                id
                name
                flags
                ordinal
              }
                order
                argument
                columnId
                businessRuleFragmentId
                businessRuleFragment {
                executionOrder
                id
                standardVersion {
                  id
                  standardId
                }
              }
              }
        standardVersion {
          id
          standard {
            id
            name
            description
            enabled
            latestVersion {
              id
              ${fragments_fragment}
            }
          }
        }
        instance {
          dataSourceId
          id
          standardId
          priority
          approvalState
          dataSourceId
          enabledState
          id
          title
        }
      }
    }
  }
`;

// Get feature flag for RLR

export const rlrFeatureEnabled = /* GraphQL */ `
  query () {
    rlrFeatureEnabled()
  }
`;
