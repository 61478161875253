import React, {
  useEffect,
  useState,
  useMemo,
  useReducer,
  useCallback,
} from "react";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import SortTable from "../../components/Table/SortTable";
import debounce from "lodash.debounce";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import {
  StyledInput,
  FormControl,
  StyledTextArea,
  FormActions,
} from "../../components/Form/FormControls";
import Fragment from "../../components/Forms/Rules/Fragment";
import styled from "styled-components/macro";
import Conditional from "../../components/Forms/Rules/Conditional";
import update from "immutability-helper";
import TableButton from "../../components/Button/TableButton";
import {
  MdOutlineCircle,
  MdCheckCircleOutline,
  MdCheckCircle,
  MdOutlineWarning,
} from "react-icons/md";
import Spinner from "../../components/Loaders/Spinner";
import Permissions from "../../components/Form/PermissionsWithoutFormik";
import { useApi } from "../../api/useApi";
import { createTag } from "../../api/tagMutations";
import cloneDeep from "lodash.clonedeep";
import { createRuleStandard } from "../../api/ruleMutations";
import SelectSources from "./SelectSources";
import { setTagInstances } from "../../api/tagMutations";
import { tagsList } from "../../api/tagQueries";
import useTagUpdateComplete from "../../Hooks/useTagUpdateComplete";
import { ToastContainer, toast } from "react-toastify";
import { Scrollbars } from "react-custom-scrollbars";
import { simpleStandardsList } from "../../api/ruleQueries";

const NewPackNameForm = ({ close, save }) => {
  const [inputValue, setInputValue] = useState("");
  const [tagNameValue, setTagNameValue] = useState("");
  const [tagDescriptionValue, setTagDescriptionValue] = useState("");

  return (
    <>
      <p>Name And Tag Your Policy Pack</p>
      <FormControl>
        <StyledInput
          type="text"
          name="value"
          value={inputValue}
          placeholder={"Policy Pack Name"}
          onChange={(e) => setInputValue(e?.target?.value)}
        />
      </FormControl>

      <FormControl>
        <StyledInput
          type="text"
          name="value"
          value={tagNameValue}
          placeholder={"Tag Name"}
          onChange={(e) => setTagNameValue(e?.target?.value)}
        />
      </FormControl>

      <FormControl>
        <StyledInput
          type="text"
          name="value"
          value={tagDescriptionValue}
          placeholder={"Tag Description"}
          onChange={(e) => setTagDescriptionValue(e?.target?.value)}
        />
      </FormControl>
      <div>
        <Button
          type="button"
          list="true"
          danger
          onClick={() => {
            save((prev) => {
              let newSave = { ...prev };
              newSave.rulePacks.push({
                name: inputValue,
                rules: [],
                tagName: tagNameValue,
                tagDescription: tagDescriptionValue,
              });

              return newSave;
            });
            close(null);
          }}
        >
          Stage New Pack
        </Button>
        <Button
          type="button"
          onClick={() => {
            close(null);
          }}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

const PolicyRow = styled.div`
  background: #eee;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const ToggleControl = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;
const ToggleAction = styled.div`
  margin-left: auto;
  cursor: pointer;
  color: ${(props) => props.theme.onSecondarySurface};
`;
const PolicyStatus = styled.div`
  margin-left: auto;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
`;

const PolicyStatusNotSelect = styled.div`
  font-size: 1.5rem;
`;
const PolicyStatusSelect = styled.div`
  font-size: 1.5rem;
  color: green;
`;

const PolicyStatusError = styled.div`
  font-size: 1.5rem;
  color: red;
`;

const PolicyStatusComplete = styled.div`
  font-size: 1.5rem;
  color: green;
`;

const exportPack = (pack) => {
  const standardPkg = JSON.stringify({
    name: pack.name,
    rules: pack.rules,
    tagName: pack.tagName,
    tagDescription: pack.tagDescription,
  });
  navigator.clipboard.writeText(standardPkg).then(
    function () {
      toast.success("Pack JSON Copied for Custom File Development", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },
    function (err) {
      toast.error("Failed To Copy", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  );
};

const DeployPolicyPack = ({ policyPack, setShowDeploy }) => {
  const [{ errors: createTagErrors, data: tagData }, createTagApi] = useApi();

  const [{ errors: createdErrors, data: created }, create, clearFields] =
    useApi();

  const [{ data: updateTagsData }, setTags, clearSourceTagFields] = useApi();

  const [{ data: currentTagData }, getTag] = useApi();

  const { tagUpdateComplete, setTagUpdateComplete } = useTagUpdateComplete();

  useEffect(() => {
    if (tagUpdateComplete) {
      const payload = tagUpdateComplete?.payload;
      const matchLastRequest =
        updateTagsData?.setTagInstances === payload?.RequestId;
      if (matchLastRequest) {
        dispatch({
          type: "HANDLE_SAVE_SOURCE",
          payload: {
            createdErrors: payload?.ErrorMessage,
          },
        });
        clearSourceTagFields();
      }
    }
  }, [
    tagUpdateComplete,
    setTagUpdateComplete,
    updateTagsData,
    clearSourceTagFields,
    // remoteObjectId,
    // query,
    // idKey,
    // fetchLatest,
  ]);

  const reducer = (state, action) => {
    switch (action.type) {
      //First State called when a query is passed to the component.
      default:
        return {
          ...state,
        };

      case "SET_TAG": {
        const tagId = action.payload.tagId;

        return {
          ...state,
          tagId: tagId,
        };
      }

      case "HANDLE_SAVE_SOURCE": {
        const createdErrors = action.payload?.createdErrors;

        const moreToProcess =
          state?.sourceProcessing < state?.selectedSources?.length - 1;
        const done =
          state?.selectedSources.length === state?.completedSources?.length + 1;

        return {
          ...state,
          doneSource: done ?? false,
          sourceProcessing:
            moreToProcess && !done ? state?.sourceProcessing + 1 : null,
          completedSources: [
            ...state?.completedSources,
            state.sourceProcessing,
          ],
          failedToApplyTag: createdErrors
            ? [
                ...state?.failedToApplyTag,
                { sourceIndex: state.sourceProcessing, errors: createdErrors },
              ]
            : [],
        };
      }

      case "SAVE_SOURCE": {
        const sourceIndex = action.payload.sourceIndex;
        const selectedSource = state?.selectedSources[sourceIndex];

        setTimeout(
          () => {
            setTags({
              query: setTagInstances,
              variables: {
                remoteObjectId: Number(selectedSource?.id),
                tagIds: [
                  ...(selectedSource?.tagInstances?.map((t) => t?.tagId) ?? []),
                  state?.tagId,
                ],
                type: "DATA_SOURCE",
              },
            });
          },
          state?.completedSources?.length ? 1 : 5000
        );

        return {
          ...state,
        };
      }

      case "APPLY_TO_SOURCES": {
        const sources = action.payload.selectedSources;

        return {
          ...state,
          stage: 4,
          sourceProcessing: 0,
          selectedSources: sources ?? [],
        };
      }

      case "BACK_FROM_SOURCES": {
        return {
          ...state,
          stage: 2,
        };
      }

      case "CONTINUE_TO_SOURCES": {
        return {
          ...state,
          stage: 3,
        };
      }
      case "TOGGLE_ALL": {
        const allSelected =
          state?.selectedPolicies?.length === policyPack?.rules?.length;
        return {
          ...state,
          selectedPolicies: allSelected
            ? []
            : [
                ...policyPack?.rules?.map((_, i) => {
                  return i;
                }),
              ],
        };
      }

      case "TOGGLE_POLICY": {
        const policyIndex = action.payload.policyIndex;
        const isSelected = state?.selectedPolicies.includes(policyIndex);

        let clone = [...state?.selectedPolicies].filter(
          (pv) => pv !== policyIndex
        );

        return {
          ...state,
          selectedPolicies: isSelected
            ? clone
            : [...state?.selectedPolicies, policyIndex],
        };
      }

      case "START_DEPLOY_POLICIES": {
        return {
          ...state,
          stage: 2,
          policyProcessing: 0,
        };
      }

      case "CREATE_NEXT": {
        return {
          ...state,
        };
      }

      case "RESET": {
        return {
          ...state,
          stage: 0,
          policyProcessing: null,
          selectedPolicies: [
            ...policyPack?.rules?.map((_, i) => {
              return i;
            }),
          ],
          completedPolicies: [],
          failedToImport: [],
          permissions: [],
          done: false,
        };
      }

      case "DEPLOY": {
        //Create Tag
        createTagApi({
          query: createTag,
          variables: {
            tag: {
              description: policyPack?.tagDescription,
              name: policyPack?.tagName,
            },
          },
        });

        return {
          ...state,
          stage: 1,
        };
      }

      case "HANDLE_SAVE": {
        const createdErrors = action.payload?.createdErrors;

        const moreToProcess =
          state?.policyProcessing < state?.selectedPolicies?.length - 1;
        const done =
          state?.selectedPolicies.length ===
          state?.completedPolicies?.length + 1;

        return {
          ...state,
          done: done ?? false,
          policyProcessing:
            moreToProcess && !done ? state?.policyProcessing + 1 : null,
          completedPolicies: [
            ...state?.completedPolicies,
            state.policyProcessing,
          ],
          failedToImport: createdErrors
            ? [
                ...state?.failedToImport,
                { policyIndex: state.policyProcessing, errors: createdErrors },
              ]
            : [...state?.failedToImport],
          stage: done ? 0 : state?.stage,
        };
      }

      // This save function exists in the normal save for other policies, will need updates to stay in sync currently
      case "SAVE_POLICY": {
        const policyIndex = action.payload.policyIndex;
        // NEeds to be SELECTED policies, NOT THE WHOLE PACK

        const policyToProcessIndex = state?.selectedPolicies[policyIndex];

        const foundRuleToSave = policyPack?.rules?.[policyToProcessIndex];

        const form = cloneDeep(foundRuleToSave);
        let ruleFragments = [];

        const cloneCards = [...form?.standard];

        cloneCards.forEach((card, i) => {
          if (card.def === "fragment") {
            if (card.source) {
              const source = card.source;
              source.typeInformation = source.typeInformation.map((st, i) => {
                return {
                  fragmentValue: st.fragmentValue,
                  typeHierarchyLevel: st.typeHierarchyLevel,
                  typeValue: st.typeValue.label
                    ? st.typeValue.value
                    : st.typeValue,
                };
              });
              ruleFragments.push(source);
            }

            if (card.operation) {
              const operation = card.operation;
              operation.typeInformation = operation.typeInformation.map(
                (st, i) => {
                  return {
                    fragmentValue: st.fragmentValue,
                    typeHierarchyLevel: st.typeHierarchyLevel,
                    typeValue: st.typeValue.label
                      ? st.typeValue.value
                      : st.typeValue,
                  };
                }
              );

              ruleFragments.push(operation);
            }
            //Handle the case in which operation is set to IsEmpty, No Target should be sent or loaded
            if (card.target) {
              const target = card.target;

              target.typeInformation = target.typeInformation.map((st, i) => {
                return {
                  typeHierarchyLevel: st.typeHierarchyLevel,
                  typeValue: st.typeValue.label
                    ? st.typeValue.value
                    : st.typeValue,
                  fragmentValue:
                    st.fragmentValue && st.fragmentValue.label
                      ? st.fragmentValue.value.toString()
                      : st.fragmentValue !== null
                      ? st.fragmentValue.toString()
                      : st.fragmentValue,
                };
              });

              ruleFragments.push(target);
            }
          } else {
            const ruleFragment = {
              typeInformation: card.typeInformation.map((information) => {
                return {
                  fragmentValue: information.fragmentValue,
                  typeHierarchyLevel: information.typeHierarchyLevel,
                  typeValue: information.typeValue,
                };
              }),
            };

            ruleFragments.push(ruleFragment);
          }
        });

        const orderedFragments = ruleFragments.map((frag, i) => {
          return { executionOrder: i, ...frag };
        });

        const cleanOrderedFragments = orderedFragments.map((f) => {
          return {
            executionOrder: f.executionOrder,
            typeInformation: f.typeInformation,
          };
        });

        const variables = {
          newStandard: {
            name: form.name,
            standardType: "RULE",
            description: form.description,
            versions: [{ fragments: cleanOrderedFragments }],
            permissions: state?.permissions,
            tagInstances: tagData?.createTag?.id
              ? [{ tagId: tagData?.createTag?.id, type: "POLICY" }]
              : null,
          },
        };

        create({ query: createRuleStandard, variables });

        return {
          ...state,
        };
      }

      case "SET_PERMISSIONS": {
        return {
          ...state,
          permissions: action.payload,
        };
      }
    }
  };
  // Intial reducer state
  const initialState = {
    stage: 0,
    policyProcessing: null,
    selectedPolicies: [
      ...policyPack?.rules?.map((_, i) => {
        return i;
      }),
    ],
    completedPolicies: [],
    failedToImport: [],
    permissions: [],
    done: false,
    selectedSources: [],
    sourceProcessing: null,
    completedSources: [],
    failedToApplyTag: [],
    doneSource: false,
  };
  //initialize the reducer state
  const [state, dispatch] = useReducer(reducer, initialState);

  //Stage 0 = Configuration
  //Stage 1 = Create Tag
  //Stage 2 = Loop Through Creating Rules

  const togglePolicy = (policyIndex) => {
    dispatch({
      type: "TOGGLE_POLICY",
      payload: {
        policyIndex,
      },
    });
  };

  const toggleAll = () => {
    dispatch({
      type: "TOGGLE_ALL",
    });
  };

  useEffect(() => {
    if (
      state.stage === 2 &&
      state.policyProcessing !== null &&
      state.policyProcessing !== undefined
    ) {
      dispatch({
        type: "SAVE_POLICY",
        payload: {
          policyIndex: state.policyProcessing,
        },
      });
    }
  }, [state.stage, state.policyProcessing]);

  useEffect(() => {
    if (state.stage === 3) {
      getTag({
        query: tagsList,
        variables: {
          where: {
            name: { eq: policyPack?.tagName },
          },
        },
      });
    }
  }, [state.stage, getTag, policyPack?.tagName]);

  useEffect(() => {
    if (currentTagData?.allTags?.[0]?.id) {
      dispatch({
        type: "SET_TAG",
        payload: {
          tagId: currentTagData?.allTags?.[0]?.id,
        },
      });
    }
  }, [currentTagData]);

  useEffect(() => {
    if (
      state.stage === 4 &&
      state.sourceProcessing !== null &&
      state.sourceProcessing !== undefined
    ) {
      dispatch({
        type: "SAVE_SOURCE",
        payload: {
          sourceIndex: state.sourceProcessing,
        },
      });
    }
  }, [state.stage, state.sourceProcessing]);

  //handle saved rule
  useEffect(() => {
    if (created || createdErrors) {
      dispatch({
        type: "HANDLE_SAVE",
        payload: {
          created,
          createdErrors,
        },
      });

      clearFields();
    }
  }, [created, createdErrors, clearFields]);

  useEffect(() => {
    //Get Tag Created
    if (tagData || createTagErrors) {
      dispatch({ type: "START_DEPLOY_POLICIES" });
    }
  }, [tagData, createTagErrors, dispatch]);

  const done = state?.done;

  if (state?.stage === 4) {
    if (state?.doneSource) {
      return (
        <div>
          <h2>Your All Set!</h2>
          <p>
            Take it from here. Go check out your sources and see how we did!
          </p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              src="https://i.giphy.com/media/vnnoqBjIrJ73y/giphy.webp"
              alt={"All Set!"}
            />
          </div>
        </div>
      );
    }
    return (
      <div>
        <h2>Applying Pack to Sources</h2>
        <p>
          We're working on getting your Policy Pack Applied to all of your
          selected Sources now. Hang tight.
        </p>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src="https://i.giphy.com/media/JIX9t2j0ZTN9S/giphy.webp"
            alt={"We're working on it!"}
          />
        </div>
      </div>
    );
  }

  if (state?.stage === 3) {
    return (
      <div>
        <h4>Source Selection</h4>
        <p>
          Already have some sources in mind for where you would like this pack
          applied? Select them below for the Policies to be applied after Pack
          Deployment.
        </p>
        <SelectSources dispatch={dispatch} />
      </div>
    );
  }

  return (
    <>
      <Scrollbars
        hideTracksWhenNotNeeded={true}
        autoHeightMax={window.innerHeight * 0.7}
        autoHeight
      >
        <div style={{ paddingRight: "1rem" }}>
          <div style={{ marginBottom: "1rem" }}>
            <h4>Overview</h4>

            <p>
              Deploying this Policy Pack will attempt to create and add the
              following Tag if it does not exist. It will also create Policies
              that are selected with the Tag applied and defined Permission
              below. If Policies already exist within the platform, you will see
              red Icon errors for Policies we were not able to add.
            </p>
          </div>

          <div style={{ marginBottom: "1rem" }}>
            <h4>Tag</h4>
            <div>
              <b>Name:</b> {policyPack?.tagName}
            </div>
            <div>
              <b>Description:</b> {policyPack?.tagDescription}
            </div>
          </div>

          <h4>Policies</h4>
          <p>
            Toggle the selection individually for each Policy by clicking on
            them below. You can also toggle all by clicking the text link
            "Toggle All".
          </p>
          <ToggleControl>
            <ToggleAction
              onClick={() => (state?.stage === 0 ? toggleAll() : null)}
            >
              Toggle All
            </ToggleAction>
          </ToggleControl>

          {policyPack?.rules?.map((rule, i) => {
            const isProcessing = state?.policyProcessing === i;
            const isSelected = state?.selectedPolicies.includes(i);
            const isComplete = state?.completedPolicies.includes(i);
            const hasError = state?.failedToImport.some(
              (failedPolicy) => failedPolicy.policyIndex === i
            );

            const findError = state?.failedToImport.find(
              (fp) => fp.policyIndex === i
            );

            return (
              <PolicyRow onClick={() => togglePolicy(i)}>
                {rule?.name}
                <PolicyStatus>
                  {isSelected ? (
                    isProcessing ? (
                      <PolicyStatusComplete>
                        <Spinner />
                      </PolicyStatusComplete>
                    ) : hasError ? (
                      <PolicyStatusError
                        title={
                          findError?.errors[0]?.message ?? "Error During Import"
                        }
                      >
                        <MdOutlineWarning />
                      </PolicyStatusError>
                    ) : isComplete ? (
                      <PolicyStatusComplete>
                        <MdCheckCircle />
                      </PolicyStatusComplete>
                    ) : (
                      <PolicyStatusSelect>
                        <MdCheckCircleOutline />
                      </PolicyStatusSelect>
                    )
                  ) : (
                    <PolicyStatusNotSelect>
                      <MdOutlineCircle />
                    </PolicyStatusNotSelect>
                  )}
                </PolicyStatus>
              </PolicyRow>
            );
          })}
          <div style={{ marginTop: "1rem" }}>
            <h4>Permissions</h4>
            <Permissions dispatch={dispatch} permissions={state?.permissions} />
          </div>
        </div>
      </Scrollbars>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginTop: "1rem",
        }}
      >
        <div style={{ justifyContent: "center", display: "flex" }}>
          <Button
            list
            danger
            onClick={() => setShowDeploy(false)}
            type="button"
          >
            Close
          </Button>
          <Button
            danger
            list
            onClick={() => dispatch({ type: "RESET" })}
            type="button"
            disabled={state?.stage !== 0}
          >
            Reset
          </Button>

          <Button
            onClick={() => dispatch({ type: "DEPLOY" })}
            type="button"
            disabled={
              !state?.permissions?.length ||
              !state?.selectedPolicies?.length ||
              state?.stage !== 0 ||
              state?.done
            }
          >
            Deploy
          </Button>
        </div>
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            marginTop: "1rem",
          }}
        >
          {done &&
            "Deployment Has Completed. Please Review your results above. If you would like to apply this pack to multiple sources now, please click 'Continue to Sources' below."}
        </div>
        {done && (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              marginTop: "1rem",
            }}
          >
            <Button
              onClick={() => dispatch({ type: "CONTINUE_TO_SOURCES" })}
              type="button"
              disabled={!state?.done}
            >
              Continue to Sources
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

const RulePacks = ({
  data,
  setViewPack,
  setImportValues,
  resetFile,
  existingPolicies,
  updateExistingPolicies,
}) => {
  const [showNewPack, setShowNewPack] = useState(false);
  const [showDeploy, setShowDeploy] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const [currentPacks, setNewPacks] = useState(data);
  const tableState = currentPacks?.rulePacks ?? [];

  useEffect(() => {
    if (!showDeploy) {
      updateExistingPolicies();
    }
  }, [showDeploy, updateExistingPolicies]);

  useEffect(() => {
    setNewPacks(data);
  }, [data]);

  const columnsData = [
    {
      Header: "Policy Pack Name",
      id: "name",
      accessor: (d) => d.name,

      Cell: ({ row: { original } }) => {
        return (
          <div>
            <div
              style={{ cursor: "pointer", color: "blue" }}
              onClick={() => setViewPack(original?.name)}
            >
              {original?.name}
            </div>
          </div>
        );
      },
    },
    {
      Header: "Policies",
      id: "policies",
      accessor: (d) => d.rules.length,
      Cell: ({ row: { original } }) => {
        return <div>{original?.rules?.length}</div>;
      },
    },
    {
      Header: "Policies Not Deployed",
      id: "policiesNotDeployed",
      Cell: ({ row: { original } }) => {
        let notFound = [];
        original?.rules.forEach((r) => {
          const findRule = existingPolicies.find((ep) => ep?.name === r?.name);
          if (!findRule) {
            notFound.push(r.name);
          }
        });
        return <div>{notFound?.length}</div>;
      },
    },
    //existingPolicies
    {
      Header: "Tag",
      id: "tag",
      accessor: (d) => d.tagName,
    },
    {
      Header: "",
      id: "actions",
      Cell: ({ row: { original } }) => {
        return (
          <>
            <TableButton
              list
              onClick={() => exportPack(original)}
              type="button"
            >
              Copy
            </TableButton>
            <TableButton onClick={() => setShowDeploy(original)}>
              Deploy
            </TableButton>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Button list type="button" onClick={() => setShowNewPack(true)}>
        Create New Pack
      </Button>
      <Button type="button" danger onClick={() => setShowReset(true)}>
        Reset Pack Data
      </Button>

      {showReset ? (
        <Modal
          title={`Confirm Reset Pack Data`}
          hide={() => setShowReset(null)}
        >
          <p>
            If you were making edits or using a custom JSON file this will reset
            the data to the current commit.
          </p>
          <Button list danger type="button" onClick={() => setShowReset(false)}>
            Cancel
          </Button>
          <Button
            type="button"
            onClick={() => {
              resetFile();
              setShowReset(false);
            }}
          >
            Reset Pack Data
          </Button>
        </Modal>
      ) : null}

      {showNewPack ? (
        <Modal
          title={`Create New Policy Pack`}
          hide={() => setShowNewPack(null)}
        >
          <NewPackNameForm close={setShowNewPack} save={setImportValues} />
        </Modal>
      ) : null}

      {showDeploy ? (
        <Modal
          title={`Deploy ${showDeploy?.name} Policy Pack`}
          hide={() => setShowDeploy(null)}
        >
          <DeployPolicyPack
            setShowDeploy={setShowDeploy}
            policyPack={showDeploy}
          />
        </Modal>
      ) : null}

      <SortTable
        key={tableState}
        data={tableState}
        columns={columnsData}
        defaultPageSize={50}
        hidePagination={true}
      />
    </>
  );
};

const RenderTable = ({ tableData, columnsData }) => {
  return <SortTable data={tableData} columns={columnsData} />;
};

const ViewPack = ({
  data,
  viewPack,
  setViewPack,
  setViewPolicy,
  setImportValues,
  existingPolicies,
}) => {
  let rules = data?.rulePacks.find((rp) => rp?.name === viewPack)?.rules ?? [];
  let pack = data?.rulePacks.find((rp) => rp?.name === viewPack);

  const [showImport, setShowImport] = useState(false);

  const [packName, setPackName] = useState(pack?.name ?? "");
  const [packTagName, setPackTagName] = useState(pack?.tagName ?? "");
  const [packTagDescription, setPackTagDescription] = useState(
    pack?.tagDescription ?? ""
  );

  const importRule = React.useCallback(
    (rule) => {
      setImportValues((prev) => {
        const ruleName = rule?.name;

        let clonePrev = { ...prev };

        //Find Pack Parent Name
        const currentIndexPack = clonePrev.rulePacks
          .map((e) => e.name)
          .indexOf(viewPack);

        //Check if it already Exists:
        const alreadyExists = clonePrev.rulePacks[currentIndexPack].rules.find(
          (r) => r.name === ruleName
        );
        if (alreadyExists) {
          toast.error(`${ruleName} Already exists in this pack.`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          clonePrev.rulePacks = update(clonePrev.rulePacks, {
            [currentIndexPack]: {
              rules: { $push: [rule] },
            },
          });
        }

        return clonePrev;
      });
    },
    [viewPack, setImportValues]
  );

  const stagePackDetails = () => {
    setImportValues((prev) => {
      let clonePrev = { ...prev };

      //Find Pack Parent Name
      const currentIndexPack = clonePrev.rulePacks
        .map((e) => e.name)
        .indexOf(viewPack);

      clonePrev.rulePacks = update(clonePrev.rulePacks, {
        [currentIndexPack]: {
          name: { $set: packName },
          tagName: { $set: packTagName },
          tagDescription: { $set: packTagDescription },
          rules: {
            $set: [...(clonePrev.rulePacks[currentIndexPack]?.rules ?? [])],
          },
        },
      });

      return clonePrev;
    });
    toast.success(`${packName} Details have been staged. Commit to Save.`, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    setViewPack();
  };

  const stageDelete = () => {
    setImportValues((prev) => {
      let clonePrev = { ...prev };

      //Find Pack Parent Name
      const currentIndexPack = clonePrev.rulePacks
        .map((e) => e.name)
        .indexOf(viewPack);

      clonePrev = update(clonePrev, {
        rulePacks: {
          $splice: [[currentIndexPack, 1]],
        },
      });

      return clonePrev;
    });
    toast.success(`${packName} has been removed and staged. Commit to Save.`, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    setViewPack();
  };

  const stageDeletePolicy = (policyName) => {
    setImportValues((prev) => {
      let clonePrev = { ...prev };

      //Find Pack Parent Name
      const currentIndexPack = clonePrev.rulePacks
        .map((e) => e.name)
        .indexOf(viewPack);

      //Find Rule Name
      const currentIndexRule = clonePrev.rulePacks[currentIndexPack].rules
        .map((e) => e.name)
        .indexOf(policyName);

      clonePrev = update(clonePrev, {
        rulePacks: {
          [currentIndexPack]: { rules: { $splice: [[currentIndexRule, 1]] } },
        },
      });

      return clonePrev;
    });
    toast.success(
      `${policyName} has been removed and staged. Commit to Save.`,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const exportRule = (policy) => {
    const standardPkg = JSON.stringify({
      standard: policy.standard,
      name: policy.name,
      description: policy.description,
    });
    navigator.clipboard.writeText(standardPkg).then(
      function () {
        toast.success("Policy JSON Copied for Import", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },
      function (err) {
        toast.error("Failed To Copy", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    );
  };

  const columnsData = [
    {
      Header: "Policy Name",
      id: "name",
      accessor: (d) => d.name,

      Cell: ({ row: { original } }) => {
        return (
          <div>
            <div
              onClick={() => setViewPolicy({ policy: original, pack: pack })}
              style={{ cursor: "pointer", color: "blue" }}
            >
              {original?.name}
            </div>
          </div>
        );
      },
    },
    {
      Header: "Description",
      id: "description",
      accessor: (d) => d.description,
      Cell: ({ row: { original } }) => {
        return <div>{original?.description}</div>;
      },
    },
    {
      Header: "Deploy Status",
      id: "deploymentStatus",
      accessor: (d) => d.description,
      Cell: ({ row: { original } }) => {
        const deployed = existingPolicies.find(
          (ep) => ep?.name === original?.name
        );

        return (
          <div>
            {deployed ? (
              <PolicyStatusComplete title="Already Deployed">
                <MdCheckCircle />
              </PolicyStatusComplete>
            ) : (
              <PolicyStatusNotSelect title="Not Deployed">
                <MdOutlineCircle />
              </PolicyStatusNotSelect>
            )}
          </div>
        );
      },
    },
    {
      Header: "",
      id: "actions",
      Cell: ({ row: { original } }) => {
        return (
          <div style={{ display: "flex", justifyContent: "right" }}>
            <TableButton
              danger
              list
              onClick={() => {
                stageDeletePolicy(original?.name);
              }}
              title={"This removes the policy from the PACK not the platform."}
            >
              Remove
            </TableButton>

            <TableButton type="button" onClick={() => exportRule(original)}>
              {`Copy`}
            </TableButton>
          </div>
        );
      },
    },
  ];

  return (
    <>
      {showImport ? (
        <Modal
          title={"Import Policy"}
          hide={() => setShowImport((prevState) => !prevState)}
        >
          <ImportContainer
            setCurrentRule={importRule}
            setShowImport={setShowImport}
          />
        </Modal>
      ) : null}

      <Button list type="button" onClick={() => setViewPack(null)}>
        Back
      </Button>

      <Button
        list
        type="button"
        onClick={() => setShowImport((prevState) => !prevState)}
      >
        Import Policy
      </Button>

      <Button list danger type="button" onClick={() => stageDelete()}>
        Delete Pack
      </Button>

      <div style={{ marginTop: "1rem" }}>
        <FormControl>
          <StyledInput
            type="text"
            name="Pack Name"
            label="Pack Name"
            value={packName}
            placeholder={`Enter A Policy Pack Name`}
            onChange={(e) => setPackName(e?.target?.value)}
          />
        </FormControl>

        <FormControl>
          <StyledInput
            type="text"
            name="Tag Name"
            label="Tag Name"
            value={packTagName}
            placeholder={`Enter A Tag Name`}
            onChange={(e) => setPackTagName(e?.target?.value)}
          />
        </FormControl>

        <FormControl>
          <StyledInput
            type="text"
            name="Tag Description"
            label="Tag Description"
            value={packTagDescription}
            placeholder={`Enter A Tag Description`}
            onChange={(e) => setPackTagDescription(e?.target?.value)}
          />
        </FormControl>
      </div>
      <div style={{ display: "flex", justifyContent: "right" }}>
        <Button list type="button" onClick={() => stagePackDetails()}>
          Stage Details
        </Button>
      </div>
      <hr />
      <h3>Policies</h3>
      <RenderTable tableData={rules} columnsData={columnsData} />
    </>
  );
};

const StandardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Break = styled.div`
  width: 100%;
`;

const StandardElement = styled.div`
  margin-bottom: 1rem;
  margin-left: ${(props) => (props.indent ? "1rem" : "")};
`;

const ComponentElements = {
  fragment: Fragment,
  conditions: Conditional,
  conditionOperators: Conditional,
};

const ImportContainer = ({ setCurrentRule, setShowImport }) => {
  const [importState, setImportState] = useState(null);
  const [importError, setImportError] = useState(null);
  return (
    <>
      <p>
        <i>
          When Importing, Permissions will <b>NOT</b> Transfer and will need to
          be set.
        </i>
      </p>
      <StyledTextArea
        name={`standardImport`}
        placeholder="Paste JSON Import Here"
        label="JSON Import"
        component="textarea"
        rows="5"
        onChange={(e) => {
          try {
            const parsedObj = JSON.parse(e);
            if (
              parsedObj?.name &&
              parsedObj?.standard?.length &&
              parsedObj?.description
            ) {
              setImportState(parsedObj);
              setImportError(false);
            } else {
              setImportError(true);
            }
          } catch (e) {
            setImportError(true);
          }
        }}
      />

      {importError ? (
        <ErrorMessages
          errors={[
            {
              message:
                "Error With Import Format, Import Object Needs Name, Description, Standards.",
            },
          ]}
        />
      ) : null}

      <FormActions>
        <Button
          type="button"
          onClick={() => {
            setCurrentRule(importState);
            setShowImport(false);
          }}
          disabled={!importState}
        >
          Import & Stage
        </Button>
      </FormActions>
    </>
  );
};

const ViewPolicy = ({ data, setViewPolicy, setImportValues }) => {
  const currentRule = data?.policy;
  const [showImport, setShowImport] = useState(false);

  const updateRule = React.useCallback(
    (rule) => {
      setImportValues((prev) => {
        let clonePrev = { ...prev };

        //Find Pack Parent Name
        const currentIndexPack = clonePrev.rulePacks
          .map((e) => e.name)
          .indexOf(data?.pack?.name);

        //Identify Parent
        let rulePack = clonePrev.rulePacks[currentIndexPack];

        //Find Rule Name
        const currentIndexRule = rulePack.rules
          .map((e) => e.name)
          .indexOf(data?.policy?.name);

        rulePack.rules[currentIndexRule] = rule;

        return clonePrev;
      });
      setViewPolicy(null);
    },
    [data?.pack?.name, setImportValues, data?.policy?.name, setViewPolicy]
  );

  return (
    <div>
      {showImport ? (
        <Modal
          title={"Import Policy"}
          hide={() => setShowImport((prevState) => !prevState)}
        >
          <ImportContainer
            setCurrentRule={updateRule}
            setShowImport={setShowImport}
          />
        </Modal>
      ) : null}

      <Button list type="button" onClick={() => setViewPolicy(null)}>
        Back
      </Button>

      <Button
        list
        type="button"
        onClick={() => setShowImport((prevState) => !prevState)}
      >
        Import Changes
      </Button>

      <h3>{currentRule?.name}</h3>
      <h4>{currentRule?.description}</h4>

      <StandardContainer>
        {currentRule?.standard?.map((element, i) => {
          const isLastFirstBracket =
            element.name === "(" &&
            currentRule[i + 1] &&
            currentRule[i + 1].name !== "(";

          const isLastLastBracket =
            element.name === ")" &&
            currentRule[i - 1] &&
            currentRule[i - 1].name !== ")";

          const isBlockedElement =
            element.name === "Then" ||
            element.name === "End If" ||
            element.name === "Else If";

          const ConditionalElementComponent = ComponentElements[element.def];

          const isOrStatement = element.name === "Or";
          const isAndStatement = element.name === "And";

          const indent = isOrStatement || isAndStatement;

          return (
            <React.Fragment key={`standardMap-${i}`}>
              {isBlockedElement ||
              isLastLastBracket ||
              isOrStatement ||
              isAndStatement ? (
                <Break />
              ) : null}
              <StandardElement
                isBlockedElement={isBlockedElement}
                indent={indent}
              >
                <ConditionalElementComponent
                  element={element}
                  index={i}
                  disableAction={true}
                />
              </StandardElement>
              {isBlockedElement || isLastFirstBracket ? <Break /> : null}
            </React.Fragment>
          );
        })}
      </StandardContainer>
    </div>
  );
};

const AccessPoint = () => {
  const [myPatToken, setMyPatToken] = useState("");

  const [inputValue, setInputValue] = useState("");

  const debouncedSave = useMemo(
    () => debounce((newValue) => setMyPatToken(newValue), 1000),
    []
  );

  const updateValue = (newValue) => {
    setInputValue(newValue);
    debouncedSave(newValue);
  };

  return (
    <>
      <h3>Enter Your Personal Access Token to Access Rule Packs</h3>
      <p>
        <i>Keep this token secure, do not share this token with anyone!</i>
      </p>
      <FormControl>
        <StyledInput
          type="password"
          name="pta"
          label="Access Token"
          value={inputValue}
          placeholder={`Enter Your Personal Access Token`}
          onChange={(e) => updateValue(e?.target?.value)}
        />
      </FormControl>

      {myPatToken ? <ImportForm myPatToken={myPatToken} /> : null}
    </>
  );
};

const ImportForm = ({ myPatToken }) => {
  const [{ data }, getPolicies] = useApi(simpleStandardsList, {
    where: {
      enabled: { eq: true },
    },
  });

  const existingPolicies =
    data?.availableBusinessRuleStandards?.edges?.map((abrs) => {
      return {
        name: abrs?.node?.name,
      };
    }) ?? [];

  const [importValues, setImportValues] = useState({});
  const [customImport, setCustomImport] = useState();
  const [viewPack, setViewPack] = useState();
  const [viewPolicy, setViewPolicy] = useState();
  const [importError, setImportError] = useState();
  const [oldObjectId, setOldObjectId] = useState();
  const [showConfirmCommit, setShowConfirmCommit] = useState();
  const [committing, setCommitting] = useState();
  const [commitUrl, setCommitUrl] = useState();
  const [commitMsg, setCommitMsg] = useState();
  const inputRef = React.useRef(null);

  const handleFile = (e) => {
    const content = e?.target?.result;

    // convert read to array
    var obj = JSON.parse(content);

    // set the imported json
    if (obj) {
      setImportValues(obj);
      setImportError();
      setCustomImport(true);
    } else {
      setImportError([
        {
          message:
            "Failed to import values, please check that the file headers are 'value,description' and usage of comma separator",
        },
      ]);
    }
  };

  const updateExistingPolicies = useCallback(() => {
    getPolicies({
      query: simpleStandardsList,
      variables: {
        where: {
          enabled: { eq: true },
        },
      },
    });
  }, [getPolicies]);

  const handleChangeFile = (file) => {
    let fileData = new FileReader();
    fileData.onloadend = handleFile;
    fileData.readAsText(file);
  };

  const recallSourceFile = useCallback(() => {
    const newerURL =
      "https://dev.azure.com/basecapanalytics/23e91887-d5c2-4cf0-a28c-5f321bc17f53/_apis/git/repositories/23858241-d00e-4aaf-9b45-b0cdfa559f8a/items?path=%2FRulePacks%2FtestingPack1.json&api-version=6.0";

    const newURL =
      "https://dev.azure.com/basecapanalytics/23e91887-d5c2-4cf0-a28c-5f321bc17f53/_apis/git/repositories/23858241-d00e-4aaf-9b45-b0cdfa559f8a/items?searchCriteria.$top=1&searchCriteria.itemVersion.version=master&searchCriteria.itemPath=%2FRulePacks%2FtestingPack1.json&api-version=6.0";

    fetch(newURL, {
      method: "GET",
      withCredentials: true,
      crossDomain: true,
      headers: {
        Authorization: "Basic " + btoa(":" + myPatToken),
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setOldObjectId(data?.value?.[0]?.commitId);
      });

    fetch(newerURL, {
      method: "GET",
      withCredentials: true,
      crossDomain: true,
      headers: {
        Authorization: "Basic " + btoa(":" + myPatToken),
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.text())
      .then((data) => {
        setImportValues(data ? JSON.parse(data) : {});
      });
  }, [myPatToken]);

  useEffect(() => {
    recallSourceFile();
  }, [myPatToken, recallSourceFile]);

  const resetFile = useCallback(() => {
    recallSourceFile();
    inputRef.current.value = null;
    setCustomImport();
  }, [recallSourceFile]);

  const pushChanges = () => {
    setCommitting(true);
    // Example POST method implementation:
    async function postData(url = "", data = {}) {
      // Default options are marked with *
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + btoa(":" + myPatToken),
        },
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      });
      return response.json(); // parses JSON response into native JavaScript objects
    }

    const newerURL =
      "https://dev.azure.com/basecapanalytics/23e91887-d5c2-4cf0-a28c-5f321bc17f53/_apis/git/repositories/23858241-d00e-4aaf-9b45-b0cdfa559f8a/pushes?api-version=6.0";

    let newData = { ...importValues };
    var currentdate = new Date();
    var datetime =
      currentdate.getDate() +
      "/" +
      (currentdate.getMonth() + 1) +
      "/" +
      currentdate.getFullYear() +
      " @ " +
      currentdate.getHours() +
      ":" +
      currentdate.getMinutes() +
      ":" +
      currentdate.getSeconds();

    const body = {
      refUpdates: [
        {
          name: "refs/heads/master",
          oldObjectId: oldObjectId,
        },
      ],
      commits: [
        {
          comment: commitMsg
            ? `${commitMsg} - ${datetime}`
            : `Updating Rule Pack ${datetime}`,
          changes: [
            {
              changeType: "edit",
              item: {
                path: "/RulePacks/testingPack1.json",
              },
              newContent: {
                content: JSON.stringify(newData),
                contentType: "rawtext",
              },
            },
          ],
        },
      ],
    };

    postData(newerURL, body).then((data) => {
      setCommitting(false);
      setCommitUrl(data?.repository?.webUrl);
      setCommitMsg();
    });
  };

  return (
    <div>
      {showConfirmCommit ? (
        <Modal
          title={`Commit Staged Changes to Policy Packs`}
          hide={() => setShowConfirmCommit(null)}
        >
          <h3 style={{ color: "red" }}>STOP.</h3>
          <h3>READ.</h3>
          <h3>CONFIRM.</h3>
          <p>
            Committing these changes will save the current state of the Policy
            Packs for our business to reference across all environments. The
            saved state will then persist. If an error occurs while saving and
            we corrupt the policy pack we can reference the most recent working
            commit.
          </p>

          <FormControl>
            <StyledInput
              type="text"
              label="Change Description (40 Char Max)"
              name="value"
              maxLength={40}
              value={commitMsg}
              placeholder={"Describe Your Changes"}
              onChange={(e) => setCommitMsg(e?.target?.value)}
            />
          </FormControl>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              list
              danger
              type="button"
              onClick={() => setShowConfirmCommit(null)}
            >
              Cancel
            </Button>
            <Button type="button" onClick={() => pushChanges()}>
              {committing ? <Spinner /> : "Confirm Commit"}
            </Button>
          </div>

          {commitUrl ? (
            <div>
              <a href={commitUrl} target="_blank" rel="noreferrer">
                Repository URL
              </a>
            </div>
          ) : null}
        </Modal>
      ) : null}

      {importError && <ErrorMessages errors={importError} />}
      {/* {errors && <ErrorMessages errors={errors} />} */}

      <div style={{ marginTop: "1rem" }}>
        <h4>Import & Deploy Custom Rule Pack JSON File</h4>

        <div style={{ marginBottom: "1rem" }}>
          <input
            ref={inputRef}
            type="file"
            accept=".json"
            onChange={(e) => handleChangeFile(e?.target?.files[0])}
          />
        </div>
      </div>

      {importValues && !viewPack ? (
        <RulePacks
          data={importValues}
          setViewPack={setViewPack}
          setImportValues={setImportValues}
          resetFile={resetFile}
          existingPolicies={existingPolicies}
          updateExistingPolicies={updateExistingPolicies}
        />
      ) : null}
      {viewPack && !viewPolicy ? (
        <ViewPack
          data={importValues}
          viewPack={viewPack}
          setViewPack={setViewPack}
          setViewPolicy={setViewPolicy}
          setImportValues={setImportValues}
          existingPolicies={existingPolicies}
        />
      ) : null}

      {viewPolicy ? (
        <ViewPolicy
          data={viewPolicy}
          setViewPolicy={setViewPolicy}
          setImportValues={setImportValues}
        />
      ) : null}
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}
      >
        <Button
          type="button"
          disabled={customImport}
          onClick={() => setShowConfirmCommit(true)}
        >
          Commit Changes
        </Button>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}
      >
        {customImport && <i>Custom JSON Files cannot be committed.</i>}
      </div>

      <ToastContainer />
    </div>
  );
};

const RulePackManager = () => {
  return <AccessPoint />;
};

export default RulePackManager;
