import React from "react";
import { useApi } from "../../api/useApi";
import { allBatchNamesByWorkflowId } from "../../api/workflowQueries";
import PagedTable from "../../components/Table/PagedTable";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import { formatTime } from "../../common/formats";
import TableButton from "../../components/Button/TableButton";
import TableActions from "../../components/Table/TableActions";
import { cancelWorkflowRun } from "../../api/workflowMutations";
import { toast } from "react-toastify";

const resultStateEnums = [
  { value: "NONE", label: "Waiting on Dependencies" },
  { value: "PIPELINE_QUEUED", label: "Queued" },
  { value: "PIPELINE_QUEUED_AND_ALERTED", label: "Queued And Alerted" },
  { value: "PIPELINE_STARTED", label: "Started" },
  { value: "PIPELINE_SUCCEEDED", label: "Success" },
  { value: "CONNECTION_FAILURE", label: "Connection Failure" },
  { value: "CANCELLED", label: "Cancelled" },
  { value: "PIPELINE_FAILED", label: "Failed" },
  { value: "GENERIC_FAILURE", label: "Failure" },
  { value: "COMPLETED", label: "Completed" },
];

const WorkflowTable = ({ workflowId }) => {
  const ref = React.useRef(null);

  //Set Function to enable child component to fetch parent reference for width
  const getTableWidth = () => {
    return ref?.current?.offsetWidth ?? 0;
  };

  //currentPage
  const [currentView, setCurrentView] = React.useState(null);

  const [
    { errors: cancelWorkflowRunErrors, data: cancelWorkflowRunData },
    cancelWorkflowRunApi,
  ] = useApi();

  const cancelWorkflowRunButton = React.useCallback(
    (id) => {
      cancelWorkflowRunApi({
        query: cancelWorkflowRun,
        variables: { id: id },
      });
    },
    [cancelWorkflowRunApi]
  );

  //Init Data Fetch
  const [{ errors, loading, data: apiData }, doFetch] = useApi();

  const batches = apiData?.allBatchNamesByWorkflowId?.edges ?? [];
  const totalCount = apiData?.allBatchNamesByWorkflowId?.totalCount;
  const pageInfo = apiData?.allBatchNamesByWorkflowId?.pageInfo;

  //Fetch for Table Paged
  const fetchData = React.useCallback(
    ({ pageSize, cursor }) => {
      setCurrentView(cursor);
      doFetch({
        query: allBatchNamesByWorkflowId,
        variables: {
          first: pageSize,
          after: cursor,
          workflowId: Number(workflowId),
        },
      });
    },
    [doFetch, setCurrentView, workflowId]
  );

  React.useEffect(() => {
    if (cancelWorkflowRunData && !cancelWorkflowRunErrors) {
      fetchData({ pageSize: 10, cursor: currentView ?? null });
      toast.success("Workflow run cancelled", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (cancelWorkflowRunErrors) {
      toast.error("Couldn't cancel workflow run", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [cancelWorkflowRunData, cancelWorkflowRunErrors, fetchData, currentView]);

  const columnsData = [
    {
      Header: "Batch Name",
      id: "batchname",
      accessor: (d) => d?.node?.batchName ? d?.node?.batchName : "None Assigned Yet",
    },
    // {
    //   Header: "Batch Name",
    //   id: "batchname",
    //   accessor: (d) => d?.node?.batchName,
    //   Cell: ({ row: { original } }) => {
    //     return (
    //       <>
    //         <StyledLink
    //           to={paths.workflowByIdAndBatch(
    //             original?.node?.workflowId,
    //             original?.node?.batchName
    //           )}
    //           data-testid="workflowByIdAndBatchLink"
    //         >
    //           {original?.node?.batchName}
    //         </StyledLink>
    //       </>
    //     );
    //   },
    // },
    {
      Header: "Start Time",
      id: "createdOn",
      accessor: (d) => d?.node?.createdOn,
      Cell: ({ row: { original } }) => {
        return formatTime(new Date(original?.node?.createdOn));
      },
    },
    {
      Header: "Status",
      id: "resultstate",
      accessor: (d) => d?.node?.resultState,
      Cell: ({ row: { original } }) => {
        return (
          resultStateEnums.find(
            (resEnum) => resEnum.value === original?.node?.resultState
          )?.label ?? "N/A"
        );
      },
    },
    {
      Header: " ",
      id: "actions",
      resizable: false,
      sortable: false,
      Cell: ({ totalColumnsWidth, row: { original } }) => {
        const canCancel = original?.node?.resultState == "NONE";
        return (
          <div style={{ textAlign: "right" }}>
            <TableActions
              minWidth={100}
              totalColumnsWidth={totalColumnsWidth}
              getTableWidth={getTableWidth}
            >
              {
                <TableButton
                  onClick={() => {
                    if (canCancel) {
                      cancelWorkflowRunButton(original?.node?.id);
                    }
                  }}
                  danger={true}
                  bumpdown={true}
                  type="button"
                  title="Cancel Run"
                  list="true"
                  disabled={!canCancel}
                  data-testid="cancelWorkflowRun"
                >
                  Cancel
                </TableButton>
              }
            </TableActions>
          </div>
        );
      },
    },
  ];

  // createdOn is when the batch started.
  // successful

  return (
    <>
      {" "}
      <PagedTable
        fetchData={fetchData}
        loading={loading}
        pageInfo={pageInfo}
        totalCount={totalCount}
        data={batches}
        columns={columnsData}
      />
      {errors ? <ErrorMessages errors={errors} /> : null}
    </>
  );
};

export default WorkflowTable;
