import React, { useState, useEffect, useCallback } from "react";

import RenderDependentPipelines from "./RenderDependentPipelines";
import DataSources from "./DataSources";
import {
  MdCancel,
  MdCached,
  MdCheckCircle,
  MdArrowDownward,
  MdHourglassEmpty,
  MdFeed,
} from "react-icons/md";
import { FaDatabase } from "react-icons/fa";
import { AiOutlineCluster } from "react-icons/ai";
import { startDataSourceIngress } from "../../../api/dataSourceMutations";
import { startServicerTransferFeed } from "../../../api/serviceTransferMutations";
import styled from "styled-components/macro";
import {
  PipelineContainer,
  SegmentContainer,
  Segment,
  SegmentHeader,
  SegmentBody,
  DataSourceHeading,
  FieldLabel,
  DataSourceWrapper,
} from "./ViewWorkflowStyles";
import { runEtlPipeline } from "../../../api/etlProviderMutations";
import { useApi } from "../../../api/useApi";
import ErrorMessages from "../../../components/Notifications/ErrorMessages";
import Spinner from "../../../components/Loaders/Spinner";
import useSetWorkflowPipelineCompleteEvent from "../../../Hooks/useSetWorkflowPipelineCompleteEvent";
import useSetWorkflowPipelineFailEvent from "../../../Hooks/useSetWorkflowPipelineFailEvent";
import Secondary from "../../../components/Button/Secondary";
import { toast } from "react-toastify";
import DataSourceName from "../../../components/Card/DataSourceName";

const Indicators = styled.div`
  align-items: center;
  display: flex;
  font-size: 0.875rem;
`;
// TODO ADD THRESHOLD CHECK
const resultStateEnums = [
  { value: null, label: "Waiting on Dependencies" },
  { value: "NONE", label: "Waiting on Dependencies" },
  { value: "PIPELINE_QUEUED", label: "Queued" },
  { value: "PIPELINE_QUEUED_AND_ALERTED", label: "Queued And Alerted" },
  { value: "PIPELINE_STARTED", label: "Started" },
  { value: "PIPELINE_SUCCEEDED", label: "Success" },
  { value: "CONNECTION_FAILURE", label: "Connection Failure" },
  { value: "CANCELLED", label: "Cancelled" },
  { value: "PIPELINE_FAILED", label: "Failed" },
  { value: "GENERIC_FAILURE", label: "Failure" },
  {
    value: "INGRESS_SOURCE_NOT_FOUND",
    label:
      "The platform could not find the ingress source; it may have been moved or deleted",
  },
  {
    value: "INVALID_RULE_MAPPING",
    label:
      "A report could not be generated due to one or more invalid rule mappings",
  },
  {
    value: "REFERENCE_REPORT_QUEUED",
    label:
      "A reference report has been queued, starting a data source run without running rules",
  },
  {
    value: "REFERENCE_REPORT_READY",
    label:
      "A reference report has run successfully, creating a reference target but without running rules",
  },
  { value: "REPORT_READY", label: "The refresh has generated a report" },
  {
    value: "REPORT_READY_INCOMPLETE",
    label:
      "The refresh has generated an incomplete report due to not all rows being measured",
  },
  {
    value: "TIMED_OUT",
    label:
      "The summary took twice as long as the previous summary and was automatically cancelled",
  },
  { value: "USER_CANCELLED", label: "User Cancelled" },
];

function showStateIcon(resultState, segment) {
  switch (resultState) {
    case null:
    case "NONE":
      return (
        <Indicators title="In Progress">
          <div
            style={{
              marginRight: ".2rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <MdHourglassEmpty />
          </div>
          {
            resultStateEnums.find((resEnum) => resEnum.value === resultState)
              ?.label
          }
        </Indicators>
      );
    case "CONNECTION_FAILURE":
    case "CANCELLED":
    case "PIPELINE_FAILED":
    case "GENERIC_FAILURE":
    case "INGRESS_SOURCE_NOT_FOUND":
    case "INVALID_RULE_MAPPING":
    case "TIMED_OUT":
    case "REPORT_READY_INCOMPLETE":
    case "USER_CANCELLED":
      return (
        <Indicators style={{ color: "#f87e7e" }} title="Failure">
          <div
            style={{
              marginRight: ".2rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <MdCancel />
          </div>
          {
            resultStateEnums.find((resEnum) => resEnum.value === resultState)
              ?.label
          }
        </Indicators>
      );
    case "PIPELINE_QUEUED":
    case "PIPELINE_QUEUED_AND_STARTED":
    case "PIPELINE_STARTED":
    case "REFERENCE_REPORT_QUEUED":
      return (
        <Indicators style={{ color: "#009fd4" }} title="In Progress">
          <div
            style={{
              marginRight: ".2rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <MdCached />
          </div>

          {
            resultStateEnums.find((resEnum) => resEnum.value === resultState)
              ?.label
          }
        </Indicators>
      );
    case "PIPELINE_SUCCEEDED":
    case "REPORT_READY":
    case "REFERENCE_REPORT_READY":
      if (
        segment?.type === "DATA_SOURCE" &&
        segment?.dataSourceDataScore < segment?.dataSourceDataQualityThreshold
      ) {
        return (
          <Indicators style={{ color: "#f87e7e" }} title="Failure">
            <div
              style={{
                marginRight: ".2rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              <MdCancel />
            </div>
            {
              resultStateEnums.find((resEnum) => resEnum.value === resultState)
                ?.label
            }{" "}
            Threshold Not Met
          </Indicators>
        );
      } else {
        return (
          <Indicators style={{ color: "#349004" }} title="Success">
            <div
              style={{
                marginRight: ".2rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              <MdCheckCircle />
            </div>

            {
              resultStateEnums.find((resEnum) => resEnum.value === resultState)
                ?.label
            }
          </Indicators>
        );
      }
      break;
    default:
      return null;
  }
}

const RenderSegment = ({
  dependencies,
  segment,
  dispatch,
  stateSources,
  stateTransformations,
}) => {
  const [sentETL, setSentETL] = useState(false);
  const [etlErrors, setEtlErrors] = useState(null);

  const [{ errors: runEtlErrors }, runEtl] = useApi();
  const [
    {
      errors: startIngressErrors,
      // loading: startLoadingIngressData,
      data: startIngressData,
    },
    startIngressProcess,
  ] = useApi();

  const startIngress = useCallback(
    (id) => {
      startIngressProcess({
        query: startDataSourceIngress,
        variables: { dataSourceId: id },
      });
    },
    [startIngressProcess]
  );

  const [
    { errors: startFeedErrors, data: startFeedExport },
    doStartFeedExport,
  ] = useApi();

  const createCSV = useCallback(
    ({ feedId }) => {
      doStartFeedExport({
        query: startServicerTransferFeed,
        variables: {
          feedId: feedId,
        },
      });
    },
    [doStartFeedExport]
  );

  const { workflowPipelineCompleteEvent } =
    useSetWorkflowPipelineCompleteEvent();

  const { workflowPipelineFailEvent } = useSetWorkflowPipelineFailEvent();

  //updated event
  useEffect(() => {
    if (workflowPipelineFailEvent) {
      if (
        workflowPipelineFailEvent?.payload?.EtlPipelineName === segment?.name
      ) {
        setEtlErrors([
          {
            message: workflowPipelineFailEvent?.payload?.ErrorMessage,
          },
        ]);

        setSentETL(false);
      }
    }
  }, [workflowPipelineFailEvent, segment]);

  useEffect(() => {
    if (runEtlErrors?.length) {
      setSentETL(false);
    }
  }, [runEtlErrors]);

  //updated event
  useEffect(() => {
    if (workflowPipelineCompleteEvent) {
      if (
        workflowPipelineCompleteEvent?.payload?.EtlPipelineName ===
        segment?.name
      ) {
        setSentETL(false);
      }
    }
  }, [workflowPipelineCompleteEvent, segment]);

  useEffect(() => {
    if (startFeedExport && !startFeedErrors) {
      toast.success("Transformation Started", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [startFeedExport, startFeedErrors]);

  useEffect(() => {
    if (startIngressData && !startIngressErrors) {
      toast.success("Source Started", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [startIngressData, startIngressErrors]);

  return (
    <>
      <PipelineContainer>
        {segment?.pipelineDependencies?.length ? (
          <RenderDependentPipelines
            dependencies={dependencies}
            pipelines={segment.pipelineDependencies}
            dispatch={dispatch}
            stateSources={stateSources}
            stateTransformations={stateTransformations}
            type="ETL_PIPELINE"
          />
        ) : null}

        {segment?.dataSourceDependencies?.length ? (
          <RenderDependentPipelines
            dependencies={dependencies}
            pipelines={segment.dataSourceDependencies.map((d) => d.id)}
            dispatch={dispatch}
            stateSources={stateSources}
            stateTransformations={stateTransformations}
            type="DATA_SOURCE"
          />
        ) : null}

        {segment?.transformationDependencies?.length ? (
          <RenderDependentPipelines
            dependencies={dependencies}
            pipelines={segment.transformationDependencies.map((t) => t.id)}
            dispatch={dispatch}
            stateSources={stateSources}
            stateTransformations={stateTransformations}
            type="TRANSFORMATION"
          />
        ) : null}
      </PipelineContainer>

      <SegmentContainer>
        <Segment>
          <SegmentHeader>
            <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
              {showStateIcon(
                segment?.type === "DATA_SOURCE"
                  ? segment?.dataSourceRefreshSummaryResultState
                  : segment?.type === "ETL_PIPELINE"
                  ? segment?.etlPipelineResultState
                  : segment?.type === "TRANSFORMATION"
                  ? segment?.servicerTransferSummaryResultState
                  : null,
                segment
              )}
            </div>
            <div
              style={{
                marginLeft: "auto",
              }}
            >
              {segment?.type === "DATA_SOURCE" && (
                <Secondary
                  title={"Run Report"}
                  disabled={sentETL}
                  onClick={() => {
                    startIngress(Number(segment?.id));
                  }}
                >
                  {sentETL ? <Spinner /> : "Run Report"}
                </Secondary>
              )}
              {segment?.type === "ETL_PIPELINE" && (
                <Secondary
                  title={"Run ETL"}
                  disabled={sentETL}
                  onClick={() => {
                    runEtl({
                      query: runEtlPipeline,
                      variables: {
                        pipelineName: segment?.name,
                        etlProviderInstanceId: segment?.etlProviderInstanceId,
                      },
                    });
                    setSentETL(true);
                  }}
                >
                  {sentETL ? <Spinner /> : "Run ETL"}
                </Secondary>
              )}
              {segment?.type === "TRANSFORMATION" && (
                <Secondary
                  title={"Run Transformation"}
                  disabled={sentETL}
                  onClick={() => {
                    createCSV({
                      feedId: segment?.id,
                    });
                  }}
                >
                  {sentETL ? <Spinner /> : "Run Transformation"}
                </Secondary>
              )}
            </div>
          </SegmentHeader>
          <SegmentBody>
            <div style={{ padding: ".4rem" }}>
              <DataSourceWrapper>
                <DataSourceHeading>
                  <FieldLabel>
                    {segment?.type === "DATA_SOURCE" && <FaDatabase />}
                    {segment?.type === "ETL_PIPELINE" && <AiOutlineCluster />}
                    {segment?.type === "TRANSFORMATION" && <MdFeed />}
                    {segment?.type}
                  </FieldLabel>
                  {segment?.type === "DATA_SOURCE"
                    ? `Min DQ Score: ${segment?.dataSourceDataQualityThreshold}`
                    : null}
                  {segment?.dataSourceDataScore !== null ? (
                    <Indicators
                      style={{
                        marginLeft: ".5rem",

                        padding: "0.2rem 0.4rem",
                        borderRadius: "4px",

                        fontSize: "0.875rem",
                      }}
                      title={"score"}
                    >
                      Score: {segment?.dataSourceDataScore}
                    </Indicators>
                  ) : null}
                </DataSourceHeading>
                {segment?.type === "DATA_SOURCE" ? (
                  <DataSourceName
                    name={segment?.name}
                    friendlyName={segment?.friendlyName}
                  />
                ) : (
                  segment?.name
                )}
              </DataSourceWrapper>
            </div>
          </SegmentBody>
        </Segment>
      </SegmentContainer>
      {startFeedErrors && <ErrorMessages errors={startFeedErrors} />}
      {startIngressErrors && <ErrorMessages errors={startIngressErrors} />}
      {runEtlErrors && <ErrorMessages errors={runEtlErrors} />}
      {etlErrors && <ErrorMessages errors={etlErrors} />}
    </>
  );
};

export default RenderSegment;
