import React, { PureComponent } from "react";
import { DropTarget } from "react-dnd";
import styled from "styled-components";
import _ from "lodash";
import { FaDatabase } from "react-icons/fa";
import { MdWarning } from "react-icons/md";

const DropTargetContainer = styled.div`
  padding: 20px;
  background: ${(props) => props.theme[props.stage]};
  font-size: 1em;
  border: 2px solid
    ${(props) =>
      props.lastDroppedItem
        ? props.theme.secondary
        : props.theme.dataTypes[props.dataType]};
  position: relative;
  cursor: auto;
  white-space: nowrap;
`;

const RemoveButton = styled.button`
  background: ${(props) => props.theme.danger};
  color: ${(props) => props.theme.onSurfaceLightSecondary};
  cursor: pointer;
  border: 0;
  font-size: 0.5em;
  position: absolute;
  width: 18px;
  height: 18px;
  bottom: 0px;
  right: 0px;
`;

DropTargetContainer.defaultProps = {
  stage: "secondary",
};

const dustbinTarget = {
  drop(props, monitor) {
    props.onDrop(monitor.getItem());
  },
  canDrop(props, monitor) {
    // You can disallow drop based on props or item
    const item = monitor.getItem();

    if (props?.isExistRule) {
      const selectedSourceId = item?.item?.dataSourceId;
      const sourceId = Number(props?.primarySourceId);

      if (selectedSourceId !== sourceId) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  },
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  };
}
const WarningIcon = styled.div`
  color: orange;
`;
const FragmentSourceInfo = ({ name }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: ".5rem",
      }}
    >
      <div style={{ marginRight: ".5rem" }}>
        {name ? (
          <FaDatabase />
        ) : (
          <WarningIcon>
            <MdWarning />
          </WarningIcon>
        )}
      </div>
      <div>{name ? name : "Remote Source Not Found"}</div>
    </div>
  );
};

class DropZone extends PureComponent {
  render() {
    const {
      isOver,
      canDrop,
      connectDropTarget,
      lastDroppedItem,
      label,
      dataType,
      zone,
      removeZone,
      columns,
      selectedDataSources,
      onClick,
    } = this.props;

    const isActive = isOver && canDrop;

    let column;
    if (zone) {
      column = columns.find((col) => col.id === zone.columnId);
    }
    const dataSourceName = column?.fullyQualifiedName?.split(/\|/);

    return (
      <DropTargetContainer
        onClick={onClick}
        stage={
          isActive
            ? "onSurfaceLightSecondary"
            : canDrop
            ? "onSecondarySurface"
            : null
        }
        ref={(instance) => connectDropTarget(instance)}
        datatype={dataType}
        lastDroppedItem={lastDroppedItem}
      >
        {label !== null && label !== undefined && !zone ? (
          _.truncate(label, {
            length: 45, // maximum 30 characters
            separator: /,?\.* +/, // separate by spaces, including preceding commas and periods
          })
        ) : (
          <div>
            <div style={{ marginBottom: ".5rem" }}>
              {_.truncate(label, {
                length: 45, // maximum 30 characters
                separator: /,?\.* +/, // separate by spaces, including preceding commas and periods
              })}
            </div>
            {selectedDataSources?.length > 1 ? (
              <FragmentSourceInfo name={dataSourceName?.[0]} />
            ) : null}

            <div title={column?.name ?? ""}>
              {_.truncate(column?.name ?? "", {
                length: 45, // maximum 30 characters
                separator: /,?\.* +/, // separate by spaces, including preceding commas and periods
              })}

              <RemoveButton
                onClick={(e) => {
                  e.stopPropagation(); // Prevents the click event from bubbling up
                  removeZone(zone);
                }}
              >
                X
              </RemoveButton>
            </div>
          </div>
        )}
      </DropTargetContainer>
    );
  }
}

export default DropTarget(
  (props) => props.accepts,
  dustbinTarget,
  collect
)(DropZone);
