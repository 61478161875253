import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useApi } from "../../api/useApiRedux";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import { toast } from "react-toastify";

const DataSourceRunp2 = ({ sourceId }) => {
  const [{ loading, errors, data }, runP2Report] = useApi();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      dsId: sourceId,
      logRowsPerSecond: false,
      logRowTiming: false,
      insertRowState: false,
      inserters: 1,
      batchInsertSize: 250,
      metricIntervalInMs: 25,
      emptyReadQueuePauseInMs: 250,
      metricLoggingModulo: 1,
      batchName: "",
      ingestionTimerTest: false,
      runRules: true,
      useDiskFileReader: true,
      readerCountDsId: "",
      readerCount: "",
      forceSpecificDsId: "",
      forceSpecific: "",
      throwForNoFile: true,
    },
  });

  const onSubmit = (formData) => {
    const instructions = {
      DsId: Number(formData.dsId),
      LogRowsPerSecond: formData.logRowsPerSecond,
      LogRowTiming: formData.logRowTiming,
      InsertRowState: formData.insertRowState,
      Inserters: parseInt(formData.inserters, 10),
      BatchInsertSize: parseInt(formData.batchInsertSize, 10),
      MetricIntervalInMs: parseInt(formData.metricIntervalInMs, 10),
      EmptyReadQueuePauseInMs: parseInt(formData.emptyReadQueuePauseInMs, 10),
      MetricLoggingModulo: parseInt(formData.metricLoggingModulo, 10),
      BatchName: formData.batchName,
      IngestionTimerTest: formData.ingestionTimerTest,
      RunRules: formData.runRules,
      UseDiskFileReader: formData.useDiskFileReader,
      ...(formData.readerCountDsId && {
        ReaderCount: {
          [formData.readerCountDsId]: parseInt(formData.readerCount, 10),
        },
      }),
      ...(formData.forceSpecificDsId && {
        ForceSpecificPrevReconRefresh: {
          [formData.forceSpecificDsId]: parseInt(formData.forceSpecific, 10),
        },
      }),
    };

    runP2Report({
      query: "/developers/instructions",
      body: { ...instructions },
    });
  };

  useEffect(() => {
    if (data && !errors?.length) {
      toast.success("P2 Report Started", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [data]);

  return (
    <div style={{ marginTop: "1rem" }}>
      <h3>Platform 2.0 Report Running</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mb={2}>
          <Controller
            name="dsId"
            control={control}
            render={({ field }) => (
              <TextField {...field} label="Data Source ID" fullWidth />
            )}
          />
        </Box>
        <Box mb={2}>
          <FormControl fullWidth>
            <InputLabel>Log Rows Per Second</InputLabel>
            <Controller
              name="logRowsPerSecond"
              control={control}
              render={({ field }) => (
                <Select {...field} label="Log Rows Per Second">
                  <MenuItem value={true}>True</MenuItem>
                  <MenuItem value={false}>False</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </Box>
        <Box mb={2}>
          <FormControl fullWidth>
            <InputLabel>Log Row Timing</InputLabel>
            <Controller
              name="logRowTiming"
              control={control}
              render={({ field }) => (
                <Select {...field} label="Log Row Timing">
                  <MenuItem value={true}>True</MenuItem>
                  <MenuItem value={false}>False</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </Box>
        <Box mb={2}>
          <Controller
            name="metricIntervalInMs"
            control={control}
            render={({ field }) => (
              <TextField {...field} label="Metric Interval (ms)" fullWidth />
            )}
          />
        </Box>
        <Box mb={2}>
          <Controller
            name="metricLoggingModulo"
            control={control}
            render={({ field }) => (
              <TextField {...field} label="Metric Logging Modulo" fullWidth />
            )}
          />
        </Box>
        <Box mb={2}>
          <Controller
            name="batchName"
            control={control}
            render={({ field }) => (
              <TextField {...field} label="Batch Name" fullWidth />
            )}
          />
        </Box>
        <Box mb={2}>
          <FormControl fullWidth>
            <InputLabel>Run Rules</InputLabel>
            <Controller
              name="runRules"
              control={control}
              render={({ field }) => (
                <Select {...field} label="Run Rules">
                  <MenuItem value={true}>True</MenuItem>
                  <MenuItem value={false}>False</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </Box>
        <Box mb={2}>
          <FormControl fullWidth>
            <InputLabel>Use Disk File Reader</InputLabel>
            <Controller
              name="useDiskFileReader"
              control={control}
              render={({ field }) => (
                <Select {...field} label="UseDiskFileReader">
                  <MenuItem value={true}>True</MenuItem>
                  <MenuItem value={false}>False</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </Box>

        <hr />

        <Box mb={2}>
          <Controller
            name="readerCountDsId"
            control={control}
            render={({ field }) => (
              <TextField {...field} label="Reader Count Source ID" fullWidth />
            )}
          />
        </Box>
        <Box mb={2}>
          <Controller
            name="readerCount"
            control={control}
            render={({ field }) => (
              <TextField {...field} label="Reader Count" fullWidth />
            )}
          />
        </Box>

        <hr />

        <Button
          variant="contained"
          type="submit"
          sx={{
            backgroundColor: "rgb(18, 17, 53)",
            "&:hover": {
              backgroundColor: "rgba(18, 17, 53, 0.8)",
            },
          }}
        >
          Run P2 Report
        </Button>

        {errors ? <ErrorMessages errors={errors} /> : null}
      </form>
    </div>
  );
};

export default DataSourceRunp2;
