import React, { useState, useEffect, useContext } from "react";
import { useApi } from "../../api/useApi";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import { allWorkflows } from "../../api/workflowQueries";
import * as paths from "../../common/paths";
import PagedTable from "../../components/Table/PagedTable";
import StyledLink from "../../components/StyledLink";
import TableButton from "../../components/Button/TableButton";
import { MdArchive } from "react-icons/md";
import { AuthContext } from "../../contexts/AuthContext";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import { setWorkflowEnabledFlag } from "../../api/workflowMutations";
import Spinner from "../../components/Loaders/Spinner";
import FilterByTags from "../../components/Tags/FilterByTags";

const WorkflowTable = () => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [sourceToDelete, setSourceToDelete] = useState(null);
  const [removeErrorState, setRemoveErrorState] = useState(null);

  const [tagFilter, setTagFilter] = useState([]);
  const [exactMatchTagFilter, setExactMatchTagFilter] = useState(false);
  const [manuallySelected, setManuallySelected] = useState(null);

  //Init Data Fetch
  const [{ errors, loading, data: apiData }, doFetch] = useApi();

  const { user } = useContext(AuthContext);

  const [
    { errors: removalErrors, loading: removalLoading, data: removalData },
    remove,
  ] = useApi();

  const toggleSourceEnabled = ({ id, enabled }) => {
    remove({
      query: setWorkflowEnabledFlag,
      variables: { id: id, enabled: !enabled },
    });
  };

  const connections = apiData?.allWorkflows?.edges ?? [];
  const totalCount = apiData?.allWorkflows?.totalCount;
  const pageInfo = apiData?.allWorkflows?.pageInfo;

  //Fetch for Table Paged
  const fetchData = React.useCallback(
    ({ pageSize, cursor }) => {
      doFetch({
        query: allWorkflows,
        variables: {
          first: pageSize,
          tagFilter: {
            tagIds: [...tagFilter?.map((tf) => tf.tagId)],
            exactMatch: exactMatchTagFilter,
          },
          after: cursor,
          where: {
            enabled: { eq: false },
          },
          order: {
            name: "ASC",
          },
        },
      });
    },
    [doFetch, exactMatchTagFilter, tagFilter]
  );

  const columnsData = [
    {
      Header: "Name",
      id: "name",
      accessor: (d) => d?.node?.name,
      Cell: ({ row: { original } }) => {
        if (original?.node?.context === "INGRESSTARGET")
          return <>{original?.node?.name}</>;
        else {
          return (
            <>
              <StyledLink
                to={paths.workflowById(original?.node?.id)}
                data-testid="workflowLink"
              >
                {original?.node?.name}
              </StyledLink>
            </>
          );
        }
      },
    },
    {
      Header: "Recurrence",
      id: "recurrence",
      accessor: (d) => `${d?.node?.scheduledJob?.recurrenceInHours}hrs`,
      width: 130,
    },
    {
      Header: "Tags",
      id: "tags",
      Cell: ({ row: { original } }) => {
        return (
          <div>
            {original?.node?.tagInstances?.map((ti, i) => {
              const isLast = original?.node?.tagInstances?.length - 1 <= i;
              return (
                <div
                  onClick={() => setManuallySelected([ti])}
                  style={{
                    display: "inline-block",
                    background: "#e6e6e6",
                    padding: "0.2rem",
                    paddingLeft: ".4rem",
                    paddingRight: ".4rem",
                    fontSize: ".8rem",
                    marginRight: isLast ? "" : ".5rem",
                    marginBottom: isLast ? "" : ".5rem",
                    cursor: "pointer",
                  }}
                >
                  {ti?.tag?.name}
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      Header: " ",
      id: "actions",
      width: 150,
      accessor: (d) => d?.node?.name,
      Cell: ({ row: { original } }) => {
        const enabled = original?.node?.enabled;

        return (
          <>
            {user && user.role >= 2 && (
              <TableButton
                danger={enabled}
                list="true"
                type="button"
                id={`${original?.node?.name}-toggleWorkflowArchiveReactivate`}
                data-testid={`${original?.node?.name}-toggleWorkflowArchiveReactivate`}
                title="Archive Toggle"
                bumpdown={true}
                onClick={() =>
                  setSourceToDelete({
                    id: original?.node?.id,
                    enabled: enabled,
                  })
                }
              >
                {enabled ? <MdArchive /> : "Reactivate"}
              </TableButton>
            )}
          </>
        );
      },
    },
  ];

  //handle remove rule update
  useEffect(() => {
    if (removalData && !removalErrors?.length) {
      fetchData({ pageSize: 10, currentCursor: null });
      setShowConfirm(false);
      setSourceToDelete(null);
      setRemoveErrorState(null);
    } else if (removalErrors?.length) {
      setRemoveErrorState(removalErrors);
    }
  }, [removalData, removalErrors, fetchData]);

  useEffect(() => {
    if (sourceToDelete) {
      setShowConfirm(true);
    } else {
      setShowConfirm(false);
      setRemoveErrorState(null);
    }
  }, [sourceToDelete]);

  return (
    <>
      {showConfirm ? (
        <Modal
          title={`Confirm Workflow ${
            sourceToDelete?.enabled ? "Archival" : "Reactivation"
          }`}
          key={`${sourceToDelete?.id}`}
          hide={() => {
            setSourceToDelete(null);
            setRemoveErrorState(null);
          }}
        >
          <p>
            Are you sure you wish to{" "}
            {sourceToDelete?.enabled ? "archive" : "reactivate"} this Workflow?
          </p>
          <div>
            <Button
              type="button"
              list="true"
              disabled={removalLoading || removeErrorState}
              danger
              onClick={() => toggleSourceEnabled(sourceToDelete)}
            >
              {removalLoading ? <Spinner /> : "Yes"}
            </Button>
            <Button
              type="button"
              disabled={removalLoading}
              onClick={() => {
                setSourceToDelete(null);
                setRemoveErrorState(null);
              }}
            >
              Cancel
            </Button>

            {removeErrorState ? (
              <ErrorMessages errors={removeErrorState} />
            ) : null}
          </div>
        </Modal>
      ) : null}

      <div style={{ display: "flex" }}>
        <div style={{ flex: 1, maxWidth: "400px", marginLeft: "auto" }}>
          <FilterByTags
            currentTags={manuallySelected}
            updateTags={setTagFilter}
            tagType="WORKFLOW"
            exactMatch={setExactMatchTagFilter}
          />
        </div>
      </div>

      <PagedTable
        fetchData={fetchData}
        pageInfo={pageInfo}
        totalCount={totalCount}
        loading={loading}
        data={connections}
        columns={columnsData}
      />
      {errors && <ErrorMessages errors={errors} />}
    </>
  );
};

export default WorkflowTable;
