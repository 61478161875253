import React, { useState } from "react";
import Card from "../../Card";
import DataSourceBusinessLogicFailuresBarWidget from "./DataSourceBusinessLogicFailuresBarWidget";
import Spinner from "../../../components/Loaders/Spinner";
import StyledLink from "../../../components/StyledLink";
import { useLocation } from "react-router-dom";
import { ReactComponent as CleanSVG } from "../../../assets/images/spotlight-empty-state-clean.svg";
import { ReactComponent as SunSvg } from "../../../assets/images/spotlight-empty-state-sun.svg";

const emptyArray = [CleanSVG, SunSvg];

const legendData = [
  { name: "HIGH", color: "#0C3F7C" },
  { name: "NORMAL", color: "#0099FF" },
  { name: "LOW", color: "#B0E5FF" },
];

const LegendIcon = ({ color }) => {
  return (
    <>
      <div
        style={{
          backgroundColor: color,
          display: "block",
          width: "1rem",
          marginRight: ".2rem",
          height: ".5rem",
          borderRadius: ".5rem",
        }}
      />
    </>
  );
};

const LegendCategory = ({ category, action, status }) => {
  if (!action) return null;
  return (
    <div
      style={{
        display: "flex",
        cursor: "pointer",
        marginRight: "1rem",
        opacity: status ? 1 : 0.5,
        alignItems: "center",
        marginBottom: "1rem",
      }}
      onClick={() => action((prevState) => !prevState)}
    >
      <LegendIcon status={status} color={category?.color} />{" "}
      <div>{category.name}</div>
    </div>
  );
};

export default React.memo(
  ({
    data,
    loading,
    errors,
    sourceId,
    disableDrillIn,
    setRuleInstanceDisplay,
    dataSource,
  }) => {
    const sourceName = dataSource?.friendlyName ?? dataSource?.name ?? "";

    //Body Section of Widget
    function Body() {
      const [showHigh, setShowHigh] = useState(true);
      const [showNormal, setShowNormal] = useState(true);
      const [showLow, setShowLow] = useState(true);
      const [showInfo, setShowInfo] = useState(true);

      if (loading) return <Spinner />;
      if (errors) return <div>Errors</div>;
      const RandomEmpty =
        emptyArray[Math.floor(Math.random() * emptyArray.length)];

      if (!data?.length)
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <RandomEmpty />
          </div>
        );
      return (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ marginBottom: "1rem", paddingRight: "1rem" }}>
              Impact:
            </div>
            {legendData.map((category, i) => {
              let action;
              let status;
              if (category.name === "HIGH") {
                action = setShowHigh;
                status = showHigh;
              } else if (category.name === "NORMAL") {
                action = setShowNormal;
                status = showNormal;
              } else if (category.name === "LOW") {
                action = setShowLow;
                status = showLow;
              } else if (category.name === "INFORMATIONAL") {
                action = setShowInfo;
                status = showInfo;
              }

              return (
                <LegendCategory
                  category={category}
                  action={action}
                  status={status}
                  key={category + i}
                />
              );
            })}
          </div>

          <DataSourceBusinessLogicFailuresBarWidget
            data={data}
            sourceId={sourceId}
            disableDrillIn={disableDrillIn}
            showHigh={showHigh}
            showNormal={showNormal}
            showLow={showLow}
            showInfo={showInfo}
            setRuleInstanceDisplay={setRuleInstanceDisplay}
          />
        </>
      );
    }

    function Actions() {
      let location = useLocation();
      return (
        <StyledLink to={`${location?.pathname}/failures`}>View All</StyledLink>
      );
    }

    return (
      <Card
        title={"Top 10 Failures"}
        titleDescription={sourceName}
        body={Body}
        actions={Actions}
        full={true}
      />
    );
  }
);
