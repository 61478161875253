import React from "react";
import {
  Pipeline,
  SegmentVerticalLine,
  VerticalLine,
} from "./CreateFormStyles";
import RenderSegment from "./RenderSegment";
const RenderDependentPipelines = ({
  dependencies,
  pipelines,
  dispatch,
  stateSources,
  type,
  stateTransformations,
}) => {
  const numberOfPipelines = pipelines.length;
  return (
    <>
      {pipelines.map((pipeline, i) => {
        let segment;
        if (type === "ETL_PIPELINE") {
          segment = dependencies.find(
            (d) =>
              d.name === pipeline?.name &&
              d.type === type &&
              d?.etlProviderInstanceId === pipeline?.etlProviderInstanceId
          );
        } else {
          segment = dependencies.find(
            (d) => d.id === pipeline && d.type === type
          );
        }

        const isLast = numberOfPipelines === i + 1;
        return (
          <Pipeline key={`${segment?.uid}-renderseg`}>
            {/* TODO REMOVE DEBUG */}
            <RenderSegment
              dependencies={dependencies}
              segment={segment}
              dispatch={dispatch}
              isLast={isLast}
              stateSources={stateSources}
              stateTransformations={stateTransformations}
            />

            <SegmentVerticalLine>
              <VerticalLine />
            </SegmentVerticalLine>
          </Pipeline>
        );
      })}
    </>
  );
};

export default RenderDependentPipelines;
