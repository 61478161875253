import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import { MdSearch as SearchIcon } from "react-icons/md";
import { useApi } from "../../../api/useApi";
import {
  recordLevelReportById,
  rlrStatus,
  rlrFailures,
} from "../../../api/reportQueries";
import { formatTime } from "../../../common/formats";
import { Link } from "react-router-dom";
import Loading from "./Loading";
import Error from "./Error";
import ErrorMessages from "../../../components/Notifications/ErrorMessages";

const RecordFinder = ({ data }) => {
  const [searchValue, setSearchValue] = useState(data?.preSearchValue || "");
  const [searchPkName, setSearchPkName] = useState(data?.preSearchValue);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(3);
  const [pageCount, setPageCount] = useState(0);

  const [{ errors: errorsReport, data: reportDataQuery }, fetchReportById] =
    useApi();
  const [{ errors: errorsStatus, data: dataStatus }, fetchStatusById] =
    useApi();
  const [{ loading: loadingRow, errors: errorsRow, data: rowData }, doFetch] =
    useApi();

  const reportData = reportDataQuery?.recordLevelReportById;
  const statusData = dataStatus?.rlrStatus;

  const isLoading = !reportDataQuery || !dataStatus;
  const hasError = errorsReport || errorsStatus || errorsRow;

  useEffect(() => {
    fetchReportById({
      query: recordLevelReportById,
      variables: { id: data?.dynamicView },
    });

    fetchStatusById({
      query: rlrStatus,
      variables: { rlrId: data?.dynamicView },
    });
  }, [fetchReportById, fetchStatusById, data?.dynamicView]);

  useEffect(() => {
    setSearchValue(data?.preSearchValue);
    setSearchPkName(data?.preSearchValue);
  }, [data?.preSearchValue]);

  const fetchData = useCallback(() => {
    if (searchPkName) {
      doFetch({
        query: rlrFailures,
        variables: {
          pageSize: pageSize,
          id: data?.dynamicView,
          page: pageIndex + 1, // Adjust for API's 1-based indexing
          pkFilter: searchPkName,
          ruleStandardColumns: reportData?.rules?.map((r) => r?.rule?.id),
        },
      });
    }
  }, [
    reportData,
    data?.dynamicView,
    searchPkName,
    pageIndex,
    pageSize,
    doFetch,
  ]);

  useEffect(() => {
    if (searchPkName) {
      fetchData();
    }
  }, [fetchData, searchPkName]);

  useEffect(() => {
    if (rowData) {
      setPageCount(rowData.rlrFailures.pageCount);
    }
  }, [rowData]);

  const handleSearchClick = () => {
    setSearchPkName(searchValue);
    setPageIndex(0); // Reset to first page on new search
  };

  const handlePageChange = (event, newPage) => {
    setPageIndex(newPage);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageIndex(0); // Reset to first page on page size change
  };

  if (hasError && data) {
    return (
      <>
        <Box
          sx={{
            marginTop: "2rem",
            padding: 2,
            border: "1px solid #ccc",
            borderRadius: 1,
          }}
        >
          <Error message="There was an error loading the data." />
        </Box>
        {errorsReport && <ErrorMessages errors={errorsReport} />}
        {errorsStatus && <ErrorMessages errors={errorsStatus} />}
        {errorsRow && <ErrorMessages errors={errorsRow} />}
      </>
    );
  }

  if (isLoading) {
    return (
      <Box
        sx={{
          marginTop: "2rem",
          padding: 2,
          border: "1px solid #ccc",
          borderRadius: 1,
        }}
      >
        <Loading />
      </Box>
    );
  }

  return (
    <>
      <Typography
        variant="h5"
        gutterBottom
        sx={{ zIndex: 2, position: "relative", top: "-10px" }}
      >
        {data?.widgetName}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        <span role="img" aria-label="clock">
          🕒
        </span>{" "}
        {statusData?.lastUpdatedTime &&
          formatTime(new Date(statusData?.lastUpdatedTime))}
      </Typography>
      <Typography
        variant="body2"
        color="primary"
        component={Link}
        to={`/dynamicviews/${reportData?.id}`}
      >
        🔍{reportData?.name}
      </Typography>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        my={2}
      >
        <Paper
          sx={{
            padding: 2,
            backgroundColor: "#fff",
            borderRadius: 2,
            boxShadow: 1,
          }}
        >
          <TextField
            label={data?.recordLabel ?? reportData?.groupingKeyAlias}
            defaultValue={data?.preSearchValue}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            InputProps={{
              endAdornment: (
                <Button onClick={handleSearchClick}>
                  <SearchIcon />
                </Button>
              ),
            }}
          />
        </Paper>

        <Box textAlign="center" marginLeft={"1rem"}>
          <Paper
            sx={{
              padding: 2,
              backgroundColor: "#fff",
              borderRadius: 2,
              boxShadow: 1,
            }}
          >
            <Typography variant="caption" color="textSecondary">
              Failure Count
            </Typography>
            <Typography variant="h5" color="error">
              {statusData?.totalFailures}
            </Typography>
          </Paper>
        </Box>
      </Box>

      <TableContainer component={Paper} sx={{ position: "relative" }}>
        {loadingRow ? (
          <div
            style={{
              position: "absolute",
              width: "100%",
              display: "block",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              background: "rgba(255, 255, 255, .5)",
            }}
          >
            <Loading />{" "}
          </div>
        ) : null}

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                {data?.recordLabel ?? reportData?.groupingKeyAlias}
              </TableCell>
              <TableCell>Failure Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowData?.rlrFailures?.nodes &&
            rowData.rlrFailures.nodes.length > 0 ? (
              rowData.rlrFailures.nodes.map((row) => (
                <TableRow key={row.primaryKey}>
                  <TableCell>
                    <Typography
                      variant="body2"
                      color="primary"
                      component={Link}
                      to={`/dynamicviews/${reportData?.id}/?pk=${row.primaryKey}`}
                    >
                      {row.primaryKey}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {row?.rules?.reduce(
                      (total, rule) => total + (rule?.failures?.length ?? 0),
                      0
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={2} align="center">
                  <Typography variant="body2" color="textSecondary">
                    No data available
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        page={pageIndex}
        onPageChange={handlePageChange}
        rowsPerPage={pageSize}
        onRowsPerPageChange={handlePageSizeChange}
        rowsPerPageOptions={[3]}
        count={rowData?.rlrFailures?.nodes?.length ? pageCount * pageSize : 0}
        labelDisplayedRows={({ page }) =>
          rowData?.rlrFailures?.nodes?.length
            ? `Page ${page + 1} of ${pageCount}`
            : "Page 1 of 1"
        }
        labelRowsPerPage="Items per page:"
      />
    </>
  );
};

export default RecordFinder;
