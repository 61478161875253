import React from "react";
import { StyledSelect } from "../../../../Form/FormControls";
import {
  DataSourceWrapper,
  DataSourceHeading,
  FieldLabel,
} from "../CreateFormStyles";

const ServicerTransferFeedAction = ({
  dispatch,
  segment,
  stateTransformations,
  setInsertSegment,
  isInserting,
  usedTransformations,
}) => {
  const createDisabledOptions = (options, usedEntities, idKey = "value") => {
    return options.map((option) => ({
      ...option,
      isDisabled: usedEntities.includes(option[idKey]),
    }));
  };

  // Create disabled options for Data Sources
  const TransformationsOptionsWithDisabled = createDisabledOptions(
    stateTransformations,
    usedTransformations
  );

  const selectedFeed = stateTransformations.find((f) => f.value === segment.id);

  return (
    <div style={{ padding: ".4rem" }}>
      <DataSourceWrapper>
        <DataSourceHeading>
          <FieldLabel>Servicer Transfer Feed</FieldLabel>
        </DataSourceHeading>
        <StyledSelect
          name={`stf-${segment?.id}`}
          label="Servicer Transfer Feed"
          options={TransformationsOptionsWithDisabled}
          value={selectedFeed}
          onChange={(e) =>
            isInserting
              ? setInsertSegment((prev) => {
                  return { ...prev, id: e?.value, name: e?.label };
                })
              : dispatch({
                  type: "SELECT_TRANSFER",
                  payload: {
                    pipeline: segment,
                    transfer: e,
                  },
                })
          }
          placeholder="Select Servicer Transfer Feed"
        />
      </DataSourceWrapper>
    </div>
  );
};

export default ServicerTransferFeedAction;
