import React, { useEffect, useState, useMemo, useCallback } from "react";
import debounce from "lodash.debounce";
import {
  StyledInput,
  FormControl,
} from "../../../components/Form/FormControls";
import { ToastContainer, toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link,
  Button,
  Box,
  Autocomplete,
  TextField,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  Backdrop,
  IconButton,
  Collapse,
  Typography,
} from "@mui/material";
import { availableDataSourcesForOptions } from "../../../api/dataSourceQueries";
import { useApi } from "../../../api/useApi";
import { dataSourceForCrosstable } from "../../../api/dataSourceQueries";
import { businessRuleInstanceVersionsAttachedToSource } from "../../../api/ruleQueries";
import { FaRegIdBadge } from "react-icons/fa";

// Table for column profiles
const ColumnProfileTable = ({ ogColumns }) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Column Name</TableCell>
          <TableCell>Ordinal</TableCell>
          <TableCell>Data Type</TableCell>
          <TableCell>Enabled</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {ogColumns.map((column, index) => (
          <TableRow key={index}>
            <TableCell>{column.name}</TableCell>
            <TableCell>{column.ordinal}</TableCell>
            <TableCell>{column.dataType}</TableCell>
            <TableCell>{column.enabled ? "Yes" : "No"}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

// Row for each profile, expandable to show the associated policy profiles
const CrossTableSource = ({ source }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton onClick={() => setOpen(!open)}>
            {open ? "^" : "v"}
          </IconButton>
        </TableCell>
        <TableCell>{source.name}</TableCell>
        <TableCell>{source.columns?.length ?? "N/A"}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={3} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={2}>
              <Typography variant="h6" gutterBottom>
                Column Profiles
              </Typography>
              <ColumnProfileTable ogColumns={source.columns} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

// Table for column profiles
const CrossTableSourcesTable = ({ crossTableSources }) => {
  const [open, setOpen] = useState(false);

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>Source Name</TableCell>
          <TableCell>Columns</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {crossTableSources?.map((source, index) => (
          <CrossTableSource source={source} />
        ))}
      </TableBody>
    </Table>
  );
};

// Table for policy profiles
const PolicyProfileTable = ({ policyProfiles }) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Policy Name</TableCell>
          <TableCell>Instance Name</TableCell>
          <TableCell>Standard Id</TableCell>
          <TableCell>Standard Version Id</TableCell>
          <TableCell>Instance Id</TableCell>
          <TableCell>Instance Version Id</TableCell>
          <TableCell>Priority</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {policyProfiles.map((policy, index) => (
          <TableRow key={index}>
            <TableCell>{policy.ruleStandardName}</TableCell>
            <TableCell>{policy.ruleInstanceName}</TableCell>
            <TableCell>{policy.ruleStandardId}</TableCell>
            <TableCell>{policy.ruleStandardVersionId}</TableCell>
            <TableCell>{policy.ruleInstanceId}</TableCell>
            <TableCell>{policy.ruleInstanceVersionId}</TableCell>
            <TableCell>{policy.businessRuleInstancePriority}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

// Row for each profile, expandable to show the associated policy profiles
const ProfileRow = ({ profile }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton onClick={() => setOpen(!open)}>
            {open ? "^" : "v"}
          </IconButton>
        </TableCell>
        <TableCell>{profile.ogName}</TableCell>
        <TableCell>{profile.ogColumns?.length ?? "N/A"}</TableCell>
        <TableCell>{profile.ogPolicyProfile?.length ?? "N/A"}</TableCell>
        <TableCell>{profile.ogCrossTableReq?.length ?? "N/A"}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={4} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={2}>
              <Typography variant="h6" gutterBottom>
                Column Profiles
              </Typography>
              <ColumnProfileTable ogColumns={profile.ogColumns} />
            </Box>
            <Box margin={2}>
              <Typography variant="h6" gutterBottom>
                Policy Instance Profiles
              </Typography>
              <PolicyProfileTable policyProfiles={profile.ogPolicyProfile} />
            </Box>
            <Box margin={2}>
              <Typography variant="h6" gutterBottom>
                Cross Table Sources
              </Typography>
              <CrossTableSourcesTable
                crossTableSources={profile?.ogCrossTableReq}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

// Main table for profiles with expandable rows for each profile
const ProfilesTable = ({ profiles }) => {
  return (
    <>
      <Typography variant="h5" gutterBottom>
        Source Profiles
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Source Name</TableCell>
              <TableCell>Columns</TableCell>
              <TableCell>Policies</TableCell>
              <TableCell>X Table Sources</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {profiles.map((profile, index) => (
              <ProfileRow key={index} profile={profile} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const SourceProfilesComp = ({ sourceProfiles }) => {
  return (
    <div>
      <ProfilesTable profiles={sourceProfiles} />
    </div>
  );
};

const SourceBuilder = ({
  sourceProfiles: incomingSourceProfiles,
  processSourceProfiles,
}) => {
  const { control, handleSubmit, watch, setValue, getValues } = useForm({
    defaultValues: {
      sources: incomingSourceProfiles
        ? incomingSourceProfiles.map((sp) => sp.ogId)
        : [],
      sourceProfiles: incomingSourceProfiles ?? [],
    },
  });
  const [processingQueue, setProcessingQueue] = useState([]);

  const [fetchedSources, setFetchedSources] = useState([]);

  const selectedSources = watch("sources");
  const sourceProfiles = watch("sourceProfiles");

  const [
    {
      loading: dataSourceOptionsLoading,
      errors: dataSourceOptionsErrors,
      data: dataSourceOptions,
    },
  ] = useApi(availableDataSourcesForOptions, {
    first: 9999,
    where: {
      enabled: { eq: true },
    },
    tagFilter: { exactMatch: false, tagIds: [] },
  });

  const [
    {
      loading: dsInstancesLoading,
      errors: dsInstancesErrors,
      data: dsInstances,
    },
    getDsInstances,
  ] = useApi();

  useEffect(() => {
    if (dsInstances) {
      const instances =
        dsInstances?.businessRuleInstanceVersionsAttachedToSource ?? [];

      const sourceInstances = {
        sourceId: instances?.sourceId,
        instances: instances?.versions,
      };

      setFetchedSources((prev) => {
        // Check if the sourceId already exists in the fetched sources
        const existingIndex = prev.findIndex(
          (source) => source.sourceId === instances?.sourceId
        );

        if (existingIndex !== -1) {
          // Replace the existing source entry
          const updatedSources = [...prev];
          updatedSources[existingIndex] = sourceInstances;
          return updatedSources;
        } else {
          // Append the new source entry
          return [...prev, sourceInstances];
        }
      });
    }
  }, [dsInstances, setFetchedSources]);

  useEffect(() => {
    if (selectedSources && fetchedSources && !dsInstancesLoading) {
      // Create a Set of fetchedSource sourceIds for quick lookup
      const fetchedSourceIds = new Set(
        fetchedSources.map((source) => source.sourceId) // Use source.sourceId
      );

      // Find the first missing ID from selectedSources
      const firstMissingSource = selectedSources.find(
        (selectedId) => !fetchedSourceIds.has(selectedId)
      );

      if (firstMissingSource) {
        //setMissingSource(firstMissingSource); // Set the first missing ID in state
        // Now you can process it in a queue or fetch it
        getDsInstances({
          query: businessRuleInstanceVersionsAttachedToSource,
          variables: {
            first: 9999, // (4352) hacky fix - TODO: implement proper pagination w/ case-insensitive matching
            sourceId: firstMissingSource,
            enabledStates: ["PUBLISHED", "DRAFT"],
            order: {
              title: "ASC",
            },
          },
        });
      }
    }
  }, [selectedSources, fetchedSources]);

  useEffect(() => {
    if (sourceProfiles) {
      processSourceProfiles(sourceProfiles);
    }
  }, [sourceProfiles]);

  const dsOptions =
    dataSourceOptions?.availableDataSources?.nodes
      ?.sort((a, b) => a?.name.localeCompare(b?.name))
      .map((dv) => ({ label: dv?.name, value: dv?.id })) ?? [];

  const dataSourcesOptions = dsOptions;

  const handleSelectAll = (isSelectAll) => {
    if (isSelectAll) {
      setValue(
        "sources",
        dataSourcesOptions.map((option) => option.value)
      );
    } else {
      setValue("sources", []);
    }
  };

  const isAllSelected =
    selectedSources.length &&
    selectedSources.length === dataSourcesOptions.length;

  const [
    { loading: loadingSource, data: dataSourceData },
    getDataSourceColumns,
  ] = useApi();

  const processSources = () => {
    let sourceProfileSetups = [];
    selectedSources?.forEach((s) => {
      const sourceFromOptions =
        dataSourceOptions?.availableDataSources?.nodes.find(
          (ds) => ds?.id === s
        );
      const sourceFromFetched = fetchedSources.find(
        (ds) => ds?.sourceId === s
      )?.instances;

      // Create a map to store the largest id for each instanceId
      const instanceMap = new Map();

      // Iterate over the data
      fetchedSources
        .find((ds) => ds?.sourceId === s)
        ?.instances?.forEach((item) => {
          const currentInstanceId = item.instanceId;

          // Check if this instanceId already exists in the map
          if (!instanceMap.has(currentInstanceId)) {
            // If it doesn't exist, set it with the current item
            instanceMap.set(currentInstanceId, item);
          } else {
            // If it exists, compare the id values and keep the one with the largest id
            const existingItem = instanceMap.get(currentInstanceId);
            if (item.id > existingItem.id) {
              instanceMap.set(currentInstanceId, item);
            }
          }
        });

      // Convert the map values back into an array of unique instances
      const uniqueInstances = Array.from(instanceMap.values());

      if (sourceFromOptions) {
        const profile = {
          ogName: sourceFromOptions?.name,
          ogColumns: sourceFromOptions?.columns,
          ogId: sourceFromOptions?.id,
          policyAndMappings:
            uniqueInstances?.map((instanceObj) => {
              return {
                ogSourceId: sourceFromOptions?.id,
                ogSourceName: sourceFromOptions?.name,
                businessRuleInstancePriority: instanceObj?.instance?.priority,
                ruleInstanceId: instanceObj?.instance?.id,
                ruleInstanceName: instanceObj?.instance?.title,
                ruleDescription:
                  instanceObj?.standardVersion?.standard?.description,
                ruleInstanceVersionId: instanceObj?.id,
                ruleStandardId: instanceObj?.instance?.standardId,
                ruleStandardName: instanceObj?.standardVersion?.standard?.name,
                ruleStandardVersionId: instanceObj?.standardVersion?.id,
                mappingData: {
                  mappings: instanceObj?.mappings ?? [],
                  calculationMappings: instanceObj?.calculationMappings ?? [],
                },
                policyStandardVersionTemplate:
                  instanceObj?.standardVersion?.standard?.latestVersion,
              };
            }) ?? [],
          ogPolicyProfile:
            uniqueInstances?.map((instanceObj) => {
              return {
                businessRuleInstancePriority: instanceObj?.instance?.priority,
                ruleInstanceId: instanceObj?.instance?.id,
                ruleInstanceVersionId: instanceObj?.id,
                ruleInstanceName: instanceObj?.instance?.title,
                ruleStandardId: instanceObj?.instance?.standardId,
                ruleStandardName: instanceObj?.standardVersion?.standard?.name,
                ruleStandardDescription:
                  instanceObj?.standardVersion?.standard?.description,
                ruleStandardVersionId: instanceObj?.standardVersion?.id,
              };
            }) ?? [],
          ogCrossTableReq: [], // Placeholder for CrossTable request
        };

        // Store profile in temporary setup
        sourceProfileSetups.push(profile);
      }
    });
    // Save the initial profile without columns (columns will be updated later)
    setValue("sourceProfiles", sourceProfileSetups);
    setProcessingQueue(sourceProfileSetups.map((sps) => sps.ogId));
  };

  useEffect(() => {
    if (processingQueue.length > 0 && !loadingSource) {
      const nextSourceId = processingQueue[0];
      // Make the API call for the next source in the queue
      getDataSourceColumns({
        query: dataSourceForCrosstable,
        variables: {
          id: Number(nextSourceId),
          where: {
            secondaryDataSources: { enabled: true },
          },
        },
      });
    }
  }, [processingQueue, loadingSource]);

  // Effect to post-process after data source API response
  useEffect(() => {
    if (dataSourceData) {
      postProcessSourcesForCrossTable();
    }
  }, [dataSourceData]);

  // Post-process after API calls are completed
  const postProcessSourcesForCrossTable = () => {
    if (dataSourceData && !loadingSource) {
      const currentSourceProfiles = getValues("sourceProfiles") || [];
      const updatedSourceProfiles = currentSourceProfiles.map((profile) => {
        if (
          profile.ogId ===
          dataSourceData?.dataSourceForCrosstable?.primaryDataSource?.id
        ) {
          return {
            ...profile,
            ogCrossTableReq:
              dataSourceData?.dataSourceForCrosstable?.secondaryDataSources ??
              [],
            ogMatchingColumns:
              dataSourceData?.dataSourceForCrosstable?.matchingColumns ?? [],
          };
        }
        return profile;
      });

      // Update the profiles
      setValue("sourceProfiles", updatedSourceProfiles);

      // Remove the processed source from the queue
      const remainingQueue = processingQueue.filter(
        (sourceId) =>
          sourceId !==
          dataSourceData?.dataSourceForCrosstable?.primaryDataSource?.id
      );
      setProcessingQueue(remainingQueue);
    }
  };

  // Trigger processSources when selectedSources change
  // causes extra renders and just click the button when your done selecting
  // useEffect(() => {
  //   if (selectedSources.length > 0) {
  //     processSources();
  //   }
  // }, [selectedSources]);

  return (
    <>
      {/* Overlay with CircularProgress */}
      <Backdrop
        sx={{
          backgroundColor: "rgba(255,255,255,.5)",
          color: "rgb(0, 159, 212)",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={
          !dataSourcesOptions?.length ||
          dsInstancesLoading ||
          dataSourceOptionsLoading ||
          loadingSource
        }
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box mb={2}>
        <Box mb={2}>
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isAllSelected}
                  onChange={(e) => handleSelectAll(e.target.checked)}
                  indeterminate={selectedSources.length > 0 && !isAllSelected}
                />
              }
              label="Select All Sources"
            />

            <Controller
              name="sources"
              control={control}
              render={({ field }) => {
                return (
                  <Autocomplete
                    multiple
                    options={dataSourcesOptions}
                    getOptionLabel={(option) => option?.label}
                    value={field.value.map((source) =>
                      dataSourcesOptions.find(
                        (option) => option.value === source
                      )
                    )}
                    onChange={(event, newValue) => {
                      field.onChange(newValue.map((item) => item.value));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Sources"
                        placeholder="Type to search..."
                      />
                    )}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox checked={selected} />
                        {option.label}
                      </li>
                    )}
                  />
                );
              }}
            />
          </FormControl>

          <Button onClick={() => processSources()}>
            Process & Update Source Profiles
          </Button>
        </Box>

        <SourceProfilesComp sourceProfiles={sourceProfiles} />
      </Box>
    </>
  );
};

export default SourceBuilder;
