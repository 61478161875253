import React, { useCallback, useEffect, useState } from "react";
import Card from "../../../components/Card";
import styled from "styled-components/macro";
import SortExpandTable from "../../../components/Table/SortExpandTable";
import Button from "../../../components/Button";
import TableButton from "../../../components/Button/TableButton";
import SubRowTable from "../../../components/Table/SubRowTable";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { createCsvReportFailureFile } from "../../../api/dataSourceMutations";
import { useApi } from "../../../api/useApi";
import Spinner from "../../../components/Loaders/Spinner";
import ErrorMessages from "../../../components/Notifications/ErrorMessages";
import SplashLoader from "../../../components/Loaders/SplashLoader";
import Notification from "../../../components/Notification";
import FailureViewDetails from "./FailureViewDetails";
import ExpandLink from "../../../components/Button/ExpandLink";
import { Route, Switch, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { dataSourceFailureInstanceDetails } from "../../../common/paths";
import { dataSourceFailureDetails } from "../../../common/paths";
import StyledLink from "../../../components/StyledLink";
import CTAMessage from "../../../components/Help/CTAMessage";
import { StyledSelect } from "../../../components/Form/FormControls";
import caseInsenSort from "../../../components/Table/helpers/caseInsenSort";
import { NotificationLoading } from "../../../components/Notification/NotificationLoading";

const DashboardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
  grid-auto-rows: dense;
  grid-gap: 1rem;
  margin-bottom: 1rem;
`;

/*
  This component provides conditional rendering of DataSource Failure Exports
    - the details re: conditional rendering can be found in Ticket #1752
*/
const ExportAllFailures = ({
  apiData,
  createCSVReport,
  csvLoading,
  refreshSummaryId,
}) => {
  const totalFailuresCount =
    apiData?.dataSource?.reportById?.failureDetails?.dataSourceMetrics
      ?.totalFailuresCount ??
    apiData?.dataSource?.reportByIdWithAlert?.failureDetails?.dataSourceMetrics
      ?.totalFailuresCount ??
    0;
  const totalHighPriorityFailuresCount =
    apiData?.dataSource?.reportById?.failureDetails?.dataSourceMetrics
      ?.totalHighPriorityFailuresCount ??
    apiData?.dataSource?.reportByIdWithAlert?.failureDetails?.dataSourceMetrics
      ?.totalHighPriorityFailuresCount ??
    0;
  const totalOtherPriorityFailuresCount =
    apiData?.dataSource?.reportById?.failureDetails?.dataSourceMetrics
      ?.totalOtherPriorityFailuresCount ??
    apiData?.dataSource?.reportByIdWithAlert?.failureDetails?.dataSourceMetrics
      ?.totalOtherPriorityFailuresCount ??
    0;

  const { href: url } = window.location;
  // eslint-disable-next-line
  const [subDomain] = /:\/\/([^\/]+)/.exec(url)?.[1]?.split(".") ?? [""];
  if (
    ![
      "naf",
      "test",
      "test2",
      "test3",
      "test4",
      "staging",
      "performance",
      "localhost:5000",
    ].includes(subDomain.toLowerCase())
  ) {
    return (
      <Button
        title="Export All Failures"
        type="button"
        disabled={csvLoading || totalFailuresCount < 1}
        onClick={() =>
          createCSVReport({
            failureType: "ALL_FAILURES",
            dataSourceRefreshSummaryId: refreshSummaryId,
            ruleInstanceVersionId: null,
          })
        }
      >
        {csvLoading ? (
          <Spinner />
        ) : (
          `Export All Failures (${totalFailuresCount})`
        )}
      </Button>
    );
  }

  const allFailureExportOptions = [];
  const allFailureExports = [
    "All Failures",
    "High Priority Failures",
    "Other Priority Failures",
  ];

  for (let label of allFailureExports) {
    const value = label
      .split(" ")
      .map((w) => w.toUpperCase())
      .join("_");

    let metric = 0;
    switch (label) {
      case "All Failures":
        metric = totalFailuresCount;
        break;
      case "High Priority Failures":
        metric = totalHighPriorityFailuresCount;
        break;
      case "Other Priority Failures":
        metric = totalOtherPriorityFailuresCount;
        break;
      default:
        break;
    }

    label += ` (${metric})`;
    const isDisabled = metric < 1;

    allFailureExportOptions.push({ label, value, isDisabled });
  }

  return (
    <div>
      <StyledSelect
        className={`react-select-container`}
        classNamePrefix={`react-select`}
        menuPortalTarget={document.body}
        name={`export-all-failures`}
        id={`export-all-failures`}
        inputId={`export-all-failures-input`}
        instanceId={`export-all-failures-instance`}
        options={allFailureExportOptions}
        placeholder="Export Failures"
        onChange={(exportType) =>
          createCSVReport({
            failureType: exportType.value,
            dataSourceRefreshSummaryId: refreshSummaryId,
            ruleInstanceVersionId: null,
          })
        }
      />
    </div>
  );
};

const ExpandedFailure = ({
  row,
  csvLoading,
  createCSVReport,
  refreshSummaryId,
}) => {
  const reasonTableData = [
    {
      failedRuleInstanceId: row?.original?.failedRuleInstanceId,
      reasonCode: "Missing Value",
      total: row?.original?.nullFailureCount,
      percentTotal: row?.original?.nullValueFailurePercentage,
      actionType: "null",
      ruleInstanceVersionId: row?.original?.failedRuleInstanceVersionId,
    },
    {
      failedRuleInstanceId: row?.original?.failedRuleInstanceId,
      reasonCode: "Incorrect Value",
      total: row?.original?.incorrectValueFailureCount,
      percentTotal: row?.original?.incorrectValueFailurePercentage,
      actionType: "null",
      ruleInstanceVersionId: row?.original?.failedRuleInstanceVersionId,
    },
    {
      failedRuleInstanceId: row?.original?.failedRuleInstanceId,
      reasonCode: "No Matching Row",
      total: row?.original?.noMatchingRowFailureCount,
      percentTotal: row?.original?.noMatchingRowFailurePercentage,
      actionType: "null",
      ruleInstanceVersionId: row?.original?.failedRuleInstanceVersionId,
    },
    {
      failedRuleInstanceId: row?.original?.failedRuleInstanceId,
      reasonCode: "Error",
      total: row?.original?.errorCount,
      percentTotal: row?.original?.errorPercentage,
      actionType: "null",
      ruleInstanceVersionId: row?.original?.failedRuleInstanceVersionId,
    },
  ];

  const userDataColumns = [
    {
      Header: "Reason Code",
      id: "reasonCode",
      accessor: (d) => d.reasonCode,
    },
    {
      Header: "Total",
      id: "total",
      accessor: (d) => d.total,
    },
    {
      Header: "% of Total",
      id: "percentTotal",
      accessor: (d) => d.percentTotal,
      Cell: ({ row: { original } }) => {
        return <>{original.percentTotal}%</>;
      },
    },
    {
      Header: "",
      id: "actions",
      accessor: (d) => d.actionType,
      Cell: ({ row: { original } }) => {
        return (
          <>
            <TableButton
              type="button"
              title={`Export ${original?.reasonCode}`}
              disabled={csvLoading || original?.total === 0}
              onClick={() =>
                createCSVReport({
                  failureType:
                    original.reasonCode === "Incorrect Value"
                      ? "INCORRECT_VALUE_FAILURE"
                      : original.reasonCode === "No Matching Row"
                      ? "NO_MATCHING_ROW"
                      : original.reasonCode === "Missing Value"
                      ? "NULL_FAILURE"
                      : "ERRORS",
                  dataSourceRefreshSummaryId: refreshSummaryId,
                  ruleInstanceVersionId: original?.ruleInstanceVersionId,
                })
              }
            >
              {csvLoading ? <Spinner /> : `Export ${original?.reasonCode}`}
            </TableButton>
          </>
        );
      },
    },
  ];

  return <SubRowTable data={reasonTableData} columns={userDataColumns} />;
};

const FailureDetails = ({ apiData, sourceId, errors }) => {
  const DataSourceName =
    apiData?.dataSource?.friendlyName ?? apiData?.dataSource?.name;
  const [showExport, setShowExport] = useState(false);

  let history = useHistory();

  const failures =
    apiData?.dataSource?.reportById?.ruleFailures?.failures ??
    apiData?.dataSource?.reportByIdWithAlert?.ruleFailures?.failures ??
    [];

  const refreshSummaryId =
    apiData?.dataSource?.reportById?.refreshSummaryId ??
    apiData?.dataSource?.reportByIdWithAlert?.refreshSummaryId ??
    null;

  const [{ loading: csvLoading, errors: csvErrors, data: csvData }, createCSV] =
    useApi();

  const resetExport = useCallback(() => {
    setShowExport(false);
  }, [setShowExport]);

  useEffect(() => {
    if (csvData) {
      setShowExport(true);
    }
  }, [csvData, setShowExport]);

  const createCSVReport = useCallback(
    ({ failureType, dataSourceRefreshSummaryId, ruleInstanceVersionId }) => {
      createCSV({
        query: createCsvReportFailureFile,
        variables: {
          dataSourceRefreshSummaryId: dataSourceRefreshSummaryId,
          failureType: failureType,
          ruleInstanceVersionId: ruleInstanceVersionId,
        },
      });
    },
    [createCSV]
  );

  //Body Section of Widget
  function Body() {
    // const [{ loading, data }, doFetch] = useApi();

    const columnsData = [
      {
        Header: "Policy Instance Name",
        id: "failedRuleInstanceName",
        sortType: (prev, curr, columnId) => {
          return caseInsenSort(prev, curr, columnId);
        },
        accessor: (d) => d?.failedRuleInstanceName,
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({ row }) => (
          <div {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <ExpandLink title={row.original.failedRuleInstanceName}>
                <div>
                  <MdExpandLess />
                </div>
                <div>{row.original.failedRuleInstanceName}</div>
              </ExpandLink>
            ) : (
              <ExpandLink title={row.original.failedRuleInstanceName}>
                <div>
                  <MdExpandMore />
                </div>
                <div>{row.original.failedRuleInstanceName}</div>
              </ExpandLink>
            )}
          </div>
        ),
      },

      {
        Header: "Policy Template Name",
        id: "failedRuleStandardName",
        accessor: (d) => d?.failedRuleStandardName,
        sortType: (prev, curr, columnId) => {
          return caseInsenSort(prev, curr, columnId);
        },
      },
      {
        Header: "Impact",
        id: "priority",
        accessor: (d) => d?.failedBusinessRuleInstancePriority,
        width: 90,
        Cell: ({ row: { original } }) => {
          return <>{original?.failedBusinessRuleInstancePriority ?? ""}</>;
        },
      },
      {
        Header: "Total",
        id: "failureCount",
        accessor: (d) => d?.failureCount,
        width: 80,
      },
      {
        Header: "Missing Value",
        id: "nullFailureCount",
        accessor: (d) => d?.nullFailureCount,
        width: 90,
      },
      {
        Header: "Incorrect Value",
        id: "incorrectValueFailureCount",
        accessor: (d) => d?.incorrectValueFailureCount,
        width: 140,
      },
      {
        Header: "No Matching Row",
        id: "noMatchingRowFailureCount",
        accessor: (d) => d?.noMatchingRowFailureCount,
        width: 140,
      },
      {
        Header: "Error",
        id: "errorCount",
        accessor: (d) => d?.errorCount,
        width: 45,
      },
      {
        Header: "% Of All Failures",
        id: "failurePercentage",
        accessor: (d) => d?.failurePercentage,
        width: 145,
      },
      {
        Header: "",
        id: "actions",
        width: 215,
        resizable: false,
        sortable: false,
        Cell: ({ row: { original } }) => {
          const failedRuleInstanceId = original?.failedRuleInstanceId; // Failed Rule Instance ID
          const failedRuleInstanceVersionId =
            original?.failedRuleInstanceVersionId; // Rule Instance Version for Table and Compare
          const failedRuleStandardVersionId =
            original?.failedRuleStandardVersionId; // Rule Standard Version

          const isDisabled =
            original?.nullFailureCount === 0 &&
            original?.incorrectValueFailureCount === 0 &&
            original?.noMatchingRowFailureCount === 0;

          return (
            <>
              <TableButton
                type="button"
                title="View Details"
                list="true"
                onClick={() => {
                  history.push(
                    dataSourceFailureInstanceDetails(
                      sourceId,
                      refreshSummaryId,
                      failedRuleInstanceId,
                      failedRuleInstanceVersionId,
                      failedRuleStandardVersionId
                    )
                  );
                }}
              >
                View Details
              </TableButton>
              <TableButton
                type="button"
                title="Export"
                disabled={csvLoading || isDisabled}
                onClick={() =>
                  createCSVReport({
                    failureType: "ALL_FAILURES",
                    dataSourceRefreshSummaryId: refreshSummaryId,
                    ruleInstanceVersionId: failedRuleInstanceVersionId,
                  })
                }
              >
                {csvLoading ? <Spinner /> : "Export"}
              </TableButton>
            </>
          );
        },
      },
    ];

    if (errors?.length) return <ErrorMessages errors={errors} />;

    return (
      <>
        {csvLoading ? (
          <NotificationLoading>
            <SplashLoader text="Requesting Data" />
          </NotificationLoading>
        ) : null}

        {showExport ? (
          <NotificationLoading>
            <Notification
              closeCallBack={resetExport}
              text="Your data extract is being prepared; an email will be sent to you when the extract is ready to download."
            />
          </NotificationLoading>
        ) : null}

        <Switch>
          <Route
            path={[
              `/sources/:sourceId/failures/:failedRuleInstanceId/:failedRuleInstanceVersionId/:failedRuleStandardVersionId`,
              `/sources/:sourceId/reports/:reportId/failures/:failedRuleInstanceId/:failedRuleInstanceVersionId/:failedRuleStandardVersionId`,
            ]}
            component={() => (
              <FailureViewDetails
                sourceId={sourceId}
                refreshSummaryId={refreshSummaryId}
                apiData={apiData}
              />
            )}
          />

          <Route
            path={[
              `/sources/:sourceId/reports/:refreshSummaryId/failures`,
              `/sources/:sourceId/failures`,
            ]}
            component={() => (
              <>
                <SortExpandTable
                  // loading={loading}
                  data={failures}
                  columns={columnsData}
                  defaultPageSize={50}
                  defaultSort={[
                    {
                      id: "failureCount",
                      desc: true,
                    },
                  ]}
                  SubRowComponent={(props) => (
                    <ExpandedFailure
                      {...props}
                      csvLoading={csvLoading}
                      createCSV={createCSV}
                      createCSVReport={createCSVReport}
                      refreshSummaryId={refreshSummaryId}
                    />
                  )}
                />
              </>
            )}
          />
        </Switch>
        {csvErrors ? <ErrorMessages errors={csvErrors} /> : null}
      </>
    );
  }

  //Actions Section of Widget
  function Actions() {
    let location = useLocation();

    const lastItem = location?.pathname.substring(
      location?.pathname.lastIndexOf("/") + 1
    );

    const totalSuccessfulRowsCount =
      apiData?.dataSource?.reportById?.failureDetails?.dataSourceMetrics
        ?.totalSuccessfulRowsCount ??
      apiData?.dataSource?.reportByIdWithAlert?.failureDetails
        ?.dataSourceMetrics?.totalSuccessfulRowsCount ??
      0;

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {lastItem === "failures" ? (
          <>
            <Button
              title="Export All Successes"
              type="button"
              list={"true"}
              disabled={csvLoading || totalSuccessfulRowsCount < 1}
              onClick={() =>
                createCSVReport({
                  failureType: "ALL_SUCCESSES",
                  dataSourceRefreshSummaryId: refreshSummaryId,
                  ruleInstanceVersionId: null,
                })
              }
            >
              {csvLoading ? (
                <Spinner />
              ) : (
                `Export All Successes (${totalSuccessfulRowsCount})`
              )}
            </Button>
            <ExportAllFailures
              apiData={apiData}
              createCSVReport={createCSVReport}
              csvLoading={csvLoading}
              refreshSummaryId={refreshSummaryId}
            />
          </>
        ) : (
          <div>
            <StyledLink
              title="View All Failures"
              to={dataSourceFailureDetails(sourceId, refreshSummaryId)}
            >
              View All Failures
            </StyledLink>
          </div>
        )}
      </div>
    );
  }

  if (!apiData) {
    return (
      <CTAMessage
        msg={
          <div>
            <p style={{ textAlign: "center" }}>No Data Available</p>
          </div>
        }
      />
    );
  }

  return (
    <>
      <DashboardGrid>
        <Card
          title={"FAILURE DETAILS"}
          titleDescription={DataSourceName}
          body={Body}
          actions={Actions}
        />
      </DashboardGrid>
    </>
  );
};

export default FailureDetails;
