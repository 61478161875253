import React, { useState, useEffect, useReducer, useCallback } from "react";
import Card from "../../Card";
import {
  MdHome,
  MdArrowBack,
  MdFolder,
  MdInsertDriveFile,
  MdDelete,
} from "react-icons/md";
import { AiOutlineTable } from "react-icons/ai";
import { useApi } from "../../../api/useApi";
import {
  browseConnectionForDataSources,
  importDataSources,
} from "../../../api/connectionMutations";
import useConnectionBrowse from "../../../Hooks/useConnectionBrowse";
import useConnectionImportFinished from "../../../Hooks/useConnectionImportFinished";
import styled from "styled-components/macro";
import SplashLoader from "../../Loaders/SplashLoader";
import { Formik, Field, getIn } from "formik";
import {
  FormControl,
  StyledField,
  ErrorMessage,
  FormActions,
  StyledSelect,
  StyledInput,
  Label,
} from "../../Form/FormControls";
import Button from "../../Button";
import ErrorMessages from "../../Notifications/ErrorMessages";
import * as Yup from "yup";
import { Scrollbars } from "react-custom-scrollbars";
import SelectTags from "../../Tags/SelectTags";
import useTagUpdateComplete from "../../../Hooks/useTagUpdateComplete";
import Modal from "../../Modal";
import SelectEgressForm from "../../Forms/Sources/EgressSelectFormSection";
import { MdOutlineHelp } from "react-icons/md";
import MarkdownFetcher from "../../../views/FeedsPage/transformerFunctions/MarkDownFetcher";

const itemTypes = [
  { label: "NONE", value: 0 },
  { label: "TABLE", value: 1 },
  { label: "VIEW", value: 2 },
  { label: "FILE", value: 3 },
  { label: "DIRECTORY", value: 4 }, //same thing
  { label: "CONTAINER", value: 5 }, //same thing
  { label: "CUBE", value: 6 }, //same thing
  { label: "CUSTOMQUERY", value: 7 },
];

const ItemRow = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: rgba(242, 242, 242, 1);
  padding: 0.5rem;
  &:hover {
    background: rgba(242, 242, 242, 0.8);
  }
`;

const FileRow = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  cursor: ${(props) =>
    props.previouslyImported || !props.canAdd ? "not-allowed" : "pointer"};
  padding: 0.5rem;
  border-left: ${(props) =>
    props.isSelected
      ? `4px solid ${props.theme.onSecondarySurface}`
      : "4px solid transparent"};
  &:hover {
    background: rgba(242, 242, 242, 0.4);
  }
`;

const SelectedRow = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  padding: 0.5rem;
`;

const SelectedRowDetails = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const BrowseIcon = styled.div`
  margin-right: 0.4rem;
  font-size: 1.3rem;
`;

const HeaderIcon = styled.div`
  margin-right: 0.4rem;
  font-size: 1.3rem;
  cursor: pointer;
  background: rgba(242, 242, 242, 1);
  padding: 0.5rem;
  &:hover {
    background: rgba(242, 242, 242, 0.8);
  }
`;

const HeaderRow = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
`;

const FileLoading = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  display: flex;
  background: rgba(255, 255, 255, 0.7);
`;

const SourcesForm = styled.div`
  display: flex;
`;

const SourcesFormSection = styled.div`
  flex: 1;
  margin-right: 1rem;
  position: relative;
`;

const DataSourceFormContainer = styled.div`
  position: relative;
`;

const DataSourceFormRow = styled.div`
  padding: 1rem;
  background: ${(props) => (props.isOdd ? "#f9f9f9" : "")};
`;

const CreateNewButton = styled.div`
  margin-bottom: 0.5em;
  display: block;
  font-size: 0.8em;
  font-family: "Source Sans Pro Semibold";
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
  color: ${(props) => props.theme.onSecondarySurface};
`;

function extensionCheck(extension) {
  const excelFileExtensions = [".xls", ".xlsx"];
  const charSeparatedExtensions = [".tsv", ".csv", ".txt"];
  const xmlFileExtensions = [".xml"];

  if (excelFileExtensions.includes(extension.toLowerCase())) {
    return true;
  } else if (charSeparatedExtensions.includes(extension.toLowerCase())) {
    return true;
  } else if (xmlFileExtensions.includes(extension.toLowerCase())) {
    return true;
  } else if (!extension) {
    return true;
  } else {
    return false;
  }
}

function fileInfoCheck(item, extension) {
  const excelFileExtensions = [".xls", ".xlsx"];
  const charSeparatedExtensions = [".tsv", ".csv", ".txt"];
  const xmlFileExtensions = [".xml"];

  const fileIngressDetails = {
    hasHeaderRow: true,
    containerName: item.container,
    directoryPathToFile: item.parentPath,
    fileNameWithWildcard: item.name,
    rowElement: item?.rowElement,
  };

  const characterSeparatedFileInfo = {
    valueSeparator: "",
    hasHeaderRow: true,
    rowElement: item?.rowElement,
    ignoreQuotes: false,
    quoteCharacter: "",
    containerName: item.container,
    directoryPathToFile: item.parentPath,
    fileNameWithWildcard: item.name,
  };

  if (excelFileExtensions.includes(extension.toLowerCase())) {
    return {
      ingressDetailsType: "EXCEL_FILE",
      characterSeparatedFileInfo: null,
      fileIngressDetails: fileIngressDetails,
      archiveAction: { value: "NONE", label: "NONE" },
    };
  } else if (charSeparatedExtensions.includes(extension.toLowerCase())) {
    return {
      ingressDetailsType: "CHARACTER_SEPARATED_FILE",
      characterSeparatedFileInfo: characterSeparatedFileInfo,
      fileIngressDetails: null,
      archiveAction: { value: "NONE", label: "NONE" },
    };
  } else if (xmlFileExtensions.includes(extension.toLowerCase())) {
    return {
      ingressDetailsType: "XML_FILE",
      characterSeparatedFileInfo: null,
      fileIngressDetails: fileIngressDetails,
      archiveAction: { value: "NONE", label: "NONE" },
    };
  } else {
    return {
      ingressDetailsType: null,
      characterSeparatedFileInfo: null,
      fileIngressDetails: null,
      archiveAction: null,
    };
  }
}

const archiveActions = [
  { value: "NONE", label: "NONE" },
  { value: "DELETE", label: "DELETE" },
  { value: "MOVE", label: "MOVE" },
];

const CubeDetailSelection = ({
  importSources,
  importedSourcesErrors,
  connectionId,
  dataSourcesToImport,
}) => {
  const [sourcesToImport, setSourcesToImport] = useState({
    connectionId: connectionId,
    dataSourcesToImport: dataSourcesToImport.map((dsti) => {
      return {
        ...dsti,
        selectedCubeDimensions: {
          dimensions: [],
          measures: [],
        },
      };
    }),
  });

  const addMeasure = (index) => {
    let dataSourcesToImport = [...sourcesToImport?.dataSourcesToImport];
    dataSourcesToImport[index].selectedCubeDimensions.measures = [
      ...dataSourcesToImport[index].selectedCubeDimensions?.measures,
      { label: null, value: null },
    ];
    setSourcesToImport((prev) => {
      return { ...prev, dataSourcesToImport };
    });
  };

  const removeMeasure = (index, innerIndex) => {
    let dataSourcesToImport = [...sourcesToImport?.dataSourcesToImport];
    let cleanArr = [
      ...dataSourcesToImport[index].selectedCubeDimensions.measures,
    ];
    cleanArr.splice(innerIndex, 1);
    dataSourcesToImport[index].selectedCubeDimensions.measures = cleanArr;
    setSourcesToImport((prev) => {
      return { ...prev, dataSourcesToImport };
    });
  };

  const addDimension = (index) => {
    let dataSourcesToImport = [...sourcesToImport?.dataSourcesToImport];
    dataSourcesToImport[index].selectedCubeDimensions.dimensions = [
      ...dataSourcesToImport[index].selectedCubeDimensions?.dimensions,
      { label: null, value: null, fields: [] },
    ];
    setSourcesToImport((prev) => {
      return { ...prev, dataSourcesToImport };
    });
  };

  const removeDimension = (index, innerIndex) => {
    let dataSourcesToImport = [...sourcesToImport?.dataSourcesToImport];
    let cleanArr = [
      ...dataSourcesToImport[index].selectedCubeDimensions.dimensions,
    ];
    cleanArr.splice(innerIndex, 1);
    dataSourcesToImport[index].selectedCubeDimensions.dimensions = cleanArr;
    setSourcesToImport((prev) => {
      return { ...prev, dataSourcesToImport };
    });
  };

  const addField = (index, innerIndex) => {
    let dataSourcesToImport = [...sourcesToImport?.dataSourcesToImport];

    dataSourcesToImport[index].selectedCubeDimensions.dimensions[
      innerIndex
    ].fields = [
      ...dataSourcesToImport[index].selectedCubeDimensions.dimensions[
        innerIndex
      ].fields,
      { label: null, value: null },
    ];

    setSourcesToImport((prev) => {
      return { ...prev, dataSourcesToImport };
    });
  };

  const removeField = (index, innerIndex, fieldIndex) => {
    let dataSourcesToImport = [...sourcesToImport?.dataSourcesToImport];
    let cleanArr = [
      ...dataSourcesToImport[index].selectedCubeDimensions.dimensions[
        innerIndex
      ].fields,
    ];
    cleanArr.splice(fieldIndex, 1);
    dataSourcesToImport[index].selectedCubeDimensions.dimensions[
      innerIndex
    ].fields = cleanArr;
    setSourcesToImport((prev) => {
      return { ...prev, dataSourcesToImport };
    });
  };

  const changeName = (index, e) => {
    let dataSourcesToImport = [...sourcesToImport?.dataSourcesToImport];
    dataSourcesToImport[index].name = e.target.value;
    setSourcesToImport((prev) => {
      return { ...prev, dataSourcesToImport };
    });
  };

  const submitCubeDetails = () => {
    const values = {
      ...sourcesToImport,
      dataSourcesToImport: sourcesToImport.dataSourcesToImport.map((dsti) => {
        return {
          container: dsti?.container,
          extension: dsti?.extension,
          itemType: dsti?.itemType,
          name: dsti?.name,
          parentPath: dsti?.parentPath,
          path: dsti?.path,
          customQuery: JSON.stringify({
            Measures: dsti.selectedCubeDimensions.measures.map((m) => m.value),
            Dimensions: Object.fromEntries(
              dsti.selectedCubeDimensions.dimensions.map((key) => [
                key.value,
                key.fields.map((kf) => kf.value),
              ])
            ),
          }),
        };
      }),
    };

    importSources({
      query: importDataSources,
      variables: values,
    });
  };

  return (
    <div>
      {importedSourcesErrors ? (
        <ErrorMessages errors={importedSourcesErrors} />
      ) : null}

      {sourcesToImport?.dataSourcesToImport.map((sti, i) => {
        const convertDimensions = JSON.parse(sti?.customQuery);

        const measureOptions = convertDimensions?.Measures.map((tc) => {
          return { label: tc, value: tc };
        });

        const dimensionOptions = Object.entries(
          convertDimensions?.Dimensions
        ).map(([key, val]) => {
          return { label: key, value: key, fields: [] };
        });

        return (
          <>
            <FormControl>
              <Label>Measure Name</Label>
              <StyledInput
                type="text"
                name="target-value"
                value={sti?.name}
                placeholder={sti?.name}
                onChange={(e) => changeName(i, e)}
              />

              <h3>Select {sti?.name} Cube Details</h3>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "1rem",
                }}
              >
                <h4 style={{ flex: 1 }}>Measures</h4>
                <Button type="button" onClick={() => addMeasure(i)}>
                  + Add Measure
                </Button>
              </div>
            </FormControl>
            {sti?.selectedCubeDimensions?.measures.map((m, innerIndex) => {
              return (
                <>
                  <FormControl>
                    <Label>Measure Name</Label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flex: 1, marginRight: "1rem" }}>
                        <StyledSelect
                          className={`react-select-container`}
                          classNamePrefix={`react-select`}
                          name={`Measure Name`}
                          id={`measureName`}
                          inputId={`measureNameSelect-input`}
                          instanceId={`measureNameSelect-instance`}
                          menuPortalTarget={document.body}
                          placeholder={`Measure Name`}
                          label="Measure Name"
                          value={m}
                          options={measureOptions}
                          onChange={(e) => {
                            let dataSourcesToImport = [
                              ...sourcesToImport?.dataSourcesToImport,
                            ];
                            dataSourcesToImport[
                              i
                            ].selectedCubeDimensions.measures[innerIndex] = e;

                            setSourcesToImport((prev) => {
                              return { ...prev, dataSourcesToImport };
                            });
                          }}
                        />{" "}
                      </div>

                      <Button
                        type="button"
                        danger
                        onClick={() => removeMeasure(i, innerIndex)}
                      >
                        x
                      </Button>
                    </div>
                  </FormControl>
                </>
              );
            })}
            <hr />

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "1rem",
              }}
            >
              <h4 style={{ flex: 1 }}>Dimensions</h4>
              <Button type="button" onClick={() => addDimension(i)}>
                + Add Dimension
              </Button>
            </div>
            <div
              style={{
                marginBottom: "1rem",
                borderLeft: "2px #009fd4 solid",
                paddingLeft: ".5rem",
              }}
            >
              The First Dimension/Field pairing will determine the structure of
              the query. That Field will be used to join all the other specified
              Dimension/Fields. In most cases, the structure of the query, and
              the results change as the first dimension/field changes.
            </div>

            {sti?.selectedCubeDimensions?.dimensions.map((m, innerIndex) => {
              const options = convertDimensions?.Dimensions[m?.value] ?? [];
              const fieldOptions = options.map((o) => {
                return {
                  label: o,
                  value: o,
                };
              });

              return (
                <div
                  style={{
                    background: "#f9f9f9",
                    padding: "1rem",
                    marginBottom: ".5rem",
                    border: innerIndex === 0 ? "solid #009fd4 1px" : "",
                  }}
                >
                  <FormControl>
                    <Label>Dimension Name</Label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flex: 1, marginRight: "1rem" }}>
                        <StyledSelect
                          className={`react-select-container`}
                          classNamePrefix={`react-select`}
                          name={`Dimension Name`}
                          id={`dimensionName`}
                          inputId={`dimensionName-input`}
                          instanceId={`dimensionName-instance`}
                          menuPortalTarget={document.body}
                          placeholder={`Dimension Name`}
                          label="Dimension Name"
                          value={m}
                          options={dimensionOptions}
                          onChange={(e) => {
                            let dataSourcesToImport = [
                              ...sourcesToImport?.dataSourcesToImport,
                            ];
                            dataSourcesToImport[
                              i
                            ].selectedCubeDimensions.dimensions[innerIndex] = e;

                            setSourcesToImport((prev) => {
                              return { ...prev, dataSourcesToImport };
                            });
                          }}
                        />
                      </div>
                      <Button
                        type="button"
                        danger
                        onClick={() => removeDimension(i, innerIndex)}
                      >
                        x
                      </Button>
                    </div>
                  </FormControl>

                  {m?.value ? (
                    <>
                      <hr />

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "1rem",
                        }}
                      >
                        <h4 style={{ flex: 1 }}>Fields</h4>
                        <Button
                          type="button"
                          onClick={() => addField(i, innerIndex)}
                        >
                          Add Field +
                        </Button>
                      </div>

                      <div>
                        {m?.fields?.map((f, fieldIndex) => {
                          return (
                            <div
                              style={{
                                padding: "1rem",
                                border:
                                  fieldIndex === 0 ? "solid #009fd4 1px" : "",
                              }}
                            >
                              <FormControl>
                                <Label>Field Name</Label>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div style={{ flex: 1, marginRight: "1rem" }}>
                                    <StyledSelect
                                      className={`react-select-container`}
                                      classNamePrefix={`react-select`}
                                      name={`Field Name`}
                                      id={`fieldName`}
                                      inputId={`fieldNameSelect-input`}
                                      instanceId={`fieldNameSelect-instance`}
                                      menuPortalTarget={document.body}
                                      placeholder={`Field Name`}
                                      label="Field Name"
                                      value={f}
                                      options={fieldOptions}
                                      onChange={(e) => {
                                        let dataSourcesToImport = [
                                          ...sourcesToImport?.dataSourcesToImport,
                                        ];
                                        dataSourcesToImport[
                                          i
                                        ].selectedCubeDimensions.dimensions[
                                          innerIndex
                                        ].fields[fieldIndex] = e;

                                        setSourcesToImport((prev) => {
                                          return {
                                            ...prev,
                                            dataSourcesToImport,
                                          };
                                        });
                                      }}
                                    />
                                  </div>
                                  <Button
                                    type="button"
                                    danger
                                    onClick={() =>
                                      removeField(i, innerIndex, fieldIndex)
                                    }
                                  >
                                    x
                                  </Button>
                                </div>
                              </FormControl>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  ) : null}
                </div>
              );
            })}
          </>
        );
      })}
      <div style={{ display: "flex" }}>
        <div style={{ marginLeft: "auto" }}>
          <Button type="button" onClick={() => submitCubeDetails()}>
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

const EgressSection = React.memo(
  ({ updateFields, fieldName, connectionId, egressType, values }) => {
    const [egressInfo, setEgressInfo] = useState([]);

    useEffect(() => {
      setEgressInfo(getIn(values, fieldName) || []);
    }, [values, fieldName]);

    const [editEgressPath, setEditEgressPath] = useState(false);
    const [localInfo, setLocalInfo] = useState();
    const [showHelper, setShowHelper] = useState(false);

    // Find the index of the entry with the same `egressType`
    let egressIndex = egressInfo.findIndex(
      (egress) => egress.egressType === egressType
    );

    // If not found, set egressIndex to the end of the array
    if (egressIndex === -1) {
      egressIndex = egressInfo.length;
    }

    const setHelper = (helperName) => {
      setShowHelper(helperName);
    };

    const setLocalData = useCallback(
      (e, connectionId) => {
        setLocalInfo({
          characterSeparatedFileInfo: {
            directoryPathToFile: e?.path ? e?.path : "/",
            containerName: e?.container ? e?.container : "/",
            hasHeaderRow: true,
            rowElement: e?.rowElement ? e?.rowElement : "",
          },
          connectionId: connectionId,
          egressDetailsType: "CHARACTER_SEPARATED_FILE",
          archiveAction: "NONE",
          egressType: egressType,
        });
      },
      [egressType]
    );

    const setEgress = useCallback(
      (localInfo) => {
        setEditEgressPath(false);
        updateFields(
          `${fieldName}[${egressIndex}].characterSeparatedFileInfo.directoryPathToFile`,
          localInfo?.characterSeparatedFileInfo?.directoryPathToFile
        );
        updateFields(
          `${fieldName}[${egressIndex}].characterSeparatedFileInfo.containerName`,
          localInfo?.characterSeparatedFileInfo?.containerName
        );
        updateFields(
          `${fieldName}[${egressIndex}].characterSeparatedFileInfo.hasHeaderRow`,
          localInfo?.characterSeparatedFileInfo?.hasHeaderRow
        );
        updateFields(
          `${fieldName}[${egressIndex}].characterSeparatedFileInfo.rowElement`,
          localInfo?.characterSeparatedFileInfo?.rowElement
        );
        updateFields(
          `${fieldName}[${egressIndex}].connectionId`,
          localInfo?.connectionId
        );
        updateFields(
          `${fieldName}[${egressIndex}].egressDetailsType`,
          localInfo?.egressDetailsType
        );
        updateFields(
          `${fieldName}[${egressIndex}].archiveAction`,
          localInfo?.archiveAction
        );
        updateFields(
          `${fieldName}[${egressIndex}].egressType`,
          localInfo?.egressType
        );
      },
      [fieldName, updateFields, egressIndex]
    );

    const clearEgress = useCallback(() => {
      setLocalInfo(null);
      updateFields(`${fieldName}`, []);
    }, [fieldName, updateFields]);

    useEffect(() => {
      if (localInfo) {
        setEgress(localInfo);
      }
    }, [localInfo, setEgress]);

    const hasPathOrContainer =
      localInfo?.characterSeparatedFileInfo?.directoryPathToFile ||
      localInfo?.characterSeparatedFileInfo?.containerName;

    function validateName(value, localInfo) {
      let error;
      const hasPathOrContainer =
        localInfo?.characterSeparatedFileInfo?.directoryPathToFile ||
        localInfo?.characterSeparatedFileInfo?.containerName;

      if (!value && hasPathOrContainer) {
        error = "File Name Required";
      }
      return error;
    }

    return (
      <div>
        {showHelper ? (
          <Modal hide={() => setShowHelper(false)}>
            <div
              style={{
                padding: "1rem",
                background: "#fbfbfb",
                marginBottom: "1rem",
              }}
            >
              <MarkdownFetcher
                hideClose
                MdFile={showHelper}
                close={setShowHelper}
              />
            </div>
          </Modal>
        ) : null}
        <h3>
          {egressType === "GENERAL"
            ? "General Export Path"
            : "SERVICER TRANSFER OUTPUT"}
          <MdOutlineHelp
            onClick={() => setHelper("ExportPath")}
            style={{
              marginLeft: ".5rem",
              fontSize: "1.2rem",
              cursor: "pointer",
            }}
            title="What is Export Path?"
          />
        </h3>
        {egressType === "GENERAL" ? (
          <p>
            Used for selecting where you want any of your exported files,
            including custom reports located when processed.
          </p>
        ) : (
          <p>
            Used for isolating where you want your transfer output files to be
            located when processed.
          </p>
        )}
        <div
          style={{
            justifyContent: "flex-start",
            display: "flex",
            alignItems: "center",
          }}
        >
          <CreateNewButton
            style={{ marginRight: "1rem" }}
            onClick={() => setEditEgressPath(true)}
          >
            Select Export Path
          </CreateNewButton>
          <CreateNewButton
            style={{ color: "red", marginLeft: "auto" }}
            onClick={() => clearEgress()}
          >
            Clear Export Path Info
          </CreateNewButton>
        </div>
        <div>
          <strong>Export Path Container: {"  "}</strong>
          {localInfo?.characterSeparatedFileInfo?.containerName || null}
        </div>
        <div>
          <strong>Export Path:</strong>
          {localInfo?.characterSeparatedFileInfo?.directoryPathToFile || null}
        </div>
        {editEgressPath && (
          <Modal title="Edit Export Path" hide={() => setEditEgressPath(false)}>
            <SelectEgressForm
              selectPath={(e, connectionId) => {
                setLocalData(e, connectionId);
              }}
            />
          </Modal>
        )}
        {hasPathOrContainer && (
          <FormControl style={{ marginTop: "1rem" }}>
            <div style={{ color: "red", fontSize: ".8rem" }}>
              *Name required
            </div>
            <Label>
              File Name With Wildcard, e.g. FILE_NAME_*.csv{" "}
              <MdOutlineHelp
                onClick={() => setHelper("WildcardEgress")}
                style={{
                  marginLeft: ".5rem",
                  fontSize: "1.2rem",
                  cursor: "pointer",
                }}
                title="How do I use the wildcard?"
              />
            </Label>
            <StyledField
              name={`${fieldName}[${egressIndex}].characterSeparatedFileInfo.fileNameWithWildcard`}
              type="text"
              placeholder="Name your Egress File"
              validate={(e) => validateName(e, localInfo)}
            />
            <ErrorMessage
              name={`${fieldName}[${egressIndex}].characterSeparatedFileInfo.fileNameWithWildcard`}
            />
          </FormControl>
        )}
      </div>
    );
  }
);

const DataSourcesForm = React.memo(
  ({
    getUpdatedConnection,
    dispatch,
    sources,
    connectionId,
    data,
    importErrors,
  }) => {
    const [waiting, setWaiting] = useState(false);
    const [sentSources, setSentSources] = useState();

    const [confirmedTags, setConfirmedTags] = useState(0);

    const [errorMessage, setErrorMessage] = useState(null);

    //Show Helper
    const [showHelper, setShowHelper] = useState(false);

    const setHelper = (helperName) => {
      setShowHelper(helperName);
    };

    const numberOfSourcesWithTags = sentSources?.dataSourcesToImport.filter(
      (ss) => ss?.tagInstances?.length
    )?.length;

    const { connectionImportFinished, setConnectionImportFinished } =
      useConnectionImportFinished();

    const { tagUpdateComplete, setTagUpdateComplete } = useTagUpdateComplete();

    const [
      { errors: importedSourcesErrors, data: importedSourcesData },
      importSources,
    ] = useApi();

    useEffect(() => {
      if (importedSourcesData && !importedSourcesErrors?.length) {
        setWaiting(true);
      }
    }, [importedSourcesData, setWaiting, importedSourcesErrors]);

    useEffect(() => {
      if (sentSources) {
        importSources({
          query: importDataSources,
          variables: sentSources,
        });
      }
    }, [sentSources, importSources]);

    //not by specific ID so we can't really wait for specifics to come back, could run into
    // a collision if your adding multiple sources

    useEffect(() => {
      if (connectionImportFinished) {
        const { ErrorMessage } = connectionImportFinished.payload;
        if (ErrorMessage) {
          importErrors(ErrorMessage);
        }

        if (connectionImportFinished && !numberOfSourcesWithTags) {
          setWaiting(false);
          getUpdatedConnection();
          setConnectionImportFinished(null);
        } else if (
          connectionImportFinished &&
          confirmedTags >= numberOfSourcesWithTags
        ) {
          setWaiting(false);
          getUpdatedConnection();
          setConnectionImportFinished(null);
        } else {
          setConnectionImportFinished(null);
        }
      }
    }, [
      connectionImportFinished,
      getUpdatedConnection,
      setConnectionImportFinished,
      numberOfSourcesWithTags,
      confirmedTags,
    ]);

    useEffect(() => {
      if (tagUpdateComplete) {
        const payload = tagUpdateComplete?.payload;

        const matchLastRequest =
          importedSourcesData?.importDataSources === payload?.RequestId;

        if (matchLastRequest) {
          if (!payload?.ErrorMessage) {
            setConfirmedTags((prev) => prev + 1);
          } else {
            const errorMessage = [
              {
                message: payload?.ErrorMessage,
              },
            ];
            setErrorMessage(errorMessage);
            setWaiting(false);
          }
        }
      }
    }, [tagUpdateComplete, setTagUpdateComplete, importedSourcesData]);

    const dataSourcesToImport = sources.map((source) => {
      const { previouslyImported, ...cleanSource } = source;
      return cleanSource;
    });

    const handleSubmit = (values) => {
      const variables = {
        ...values,
        dataSourcesToImport: values.dataSourcesToImport.map((source) => {
          return {
            ...source,
            ingressInformation: {
              ...source.ingressInformation,
              archiveAction:
                source?.ingressInformation?.archiveAction?.value ?? "NONE",
              connectionId: connectionId,
            },
            egressInformation: source.egressInformation?.length
              ? source.egressInformation
              : [],
          };
        }),
      };
      setSentSources(variables);
    };

    if (data?.serverType === "CUBE")
      return (
        <DataSourceFormContainer>
          {waiting && (
            <FileLoading>
              <SplashLoader text="Initializing Data Sources" />
            </FileLoading>
          )}

          <CubeDetailSelection
            connectionId={connectionId}
            importedSourcesErrors={importedSourcesErrors}
            dataSourcesToImport={dataSourcesToImport}
            importSources={importSources}
          />
        </DataSourceFormContainer>
      );
    return (
      <DataSourceFormContainer>
        {waiting && (
          <FileLoading>
            <SplashLoader text="Initializing Data Sources" />
          </FileLoading>
        )}

        <h3>Source Meta Data</h3>
        <p>Fill out the following details for each new source.</p>
        <Formik
          initialValues={{
            connectionId: connectionId,
            dataSourcesToImport: dataSourcesToImport,
          }}
          enableReinitialize
          validateOnMount={true}
          validationSchema={Yup.object().shape({
            dataSourcesToImport: Yup.array().of(
              Yup.object({
                ingressInformation: Yup.object().shape({
                  ingressDetailsType: Yup.string().required("Required"),
                  archiveAction: Yup.mixed().required("Required"),
                  characterSeparatedFileInfo: Yup.mixed().when(
                    "ingressDetailsType",
                    {
                      is: (val) => val === "CHARACTER_SEPARATED_FILE",
                      then: Yup.object().shape({
                        valueSeparator: Yup.string().required("Required"),
                        hasHeaderRow: Yup.boolean(),
                        fileNameWithWildcard: Yup.string().required("Required"),
                      }),
                      otherwise: Yup.mixed().nullable(),
                    }
                  ),
                  fileIngressDetails: Yup.mixed().when("ingressDetailsType", {
                    is: (val) => val !== "CHARACTER_SEPARATED_FILE",
                    then: Yup.object().shape({
                      hasHeaderRow: Yup.boolean(),
                      fileNameWithWildcard: Yup.string().required("Required"),
                    }),
                    otherwise: Yup.mixed().nullable(),
                  }),
                }),
              })
            ),
          })}
        >
          {(props) => {
            return (
              <form>
                {showHelper ? (
                  <Modal hide={() => setShowHelper(false)}>
                    <div
                      style={{
                        padding: "1rem",
                        background: "#fbfbfb",
                        marginBottom: "1rem",
                      }}
                    >
                      <MarkdownFetcher
                        hideClose
                        MdFile={showHelper}
                        close={setShowHelper}
                      />
                    </div>
                  </Modal>
                ) : null}
                {props.values.dataSourcesToImport.map((item, i) => {
                  const ingressDetailsType =
                    item.ingressInformation.ingressDetailsType ===
                    "CHARACTER_SEPARATED_FILE"
                      ? "characterSeparatedFileInfo"
                      : "fileIngressDetails";

                  const isXML =
                    item.ingressInformation.ingressDetailsType === "XML_FILE";

                  // Check if it is a transfer output
                  const isTransferOutput = item?.tagInstances?.find(
                    (ti) => ti?.tagId === -3
                  );

                  return (
                    <DataSourceFormRow
                      isOdd={i % 2 === 1}
                      key={`dataSourceFormRow-${i}`}
                    >
                      <h4>{item?.name}</h4>

                      {!isXML && (
                        <FormControl>
                          <label>
                            Has Header Row
                            <Field
                              type="checkbox"
                              name={`dataSourcesToImport[${i}].ingressInformation.${ingressDetailsType}.hasHeaderRow`}
                            />
                          </label>
                        </FormControl>
                      )}
                      {item.ingressDetailsType !== "CHARACTER_SEPARATED_FILE" &&
                      !isXML ? (
                        <FormControl>
                          <Label>
                            Value Separator
                            <MdOutlineHelp
                              onClick={() => setHelper("ValueSeparator")}
                              style={{
                                marginLeft: ".5rem",
                                fontSize: "1.2rem",
                                cursor: "pointer",
                              }}
                              title="What are common value separators?"
                            />
                          </Label>
                          <StyledField
                            name={`dataSourcesToImport[${i}].ingressInformation.${ingressDetailsType}.valueSeparator`}
                            type="text"
                            placeholder="Value Separator Character"
                          />

                          <ErrorMessage
                            name={`dataSourcesToImport[${i}].ingressInformation.${ingressDetailsType}.valueSeparator`}
                          />
                        </FormControl>
                      ) : null}
                      <FormControl>
                        <Label>
                          File Name With Wildcard, e.g. FILE_NAME_*.csv{" "}
                          <MdOutlineHelp
                            onClick={() => setHelper("WildcardIngress")}
                            style={{
                              marginLeft: ".5rem",
                              fontSize: "1.2rem",
                              cursor: "pointer",
                            }}
                            title="How do I use the wildcard?"
                          />
                        </Label>
                        <StyledField
                          name={`dataSourcesToImport[${i}].ingressInformation.${ingressDetailsType}.fileNameWithWildcard`}
                          type="text"
                          placeholder="File Name With Wildcard"
                        />

                        <ErrorMessage
                          name={`dataSourcesToImport[${i}].ingressInformation.${ingressDetailsType}.fileNameWithWildcard`}
                        />
                      </FormControl>

                      {isXML && (
                        <FormControl>
                          <Label>Row Element Name</Label>
                          <StyledField
                            name={`dataSourcesToImport[${i}].ingressInformation.${ingressDetailsType}.rowElement`}
                            type="text"
                            placeholder="Row Element Name"
                          />

                          <ErrorMessage
                            name={`dataSourcesToImport[${i}].ingressInformation.${ingressDetailsType}.rowElement`}
                          />
                        </FormControl>
                      )}

                      <FormControl>
                        <Label>Archive Action</Label>

                        <StyledSelect
                          className={`react-select-container`}
                          classNamePrefix={`react-select`}
                          name={`dataSourcesToImport[${i}].ingressInformation.archiveAction`}
                          id={`dataSourcesToImport[${i}].ingressInformation.archiveAction`}
                          inputId={`dataSourcesToImport[${i}].ingressInformation.archiveActionSelect-input`}
                          instanceId={`dataSourcesToImport[${i}].ingressInformation.archiveActionSelect-instance`}
                          label="Archive Action"
                          menuPortalTarget={document.body}
                          options={archiveActions.map((aa) => {
                            return {
                              value: aa.value,
                              label: aa.label,
                              isDisabled:
                                aa.value === "NONE" &&
                                props.values.dataSourcesToImport[i]
                                  .ingressInformation !== null,
                            };
                          })}
                          placeholder={`Action`}
                          value={
                            props.values.dataSourcesToImport[i]
                              .ingressInformation.archiveAction.value === `NONE`
                              ? (props.values.dataSourcesToImport[
                                  i
                                ].ingressInformation.archiveAction =
                                  archiveActions.find(
                                    (aa) => aa.value === `MOVE`
                                  ))
                              : props.values.dataSourcesToImport[i]
                                  .ingressInformation.archiveAction
                          }
                          menuPlacement="auto"
                          onChange={(e) =>
                            props.setFieldValue(
                              `dataSourcesToImport[${i}].ingressInformation.archiveAction`,
                              e
                            )
                          }
                        />
                      </FormControl>
                      {!isXML && (
                        <FormControl>
                          <label>
                            Ignore Quotes
                            <MdOutlineHelp
                              onClick={() => setHelper("QuotesIgnore")}
                              style={{
                                marginLeft: ".5rem",
                                fontSize: "1.2rem",
                                cursor: "pointer",
                              }}
                              title="What does ignoring quotes do?"
                            />
                            <Field
                              type="checkbox"
                              name={`dataSourcesToImport[${i}].ingressInformation.${ingressDetailsType}.ignoreQuotes`}
                            />
                          </label>
                        </FormControl>
                      )}
                      {!isXML && (
                        <FormControl>
                          <Label>
                            Quote Character
                            <MdOutlineHelp
                              onClick={() => setHelper("QuotesIngress")}
                              style={{
                                marginLeft: ".5rem",
                                fontSize: "1.2rem",
                                cursor: "pointer",
                              }}
                              title="What does ignoring quotes, or specifying the quote character do?"
                            />
                          </Label>
                          <StyledField
                            name={`dataSourcesToImport[${i}].ingressInformation.${ingressDetailsType}.quoteCharacter`}
                            type="text"
                            placeholder="Quote Character"
                          />

                          <ErrorMessage
                            name={`dataSourcesToImport[${i}].ingressInformation.${ingressDetailsType}.quoteCharacter`}
                          />
                        </FormControl>
                      )}

                      <h3>Tags</h3>
                      <p>Select the appropriate tags below.</p>
                      <SelectTags
                        updateTags={props.setFieldValue}
                        fieldName={`dataSourcesToImport[${i}].tagInstances`}
                        tagType={"DATA_SOURCE"}
                      />

                      <EgressSection
                        updateFields={props.setFieldValue}
                        fieldName={`dataSourcesToImport[${i}].egressInformation`}
                        egressType={"GENERAL"}
                        values={props?.values}
                      />

                      <EgressSection
                        updateFields={props.setFieldValue}
                        fieldName={`dataSourcesToImport[${i}].egressInformation`}
                        egressType={"SERVICER_TRANSFER_OUTPUT"}
                        values={props?.values}
                      />
                      {/* <RemoteInfoForm apiData={apiData}  /> */}
                    </DataSourceFormRow>
                  );
                })}
                <FormActions>
                  {importedSourcesErrors ? (
                    <ErrorMessages errors={importedSourcesErrors} />
                  ) : null}

                  {errorMessage ? (
                    <ErrorMessages errors={errorMessage} />
                  ) : null}

                  <FormControl>
                    <Button
                      type="button"
                      list="true"
                      onClick={() =>
                        dispatch({ type: "SET_FORM_STATE", payload: 1 })
                      }
                    >
                      Back
                    </Button>
                    <Button
                      type="button"
                      onClick={() => handleSubmit(props.values)}
                      disabled={props.isSubmitting || !props.isValid}
                    >
                      Confirm Source Details
                    </Button>
                  </FormControl>
                </FormActions>
              </form>
            );
          }}
        </Formik>
      </DataSourceFormContainer>
    );
  }
);

const TreeItem = ({ data, isSelected, dispatch }) => {
  //Check if can expand
  const expandable =
    data.itemType === "DIRECTORY" || data.itemType === "CONTAINER";
  const canAdd = extensionCheck(data?.extension);
  if (expandable) {
    return (
      <ItemRow
        onClick={() => dispatch({ type: "CONTAINER_CLICK", payload: data })}
      >
        {expandable && (
          <BrowseIcon>
            <MdFolder />
          </BrowseIcon>
        )}
        {data?.name}
      </ItemRow>
    );
  } else {
    return (
      <FileRow
        onClick={() =>
          !data?.previouslyImported &&
          canAdd &&
          dispatch({ type: "SET_SELECTED", payload: data })
        }
        isSelected={isSelected || data.previouslyImported}
        disable={isSelected || data.previouslyImported || !canAdd}
        previouslyImported={data.previouslyImported}
        title={
          !canAdd
            ? "UNSUPPORTED FILE TYPE"
            : data?.previouslyImported
            ? "Previously Imported, Check Archive if not in list"
            : "Select to Import"
        }
        canAdd={canAdd}
      >
        {data?.itemType === "FILE" ? (
          <BrowseIcon>
            <MdInsertDriveFile />
          </BrowseIcon>
        ) : (
          <BrowseIcon>
            <AiOutlineTable />
          </BrowseIcon>
        )}

        {data?.name}
      </FileRow>
    );
  }
};

const SelectSourceForm = ({
  setWaiting,
  waiting,
  state,
  dispatch,
  browseData,
  getUpdatedConnection,
  importErrors,
  importedSourcesErrors,
}) => {
  const { browseNotification, setBrowseNotification } = useConnectionBrowse();
  const { connectionImportFinished, setConnectionImportFinished } =
    useConnectionImportFinished();

  // useEffect(() => {
  //   const currentSources = data?.dataSources;
  //   const sourceNames = currentSources.map(source => source.name);
  //   dispatch({ type: "SET_CURRENT_NAMES", payload: sourceNames });
  // }, [data, dispatch]);

  useEffect(() => {
    if (browseNotification && state.fetchingItem) {
      const browseItems = browseNotification.payload.BrowseItems;
      if (browseItems?.length) {
        const items = browseItems?.map((item) => {
          return {
            container: item.Container,
            // add schema to name for db and tables
            name: `${
              item.ItemType === 1 || item.ItemType === 2 ? `${item.Path}.` : ""
            }${item.Name}`,
            extension: item.Extension,
            parentPath: item.ParentPath,
            path: item.Path,
            previouslyImported: item.PreviouslyImported,
            itemType: itemTypes.find((type) => type.value === item.ItemType)
              ?.label,
            ingressInformation: item.IngressInformation,
            customQuery: JSON.stringify(item?.CustomQuery),
          };
        });
        dispatch({ type: "SET_RESULTS", payload: items });
        setBrowseNotification(null);
      } else {
        dispatch({ type: "SET_RESULTS", payload: [] });
        setBrowseNotification(null);
      }
    }
  }, [browseNotification, setBrowseNotification, state.fetchingItem, dispatch]);

  useEffect(() => {
    if (browseData) {
      const request = browseData?.browseConnectionForDataSources ?? null;
      dispatch({ type: "SET_FETCHING", payload: { request } });
    }
  }, [browseData, dispatch]);

  useEffect(() => {
    if (connectionImportFinished) {
      const { ErrorMessage } = connectionImportFinished.payload;
      if (ErrorMessage) {
        importErrors(ErrorMessage);
      }
      setWaiting(false);
      getUpdatedConnection();
      setConnectionImportFinished(null);
    }
  }, [
    connectionImportFinished,
    getUpdatedConnection,
    setConnectionImportFinished,
    importErrors,
    setWaiting,
  ]);

  const areFilesSelected = state.selected.filter(
    (item) => item.itemType === "FILE"
  )?.length;

  const isCube = state.selected.filter(
    (item) => item.itemType === "CUBE"
  )?.length;

  return (
    <div>
      {waiting && (
        <FileLoading>
          <SplashLoader text="Initializing Data Sources" />
        </FileLoading>
      )}

      <SourcesForm>
        <SourcesFormSection>
          <HeaderRow>
            <HeaderIcon
              onClick={() => dispatch({ type: "GO_TO_ROOT", payload: null })}
            >
              <MdHome />
            </HeaderIcon>
            {state.history.length ? (
              <HeaderIcon
                onClick={() => dispatch({ type: "GO_BACK", payload: null })}
              >
                <MdArrowBack />
              </HeaderIcon>
            ) : null}
            {state?.currentItem?.itemToBrowse?.itemType === "CONTAINER"
              ? state?.currentItem?.itemToBrowse?.name
              : state?.currentItem?.itemToBrowse?.path ?? "/"}
          </HeaderRow>
          <Scrollbars style={{ minHeight: "calc(100vh - 350px)" }}>
            {state.loading ? (
              <FileLoading>
                <SplashLoader text="Loading Results" />
              </FileLoading>
            ) : null}

            {state.tree.map((data, i) => {
              const isSelected = state.selected.find(
                (item) => item.name === data.name
              );

              return (
                <TreeItem
                  key={`treeItem-${i}`}
                  data={data}
                  dispatch={dispatch}
                  isSelected={isSelected}
                />
              );
            })}
          </Scrollbars>
        </SourcesFormSection>

        <SourcesFormSection>
          <div>
            <h3>Selected Sources:</h3>

            <div style={{ marginBottom: "1rem" }}>
              Browse and select files or tables from the navigation on the left.
            </div>
          </div>

          {state.selected.length ? (
            <>
              {state.selected.map((item, i) => {
                return (
                  <SelectedRow key={`selectedRow-${i}`}>
                    {item?.itemType === "FILE" ? (
                      <BrowseIcon>
                        <MdInsertDriveFile />
                      </BrowseIcon>
                    ) : (
                      <BrowseIcon>
                        <AiOutlineTable />
                      </BrowseIcon>
                    )}
                    <SelectedRowDetails>
                      <div data-testid={`added-${item?.name}`}>
                        {item?.name}
                      </div>
                      <div
                        style={{ marginLeft: "auto", cursor: "pointer" }}
                        onClick={() =>
                          dispatch({ type: "REMOVE_SELECTED", payload: i })
                        }
                        data-testid={`delete-${item?.name}`}
                      >
                        <MdDelete />
                      </div>
                    </SelectedRowDetails>
                  </SelectedRow>
                );
              })}

              {importedSourcesErrors ? (
                <ErrorMessages errors={importedSourcesErrors} />
              ) : null}

              {state.createError ? (
                <ErrorMessages errors={[{ message: state.createError }]} />
              ) : null}

              <Button
                type="button"
                onClick={() =>
                  areFilesSelected
                    ? dispatch({ type: "SET_FORM_STATE", payload: 2 })
                    : isCube
                    ? dispatch({ type: "SET_FORM_STATE", payload: 2 })
                    : dispatch({ type: "SUBMIT_SQL_FORM", payload: null })
                }
              >
                Continue
              </Button>
            </>
          ) : null}
        </SourcesFormSection>
      </SourcesForm>
    </div>
  );
};

const FileBrowser = ({ getUpdatedConnection, data, importErrors }) => {
  const connectionId = data?.id ?? null;

  const [waiting, setWaiting] = useState(false);

  const [
    { errors: importedSourcesErrors, data: importedSourcesData },
    importSources,
  ] = useApi();

  useEffect(() => {
    if (importedSourcesData && !importedSourcesErrors?.length) {
      setWaiting(true);
    }
  }, [importedSourcesData, setWaiting, importedSourcesErrors]);

  const [{ data: browseData }, browse] = useApi();

  useEffect(() => {
    browse({
      query: browseConnectionForDataSources,
      variables: {
        request: {
          itemToBrowse: null,
          connectionId: connectionId,
        },
      },
    });
  }, [browse, connectionId]);

  const reducer = (state, action) => {
    switch (action.type) {
      default:
        return {
          ...state,
        };

      // GO BACK
      case "GO_BACK": {
        const latestHistory = state.history.slice(0);
        const latest = latestHistory.pop();

        browse({
          query: browseConnectionForDataSources,
          variables: {
            request: { ...latest },
          },
        });

        return {
          ...state,
          history: latestHistory,
          fetchingItem: latest,
          loading: true,
          isBack: true,
        };
      }

      // GO TO ROOT
      case "GO_TO_ROOT": {
        browse({
          query: browseConnectionForDataSources,
          variables: {
            request: {
              itemToBrowse: null,
              connectionId: connectionId,
            },
          },
        });

        return {
          ...state,
          history: [],
          fetchingItem: null,
          currentItem: null,
          loading: true,
        };
      }

      // SET RESULTS
      case "SET_RESULTS": {
        return {
          ...state,
          tree: action.payload,
          history: state.isBack
            ? state.history
            : state.currentItem === null
            ? []
            : [...state.history, state.currentItem],
          fetchingItem: null,
          currentItem: state.fetchingItem,
          loading: false,
          isBack: null,
        };
      }

      // SET FETCHING
      case "SET_FETCHING": {
        return {
          ...state,
          fetchingItem: action?.payload?.request,
        };
      }

      // SET SELECTED
      case "SET_SELECTED": {
        const arr = state.selected.includes(action.payload)
          ? state.selected.filter((i) => i !== action.payload)
          : [...state.selected, action.payload];

        return {
          ...state,
          selected: arr,
        };
      }

      // REMOVE SELECTED
      case "REMOVE_SELECTED": {
        const currentSelected = state.selected.slice(0);
        currentSelected.splice(action.payload, 1);
        return {
          ...state,
          selected: currentSelected,
        };
      }

      // SET CURRENT NAMES
      case "SET_CURRENT_NAMES": {
        return {
          ...state,
          currentNames: action.payload,
        };
      }

      // SET FETCHING ITEM
      case "CONTAINER_CLICK": {
        const { previouslyImported, ...cleanItem } = action.payload;

        browse({
          query: browseConnectionForDataSources,
          variables: {
            request: {
              itemToBrowse: cleanItem,
              connectionId: connectionId,
            },
          },
        });

        return {
          ...state,
          loading: true,
        };
      }

      // SET FORM STATE
      case "SET_FORM_STATE": {
        const dataSourcesToImport = state.selected.map((item) => {
          const { extension } = item;
          return {
            ...item,
            ingressInformation: {
              ...fileInfoCheck(item, extension),
            },
            egressInformation: [],
          };
        });

        return {
          ...state,
          dataSourcesToImport:
            action.payload === 1 ? null : dataSourcesToImport,
          formState: action.payload,
        };
      }

      // SUBMIT FORM
      case "SUBMIT_SQL_FORM": {
        const dataSourcesToImport = state.selected.map((item) => {
            // remove everything before the last period and save as the schema.
            const lastDotIndex = item.name.lastIndexOf(".");
            if (lastDotIndex !== -1) {
                item.name = item.name.slice(lastDotIndex + 1); // returns the tablename, after the last dot
            }
          const { previouslyImported, ...cleanItem } = item;

          return {
            ...cleanItem,
            ingressInformation: null,
            egressInformation: [],
          };
        });

        const variables = {
          connectionId: connectionId,
          dataSourcesToImport: dataSourcesToImport,
        };

        importSources({ query: importDataSources, variables: variables });
        return {
          ...state,
        };
      }

      // CREATE ERROR
      case "CREATE_ERROR": {
        return {
          ...state,
          createError: action.payload,
        };
      }
    }
  };

  const initialState = {
    history: [],
    tree: [],
    selected: [],
    fetchingItem: null,
    currentItem: null,
    loading: true,
    formState: 1,
    dataSourcesToImport: [],
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  if (state.formState === 1)
    return (
      <SelectSourceForm
        importedSourcesErrors={importedSourcesErrors}
        importErrors={importErrors}
        getUpdatedConnection={getUpdatedConnection}
        state={state}
        connectionType={data?.serverType}
        connectionId={data?.id}
        dispatch={dispatch}
        browseData={browseData}
        waiting={waiting}
        setWaiting={setWaiting}
      />
    );

  if (state.formState === 2)
    return (
      <DataSourcesForm
        dispatch={dispatch}
        connectionId={connectionId}
        data={data}
        sources={state.dataSourcesToImport}
        getUpdatedConnection={getUpdatedConnection}
        importErrors={importErrors}
      />
    );
};

const FileBrowserComponent = ({ getUpdatedConnection, data, importErrors }) => {
  //Body Section of Widget
  function Body() {
    return (
      <FileBrowser
        data={data}
        getUpdatedConnection={getUpdatedConnection}
        importErrors={importErrors}
      />
    );
  }

  //Actions Section of Widget
  return <Card headless={true} body={Body} />;
};

export default FileBrowserComponent;
