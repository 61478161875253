export const allWorkflows = /* GraphQL */ `
  query (
    $first: Int
    $after: String
    $tagFilter: CustomTagFilterInput!
    $order: [WorkflowSortInput!]
    $where: WorkflowFilterInput
    $customSort: [CustomSortInputModelInput!]
  ) {
    allWorkflows(
      first: $first
      after: $after
      tagFilter: $tagFilter
      order: $order
      where: $where
      customSort: $customSort
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
      edges {
        cursor
        node {
          id
          enabled
          scheduledJob {
            recurrenceInHours
          }
          nextScheduledReport
          name
          dependencies {
            id
            dataSourceId
            etlPipelineId
            servicerTransferFeedId
          }
          tagInstances {
            id
            remoteObjectId
            tag {
              name
              description
              id
            }
            tagId
            type
          }
        }
      }
    }
  }
`;

export const workflowById = /* GraphQL */ `
  query ($workflowId: Int!) {
    workflowById(workflowId: $workflowId) {
      alertTriggerTimestamp
      name
      dependencies {
        dataSourceDataQualityThreshold
        dataSourceDataScore
        dataSourceEtlParameterName
        dataSourceRefreshSummaryResultState
        etlPipelineResultState
        etlProviderInstanceId
        id
        name
        friendlyName
        servicerTransferSummaryResultState
        type
        dataSourceDependencies {
          id
          name
          friendlyName
        }
        pipelineDependencies {
          etlProviderInstanceId
          name
        }
        transformationDependencies {
          id
          name
        }
      }
      alertTriggerTime {
        activeMonitoring
        frequency
        hour
        isAM
        minutes
        timeZone
        weekday
        workingDayObservationTypes
      }
      alerts {
        alertType
        boolFlag
        enabled
        id
        sourceId
        sourceType
        threshold
        triggerTime
      }
      batchId
      batchName
      tagInstanceInputs {
        name
        remoteObjectId
        tagId
        type
      }
      tagInstances {
        enabled
        id
        remoteObjectId
        tagId
        type
      }
    }
  }
`;

export const workflowByIdTags = /* GraphQL */ `
  query ($workflowId: Int!) {
    workflowById(workflowId: $workflowId) {
      tagInstances {
        id
        remoteObjectId
        tag {
          name
          description
          id
        }
        tagId
        type
      }
    }
  }
`;

export const allBatchesByWorkflowId = /* GraphQL */ `
  query ($workflowId: Int!) {
    allBatchesByWorkflowId(workflowId: $workflowId) {
      batchNames
    }
  }
`;

export const workflowAlerts = /* GraphQL */ `
  query ($workflowId: Int!) {
    workflowById(workflowId: $workflowId) {
      alerts {
        id
        enabled
        instances {
          id
          alertId
          notificationType
          enabled
          members {
            id
            alertInstanceId
            userId
            user {
              displayName
              emailAddress
              id
              profilePhoto
            }
            workGroupId
            group {
              displayName
              id
            }
          }
        }
        alertType
      }
    }
  }
`;

export const allBatchNamesByWorkflowId = /* GraphQL */ `
  query (
    $first: Int
    $after: String
    $order: [WorkflowBatchSummarySortInput!]
    $where: WorkflowBatchSummaryFilterInput
    $workflowId: Int!
  ) {
    allBatchNamesByWorkflowId(
      first: $first
      after: $after
      order: $order
      where: $where
      workflowId: $workflowId
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
      edges {
        cursor
        node {
          id
          batchName
          resultState
          createdOn
          workflowId
        }
      }
    }
  }
`;
