import React, { useState } from "react";
import { StyledSelect } from "../../../../Form/FormControls";
import DataSourceAction from "./DataSourceAction";
import ServicerTransferFeedAction from "./ServicerTransferFeedAction";
import EtlPipelineAction from "./EtlPipelineAction";
import { v4 as uuid } from "uuid";
import Button from "../../../../Button";

const ActionSelector = ({
  dispatch,
  segment,
  dependencies,
  stateSources,
  stateTransformations,
  isInserting,
}) => {
  const buildUsedEntities = (dependencies) => {
    const usedDataSources = new Set();
    const usedTransformations = new Set();
    const usedEtlPipelines = new Set();
    const uniquePipelineKeys = new Set(); // Auxiliary set to track unique pipeline keys

    // Loop through each dependency
    dependencies.forEach((dep) => {
      // Add data source IDs from both root nodes and dependencies
      if (dep.id && dep.type === "DATA_SOURCE") {
        usedDataSources.add(dep.id);
      }
      dep.dataSourceDependencies?.forEach((dataSourceId) =>
        usedDataSources.add(dataSourceId)
      );

      // Add transformation IDs from both root nodes and dependencies
      if (dep.id && dep.type === "TRANSFORMATION") {
        usedTransformations.add(dep.id);
      }
      dep.transformationDependencies?.forEach((transformationId) =>
        usedTransformations.add(transformationId)
      );

      // Add ETL pipelines from both root nodes and dependencies
      if (
        dep.type === "ETL_PIPELINE" &&
        dep.etlProviderInstanceId &&
        dep.name
      ) {
        const pipelineKey = `${dep.name}_${dep.etlProviderInstanceId}`;
        if (!uniquePipelineKeys.has(pipelineKey)) {
          uniquePipelineKeys.add(pipelineKey);
          usedEtlPipelines.add({
            name: dep.name,
            etlPipelineId: dep.etlProviderInstanceId,
          });
        }
      }

      dep.pipelineDependencies?.forEach((pipelineDep) => {
        const pipelineKey = `${pipelineDep.name}_${pipelineDep.etlProviderInstanceId}`;
        if (!uniquePipelineKeys.has(pipelineKey)) {
          uniquePipelineKeys.add(pipelineKey);
          usedEtlPipelines.add({
            name: pipelineDep.name,
            etlPipelineId: pipelineDep.etlProviderInstanceId,
          });
        }
      });
    });

    // Convert sets to arrays
    return {
      usedDataSources: Array.from(usedDataSources),
      usedTransformations: Array.from(usedTransformations),
      usedEtlPipelines: Array.from(usedEtlPipelines),
    };
  };

  const { usedDataSources, usedTransformations, usedEtlPipelines } =
    buildUsedEntities(dependencies);

  const parentId = isInserting?.segment?.id;
  const parentName = isInserting?.segment?.name;
  const parentEtlProviderInstanceId =
    isInserting?.segment?.etlProviderInstanceId;
  const parentType = isInserting?.segment?.type;

  const initSegment =
    isInserting?.insertType === "child"
      ? {
          name: null,
          dataSourceDependencies:
            parentType === "DATA_SOURCE" ? [parentId] : [],
          pipelineDependencies:
            parentType === "ETL_PIPELINE"
              ? [
                  {
                    name: parentName,
                    etlProviderInstanceId: parentEtlProviderInstanceId,
                  },
                ]
              : [],
          transformationDependencies:
            parentType === "TRANSFORMATION" ? [parentId] : [],
          id: null,
          uid: uuid(),
          isTermination: segment?.isTermination,
          type: null,
          etlProviderInstanceId: parentEtlProviderInstanceId ?? null, // Set etlProviderInstanceId correctly
          dataQualityThreshold: null,
          currentThreshold: 0,
        }
      : isInserting?.insertType === "link"
      ? {
          name: null,
          dataSourceDependencies: [],
          pipelineDependencies: [],
          transformationDependencies: [],
          id: null,
          uid: uuid(),
          isTermination: segment?.isTermination,
          type: null,
          etlProviderInstanceId: null,
          dataQualityThreshold: null,
          currentThreshold: 0,
        }
      : isInserting?.insertType === "parent" ||
        isInserting?.insertType === "replace"
      ? {
          name: null,
          dataSourceDependencies: segment?.dataSourceDependencies ?? [],
          pipelineDependencies: segment?.pipelineDependencies ?? [],
          transformationDependencies: segment?.transformationDependencies ?? [],
          id: null,
          uid: uuid(),
          isTermination: segment?.isTermination,
          type: null,
          etlProviderInstanceId: null,
          dataQualityThreshold: null,
          currentThreshold: 0,
        }
      : null;

  const [insertSegment, setInsertSegment] = useState(initSegment);

  const actionTypeOptions = [
    { value: "DATA_SOURCE", label: "Data Source" },
    { value: "ETL_PIPELINE", label: "ETL Pipeline" },
    { value: "TRANSFORMATION", label: "Servicer Transfer Feed" },
  ];

  const [selectedActionType, setSelectedActionType] = useState(
    segment?.type && !isInserting
      ? actionTypeOptions.find((o) => o.value === segment.type)
      : null
  );

  const handleActionTypeChange = (selectedOption) => {
    if (isInserting) {
      setSelectedActionType(selectedOption);
      setInsertSegment({
        ...initSegment,
        type: selectedOption?.value,
      });
    } else {
      setSelectedActionType(selectedOption);
      dispatch({
        type: "SELECT_DEPENDENCY_TYPE",
        payload: {
          pipeline: segment,
          type: selectedOption?.value,
        },
      });
    }
  };
  // Once parent is found, search for segments that depend on this parent
  let referencingSegments = [];

  if (isInserting && isInserting.insertType === "parent") {
    let updatedSegment = {
      ...segment,
    };

    if (insertSegment?.type === "DATA_SOURCE" && insertSegment?.id) {
      updatedSegment.dataSourceDependencies = [insertSegment?.id];
    }

    if (insertSegment?.type === "TRANSFORMATION" && insertSegment?.id) {
      updatedSegment.transformationDependencies = [insertSegment?.id];
    }
    if (
      insertSegment?.type === "ETL_PIPELINE" &&
      insertSegment?.etlProviderInstanceId &&
      insertSegment?.name
    ) {
      updatedSegment.pipelineDependencies = [
        {
          etlProviderInstanceId: insertSegment?.etlProviderInstanceId,
          name: insertSegment?.name,
        },
      ];
    }

    referencingSegments = [updatedSegment];
  }

  if (isInserting && isInserting?.insertType === "child") {
    // Helper function to find segments that depend on a given id
    const findDependentSegment = (targetId, depType) => {
      return dependencies.find((dep) => dep[depType].includes(targetId));
    };

    const findDependentSegmentETL = (targetEtlId, targetEtlName, depType) => {
      return dependencies.find((dep) =>
        dep[depType].forEach((d) => {
          return (
            d.etlProviderInstanceId === targetEtlId && d.name === targetEtlName
          );
        })
      );
    };

    // Find the parent and child by traversing dependencies
    let parent = null;
    let child = null;

    // Identify the parent and child segments based on existing dependencies using id
    dependencies.forEach((dep) => {
      if (
        dep.id === segment.id &&
        isInserting?.insertType === "child" &&
        dep.type !== "ETL_PIPELINE"
      ) {
        parent = dep; // Current segment is the parent
      } else if (
        dep.etlProviderInstanceId === segment.etlProviderInstanceId &&
        isInserting?.insertType === "child" &&
        dep.type === "ETL_PIPELINE"
      ) {
        parent = dep; // Current segment is the parent
      }

      // Check if current dependency is a parent of the inserting segment using id and type
      if (
        findDependentSegment(segment.id, "dataSourceDependencies") ||
        findDependentSegmentETL(
          segment.etlProviderInstanceId,
          segment.name,
          "pipelineDependencies"
        ) ||
        findDependentSegment(segment.id, "transformationDependencies")
      ) {
        child = dep; // Current segment is a child
      }
    });
    console.error(parent, "parent id?");
    console.error(child, "child id?");
    if (!parent || !child) {
      console.error("Could not identify parent or child");
      // return;
    }

    // Find all segments that reference the parent
    dependencies.forEach((dep) => {
      const isReferencingParent =
        dep.dataSourceDependencies.includes(parent.id) ||
        dep.pipelineDependencies.includes(parent.id) ||
        dep.transformationDependencies.includes(parent.id);

      if (isReferencingParent) {
        referencingSegments.push(dep);
      }
    });

    referencingSegments = referencingSegments.map((dep) => {
      const updatedDep = { ...dep };

      if (isInserting?.insertType === "child") {
        // Handle DATA_SOURCE dependencies
        if (parent.type === "DATA_SOURCE") {
          // Remove the parent ID from dataSourceDependencies
          updatedDep.dataSourceDependencies =
            updatedDep.dataSourceDependencies.filter(
              (referenceId) => referenceId !== parent.id
            );

          if (insertSegment.type === "DATA_SOURCE" && insertSegment.id) {
            // Add the new segment's ID to dataSourceDependencies

            updatedDep.dataSourceDependencies.push(insertSegment.id);
          }

          if (insertSegment?.type === "TRANSFORMATION" && insertSegment.id) {
            // Add the new segment's ID to transformationDependencies
            updatedDep.transformationDependencies.push(insertSegment.id);
          }
        }

        // Handle TRANSFORMATION dependencies
        if (parent.type === "TRANSFORMATION") {
          // Remove the parent ID from transformationDependencies
          updatedDep.transformationDependencies =
            updatedDep.transformationDependencies.filter(
              (referenceId) => referenceId !== parent.id
            );

          if (insertSegment?.type === "TRANSFORMATION" && insertSegment.id) {
            // Add the new segment's ID to transformationDependencies
            updatedDep.transformationDependencies.push(insertSegment.id);
          }
          if (insertSegment.type === "DATA_SOURCE" && insertSegment.id) {
            // Add the new segment's ID to dataSourceDependencies
            updatedDep.dataSourceDependencies.push(insertSegment.id);
          }
        }

        // Handle ETL_PIPELINE dependencies
        if (
          parent.type === "ETL_PIPELINE" ||
          insertSegment.type === "ETL_PIPELINE"
        ) {
          // Remove the parent reference from pipelineDependencies
          updatedDep.pipelineDependencies =
            updatedDep.pipelineDependencies.filter(
              (pipelineDep) =>
                pipelineDep.etlProviderInstanceId !==
                  parentEtlProviderInstanceId || pipelineDep.name !== parentName
            );

          // Add the new ETL pipeline to pipelineDependencies if not already present
          if (
            !updatedDep.pipelineDependencies.some(
              (pipelineDep) =>
                pipelineDep.etlProviderInstanceId ===
                  insertSegment.etlProviderInstanceId &&
                pipelineDep.name === insertSegment.name
            )
          ) {
            updatedDep.pipelineDependencies.push({
              etlProviderInstanceId: insertSegment.etlProviderInstanceId,
              name: insertSegment.name,
            });
          }
        }
      }

      return updatedDep;
    });
  }

  if (isInserting && isInserting.insertType === "link") {
    const targetType = isInserting?.segment?.type;
    const targetId = isInserting?.segment?.id;
    const targetEtlProviderInstanceId =
      isInserting?.segment?.etlProviderInstanceId;
    const targetName = isInserting?.segment?.name;

    // Find all segments that depend on the target segment
    const inreferencingSegments = dependencies.filter((dep) => {
      // Check based on the type of the target segment
      if (targetType === "DATA_SOURCE") {
        // Look for matching ID in dataSourceDependencies
        return dep.dataSourceDependencies?.includes(targetId);
      } else if (targetType === "TRANSFORMATION") {
        // Look for matching ID in transformationDependencies
        return dep.transformationDependencies?.includes(targetId);
      } else if (targetType === "ETL_PIPELINE") {
        // For ETL pipeline, match by etlProviderInstanceId and name
        return dep.pipelineDependencies?.some(
          (pipelineDep) =>
            pipelineDep.etlProviderInstanceId === targetEtlProviderInstanceId &&
            pipelineDep.name === targetName
        );
      }
      return false; // If the targetType is none of the above, return false
    });

    // Update referencing segments to include the new segment based on its type
    referencingSegments = inreferencingSegments.map((dep) => {
      const updatedDep = { ...dep };

      // Check the type of the new segment and update the corresponding dependency array
      if (insertSegment.type === "DATA_SOURCE") {
        // Handle Data Source case
        updatedDep.dataSourceDependencies = Array.from(
          new Set([...updatedDep.dataSourceDependencies, insertSegment.id])
        );
      } else if (insertSegment.type === "TRANSFORMATION") {
        // Handle Transformation case
        updatedDep.transformationDependencies = Array.from(
          new Set([...updatedDep.transformationDependencies, insertSegment.id])
        );
      } else if (insertSegment.type === "ETL_PIPELINE") {
        // Handle ETL Pipeline case
        updatedDep.pipelineDependencies = Array.from(
          new Set([
            ...updatedDep.pipelineDependencies,
            {
              etlProviderInstanceId: insertSegment.etlProviderInstanceId,
              name: insertSegment.name,
            },
          ])
        );
      }

      return updatedDep;
    });
  }

  return (
    <div>
      {!selectedActionType ? (
        <StyledSelect
          name="actionType"
          label="Action Type"
          options={
            dependencies?.length === 1
              ? actionTypeOptions.filter((o) => o.value !== "ETL_PIPELINE")
              : actionTypeOptions
          }
          value={selectedActionType}
          onChange={handleActionTypeChange}
          placeholder="Select Action Type"
          menuPortalTarget={document.body}
        />
      ) : (
        !isInserting && (
          <Button
            onClick={() =>
              dispatch({
                type: "INSERT_SEGMENT_NEW_TYPE",
                payload: { segment, insertType: "replace" },
              })
            }
          >
            Edit Entity
          </Button>
        )
      )}

      <div>
        {selectedActionType?.value === "DATA_SOURCE" ? (
          <DataSourceAction
            dispatch={dispatch}
            segment={isInserting ? insertSegment : segment}
            stateSources={stateSources}
            stateTransformations={stateTransformations}
            isInserting={isInserting}
            setInsertSegment={setInsertSegment}
            usedDataSources={usedDataSources}
          />
        ) : selectedActionType?.value === "ETL_PIPELINE" ? (
          <EtlPipelineAction
            dispatch={dispatch}
            segment={isInserting ? insertSegment : segment}
            stateSources={stateSources}
            stateTransformations={stateTransformations}
            isInserting={isInserting}
            setInsertSegment={setInsertSegment}
            usedEtlPipelines={usedEtlPipelines}
          />
        ) : selectedActionType?.value === "TRANSFORMATION" ? (
          <ServicerTransferFeedAction
            dispatch={dispatch}
            segment={isInserting ? insertSegment : segment}
            stateSources={stateSources}
            stateTransformations={stateTransformations}
            isInserting={isInserting}
            setInsertSegment={setInsertSegment}
            usedTransformations={usedTransformations}
          />
        ) : null}
      </div>

      {isInserting && (
        <Button
          disabled={!selectedActionType
            || (selectedActionType?.value === "DATA_SOURCE" && (insertSegment.dataQualityThreshold == null || !insertSegment.id))
            || (selectedActionType?.value === "ETL_PIPELINE" && (!insertSegment.name || !insertSegment.etlProviderInstanceId))
            || (selectedActionType?.value === "TRANSFORMATION" && (!insertSegment.id))}
          onClick={() =>
            isInserting.insertType === "replace"
              ? dispatch({
                  type: "REPLACE_SEGMENT",
                  payload: {
                    replacement: insertSegment,
                    original: segment,
                  },
                })
              : dispatch({
                  type: "INSERT_SEGMENT",
                  payload: {
                    newSegment: insertSegment,
                    segmentsToUpdate: referencingSegments,
                  },
                })
          }
        >
          Insert
        </Button>
      )}
    </div>
  );
};

export default ActionSelector;
