import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom"; // Import useParams from react-router-dom
import Card from "../../components/Card";
import {
    Container,
    Button,
    Box,
    Modal,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Paper,
    IconButton,
    Menu,
    MenuItem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from "@mui/material";
import {
    MdExpandMore as ExpandMoreIcon,
    MdArrowBack,
    MdMoreVert,
    MdScreenshotMonitor,
    MdEdit,
} from "react-icons/md";
import { FaFilePdf } from "react-icons/fa";
import RecordFinder from "./widgetTemplates/RecordFinder";
import RecordFinderConfig from "./widgetConfigurations/RecordFinderConfig";
import ReportSummaryConfig from "./widgetConfigurations/ReportSummaryConfig";
import ReportSummary from "./widgetTemplates/ReportSummary";
import PolicyProfileConfig from "./widgetConfigurations/PolicyProfileConfig";
import PolicyProfile from "./widgetTemplates/PolicyProfile";
import DynamicViews from "./widgetTemplates/DynamicViews";
import DynamicViewsConfig from "./widgetConfigurations/DynamicViewsConfig";
import Oversight from "./widgetTemplates/Oversight";
import OversightConfig from "./widgetConfigurations/OversightConfig";
import PreviewComponent from "./Preview";
import DataSourceAPIScoreConfig from "./widgetConfigurations/DataSourceAPIScoreConfig";
import DataSourceTableConfig from "./widgetConfigurations/DataSourceTableConfig";
import DataSourceListConfig from "./widgetConfigurations/DataSourceListConfig";
import DataSourceScore from "./widgetTemplates/DataSourceScore";
import DataSourceList from "./widgetTemplates/DataSourceList";
import { Responsive, WidthProvider } from "react-grid-layout";
import { useHistory } from "react-router-dom";
import { workspaceById } from "../../api/workspaceQueries";
import {
    updateWorkspace,
    updateWorkspaceEnabled,
} from "../../api/workspaceMutations";
import { useApi } from "../../api/useApi";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Permissions from "../../components/Form/PermissionsWithoutFormik";

// Wrap GridLayout with WidthProvider
const ResponsiveGridLayout = WidthProvider(Responsive);

// Map widget configs names to their respective components
const widgetConfigurations = {
    "Dynamic View": RecordFinderConfig,
    "Dynamic Views": DynamicViewsConfig,
    Oversight: OversightConfig,
    "Report Summary": ReportSummaryConfig,
    "Sources List": DataSourceListConfig,
    "Policy Profile": PolicyProfileConfig,
    // Map explore config names to their respective components
    "Source Report: Score": DataSourceAPIScoreConfig,
    "Data Source Table": DataSourceTableConfig,
};

// Map widget names to their respective components
const widgetComponents = {
    "Dynamic View": RecordFinder,
    "Dynamic Views": DynamicViews,
    Oversight: Oversight,
    "Report Summary": ReportSummary,
    "Sources List": DataSourceList,
    "Policy Profile": PolicyProfile,
    // Source Report Score
    "Source Report: Score": DataSourceScore,
};

const Workspace = (props) => {
    const { workspaceId: paramId } = useParams();
    const workspaceId = props?.incomingWorkspace?.id || paramId;

    const [workspace, setWorkspace] = useState(props?.incomingWorkspace ?? null);

    const [
        { loading, errors: workspaceErrors, data: workspaceData },
        getWorkspace,
    ] = useApi();

    useEffect(() => {
        if (workspaceData?.workspaceById) {
            const parsedWorkspace = {
                ...workspaceData.workspaceById,
                configuration: JSON.parse(workspaceData.workspaceById.configuration),
            };
            setWorkspace(parsedWorkspace);
        }
    }, [workspaceData]);

    useEffect(() => {
        if (!props?.incomingWorkspace) {
            getWorkspace({
                query: workspaceById,
                variables: {
                    workSpaceId: Number(workspaceId),
                },
            });
        }
    }, [workspaceId, props?.incomingWorkspace]);

    const refreshWorkspace = () => {
        getWorkspace({
            query: workspaceById,
            variables: {
                workSpaceId: Number(workspaceId),
            },
        });
    };

    const Body = () => {
        const [openAddWidget, setOpenAddWidget] = useState(false);
        const [openEditWidget, setOpenEditWidget] = useState(false);
        const [openAddSection, setOpenAddSection] = useState(false);
        const [widgetStep, setWidgetStep] = useState(1);
        const [selectedCategory, setSelectedCategory] = useState("General");
        const [selectedWidget, setSelectedWidget] = useState(null);
        const [formData, setFormData] = useState(null);
        const [editFormData, setEditFormData] = useState(null);
        const [currentWidget, setCurrentWidget] = useState(null);
        const [anchorEl, setAnchorEl] = useState(null);
        const [openRemove, setOpenRemove] = useState(false);
        const [newSection, setNewSection] = useState({ name: "", description: "" });
        const [openEditSection, setOpenEditSection] = useState(false);
        const [sectionToEdit, setSectionToEdit] = useState(null);
        const [openRemoveSection, setOpenRemoveSection] = useState(false);
        const [sectionToRemove, setSectionToRemove] = useState(null);
        const [removeSectionAndWidgets, setRemoveSectionAndWidgets] =
            useState(null);

        const [
            { loading: updateLoading, errors: updateErrors, data: updateData },
            updateWorkspaceAction,
        ] = useApi();

        useEffect(() => {
            if (updateData && !updateErrors) {
                const parsedWorkspace = {
                    ...updateData?.updateWorkspace,
                    configuration: JSON.parse(updateData?.updateWorkspace?.configuration),
                };
                setWorkspace(parsedWorkspace);
            }
        }, [updateData]);

        const layoutPerSection = (section) => {
            return workspace.configuration.widgets
                .filter((widget) => widget.parameters?.sectionId === section.id)
                .map((widget, index) => ({
                    i: widget.id,
                    x: widget.x || 0,
                    y: widget.y || index,
                    w: widget.width || widget.parameters.defaultWidth || 4,
                    h: widget.height || widget.parameters.defaultHeight || 4,
                    minW: widget.parameters.minWidth || 2,
                    maxW: widget.parameters.maxWidth || 4,
                    minH: widget.parameters.minHeight || 4,
                    maxH: widget.parameters.maxHeight || 4,
                }));
        };

        const layoutWithoutSections = workspace.configuration.widgets
            .filter((widget) => !widget.parameters?.sectionId)
            .map((widget, index) => ({
                i: widget.id,
                x: widget.x || 0,
                y: widget.y || index,
                w: widget.width || widget.parameters.defaultWidth || 4,
                h: widget.height || widget.parameters.defaultHeight || 4,
                minW: widget.parameters.minWidth || 2,
                maxW: widget.parameters.maxWidth || 4,
                minH: widget.parameters.minHeight || 4,
                maxH: widget.parameters.maxHeight || 4,
            }));

        const handleOpenEditSection = (section) => {
            setSectionToEdit(section);
            setOpenEditSection(true);
        };

        const handleCloseEditSection = () => {
            setSectionToEdit(null);
            setOpenEditSection(false);
        };

        const handleOpenRemoveSection = (section) => {
            setSectionToRemove(section);
            setRemoveSectionAndWidgets(null);
            setOpenRemoveSection(true);
        };

        const handleCloseRemoveSection = () => {
            setSectionToRemove(null);
            setRemoveSectionAndWidgets(null);
            setOpenRemoveSection(false);
        };

        const handleSaveSectionChanges = (updatedSection) => {
            // Update the sections within the workspace configuration
            const updatedSections = workspace.configuration.sections.map((section) =>
                section.id === updatedSection.id ? updatedSection : section
            );

            // Create a local variable for the updated workspace
            const newWorkspace = {
                ...workspace,
                configuration: {
                    ...workspace.configuration,
                    sections: updatedSections,
                },
            };

            // Call the updateWorkspaceAction to save the changes
            updateWorkspaceAction({
                query: updateWorkspace,
                variables: {
                    workSpaceModel: {
                        ...newWorkspace,
                        configuration: JSON.stringify(newWorkspace.configuration ?? ""),
                    },
                },
            });

            handleCloseEditSection();
        };

        const handleRemoveSection = () => {
            // Create a copy of the workspace to update
            let updatedWorkspace = { ...workspace };

            // Filter out the section to remove
            const updatedSections = updatedWorkspace.configuration.sections.filter(
                (section) => section.id !== sectionToRemove.id
            );

            let updatedWidgets = [...updatedWorkspace.configuration.widgets];

            // Update or remove widgets based on the section removal
            if (!removeSectionAndWidgets) {
                updatedWidgets = updatedWidgets.map((widget) =>
                    widget.parameters?.sectionId === sectionToRemove.id
                        ? {
                            ...widget,
                            parameters: { ...widget.parameters, sectionId: null },
                        }
                        : widget
                );
            } else {
                updatedWidgets = updatedWidgets.filter(
                    (widget) => widget.parameters?.sectionId !== sectionToRemove.id
                );
            }

            // Update the workspace configuration with the filtered sections and widgets
            updatedWorkspace = {
                ...updatedWorkspace,
                configuration: {
                    ...updatedWorkspace.configuration,
                    sections: updatedSections,
                    widgets: updatedWidgets,
                },
            };

            // Call the updateWorkspaceAction to save the changes
            updateWorkspaceAction({
                query: updateWorkspace,
                variables: {
                    workSpaceModel: {
                        ...updatedWorkspace,
                        configuration: JSON.stringify(updatedWorkspace.configuration ?? ""),
                    },
                },
            });

            handleCloseRemoveSection();
        };

        const handleOpenAddWidget = () => setOpenAddWidget(true);
        const handleCloseAddingModal = () => {
            setOpenAddWidget(false);
            setWidgetStep(1);
            setSelectedCategory("General");
            setSelectedWidget(null);
            setFormData(null);
        };
        const handleCloseAddWidget = () => {
            if (selectedWidget && formData) {
                const newWidget = {
                    id: Date.now().toString(),
                    type: selectedWidget,
                    parameters: formData,
                    x: 0,
                    y: workspace.configuration.widgets.length,
                    width: formData.defaultWidth || 4,
                    height: formData.defaultHeight || 4,
                };

                const updatedWidgets = [...workspace.configuration.widgets, newWidget];

                const updatedWorkspace = {
                    ...workspace,
                    configuration: {
                        ...workspace.configuration,
                        widgets: updatedWidgets,
                    },
                };

                // Call the updateWorkspaceAction to save the changes
                updateWorkspaceAction({
                    query: updateWorkspace,
                    variables: {
                        workSpaceModel: {
                            ...updatedWorkspace,
                            configuration: JSON.stringify(
                                updatedWorkspace.configuration ?? ""
                            ),
                        },
                    },
                });
            }

            // Reset the state related to the widget adding process
            setOpenAddWidget(false);
            setWidgetStep(1);
            setSelectedCategory("General");
            setSelectedWidget(null);
            setFormData(null);
        };

        const handleOpenEditWidget = (widget, workspaceId) => {
            setCurrentWidget({ ...widget, workspaceId });
            setSelectedWidget(widget.type);
            setEditFormData(widget.parameters);
            setOpenEditWidget(true);
        };

        const handleCloseEditWidget = () => setOpenEditWidget(false);

        const handleOpenAddSection = () => setOpenAddSection(true);
        const handleCloseAddSection = () => {
            setNewSection({ name: "", description: "" });
            setOpenAddSection(false);
        };

        const handleAddSectionChange = (e) => {
            const { name, value } = e.target;
            setNewSection((prev) => ({ ...prev, [name]: value }));
        };

        const handleSaveSection = () => {
            if (!newSection.name) {
                // Handle validation
                return;
            }

            // Add the new section to the workspace's configuration
            const updatedSections = [
                ...workspace.configuration.sections,
                { ...newSection, id: Date.now() },
            ];

            // Create a local variable for the updated workspace
            const newWorkspace = {
                ...workspace,
                configuration: {
                    ...workspace.configuration,
                    sections: updatedSections,
                },
            };

            // Call the updateWorkspaceAction to save the changes
            updateWorkspaceAction({
                query: updateWorkspace,
                variables: {
                    workSpaceModel: {
                        ...newWorkspace,
                        configuration: JSON.stringify(newWorkspace.configuration ?? ""),
                    },
                },
            });

            handleCloseAddSection();
        };

        const handleAccordionChange = (category) => {
            setSelectedCategory((prev) => (prev === category ? null : category));
        };

        const handleMenuOpen = (event, widget, workspaceId) => {
            setAnchorEl(event.currentTarget);
            setCurrentWidget({ ...widget, workspaceId });
        };

        const handleMenuClose = () => {
            setAnchorEl(null);
        };

        const handleEditClick = () => {
            handleMenuClose();
            handleOpenEditWidget(currentWidget, currentWidget.workspaceId);
        };

        const workspaceRef = useRef(null); // Ref for capturing workspace

        const handleExportWorkspacePDF = () => {
            const input = workspaceRef.current; // Reference to the whole workspace
            const boxToHide = document.getElementById("add-box");
            const controlsToHide = document.getElementById("exportControls");
            if (!input || !boxToHide || !controlsToHide) {
                console.error("Workspace or controls not found");
                return;
            }

            // Hide the box before capturing
            boxToHide.style.display = "none";
            controlsToHide.style.display = "none";

            // Wait for 1-2 seconds for the box to be hidden
            setTimeout(() => {
                html2canvas(input, { useCORS: true, scale: 2 })
                    .then((canvas) => {
                        const imgData = canvas.toDataURL("image/jpeg", 0.5);
                        const pdf = new jsPDF({
                            orientation: "landscape",
                            unit: "px",
                            format: [canvas.width, canvas.height],
                        });
                        pdf.addImage(imgData, "JPEG", 0, 0, canvas.width, canvas.height);
                        pdf.save("workspace.pdf");

                        // Re-enable the box after capturing
                        boxToHide.style.display = "flex";
                        controlsToHide.style.display = "flex";
                    })
                    .catch((error) => {
                        console.error("Error capturing the workspace:", error);
                        // Re-enable the box in case of an error
                        boxToHide.style.display = "flex";
                        controlsToHide.style.display = "flex";
                    });
            }, 1000); // Adjust the delay as needed (1000ms = 1 second)
        };

        const handleExportWorkspaceJPG = () => {
            const input = workspaceRef.current; // Reference to the whole workspace
            const boxToHide = document.getElementById("add-box");
            const controlsToHide = document.getElementById("exportControls");
            if (!input || !boxToHide || !controlsToHide) {
                console.error("Workspace or controls not found");
                return;
            }

            // Hide the box before capturing
            boxToHide.style.display = "none";
            controlsToHide.style.display = "none";

            // Wait for 1-2 seconds for the box to be hidden
            setTimeout(() => {
                html2canvas(input, { useCORS: true, scale: 2 })
                    .then((canvas) => {
                        const imgData = canvas.toDataURL("image/jpeg");
                        const link = document.createElement("a");
                        link.href = imgData;
                        link.download = "workspace.jpg";
                        link.click();

                        // Re-enable the box after capturing
                        boxToHide.style.display = "flex";
                        controlsToHide.style.display = "flex";
                    })
                    .catch((error) => {
                        console.error("Error capturing the workspace:", error);
                        // Re-enable the box in case of an error
                        boxToHide.style.display = "flex";
                        controlsToHide.style.display = "flex";
                    });
            }, 1000); // Adjust the delay as needed (1000ms = 1 second)
        };

        const handleExportPDF = () => {
            const input = document.getElementById(currentWidget.id);

            if (!input) {
                console.error("Element not found:", currentWidget.id);
                return;
            }

            // Clone the widget HTML and create a new container for the screenshot
            const clone = input.cloneNode(true);
            const container = document.createElement("div");

            // Apply minimal CSS to the cloned widget
            container.style.position = "fixed";
            container.style.top = "0";
            container.style.left = "0";
            container.style.width = "100%";
            container.style.height = "100vh"; // Ensure it takes full viewport height
            container.style.overflow = "hidden"; // Prevent overflow issues
            container.style.zIndex = "-1"; // Ensure it doesn't interfere with the UI
            container.style.backgroundColor = "#fff"; // Ensure background is white for better screenshot quality

            // Apply minimal CSS to the cloned elements
            const applyMinimalStyles = (element) => {
                element.style.boxShadow = "none"; // Remove box-shadow as it is unsupported
                element.style.filter = "none"; // Remove filter as it is unsupported
                element.style.zoom = "1"; // Reset zoom as it is unsupported
                element.style.backgroundBlendMode = "normal"; // Reset background blend mode
                element.style.mixBlendMode = "normal"; // Reset mix blend mode
                element.style.borderImage = "none"; // Remove border image
                element.style.objectFit = "fill"; // Reset object fit
                element.style.writingMode = "horizontal-tb"; // Reset writing mode
                element.style.fontVariantLigatures = "normal"; // Reset font variant ligatures
            };

            // Apply minimal styles to all child elements
            clone.querySelectorAll("*").forEach((child) => applyMinimalStyles(child));
            applyMinimalStyles(clone);

            container.appendChild(clone);
            document.body.appendChild(container);

            // Ensure the element is fully rendered before taking the screenshot
            setTimeout(() => {
                html2canvas(clone, {
                    useCORS: true, // Ensure cross-origin images are rendered correctly
                    scale: 2, // Increase resolution of the screenshot
                })
                    .then((canvas) => {
                        const imgData = canvas.toDataURL("image/jpeg", 0.5); // Compress image to reduce size
                        const pdf = new jsPDF({
                            orientation: "landscape",
                            unit: "px",
                            format: [canvas.width, canvas.height],
                        });
                        pdf.addImage(imgData, "JPEG", 0, 0, canvas.width, canvas.height);
                        pdf.save(`${currentWidget.parameters.widgetName}.pdf`);

                        // Clean up by removing the cloned container
                        document.body.removeChild(container);
                    })
                    .catch((error) => {
                        console.error("Error capturing the widget:", error);
                        // Clean up by removing the cloned container
                        document.body.removeChild(container);
                    });
            }, 100); // Adjust timeout if needed
        };

        const handleExportJPG = () => {
            const input = document.getElementById(currentWidget.id);

            if (!input) {
                console.error("Element not found:", currentWidget.id);
                return;
            }

            // Clone the widget HTML and create a new container for the screenshot
            const clone = input.cloneNode(true);
            const container = document.createElement("div");

            // Apply minimal CSS to the cloned widget
            container.style.position = "fixed";
            container.style.top = "0";
            container.style.left = "0";
            container.style.width = "100%";
            container.style.height = "100vh"; // Ensure it takes full viewport height
            container.style.overflow = "hidden"; // Prevent overflow issues
            container.style.zIndex = "-1"; // Ensure it doesn't interfere with the UI
            container.style.backgroundColor = "#fff"; // Ensure background is white for better screenshot quality

            // Apply minimal CSS to the cloned elements
            const applyMinimalStyles = (element) => {
                element.style.boxShadow = "none"; // Remove box-shadow as it is unsupported
                element.style.filter = "none"; // Remove filter as it is unsupported
                element.style.zoom = "1"; // Reset zoom as it is unsupported
                element.style.backgroundBlendMode = "normal"; // Reset background blend mode
                element.style.mixBlendMode = "normal"; // Reset mix blend mode
                element.style.borderImage = "none"; // Remove border image
                element.style.objectFit = "fill"; // Reset object fit
                element.style.writingMode = "horizontal-tb"; // Reset writing mode
                element.style.fontVariantLigatures = "normal"; // Reset font variant ligatures
            };

            // Apply minimal styles to all child elements
            clone.querySelectorAll("*").forEach((child) => applyMinimalStyles(child));
            applyMinimalStyles(clone);

            container.appendChild(clone);
            document.body.appendChild(container);

            // Ensure the element is fully rendered before taking the screenshot
            setTimeout(() => {
                html2canvas(clone, {
                    useCORS: true, // Ensure cross-origin images are rendered correctly
                    scale: 2, // Increase resolution of the screenshot
                })
                    .then((canvas) => {
                        const imgData = canvas.toDataURL("image/jpeg");
                        const link = document.createElement("a");
                        link.href = imgData;
                        link.download = `${currentWidget.parameters.widgetName}.jpg`;
                        link.click();

                        // Clean up by removing the cloned container
                        document.body.removeChild(container);
                    })
                    .catch((error) => {
                        console.error("Error capturing the widget:", error);
                        // Clean up by removing the cloned container
                        document.body.removeChild(container);
                    });
            }, 100); // Adjust timeout if needed
        };

        const onSubmit = (data) => {
            setFormData(data);
        };

        const onEditSubmit = (data) => {
            // Update the widgets in the specific workspace
            const updatedWidgets = workspace.configuration.widgets.map((widget) =>
                widget.id === currentWidget.id
                    ? { ...widget, parameters: { ...data, id: currentWidget.id } }
                    : widget
            );

            // Create the updated workspace object
            const updatedWorkspace = {
                ...workspace,
                configuration: { ...workspace.configuration, widgets: updatedWidgets },
            };

            // Call the updateWorkspaceAction to save the changes
            updateWorkspaceAction({
                query: updateWorkspace,
                variables: {
                    workSpaceModel: {
                        ...updatedWorkspace,
                        configuration: JSON.stringify(updatedWorkspace.configuration ?? ""),
                    },
                },
            });

            setOpenEditWidget(false);
        };

        const onDelete = () => {
            // Filter out the current widget from the list
            const updatedWidgets = workspace.configuration.widgets.filter(
                (widget) => widget.id !== currentWidget.id
            );

            // Update the workspace configuration with the new widget list
            updateWorkspaceAction({
                query: updateWorkspace,
                variables: {
                    workSpaceModel: {
                        ...workspace,
                        configuration: JSON.stringify({
                            ...workspace.configuration,
                            widgets: updatedWidgets, // Remove the widget here
                        }),
                    },
                },
            });

            // Close modals after deletion
            setOpenEditWidget(false);
            setOpenRemove(false);
        };

        const WidgetConfigComponent = widgetConfigurations[selectedWidget] || null;

        const handleClose = () => {
            setOpenRemove(false);
            setCurrentWidget();
        };

        const handleDelete = () => {
            setOpenRemove(true);
        };

        return (
            <Container maxWidth="false" ref={workspaceRef}>
                <Box>
                    {/* Render your workspace layout and widgets */}
                    {workspace && (
                        <div
                            id="exportControls"
                            style={{
                                position: "fixed",
                                right: 0,
                                display: "flex",
                                flexDirection: "column",
                                margin: "1.5rem",
                                paddingTop: 0,
                                marginTop: 0,
                            }}
                        >
                            {/* Add export buttons here */}

                            <IconButton
                                title={"Export Workspace as PDF"}
                                onClick={handleExportWorkspacePDF}
                            >
                                <FaFilePdf />
                            </IconButton>

                            <IconButton
                                title={"Export Workspace as Image"}
                                onClick={handleExportWorkspaceJPG}
                            >
                                <MdScreenshotMonitor />
                            </IconButton>
                        </div>
                    )}
                </Box>
                {updateErrors && <ErrorMessages errors={updateErrors} />}
                {workspaceErrors && <ErrorMessages errors={workspaceErrors} />}
                {!workspace?.configuration?.widgets?.length && (
                    <Container maxWidth="md">
                        <Box mt={5} component={Paper} p={3}>
                            <Typography variant="h4" gutterBottom>
                                Welcome to Your Workspace
                            </Typography>
                            <Typography variant="body1" paragraph>
                                Workspaces empower you to craft your unique data narratives by
                                offering a flexible and customizable environment.
                            </Typography>
                            <Typography variant="body1" paragraph>
                                <strong>Get Started by Adding Widgets:</strong> Begin by
                                clicking the "Add Widget" button below to explore the various
                                pre-defined widgets or dive into our specialized components to
                                display the data that matters most to you.
                            </Typography>
                            <Box mt={4} display="flex" justifyContent="center">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setOpenAddWidget(true)}
                                    sx={{
                                        backgroundColor: "rgb(18, 17, 53)",
                                        "&:hover": {
                                            backgroundColor: "rgba(18, 17, 53, 0.8)",
                                        },
                                    }}
                                >
                                    Add Widget
                                </Button>
                            </Box>
                        </Box>
                    </Container>
                )}

                <Container maxWidth={false}>
                    {layoutWithoutSections.length > 0 && (
                        <ResponsiveGridLayout
                            className="layout"
                            layout={layoutWithoutSections}
                            rowHeight={30}
                            useCSSTransforms={false}
                            breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                            cols={{ lg: 4, md: 3, sm: 2, xs: 1, xxs: 1 }}
                            draggableCancel=".cancelSelectorName"
                            onDragStop={(layout) => {
                                const updatedWorkspace = {
                                    ...workspace,
                                    configuration: {
                                        ...workspace.configuration,
                                        widgets: workspace.configuration.widgets.map((widget) => {
                                            const widgetLayout = layout.find(
                                                (l) => l.i === widget.id
                                            );
                                            if (widgetLayout) {
                                                return {
                                                    ...widget,
                                                    x: widgetLayout.x,
                                                    y: widgetLayout.y,
                                                    width: widgetLayout.w,
                                                    height: widgetLayout.h,
                                                };
                                            }
                                            return widget;
                                        }),
                                    },
                                };

                                // Call the updateWorkspaceAction to save the changes
                                updateWorkspaceAction({
                                    query: updateWorkspace,
                                    variables: {
                                        workSpaceModel: {
                                            ...updatedWorkspace,
                                            configuration: JSON.stringify(
                                                updatedWorkspace.configuration ?? ""
                                            ),
                                        },
                                    },
                                });
                            }}
                            onResizeStop={(layout) => {
                                const updatedWorkspace = {
                                    ...workspace,
                                    configuration: {
                                        ...workspace.configuration,
                                        widgets: workspace.configuration.widgets.map((widget) => {
                                            const widgetLayout = layout.find(
                                                (l) => l.i === widget.id
                                            );
                                            if (widgetLayout) {
                                                return {
                                                    ...widget,
                                                    x: widgetLayout.x,
                                                    y: widgetLayout.y,
                                                    width: widgetLayout.w,
                                                    height: widgetLayout.h,
                                                };
                                            }
                                            return widget;
                                        }),
                                    },
                                };

                                // Call the updateWorkspaceAction to save the changes
                                updateWorkspaceAction({
                                    query: updateWorkspace,
                                    variables: {
                                        workSpaceModel: {
                                            ...updatedWorkspace,
                                            configuration: JSON.stringify(
                                                updatedWorkspace.configuration ?? ""
                                            ),
                                        },
                                    },
                                });
                            }}
                            draggableHandle=".widget-drag-handle"
                        >
                            {workspace.configuration.widgets
                                .filter((widget) => !widget.parameters?.sectionId)
                                .map((widget) => {
                                    const WidgetComponent = widgetComponents[widget.type] || null;

                                    return (
                                        WidgetComponent && (
                                            <div
                                                key={widget.id}
                                                id={widget.id}
                                                data-grid={{
                                                    i: widget.id,
                                                    x: widget.x,
                                                    y: widget.y,
                                                    w: widget.width,
                                                    h: widget.height,
                                                }}
                                                style={{ overflow: "hidden" }}
                                            >
                                                <Paper
                                                    sx={{
                                                        padding: 2,
                                                        backgroundColor: "#f5f5f5",
                                                        borderRadius: 0,
                                                        position: "relative",
                                                        height: "100%",
                                                        border: "1px solid #e9ecef",
                                                        boxShadow: "none", // Ensure no additional shadow
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "block",
                                                            position: "absolute",
                                                            top: 0,
                                                            left: 0,
                                                            width: "100%",
                                                            height: "43px",
                                                            cursor: "move",
                                                            background: "#eee",
                                                        }}
                                                    ></div>
                                                    <div
                                                        style={{
                                                            display: "block",
                                                            position: "absolute",
                                                            top: 0,
                                                            left: 0,
                                                            width: "100%",
                                                            height: "43px",
                                                            cursor: "move",

                                                            zIndex: 3,
                                                        }}
                                                        className="widget-drag-handle"
                                                    ></div>
                                                    <WidgetComponent data={widget.parameters} />
                                                    <IconButton
                                                        className="cancelSelectorName"
                                                        onClick={(event) =>
                                                            handleMenuOpen(event, widget, workspace.id)
                                                        }
                                                        sx={{
                                                            position: "absolute",
                                                            top: 8,
                                                            right: 8,
                                                            zIndex: 99,
                                                            backgroundColor: "rgba(0,0,0, 0.1)",
                                                            "&:hover": {
                                                                backgroundColor: "rgb(0, 159, 212)",
                                                            },
                                                            borderRadius: 0,
                                                            padding: "0.3rem",
                                                            fontSize: "1rem",
                                                        }}
                                                    >
                                                        <MdMoreVert />
                                                    </IconButton>
                                                    <Menu
                                                        anchorEl={anchorEl}
                                                        open={Boolean(anchorEl)}
                                                        onClose={handleMenuClose}
                                                        anchorOrigin={{
                                                            vertical: "bottom",
                                                            horizontal: "right",
                                                        }}
                                                        transformOrigin={{
                                                            vertical: "top",
                                                            horizontal: "right",
                                                        }}
                                                    >
                                                        <MenuItem onClick={handleExportPDF}>
                                                            Export as PDF
                                                        </MenuItem>
                                                        <MenuItem onClick={handleExportJPG}>
                                                            Export as JPG
                                                        </MenuItem>
                                                        <MenuItem onClick={handleEditClick}>Edit</MenuItem>
                                                        <MenuItem onClick={handleDelete}>Remove</MenuItem>
                                                    </Menu>
                                                </Paper>
                                            </div>
                                        )
                                    );
                                })}
                        </ResponsiveGridLayout>
                    )}

                    {workspace?.configuration?.sections?.map((section) => (
                        <Box key={section.id} sx={{ mb: 4 }}>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{
                                    background: "rgb(18, 17, 53)",
                                    padding: "1rem",
                                    color: "#fff",
                                    marginBottom: "1rem",
                                }}
                            >
                                <Box>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Box>
                                            <Typography variant="h4">{section.name}</Typography>

                                            <Typography variant="body1" sx={{ mb: 2 }}>
                                                {section.description}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box>
                                    <IconButton
                                        sx={{
                                            color: "#fff",
                                            "&:hover": {
                                                color: "rgb(0, 159, 212)",
                                            },
                                        }}
                                        onClick={() => handleOpenEditSection(section)}
                                    >
                                        <MdEdit />
                                    </IconButton>
                                    <IconButton
                                        sx={{
                                            color: "#fff",
                                            "&:hover": {
                                                color: "rgb(0, 159, 212)",
                                            },
                                        }}
                                        onClick={() => handleOpenRemoveSection(section)}
                                    >
                                        <MdMoreVert />
                                    </IconButton>
                                </Box>
                            </Box>
                            <ResponsiveGridLayout
                                className="layout"
                                layout={layoutPerSection(section)}
                                rowHeight={30}
                                breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                                cols={{ lg: 4, md: 3, sm: 2, xs: 1, xxs: 1 }}
                                draggableCancel=".cancelSelectorName"
                                onDragStop={(layout) => {
                                    const updatedWorkspace = {
                                        ...workspace,
                                        configuration: {
                                            ...workspace.configuration,
                                            widgets: workspace.configuration.widgets.map((widget) => {
                                                const widgetLayout = layout.find(
                                                    (l) => l.i === widget.id
                                                );
                                                if (widgetLayout) {
                                                    return {
                                                        ...widget,
                                                        x: widgetLayout.x,
                                                        y: widgetLayout.y,
                                                        width: widgetLayout.w,
                                                        height: widgetLayout.h,
                                                    };
                                                }
                                                return widget;
                                            }),
                                        },
                                    };

                                    // Call the updateWorkspaceAction to save the changes
                                    updateWorkspaceAction({
                                        query: updateWorkspace,
                                        variables: {
                                            workSpaceModel: {
                                                ...updatedWorkspace,
                                                configuration: JSON.stringify(
                                                    updatedWorkspace.configuration ?? ""
                                                ),
                                            },
                                        },
                                    });
                                }}
                                onResizeStop={(layout) => {
                                    const updatedWorkspace = {
                                        ...workspace,
                                        configuration: {
                                            ...workspace.configuration,
                                            widgets: workspace.configuration.widgets.map((widget) => {
                                                const widgetLayout = layout.find(
                                                    (l) => l.i === widget.id
                                                );
                                                if (widgetLayout) {
                                                    return {
                                                        ...widget,
                                                        x: widgetLayout.x,
                                                        y: widgetLayout.y,
                                                        width: widgetLayout.w,
                                                        height: widgetLayout.h,
                                                    };
                                                }
                                                return widget;
                                            }),
                                        },
                                    };

                                    // Call the updateWorkspaceAction to save the changes
                                    updateWorkspaceAction({
                                        query: updateWorkspace,
                                        variables: {
                                            workSpaceModel: {
                                                ...updatedWorkspace,
                                                configuration: JSON.stringify(
                                                    updatedWorkspace.configuration ?? ""
                                                ),
                                            },
                                        },
                                    });
                                }}
                                draggableHandle=".widget-drag-handle"
                            >
                                {workspace.configuration.widgets
                                    .filter(
                                        (widget) => widget.parameters?.sectionId === section.id
                                    )
                                    .map((widget) => {
                                        const WidgetComponent =
                                            widgetComponents[widget.type] || null;

                                        return (
                                            WidgetComponent && (
                                                <div
                                                    key={widget.id}
                                                    id={widget.id}
                                                    data-grid={{
                                                        i: widget.id,
                                                        x: widget.x,
                                                        y: widget.y,
                                                        w: widget.width,
                                                        h: widget.height,
                                                    }}
                                                    style={{ overflow: "hidden" }}
                                                >
                                                    <Paper
                                                        sx={{
                                                            padding: 2,
                                                            backgroundColor: "#f5f5f5",
                                                            borderRadius: 0,
                                                            position: "relative",
                                                            height: "100%",
                                                            border: "1px solid #e9ecef",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "block",
                                                                position: "absolute",
                                                                top: 0,
                                                                left: 0,
                                                                width: "100%",
                                                                height: "43px",
                                                                cursor: "move",
                                                                background: "#eee",
                                                            }}
                                                            className="widget-drag-handle"
                                                        ></div>
                                                        <WidgetComponent data={widget.parameters} />
                                                        <IconButton
                                                            className="cancelSelectorName"
                                                            onClick={(event) =>
                                                                handleMenuOpen(event, widget, workspace.id)
                                                            }
                                                            sx={{
                                                                position: "absolute",
                                                                top: 8,
                                                                right: 8,
                                                                zIndex: 99,
                                                                backgroundColor: "rgba(0,0,0, 0.1)",
                                                                "&:hover": {
                                                                    backgroundColor: "rgb(0, 159, 212)",
                                                                },
                                                                borderRadius: 0,
                                                                padding: "0.3rem",
                                                                fontSize: "1rem",
                                                            }}
                                                        >
                                                            <MdMoreVert />
                                                        </IconButton>
                                                        <Menu
                                                            anchorEl={anchorEl}
                                                            open={Boolean(anchorEl)}
                                                            onClose={handleMenuClose}
                                                            anchorOrigin={{
                                                                vertical: "bottom",
                                                                horizontal: "right",
                                                            }}
                                                            transformOrigin={{
                                                                vertical: "top",
                                                                horizontal: "right",
                                                            }}
                                                        >
                                                            <MenuItem onClick={handleExportPDF}>
                                                                Export as PDF
                                                            </MenuItem>
                                                            <MenuItem onClick={handleExportJPG}>
                                                                Export as JPG
                                                            </MenuItem>
                                                            <MenuItem onClick={handleEditClick}>
                                                                Edit
                                                            </MenuItem>
                                                            <MenuItem onClick={handleDelete}>Remove</MenuItem>
                                                        </Menu>
                                                    </Paper>
                                                </div>
                                            )
                                        );
                                    })}
                            </ResponsiveGridLayout>
                        </Box>
                    ))}
                    {!props.incomingWorkspace && (
                        <Box
                            id="add-box"
                            sx={{
                                position: "fixed",
                                bottom: 0,
                                left: 0,
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                backgroundColor: "#fff",
                                py: 2,
                                boxShadow: "0 -2px 10px rgba(0,0,0,0.1)",
                            }}
                        >
                            <Button
                                variant="contained"
                                onClick={handleOpenAddWidget}
                                sx={{
                                    marginLeft: "200px",
                                    marginRight: 2,
                                    backgroundColor: "rgb(18, 17, 53)",
                                    "&:hover": {
                                        backgroundColor: "rgba(18, 17, 53, 0.8)",
                                    },
                                }}
                            >
                                Add Widget
                            </Button>
                            <Button
                                sx={{
                                    backgroundColor: "rgb(18, 17, 53)",
                                    "&:hover": {
                                        backgroundColor: "rgba(18, 17, 53, 0.8)",
                                    },
                                }}
                                variant="contained"
                                onClick={handleOpenAddSection}
                            >
                                Add Section
                            </Button>
                        </Box>
                    )}

                    <Modal open={openEditWidget} onClose={handleCloseEditWidget}>
                        <Box
                            sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                width: { xs: "60%", sm: "70%", md: "80%", lg: "90%" },
                                maxWidth: "calc(100vw - 10%)",
                                maxHeight: "calc(100vh - 20px)",
                                bgcolor: "background.paper",
                                p: 4,
                                boxShadow: 24,
                                borderRadius: 2,
                                overflow: "auto",
                            }}
                        >
                            <Typography variant="h6">Edit Widget</Typography>
                            {selectedWidget && WidgetConfigComponent && (
                                <WidgetConfigComponent
                                    data={editFormData}
                                    onSubmit={onEditSubmit}
                                    onDelete={onDelete}
                                    isEditing={true}
                                    workspaceId={workspaceId}
                                    workspace={workspace}
                                />
                            )}
                        </Box>
                    </Modal>
                    <Modal open={openAddWidget} onClose={handleCloseAddingModal}>
                        <Box
                            sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                width: { xs: "60%", sm: "70%", md: "80%", lg: "90%" },
                                maxWidth: "calc(100vw - 10%)",
                                maxHeight: "calc(100vh - 20px)",
                                bgcolor: "background.paper",
                                p: 4,
                                boxShadow: 24,
                                borderRadius: 2,
                                overflow: "auto",
                            }}
                        >
                            {widgetStep === 1 ? (
                                <Box>
                                    <Typography variant="h6">Select Widget Type</Typography>
                                    <p>
                                        Explore a variety of pre-defined widgets or dive into our
                                        specialized components based on available APIs to explore
                                        and display the data that matters most to you.
                                    </p>
                                    <Box mt={2}>
                                        <Button
                                            variant="outlined"
                                            fullWidth
                                            onClick={() => setWidgetStep(3)}
                                            sx={{ marginBottom: 2 }}
                                        >
                                            Preset Widgets
                                        </Button>

                                        <Button
                                            variant="outlined"
                                            fullWidth
                                            onClick={() => setWidgetStep(2)}
                                        >
                                            Explore Data
                                        </Button>
                                    </Box>
                                </Box>
                            ) : widgetStep === 2 ? (
                                <Box>
                                    <Box display="flex" alignItems="center">
                                        <IconButton
                                            onClick={() =>
                                                selectedWidget ? setSelectedWidget() : setWidgetStep(1)
                                            }
                                        >
                                            <MdArrowBack />
                                        </IconButton>
                                        <Typography variant="h6">Explore Data</Typography>
                                    </Box>

                                    <Box display="flex">
                                        {!selectedWidget && (
                                            <Box mr={2} width={"250px"}>
                                                <Accordion
                                                    expanded={selectedCategory === "Sources"}
                                                    onChange={() => handleAccordionChange("Sources")}
                                                >
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                        <Typography>Source Report</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Button
                                                            fullWidth
                                                            onClick={() =>
                                                                setSelectedWidget("Source Report: Score")
                                                            }
                                                        >
                                                            Score
                                                        </Button>
                                                        <Button
                                                            disabled
                                                            fullWidth
                                                            onClick={() =>
                                                                setSelectedWidget(
                                                                    "Source Report: Constraint Failures"
                                                                )
                                                            }
                                                        >
                                                            Score History
                                                        </Button>
                                                        <Button
                                                            disabled
                                                            fullWidth
                                                            onClick={() =>
                                                                setSelectedWidget(
                                                                    "Source Report: Column Profiles"
                                                                )
                                                            }
                                                        >
                                                            Failures
                                                        </Button>
                                                        <Button
                                                            disabled
                                                            fullWidth
                                                            onClick={() =>
                                                                setSelectedWidget(
                                                                    "Source Report: Constraint Failures"
                                                                )
                                                            }
                                                        >
                                                            Total Failures
                                                        </Button>
                                                        <Button
                                                            disabled
                                                            fullWidth
                                                            onClick={() =>
                                                                setSelectedWidget(
                                                                    "Source Report: Constraint Failures"
                                                                )
                                                            }
                                                        >
                                                            Failures: Metrics
                                                        </Button>
                                                        <Button
                                                            disabled
                                                            fullWidth
                                                            onClick={() =>
                                                                setSelectedWidget(
                                                                    "Source Report: Column Profiles"
                                                                )
                                                            }
                                                        >
                                                            Failures: Column Entries
                                                        </Button>

                                                        <Button
                                                            disabled
                                                            fullWidth
                                                            onClick={() =>
                                                                setSelectedWidget("Data Source Table")
                                                            }
                                                        >
                                                            Failures: Policies
                                                        </Button>

                                                        <Button
                                                            disabled
                                                            fullWidth
                                                            onClick={() =>
                                                                setSelectedWidget("Data Source Table")
                                                            }
                                                        >
                                                            Failure Opportunities
                                                        </Button>

                                                        <Button
                                                            disabled
                                                            fullWidth
                                                            onClick={() =>
                                                                setSelectedWidget("Data Source Table")
                                                            }
                                                        >
                                                            Failure History
                                                        </Button>
                                                        <Button
                                                            disabled
                                                            fullWidth
                                                            onClick={() =>
                                                                setSelectedWidget("Data Source Table")
                                                            }
                                                        >
                                                            Row Count
                                                        </Button>
                                                    </AccordionDetails>
                                                </Accordion>

                                                <Accordion
                                                    expanded={selectedCategory === "Policies"}
                                                    onChange={() => handleAccordionChange("Policies")}
                                                >
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                        <Typography>Policies</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Button
                                                            disabled
                                                            fullWidth
                                                            onClick={() =>
                                                                setSelectedWidget("Policy Profile")
                                                            }
                                                        >
                                                            Policy Profile
                                                        </Button>
                                                    </AccordionDetails>
                                                </Accordion>

                                                {/* <Accordion
                        expanded={selectedCategory === "Transfers"}
                        onChange={() => handleAccordionChange("Transfers")}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>Transfers</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Button
                            fullWidth
                            onClick={() => setSelectedWidget("Widget2")}
                          >
                            Widget2
                          </Button>
                        </AccordionDetails>
                      </Accordion> */}
                                            </Box>
                                        )}
                                        <Box>
                                            {selectedWidget ? (
                                                <Box sx={{ maxWidth: "300px" }}>
                                                    <Typography
                                                        variant="h6"
                                                        sx={{ marginBottom: "1rem" }}
                                                    >
                                                        Configure {selectedWidget}
                                                    </Typography>

                                                    {WidgetConfigComponent && (
                                                        <WidgetConfigComponent
                                                            data={formData}
                                                            onSubmit={onSubmit}
                                                            workspaceId={workspaceId}
                                                            workspace={workspace}
                                                        />
                                                    )}
                                                </Box>
                                            ) : (
                                                <Typography variant="body1">
                                                    Select a widget to configure
                                                </Typography>
                                            )}
                                        </Box>
                                        {selectedWidget && (
                                            <PreviewComponent
                                                data={formData}
                                                selectedWidget={selectedWidget}
                                                workspaceId={workspaceId}
                                                workspace={workspace}
                                                handleCloseAddWidget={handleCloseAddWidget}
                                                setWorkspace={setWorkspace}
                                            />
                                        )}
                                    </Box>
                                </Box>
                            ) : (
                                <Box>
                                    <Box display="flex" alignItems="center">
                                        <IconButton
                                            onClick={() =>
                                                selectedWidget ? setSelectedWidget() : setWidgetStep(1)
                                            }
                                        >
                                            <MdArrowBack />
                                        </IconButton>
                                        <Typography variant="h6">Explore Data</Typography>
                                    </Box>
                                    <Box display="flex">
                                        {!selectedWidget && (
                                            <Box mr={2} width={"250px"}>
                                                <Accordion
                                                    expanded={selectedCategory === "General"}
                                                    onChange={() => handleAccordionChange("General")}
                                                >
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                        <Typography>General</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Button
                                                            fullWidth
                                                            onClick={() => setSelectedWidget("Dynamic View")}
                                                        >
                                                            Dynamic View
                                                        </Button>
                                                        <Button
                                                            fullWidth
                                                            onClick={() => setSelectedWidget("Dynamic Views")}
                                                        >
                                                            Dynamic Views
                                                        </Button>
                                                        <Button
                                                            fullWidth
                                                            onClick={() => setSelectedWidget("Oversight")}
                                                        >
                                                            Oversight
                                                        </Button>
                                                    </AccordionDetails>
                                                </Accordion>

                                                <Accordion
                                                    expanded={selectedCategory === "Sources"}
                                                    onChange={() => handleAccordionChange("Sources")}
                                                >
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                        <Typography>Sources</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Button
                                                            fullWidth
                                                            onClick={() =>
                                                                setSelectedWidget("Report Summary")
                                                            }
                                                        >
                                                            Report Summary
                                                        </Button>
                                                        <Button
                                                            fullWidth
                                                            onClick={() => setSelectedWidget("Sources List")}
                                                        >
                                                            Sources List
                                                        </Button>
                                                    </AccordionDetails>
                                                </Accordion>

                                                <Accordion
                                                    expanded={selectedCategory === "Policies"}
                                                    onChange={() => handleAccordionChange("Policies")}
                                                >
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                        <Typography>Policies</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Button
                                                            fullWidth
                                                            onClick={() =>
                                                                setSelectedWidget("Policy Profile")
                                                            }
                                                        >
                                                            Policy Profile
                                                        </Button>
                                                    </AccordionDetails>
                                                </Accordion>

                                                <Accordion
                                                    expanded={selectedCategory === "Transfers"}
                                                    onChange={() => handleAccordionChange("Transfers")}
                                                >
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                        <Typography>Transfers</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Button
                                                            disabled
                                                            fullWidth
                                                            onClick={() => setSelectedWidget("Widget2")}
                                                        >
                                                            Summary
                                                        </Button>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Box>
                                        )}
                                        <Box>
                                            {selectedWidget ? (
                                                <Box sx={{ maxWidth: "300px" }}>
                                                    <Typography
                                                        variant="h6"
                                                        sx={{ marginBottom: "1rem" }}
                                                    >
                                                        Configure {selectedWidget}
                                                    </Typography>

                                                    {WidgetConfigComponent && (
                                                        <WidgetConfigComponent
                                                            data={formData}
                                                            onSubmit={onSubmit}
                                                            workspaceId={workspaceId}
                                                            workspace={workspace}
                                                        />
                                                    )}
                                                </Box>
                                            ) : (
                                                <Typography variant="body1">
                                                    Select a widget to configure
                                                </Typography>
                                            )}
                                        </Box>
                                        {selectedWidget && (
                                            <PreviewComponent
                                                data={formData}
                                                workspaceId={workspaceId}
                                                workspace={workspace}
                                                selectedWidget={selectedWidget}
                                                handleCloseAddWidget={handleCloseAddWidget}
                                                setWorkspace={setWorkspace}
                                            />
                                        )}
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Modal>

                    <Modal open={openAddSection} onClose={handleCloseAddSection}>
                        <Box
                            sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                width: 400,
                                maxHeight: "calc(100vh - 20px)",
                                bgcolor: "background.paper",
                                p: 4,
                                boxShadow: 24,
                                borderRadius: 2,
                            }}
                        >
                            <Typography variant="h6">Add Section</Typography>
                            <Box component="form" sx={{ mt: 2 }}>
                                <TextField
                                    label="Section Name"
                                    name="name"
                                    value={newSection.name}
                                    onChange={handleAddSectionChange}
                                    fullWidth
                                    required
                                    sx={{ mb: 2 }}
                                />
                                <TextField
                                    label="Section Description"
                                    name="description"
                                    value={newSection.description}
                                    onChange={handleAddSectionChange}
                                    fullWidth
                                    multiline
                                    rows={4}
                                    sx={{ mb: 2 }}
                                />
                                <Box display="flex" justifyContent="flex-end">
                                    <Button onClick={handleCloseAddSection} sx={{ mr: 2 }}>
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSaveSection}
                                        sx={{
                                            backgroundColor: "rgb(18, 17, 53)",
                                            "&:hover": {
                                                backgroundColor: "rgba(18, 17, 53, 0.8)",
                                            },
                                        }}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Modal>

                    <Modal open={openEditSection} onClose={handleCloseEditSection}>
                        <Box
                            sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                width: 400,
                                maxHeight: "calc(100vh - 20px)",
                                bgcolor: "background.paper",
                                p: 4,
                                boxShadow: 24,
                                borderRadius: 2,
                            }}
                        >
                            <Typography variant="h6">Edit Section</Typography>
                            <Box component="form" sx={{ mt: 2 }}>
                                <TextField
                                    label="Section Name"
                                    name="name"
                                    value={sectionToEdit?.name || ""}
                                    onChange={(e) =>
                                        setSectionToEdit((prev) => ({
                                            ...prev,
                                            name: e.target.value,
                                        }))
                                    }
                                    fullWidth
                                    required
                                    sx={{ mb: 2 }}
                                />
                                <TextField
                                    label="Section Description"
                                    name="description"
                                    value={sectionToEdit?.description || ""}
                                    onChange={(e) =>
                                        setSectionToEdit((prev) => ({
                                            ...prev,
                                            description: e.target.value,
                                        }))
                                    }
                                    fullWidth
                                    multiline
                                    rows={4}
                                    sx={{ mb: 2 }}
                                />
                                <Box display="flex" justifyContent="flex-end">
                                    <Button onClick={handleCloseEditSection} sx={{ mr: 2 }}>
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleSaveSectionChanges(sectionToEdit)}
                                        sx={{
                                            backgroundColor: "rgb(18, 17, 53)",
                                            "&:hover": {
                                                backgroundColor: "rgba(18, 17, 53, 0.8)",
                                            },
                                        }}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Modal>

                    <Dialog open={openRemoveSection} onClose={handleCloseRemoveSection}>
                        <DialogTitle>Confirm Deletion</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Are you sure you want to delete the section "
                                {sectionToRemove?.name}"?
                            </DialogContentText>
                            <Box mt={2}>
                                <Typography variant="body2">
                                    What would you like to do with the widgets in this section?
                                </Typography>
                                <Box mt={2}>
                                    <Button
                                        variant={
                                            removeSectionAndWidgets === false
                                                ? "contained"
                                                : "outlined"
                                        }
                                        fullWidth
                                        onClick={() => setRemoveSectionAndWidgets(false)}
                                        sx={{ mb: 2 }}
                                    >
                                        Remove Section Only
                                    </Button>
                                    <Button
                                        variant={
                                            removeSectionAndWidgets === true
                                                ? "contained"
                                                : "outlined"
                                        }
                                        fullWidth
                                        onClick={() => setRemoveSectionAndWidgets(true)}
                                    >
                                        Remove Section and Widgets
                                    </Button>
                                </Box>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseRemoveSection} color="primary">
                                Cancel
                            </Button>
                            <Button
                                onClick={handleRemoveSection}
                                color="secondary"
                                disabled={removeSectionAndWidgets === null}
                            >
                                Delete
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={openRemove} onClose={handleClose}>
                        <DialogTitle>Confirm Deletion</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Are you sure you want to delete the widget "
                                {currentWidget?.parameters?.widgetName}"?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={onDelete} color="secondary">
                                Delete
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Container>
            </Container>
        );
    };

    if (loading) {
        return <Typography>Loading...</Typography>;
    }

    if (!workspace) {
        return <Typography>Workspace not found</Typography>;
    }

    function Actions({
        workspace,
        workspaceId,
        onUpdateWorkspace,
        getWorkspace,
    }) {
        const [openEdit, setOpenEdit] = useState(false);
        const [openConfirm, setOpenConfirm] = useState(false);
        const [name, setName] = useState(workspace.name ?? "");
        const [description, setDescription] = useState(workspace.description ?? "");
        const [permissions, setPermissions] = useState(
            workspace?.permissions ?? []
        );
        const history = useHistory();

        const [
            { loading: updateLoading, errors: updateErrors, data: updateData },
            updateWorkspaceAction,
        ] = useApi();

        const [
            {
                loading: updateWorkspaceEnabledLoading,
                errors: updateWorkspaceEnabledErrors,
                data: updateWorkspaceEnabledData,
            },
            updateWorkspaceEnabledFn,
        ] = useApi();

        useEffect(() => {
            if (updateData && !updateErrors) {
                onUpdateWorkspace();
            }
        }, [updateData]);

        useEffect(() => {
            if (updateWorkspaceEnabledData && !updateWorkspaceEnabledErrors) {
                handleConfirmClose();
                history.push("/workspaces");
            } else {
                handleConfirmClose();
            }
        }, [updateWorkspaceEnabledData]);

        const handleEditOpen = () => setOpenEdit(true);
        const handleClearPermissions = () => setPermissions([]);
        const handleEditClose = () => {
            setOpenEdit(false);
        };
        const handleConfirmOpen = () => setOpenConfirm(true);
        const handleConfirmClose = () => setOpenConfirm(false);

        const handleEditSubmit = () => {
            updateWorkspaceAction({
                query: updateWorkspace,
                variables: {
                    workSpaceModel: {
                        ...workspace,
                        configuration: JSON.stringify(workspace?.configuration ?? ""),
                        name,
                        description,
                        permissions,
                    },
                },
            });

            handleEditClose();
        };

        const handleDelete = () => {
            const enableWorkspace = {
                workSpaceId: workspaceId,
                enabled: false,
            };

            updateWorkspaceEnabledFn({
                query: updateWorkspaceEnabled,
                variables: enableWorkspace,
            });
        };

        return (
            <>
                <IconButton onClick={handleEditOpen}>
                    <MdEdit />
                </IconButton>
                <Modal open={openEdit} onClose={handleEditClose}>
                    <Box
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: 600,
                            maxHeight: "calc(100vh - 20px)",
                            bgcolor: "background.paper",
                            p: 4,
                            boxShadow: 24,
                            borderRadius: 2,
                            zIndex: 1300,
                            overflowY: "auto", // Make modal scrollable if content is too tall
                        }}
                    >
                        {updateWorkspaceEnabledErrors && (
                            <ErrorMessages errors={updateWorkspaceEnabledErrors} />
                        )}

                        <TextField
                            label="Workspace Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            fullWidth
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            label="Workspace Description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            fullWidth
                            multiline
                            rows={4}
                            sx={{ mb: 2 }}
                        />

                        <h3>Permissions</h3>
                        <Box sx={{ maxHeight: "50vh", overflowY: "auto" }}>
                            <Permissions callFn={setPermissions} permissions={permissions} />
                        </Box>

                        <Box display="flex" justifyContent="flex-end">
                            <Button onClick={handleClearPermissions} sx={{ mr: 2 }}>
                                Clear Permissions
                            </Button>
                            <Button onClick={handleEditClose} sx={{ mr: 2 }}>
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleEditSubmit}
                                sx={{
                                    backgroundColor: "rgb(18, 17, 53)",
                                    "&:hover": {
                                        backgroundColor: "rgba(18, 17, 53, 0.8)",
                                    },
                                }}
                            >
                                Save
                            </Button>
                        </Box>
                        <Box display="flex" justifyContent="flex-end" sx={{ mt: 2 }}>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleConfirmOpen}
                            >
                                Delete Workspace
                            </Button>
                        </Box>
                    </Box>
                </Modal>
                <Dialog open={openConfirm} onClose={handleConfirmClose}>
                    <DialogTitle>Confirm Deletion</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this workspace?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleConfirmClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleDelete} color="secondary">
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

    //Actions Section of Widget
    return (
        <Card
            title={workspace.name}
            titleDescription={workspace.description}
            body={() => <Body onUpdateWorkspace={refreshWorkspace} />}
            actions={() =>
                !props.incomingWorkspace && (
                    <Actions
                        workspace={workspace}
                        workspaceId={workspace.id}
                        onUpdateWorkspace={refreshWorkspace}
                        getWorkspace={getWorkspace}
                    />
                )
            }
        />
    );
};

export default Workspace;
