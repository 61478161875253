import React from "react";
import { FaDatabase } from "react-icons/fa";

const DataSourceName = ({ dataSource, notStacked, name, friendlyName }) => {
  if (notStacked) {
    if (name && friendlyName) {
      return (
        <>
          <div>
            {friendlyName} | {name}
          </div>
        </>
      );
    } else if (!name && friendlyName) {
      return <div>{friendlyName}</div>;
    } else if (!friendlyName && name) {
      return <div>{name}</div>;
    }
  } else if (dataSource) {
    return (
      <div>
        {dataSource.friendlyName ? (
          <div>
            <div style={{ fontSize: ".7rem", marginBottom: ".5rem" }}>
              <FaDatabase /> {dataSource.name}
            </div>
            <div>{dataSource.friendlyName}</div>
          </div>
        ) : (
          <div>{dataSource.name}</div>
        )}
      </div>
    );
  } else if (name && friendlyName) {
    return (
      <div>
        <div style={{ fontSize: ".7rem", marginBottom: ".5rem" }}>
          <FaDatabase /> {name}
        </div>
        <div>{friendlyName}</div>
      </div>
    );
  } else if (!name && friendlyName) {
    return <div>{friendlyName}</div>;
  } else if (!friendlyName) {
    return <div>{name}</div>;
  } else {
    return null;
  }
};

export default DataSourceName;
