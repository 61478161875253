import React, { useState, useEffect } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Box,
  IconButton,
} from "@mui/material";
import { useApi } from "../../../api/useApi";
import { recordLevelReportById, rlrStatus } from "../../../api/reportQueries";
import Loading from "./Loading";
import Error from "./Error";
import { formatTime } from "../../../common/formats";
import { FaFileCsv } from "react-icons/fa";
import { Link } from "react-router-dom";

const DynamicViews = ({ data }) => {
  const [dynamicViewData, setDynamicViewData] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(data?.pageSize ?? 3);
  const [isLoading, setIsLoading] = useState(true);
  const [idsToFetch, setIdsToFetch] = useState(data?.dynamicView || []); // Initialize with an empty array if undefined
  const [fetchedIds, setFetchedIds] = useState(new Set()); // Track fetched IDs to avoid duplicates

  const [{ errors: errorsReport, data: reportDataQuery }, fetchReportById] =
    useApi();
  const [{ errors: errorsStatus, data: statusDataQuery }, fetchRlrStatus] =
    useApi();

  useEffect(() => {
    if (data?.pageSize) {
      setPageSize(data.pageSize);
    }
  }, [data]); // This will run whenever `data` changes

  // Fetch the data for each ID sequentially
  useEffect(() => {
    const fetchSequentially = async () => {
      if (!idsToFetch || idsToFetch.length === 0) {
        setIsLoading(false); // Stop loading if no more IDs to fetch
        return;
      }

      const id = idsToFetch[0]; // Get the first ID to fetch

      // Skip fetching if this ID has already been fetched
      if (fetchedIds.has(id)) {
        setIdsToFetch((prevIds) => prevIds.slice(1)); // Remove the fetched ID
        return;
      }

      // Fetch the recordLevelReportById and rlrStatus in sequence (but not in parallel)
      await fetchReportById({
        query: recordLevelReportById,
        variables: { id },
      });
      await fetchRlrStatus({
        query: rlrStatus,
        variables: { rlrId: id },
      });
    };

    if (idsToFetch.length > 0) {
      fetchSequentially();
    } else {
      setIsLoading(false); // Ensure isLoading is set to false when no more data is left to fetch
    }
  }, [idsToFetch, fetchReportById, fetchRlrStatus, fetchedIds]);

  // Process reportDataQuery and statusDataQuery once they are fetched
  useEffect(() => {
    if (reportDataQuery && statusDataQuery) {
      const reportData = reportDataQuery.recordLevelReportById;
      const statusData = statusDataQuery.rlrStatus;

      // Only add to dynamicViewData if the ID hasn't been processed yet
      if (!fetchedIds.has(reportData.id)) {
        setDynamicViewData((prevData) => [
          ...prevData,
          {
            ...reportData,
            status: statusData, // Add the status from rlrStatus
          },
        ]);
        setFetchedIds((prev) => new Set(prev).add(reportData.id));
        setIdsToFetch((prevIds) => prevIds.slice(1)); // Remove the fetched ID from idsToFetch
      }
    }
  }, [reportDataQuery, statusDataQuery, fetchedIds]);

  useEffect(() => {
    if (data?.dynamicView) {
      setIdsToFetch(data.dynamicView);
    } else {
      setIsLoading(false);
    }
  }, [data?.dynamicView]);

  if (isLoading) {
    return <Loading />;
  }

  if (errorsReport || errorsStatus) {
    return <Error message="There was an error loading the data." />;
  }

  // Function to convert data to CSV format
  const downloadCSV = () => {
    const headers = ["Report Name", "Last Updated Date", "Failure Count"];
    const rows = dynamicViewData.map((view) => [
      view.name,
      view.status?.lastUpdatedTime
        ? formatTime(new Date(view.status?.lastUpdatedTime))
        : "N/A",
      view.status?.totalFailures ?? 0,
    ]);

    let csvContent =
      "data:text/csv;charset=utf-8," +
      headers.join(",") +
      "\n" +
      rows.map((e) => e.join(",")).join("\n");

    // Get today's date in YYYY-MM-DD format
    const today = new Date();
    const formattedDate = today.toISOString().slice(0, 10); // YYYY-MM-DD format

    // Create a download link and trigger it
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      `${
        data?.widgetName ? data.widgetName : "dynamic_view_data"
      }_${formattedDate}.csv`
    );
    document.body.appendChild(link); // Required for Firefox
    link.click();
    document.body.removeChild(link); // Clean up
  };

  // Calculate the total number of pages
  const totalPages = Math.ceil(dynamicViewData.length / pageSize);

  // Slice the data for the current page
  const currentPageData = dynamicViewData.slice(
    pageIndex * pageSize,
    pageIndex * pageSize + pageSize
  );

  const handlePageChange = (event, newPage) => {
    setPageIndex(newPage);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageIndex(0); // Reset to first page on page size change
  };

  return (
    <>
      <Typography
        variant="h5"
        gutterBottom
        sx={{ zIndex: 2, position: "relative", top: "-10px" }}
      >
        {data?.widgetName}
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end", // Align the icon to the right
          mb: 2, // Optional margin below the icon
        }}
      >
        <IconButton onClick={downloadCSV} color="primary">
          <FaFileCsv size={24} />
        </IconButton>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: "#f5f5f5", // Light grey background for header
                fontWeight: "bold", // Bold font for the header text
              }}
            >
              <TableCell>
                {data?.reportLabel ? data?.reportLabel : "Report Name"}
              </TableCell>
              <TableCell>Last Updated Date</TableCell>
              <TableCell>Failure Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentPageData.length > 0 ? (
              currentPageData.map((view) => (
                <TableRow
                  key={view.id}
                  onClick={() =>
                    window.open(`/dynamicviews/${view.id}`, "_blank")
                  }
                  style={{ cursor: "pointer" }}
                >
                  <TableCell sx={{ color: "rgb(3, 152, 204)" }}>
                    {view.name}
                  </TableCell>
                  <TableCell>
                    {view.status?.lastUpdatedTime
                      ? formatTime(new Date(view.status?.lastUpdatedTime))
                      : null}
                  </TableCell>{" "}
                  <TableCell
                    sx={{
                      color:
                        view.status?.totalFailures >= data?.failureThreshold
                          ? "red"
                          : "inherit",
                    }}
                  >
                    {view.status?.totalFailures}
                  </TableCell>{" "}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        page={pageIndex}
        onPageChange={handlePageChange}
        rowsPerPage={pageSize}
        onRowsPerPageChange={handlePageSizeChange}
        rowsPerPageOptions={[20]}
        count={dynamicViewData.length}
        labelDisplayedRows={({ page }) => `Page ${page + 1} of ${totalPages}`}
        labelRowsPerPage="Items per page:"
      />
    </>
  );
};

export default DynamicViews;
