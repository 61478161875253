import React, { Component, useCallback, useEffect, useState } from "react";
import styled from "styled-components/macro";
import DropZone from "../RuleImplementations/DropZone";
import Button from "../Button";
import Secondary from "../Button/Secondary";
import SortTable from "../Table/SortTable";
import { Formik } from "formik";
import {
  FormControl,
  FieldError,
  StyledField,
  ServerError,
} from "../Form/FormControls";
import {
  BusinessRuleVariables,
  BusinessRuleVariableOperations,
  ColumnModifiers,
} from "../Forms/Rules/buildingBlocks";
import ErrorMessages from "../Notifications/ErrorMessages";
import ValidationErrorMessages from "../Notifications/ValidationErrorMessages";
import {
  createNewRuleInstance,
  addRuleInstanceVersion,
  setFilterRule,
  setRuleInstanceName,
  validatePolicyMapping,
} from "../../api/ruleMutations";
import { patterns } from "../Forms/Rules/patterns";
import ReactTooltip from "react-tooltip";
import { MdDelete, MdEdit, MdInfoOutline } from "react-icons/md";
import RenderOperation from "./OperationHelper";
import { getSuggestedZoneMapping } from "../RuleImplementations/getSuggestedZoneMapping";
import { useApi } from "../../api/useApi";
import Spinner from "../Loaders/Spinner";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  Modal,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Input,
  List,
  ListItem,
  Typography,
} from "@mui/material";

const ValidationMessage = styled.div`
  padding: 1rem;
  padding-bottom: 0;
  color: red;
`;

const EditModal = ({
  open,
  onClose,
  selectedDataSources,
  handleSelectColumn,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (event) =>
    setSearchTerm(event.target.value.toLowerCase());

  const filteredSources = selectedDataSources
    .map((source) => ({
      ...source,
      columns: source.columns.filter(
        (column) =>
          column.name && column.name.toLowerCase().includes(searchTerm)
      ),
    }))
    .filter((source) => source.columns.length > 0);

  return (
    <Modal open={open} onClose={onClose}>
      <div
        style={{
          background: "white",
          width: "350px", // Adjust width to fit left side
          height: "100vh", // Full viewport height
          position: "fixed",
          top: 0,
          left: 0,
          display: "flex",
          flexDirection: "column",
          border: 0,
          outline: 0,
          boxShadow: "2px 0px 10px rgba(0,0,0,0.1)", // Shadow to distinguish from main content
        }}
      >
        {/* Fixed Header */}
        <div
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            backgroundColor: "white",
            padding: "20px",
            boxShadow: "0px 2px 10px rgba(0,0,0,0.1)", // Shadow for header
          }}
        >
          <Typography variant="h6">Select Column To Map</Typography>
          <Input
            placeholder="Search columns..."
            onChange={handleSearch}
            fullWidth
          />
        </div>

        {/* Scrollable Content */}
        <div
          style={{
            overflowY: "auto",
            flexGrow: 1, // Allows content to take up remaining space
            padding: "20px",
          }}
        >
          {filteredSources.map((source, i) => (
            <Accordion key={source.id} defaultExpanded={i === 0}>
              <AccordionSummary style={{ backgroundColor: "#efefef" }}>
                <Typography>{source?.friendlyName ?? source?.name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {source.columns.map((column) => (
                    <ListItem
                      button
                      key={column.id}
                      onClick={() => handleSelectColumn(column)}
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div
                          style={{
                            fontSize: ".5rem",
                            paddingBottom: ".5rem",
                          }}
                        >
                          {column?.dataType}
                        </div>
                        <div>{column?.name}</div>
                      </div>
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
    </Modal>
  );
};

const EditInstanceName = ({
  instanceId,
  currentTitle,
  setFieldValue,
  setState,
}) => {
  const [{ loading, errors, data }, updateTitleReq] = useApi();

  const [currentValue, setCurrentValue] = useState(currentTitle);

  useEffect(() => {
    if (data && !errors) {
      setFieldValue("title", currentValue);
      setState();
    }
  }, [data, errors, currentValue, setFieldValue, setState]);

  const updateTitle = () => {
    updateTitleReq({
      query: setRuleInstanceName,
      variables: {
        instanceId: instanceId,
        newName: currentValue,
      },
    });
  };

  return (
    <div>
      <FormControl>
        <StyledField
          name={`Policy Instance Name`}
          type="text"
          placeholder="Edit Instance Name"
          label="Policy Instance Name"
          value={currentValue}
          onChange={(e) => {
            setCurrentValue(e?.target?.value);
          }}
        />
      </FormControl>

      {errors ? <ErrorMessages errors={errors} /> : null}

      <Button type="button" onClick={() => updateTitle()}>
        {loading ? <Spinner /> : "Save Name"}
      </Button>
    </div>
  );
};

const defaultGroup = {
  columns: [
    {
      columnId: null,
    },
  ],
};

const DuplicateForm = ({
  columns,
  selectedDataSources,
  sourceId,
  handleUpdate,
  initialMappings,
}) => {
  function groupByArray(xs, key) {
    return xs.reduce(function (rv, x) {
      let v = key instanceof Function ? key(x) : x[key];
      let el = rv.find((r) => r && r.key === v);
      if (el) {
        el.columns.push(x);
      } else {
        rv.push({ key: v, columns: [x] });
      }
      return rv;
    }, []);
  }

  const [localGroups, setLocalGroups] = useState(
    initialMappings?.length
      ? groupByArray(initialMappings, "argument")
      : [defaultGroup]
  );

  useEffect(() => {
    if (localGroups) {
      let uniqueMappings = [];

      localGroups.forEach((lg, lgi) => {
        lg?.columns?.forEach((c, ci) => {
          uniqueMappings.push({
            businessRuleFragmentId: -2,
            columnId: c?.columnId,
            argument: lgi,
            order: ci,
          });
        });
      });

      handleUpdate(uniqueMappings);
    }
  }, [localGroups, handleUpdate]);

  const addGroup = () => {
    setLocalGroups((prev) => {
      let clonedGroupList = [
        ...prev,
        {
          columns: [
            ...(prev?.[0]?.columns?.map((_) => {
              return {
                columnId: null,
              };
            }) ?? [
              {
                columnId: null,
              },
            ]),
          ],
        },
      ];

      return clonedGroupList;
    });
  };

  const removeGroup = (groupIndex) => {
    setLocalGroups((prev) => {
      let clonedGroupList = [...prev];
      //remove 1 and group index
      clonedGroupList.splice(groupIndex, 1);
      return clonedGroupList;
    });
  };

  const addColumn = () => {
    setLocalGroups((prev) => {
      let clonedGroupList = [...prev];

      //add new column at group index
      clonedGroupList.forEach((cgl) => {
        cgl.columns.push({ columnId: null });
      });

      return clonedGroupList;
    });
  };

  const removeColumn = (columnIndex) => {
    setLocalGroups((prev) => {
      let clonedGroupList = [...prev];
      //add new column at group index
      clonedGroupList.forEach((clg) => {
        let clonedColumns = clg.columns;
        clonedColumns.splice(columnIndex, 1);
        clg.columns = clonedColumns;
      });

      return clonedGroupList;
    });
    // setLocalGroups((prev) => {
    //   let clonedGroupList = [...prev];
    //   //add new column at group index
    //   let clonedColumns = clonedGroupList[groupIndex].columns;
    //   clonedColumns.splice(columnIndex, 1);
    //   clonedGroupList[groupIndex].columns = clonedColumns;

    //   return clonedGroupList;
    // });
  };

  const handleDrop = (groupIndex, columnIndex, draggable) => {
    setLocalGroups((prev) => {
      let clonedGroupList = [...prev];

      //add new column at group index
      clonedGroupList[groupIndex].columns[columnIndex] = {
        columnId: draggable.item.id,
      };

      return clonedGroupList;
    });
  };

  const clearDrop = (groupIndex, columnIndex) => {
    setLocalGroups((prev) => {
      let clonedGroupList = [...prev];

      //add new column at group index
      clonedGroupList[groupIndex].columns[columnIndex] = {
        columnId: null,
      };

      return clonedGroupList;
    });
  };

  const columnsData = [
    {
      Header: "Unique Collections",
      id: "CollectionId",
      Cell: ({ row }) => {
        return (
          <div>
            <div>Collection {row?.index + 1}</div>

            <Button
              danger
              list
              type="button"
              onClick={() => removeGroup(row?.index)}
            >
              Remove Collection
            </Button>
          </div>
        );
      },
    },
    ...(localGroups?.[0]?.columns?.map((lgc, i) => {
      return {
        Header: () => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: "1rem" }}>Column {i + 1}</div>

            <Button
              danger
              title={"Remove Column"}
              type="button"
              onClick={() => removeColumn(i)}
            >
              <MdDelete />
            </Button>
          </div>
        ),
        id: `column${i}`,
        Cell: ({ row }) => {
          const groupIndex = row?.index;
          const columnIndex = i;
          const c = row?.original?.columns[i];

          return (
            <div>
              <DropZone
                accepts={["column"]}
                onDrop={(draggable) =>
                  handleDrop(groupIndex, columnIndex, draggable)
                }
                zone={c?.columnId ? c : null}
                columns={columns}
                label={`column ${columnIndex + 1}`}
                dataType={"column"}
                removeZone={() => clearDrop(groupIndex, columnIndex)}
                primarySourceId={sourceId}
                isSource={true}
                selectedDataSources={selectedDataSources}
              />
            </div>
          );
        },
      };
    }) ?? []),
  ];

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {localGroups?.length ? (
          <Secondary list type="button" onClick={() => addColumn()}>
            Add Column
          </Secondary>
        ) : null}

        <Secondary type="button" onClick={() => addGroup()}>
          Add Unique Collection
        </Secondary>
      </div>
      <div
        style={{
          overflow: "auto",
          display: "flex",
          marginTop: "1em",
          paddingBottom: "1em",
          flexWrap: "wrap",
          flexGrow: 1,
          flexDirection: "column",
          position: "relative",
        }}
      >
        <div style={{ position: "absolute", left: 0, right: 0 }}>
          <SortTable data={localGroups} columns={columnsData} removeOverflow />
        </div>
      </div>
    </>
  );
};

const StandardElement = styled.div`
  margin-bottom: 1rem;
  margin-left: ${(props) => (props.indent ? "1rem" : "")};
`;

const FormWrap = styled.div`
  color: ${(props) => props.theme.onSurface};
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

const TemplateContainer = styled.div`
  display: flex;
  margin-top: 1em;
  padding-bottom: 1em;
  flex-wrap: wrap;

  flex-grow: 1;
  flex-direction: column;
`;

const FragmentContainer = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  border: 1px dashed ${(props) => props.theme.surfaceAlt};
  border-left: 4px solid
    ${(props) => (props.hasError ? "red" : props.theme.onSecondarySurface)};
  padding: 1em;
  &:last-child {
    margin-right: 0;
  }
  align-items: center;
  margin-bottom: 1rem;
`;

const ConditionalContainer = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid ${(props) => props.theme.surfaceAlt};
`;

const FragmentSourceSection = styled.div`
  flex: 1;
  margin-right: 1em;
  background: rgba(0, 0, 0, 0.03);
  flex-grow: inherit;
  margin-top: 0.5rem;
  border: ${(props) => (props.hasError ? "red solid 2px" : "")};
`;

const FragmentOperationSection = styled.div`
  flex: 1;
  margin-right: 1em;
  flex-grow: 1;
  margin-top: 0.5rem;
`;

const FragmentTargetSection = styled.div`
  flex: 1;
  margin-right: 1em;
  background: rgba(0, 0, 0, 0.03);
  flex-grow: inherit;
  margin-top: 0.5rem;
`;

const Break = styled.div`
  width: 100%;
`;

const FragmentSectionBody = styled.div`
  padding: 0.8em;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  /* max-width: 300px; */
  white-space: inherit;
`;

const FlexSpread = styled.div`
  display: flex;

  display: flex;
  flex-grow: 1;
`;

const RuleFormControls = styled.div`
  display: flex;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 25px;
`;

const FlexSpan = styled.div`
  display: flex;
  flex-grow: 1;
`;

function lookUpMappingsForCrossTable(fragments, sourceId, columns) {
  let isCross = false;
  if (sourceId) {
    fragments.forEach((f) => {
      const columnId = f?.columnId;
      const foundColumn = columns.find((c) => c?.id === columnId);
      if (foundColumn) {
        if (foundColumn?.dataSourceId !== Number(sourceId)) {
          isCross = true;
        }
      }
    });

    return isCross;
  } else {
    return false;
  }
}

const ValidationComp = ({
  callServerPolicyMappingValidation,
  values,
  fragments,
  setValidationErrors,
}) => {
  const [{ loading, errors, data }, validateRequest, clearFields] = useApi();
  const [initializeValidation, setInitializeValidation] = useState(false);
  const [clickedValidate, setClickedValidate] = useState(false);
  const getValidation = useCallback(() => {
    validateRequest({
      query: validatePolicyMapping,
      variables: callServerPolicyMappingValidation(),
    });
  }, [validateRequest, callServerPolicyMappingValidation]);

  useEffect(() => {
    if (data && data?.validatePolicyMapping?.length) {
      setValidationErrors(data?.validatePolicyMapping);

      setInitializeValidation(true);
      clearFields();
    } else if (
      clickedValidate &&
      !data?.validatePolicyMapping?.length &&
      !errors?.length
    ) {
      toast.success("Validation Passed", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setValidationErrors([]);

      setInitializeValidation(true);
      clearFields();
    } else if (data && !data?.validatePolicyMapping?.length) {
      setValidationErrors([]);

      setInitializeValidation(true);
      clearFields();
    }

    setClickedValidate(false);
  }, [data, setValidationErrors, values]); // eslint-disable-line

  useEffect(() => {
    if (initializeValidation) {
      getValidation();
    }
  }, [values, fragments, initializeValidation]); // eslint-disable-line
  // omission of getValidation due to loop, tested works fine with fragments
  return (
    <div>
      {errors ? <ErrorMessages errors={errors} /> : null}
      <Button
        style={{ marginLeft: "auto" }}
        type="button"
        onClick={() => {
          getValidation();
          setClickedValidate(true);
        }}
        disabled={loading}
      >
        {loading ? <Spinner /> : "Validate"}
      </Button>
      <ToastContainer />
    </div>
  );
};

// Submit

const SaveComp = ({
  isFilter,
  values,
  fragments,
  calculationMappings,
  standardId,
  sourceId,
  editingTemplate,
  isAReconciliationRule,
  pKMatchingColumns,
  columns,
  setValidationErrors,
  standardVersionId,
}) => {
  const [
    { loading: loadingUpdate, errors: formErrors, data: response },
    doUpdateRule,
  ] = useApi();
  //catching and saving which submit type there is for the boolean setting
  const [submitType, setSubmitType] = useState(null);

  let history = useHistory();

  useEffect(() => {
    if (response && !formErrors?.length) {
      // since we have two endpoints, just dynamically check and pass the data
      const dynamicNames = ["createNewRuleInstance", "addRuleInstanceVersion"];

      const errorData = dynamicNames.reduce((acc, name) => {
        if (response?.[name]?.errors?.length > 0) {
          return [...acc, ...response[name].errors];
        }
        return acc;
      }, []);

      if (errorData.length === 0 || submitType === "Draft") {
        if (!isFilter) {
          history.push(`/sources/${sourceId}/manage/policies`);
        } else {
          history.push(`/sources/${sourceId}/manage/filters`);
        }
      } else {
        setValidationErrors(errorData);
      }
    }
  }, [response, isFilter, sourceId, history, formErrors, submitType]); // eslint-disable-line
  // omission of getValidation due to loop, tested works fine with fragments

  const handleSubmit = (values, submitState) => {
    setSubmitType(submitState);
    //Current
    const cleanFragments = fragments.map((fragment) => {
      return {
        businessRuleFragmentId: fragment?.businessRuleFragmentId,
        columnId: fragment?.columnId,
      };
    });

    let variables;

    if (isFilter) {
      if (editingTemplate) {
        variables = {
          instanceId: editingTemplate?.instanceId,
          newVersion: {
            standardVersionId: standardVersionId,
            mappings: cleanFragments,
            matchingColumns: [],
            calculationMappings: calculationMappings,
          },
          saveAsDraft: submitState === "Draft" ? true : false,
        };
        doUpdateRule({
          query: addRuleInstanceVersion,
          variables,
        });
      } else {
        variables = {
          dataSourceId: sourceId,
          instance: {
            standardId: Number(standardId),
            isAReconciliationRuleInstance: false,
            isACrosstableRuleInstance: false,
            title: values.title,
            versions: [
              {
                standardVersionId: standardVersionId,
                mappings: cleanFragments,
                matchingColumns: [],
                calculationMappings: calculationMappings,
              },
            ],
          },
          saveAsDraft: submitState === "Draft" ? true : false,
        };
        doUpdateRule({
          query: setFilterRule,
          variables,
        });
      }
    } else {
      if (editingTemplate) {
        variables = {
          instanceId: editingTemplate?.instanceId,
          newVersion: {
            standardVersionId: standardVersionId,
            mappings: cleanFragments,
            calculationMappings: calculationMappings,
            matchingColumns:
              pKMatchingColumns?.map((pk) => {
                return {
                  dataSourceColumnId1: pk?.dataSourceColumn1?.id,
                  dataSourceColumnId2: pk?.dataSourceColumn2?.id,
                };
              }) ?? [],
          },
          saveAsDraft: submitState === "Draft" ? true : false,
        };
        doUpdateRule({
          query: addRuleInstanceVersion,
          variables,
        });
      } else {
        variables = {
          dataSourceId: sourceId,
          instance: {
            standardId: Number(standardId),
            isAReconciliationRuleInstance: isAReconciliationRule,
            isACrosstableRuleInstance: lookUpMappingsForCrossTable(
              [...fragments, ...calculationMappings],
              sourceId,
              columns
            ),
            title: values.title,
            versions: [
              {
                standardVersionId: standardVersionId,
                mappings: cleanFragments,
                calculationMappings: calculationMappings,
                matchingColumns:
                  pKMatchingColumns?.map((pk) => {
                    return {
                      dataSourceColumnId1: pk?.dataSourceColumn1?.id,
                      dataSourceColumnId2: pk?.dataSourceColumn2?.id,
                    };
                  }) ?? [],
              },
            ],
          },
          saveAsDraft: submitState === "Draft" ? true : false,
        };
        doUpdateRule({
          query: createNewRuleInstance,
          variables,
        });
      }
    }
  };

  return (
    <div>
      {formErrors ? <ErrorMessages errors={formErrors} /> : null}

      <Button
        style={{ marginLeft: "auto" }}
        type="button"
        list
        onClick={() => handleSubmit(values, "Draft")}
        disabled={!values.title || loadingUpdate}
      >
        {loadingUpdate ? <Spinner /> : "Save Draft"}
      </Button>

      <Button
        style={{ marginLeft: "auto" }}
        type="button"
        onClick={() => handleSubmit(values, "Publish")}
        disabled={!values.title || loadingUpdate}
      >
        {loadingUpdate ? <Spinner /> : "Publish"}
      </Button>
    </div>
  );
};

export default class RuleFragmentForm extends Component {
  constructor(props) {
    super();
    this.state = {
      dropped: null,
      fragments: [],
      calculationMappings: [],
      error: null,
      template: {
        title: props.standardName,
        id: props.standardId,
        fragments: buildTemplate(props.template),
      },
      showEditInstanceName: false,
      validationErrors: [],
      hasScrollbar: false,
      selectedFragment: null, // New state for managing selected fragment
      isModalOpen: false, // Modal visibility
    };
  }

  componentDidMount() {
    const { editingTemplate } = this.props;

    //Check if the edit for fragment is latest, otherwise there is a mismatch and we'll
    //wipe the current mappings and leave the title

    if (editingTemplate) {
      const mappings = editingTemplate?.mappings.filter(
        (cf) => cf?.businessRuleFragmentId >= 1
      );

      const calculationMappings = editingTemplate?.calculationMappings ?? [];
      const isLatest =
        editingTemplate?.standardVersion?.id ===
        editingTemplate?.standardVersion?.standard?.latestVersion?.id;

      if (isLatest) {
        this.setState({
          fragments: mappings ?? [],
          calculationMappings: calculationMappings,
        });

        this.props.updateFragments([...mappings, ...calculationMappings]);
      } else {
        this.setState({
          fragments: [],
        });
      }
    } else {
      const isDisabled = window.localStorage.getItem("disable-suggestions");
      if (!isDisabled) {
        const updatedFragments = getSuggestedZoneMapping(
          this.props.suggestedColumns
        );
        this.setState({
          fragments: updatedFragments,
        });

        if (this.props?.suggestedColumns?.suggestions?.length) {
          this.props.updateFragments(updatedFragments);
        }
      }
    }
    if (window.innerHeight < document.documentElement.scrollHeight) {
      this.setState({ hasScrollbar: true });
    } else {
      this.setState({ hasScrollbar: false });
    }
  }

  render() {
    const {
      fragments,
      error,
      template,
      calculationMappings,
      showEditInstanceName,
      validationErrors,
      hasScrollbar,
    } = this.state;

    const {
      editingTemplate,
      isFilter,
      standardId,
      matchingColumns,
      asOfDateColumns,
      isAReconciliationRule,
      pKMatchingColumns,
    } = this.props;

    const setValidationErrors = (errors) => {
      this.setState({ validationErrors: errors });
    };

    if (
      (isAReconciliationRule && matchingColumns?.length === 0) ||
      asOfDateColumns === 0
    ) {
      return null;
    }

    return (
      <>
        {validationErrors && validationErrors?.length > 0 && (
          <ValidationErrorMessages errors={validationErrors} />
        )}

        <FlexSpread>
          <Formik
            initialValues={{
              title: editingTemplate
                ? editingTemplate?.instance?.title
                : template.title,
            }}
            enableReinitialize={true}
            validate={(values) => {
              let errors = {};
              if (!values.title) {
                errors.title = "Required";
              }
              return errors;
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
            }) => {
              const callServerPolicyMappingValidation = () => {
                //Validate Mappings Similar to Submit logic, will refactor to see if we can remove dupe

                const { fragments, calculationMappings } = this.state;

                //Current
                const cleanFragments = fragments.map((fragment) => {
                  return {
                    businessRuleFragmentId: fragment?.businessRuleFragmentId,
                    columnId: fragment?.columnId,
                  };
                });

                const { sourceId, pKMatchingColumns } = this.props;

                let errors = {};

                if (errors && errors.missing) {
                  this.setState({ error: errors.missing && errors.missing });
                  return;
                }

                let mapping; // used for validation
                mapping = {
                  title: values.title,
                  standardId: standardId, // use the standard id not the version id
                  primaryDataSourceId: sourceId,
                  mappings: cleanFragments,
                  matchingColumns:
                    pKMatchingColumns?.map((pk) => {
                      return {
                        dataSourceColumnId1: pk?.dataSourceColumn1?.id,
                        dataSourceColumnId2: pk?.dataSourceColumn2?.id,
                      };
                    }) ?? [], // matchingColumns
                  calculationMappings: calculationMappings,
                };

                return { mapping };
              };

              return (
                <form style={{ flexGrow: 1, display: "flex" }}>
                  <FlexSpan>
                    <FormWrap>
                      {/* TODO: Fix Header Template Update and refactor fragment usage */}
                      {/* <TemplateHeader>
                    <MdDeveloperBoard /> {template.name}
                  </TemplateHeader>
                  <TemplateDescription>
                    {template.description}
                  </TemplateDescription> */}
                      {/* Loop over the fragments for this section */}
                      {editingTemplate ? (
                        <>
                          {showEditInstanceName ? (
                            <EditInstanceName
                              instanceId={editingTemplate?.instanceId}
                              currentTitle={values.title}
                              setFieldValue={setFieldValue}
                              setState={() =>
                                this.setState({
                                  showEditInstanceName: false,
                                })
                              }
                            />
                          ) : (
                            <h3>
                              {values.title}{" "}
                              <Button
                                type="button"
                                title="Edit Instance Name"
                                style={{ marginLeft: "1rem" }}
                                onClick={() =>
                                  this.setState({ showEditInstanceName: true })
                                }
                              >
                                <MdEdit />
                              </Button>
                            </h3>
                          )}
                        </>
                      ) : (
                        <FormControl>
                          <StyledField
                            type="title"
                            name="title"
                            label={`${isFilter ? "Filter" : "Rule"} Name`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.title}
                            placeholder={`${isFilter ? "Filter" : "Rule"} Name`}
                          />
                          {touched.title && errors?.title && (
                            <FieldError>{errors.title}</FieldError>
                          )}
                        </FormControl>
                      )}
                      <TemplateContainer>
                        {template.fragments.map((fragment, i) => {
                          if (fragment.def === "fragment") {
                            return this.FragmentWrapper(
                              fragment,
                              i,
                              template.fragments,
                              standardId
                            );
                          } else {
                            return this.ConditionOperation(
                              fragment,
                              i,
                              template.fragments
                            );
                          }
                        })}
                      </TemplateContainer>

                      {/* Render the Modal */}
                      <EditModal
                        open={this.state.isModalOpen}
                        onClose={this.closeModal}
                        selectedDataSources={this.props.selectedDataSources}
                        handleSelectColumn={this.handleSelectColumn}
                      />

                      <RuleFormControls>
                        {hasScrollbar &&
                          validationErrors &&
                          validationErrors?.length > 0 && (
                            <ValidationErrorMessages
                              errors={validationErrors}
                            />
                          )}

                        {error ? (
                          <>
                            <ServerError msg={error} />{" "}
                          </>
                        ) : null}

                        <div style={{ flex: 1 }}>
                          {" "}
                          <ValidationComp
                            values={values}
                            fragments={fragments}
                            callServerPolicyMappingValidation={
                              callServerPolicyMappingValidation
                            }
                            setValidationErrors={setValidationErrors}
                          />
                        </div>

                        <SaveComp
                          isFilter={isFilter}
                          values={values}
                          fragments={fragments}
                          calculationMappings={calculationMappings}
                          standardId={standardId}
                          sourceId={this?.props?.sourceId}
                          standardVersionId={this?.props?.standardVersionId}
                          editingTemplate={editingTemplate}
                          isAReconciliationRule={isAReconciliationRule}
                          pKMatchingColumns={pKMatchingColumns}
                          columns={this?.props?.columns}
                          setValidationErrors={setValidationErrors}
                        />
                      </RuleFormControls>
                    </FormWrap>
                  </FlexSpan>
                </form>
              );
            }}
          </Formik>
        </FlexSpread>
      </>
    );
  }

  openModal = (fragment, isCalculation = false, argument = null) => {
    this.setState({
      isModalOpen: true,
      selectedFragment: {
        ...fragment,
        isCalculation,
        argument, // Only set if it's a calculation
      },
    });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false, selectedFragment: null });
  };

  handleSelectColumn = (column) => {
    const { selectedFragment } = this.state;

    if (!selectedFragment) return;

    // Check if the selected fragment is a calculation target and has an `argument` property
    if (selectedFragment.isCalculation) {
      // Call handleCalcDrop if it's a calculation
      this.handleCalcDrop(
        selectedFragment,
        { item: column },
        selectedFragment.argument
      );
    } else {
      // Otherwise, call handleDrop as usual
      this.handleDrop(selectedFragment, { item: column });
    }

    // Close the modal
    this.closeModal();
  };

  handleDrop = (fragment, draggable) => {
    const { fragments, calculationMappings } = this.state;

    let updatedRules = [...fragments];

    updatedRules = updatedRules.filter(
      (rule) => rule.businessRuleFragmentId !== fragment.id
    );

    const columnMapping = {
      businessRuleFragmentId: fragment.id, //Fragment
      columnId: draggable.item.id, //column from Fragment
    };

    updatedRules.push(columnMapping);

    this.setState({ dropped: draggable, fragments: updatedRules });

    this.props.updateFragments([...updatedRules, ...calculationMappings]);
  };

  handleCalcDrop = (fragment, draggable, argumentOrder) => {
    const { calculationMappings, fragments } = this.state;

    let updatedCalcMappings = [...calculationMappings];

    const index = updatedCalcMappings.findIndex(
      (rule) =>
        rule.businessRuleFragmentId === fragment.id &&
        rule.argument === argumentOrder
    );

    const columnMapping = {
      businessRuleFragmentId: fragment.id, //Fragment
      columnId: draggable.item.id, //column from Fragment
      argument: argumentOrder,
    };

    //Replace
    if (index !== -1) {
      updatedCalcMappings[index] = columnMapping;
    } else {
      //ADD
      updatedCalcMappings.push(columnMapping);
    }

    this.setState({
      dropped: draggable,
      calculationMappings: updatedCalcMappings,
    });
    this.props.updateFragments([...fragments, ...updatedCalcMappings]);
  };

  getDroppedItem = (fragment) => {
    const { fragments } = this.state;

    const item = fragments.find(
      (frag) => frag.businessRuleFragmentId === fragment.id
    );

    if (item) return item;
  };

  getDroppedCalcItem = (fragment, argument) => {
    const { calculationMappings } = this.state;

    const item = calculationMappings.find(
      (frag) =>
        frag.businessRuleFragmentId === fragment.id &&
        frag.argument === argument
    );

    if (item) return item;
  };

  removeZone = (zone) => {
    const { fragments, calculationMappings } = this.state;
    let updatedRules = [...fragments];

    updatedRules = updatedRules.filter(
      (rule) => rule.businessRuleFragmentId !== zone.businessRuleFragmentId
    );

    this.setState({ fragments: updatedRules });
    this.props.updateFragments([...updatedRules, ...calculationMappings]);
  };

  removeCalcZone = (zone) => {
    const { calculationMappings, fragments } = this.state;
    let updatedCalcMappings = [...calculationMappings];

    const index = updatedCalcMappings.findIndex(
      (rule) =>
        rule.businessRuleFragmentId === zone.businessRuleFragmentId &&
        rule.argument === zone.argument
    );

    if (index !== -1) {
      updatedCalcMappings.splice(index, 1);
    }

    this.setState({ calculationMappings: updatedCalcMappings });
    this.props.updateFragments([...fragments, ...updatedCalcMappings]);
  };

  handleUpdate = (mappings) => {
    const { fragments } = this.state;
    this.setState({ calculationMappings: mappings });
    this.props.updateFragments([...fragments, ...mappings]);
  };

  FragmentWrapper = (fragment, _, __, standardId) => {
    const { fragments, calculationMappings, validationErrors } = this.state;

    const { columns, selectedDataSources } = this.props;

    const source = fragment.source;
    const sourceHasValidationError = validationErrors.find(
      (error) =>
        error.resourceType === "FRAGMENT" && error.resourceId === source?.id
    );

    const operation = fragment.operation;
    const operationHasValidationError = validationErrors.find(
      (error) =>
        error.resourceType === "FRAGMENT" && error.resourceId === operation?.id
    );

    const target = fragment.target;
    const targetHasValidationError = validationErrors.find(
      (error) =>
        error.resourceType === "FRAGMENT" && error.resourceId === target?.id
    );

    const sourceZone = fragments.find(
      (frag) => frag.businessRuleFragmentId === source.id
    );

    const targetZone = target
      ? fragments.find((frag) => frag.businessRuleFragmentId === target.id)
      : null;

    const businessOperation = operation?.typeInformation?.[1]?.typeValue;

    const isExistRule = businessOperation === 16 || businessOperation === 17;

    const sourceMod = source.typeInformation[2]?.typeValue
      ? ` (${
          ColumnModifiers[source.typeInformation[2]?.typeValue - 1]?.label
        })${
          source.typeInformation[2]?.typeValue === 2
            ? `(Days: ${source.typeInformation[2]?.fragmentValue}) `
            : null
        }`
      : "";

    const targetMod =
      target != null && target.typeInformation[3]?.typeValue
        ? ` (${
            ColumnModifiers[target.typeInformation[3]?.typeValue - 1]?.label
          })${
            target.typeInformation[3]?.typeValue === 2
              ? `(Days: ${target.typeInformation[3]?.fragmentValue}) `
              : null
          }`
        : "";

    const isByAmount = operation.typeInformation[2]?.typeValue === 4;
    const isByPercentage = operation.typeInformation[2]?.typeValue === 5;

    let calcMatches;
    if (target?.typeInformation?.[1]?.typeValue === 4) {
      const matches =
        target?.typeInformation?.[1]?.fragmentValue.match(/\[[^\][]*\]/g);

      calcMatches = matches.map((cm, i) => {
        const cleanedString = cm.replace(/[\[\]']+/g, "");
        return cleanedString ? cleanedString : i;
      });
    }

    if (standardId === -2) {
      return (
        <DuplicateForm
          sourceZone={sourceZone}
          columns={columns}
          selectedDataSources={selectedDataSources}
          initialMappings={
            this?.props?.editingTemplate?.calculationMappings ?? []
          }
          sourceId={this?.props?.sourceId}
          handleUpdate={this.handleUpdate}
        />
      );
    }

    if (standardId === -3) {
      return "Entire Row - Duplicate Records Check";
    }

    // currently checking for value on source side, if so we hide it from user
    const isNotCalcValue = source?.typeInformation[1]?.typeValue !== 4;

    const isSourceSelected = this.state.selectedFragment
      ? this.state.selectedFragment?.id === source?.id
      : null;
    const isTargetSelected = this.state.selectedFragment
      ? this.state.selectedFragment?.id === target?.id
      : null;

    let calcSelected = null;
    if (isTargetSelected) {
      calcSelected = this.state.selectedFragment?.argument;
    }

    return (
      <FragmentContainer>
        {isNotCalcValue && (
          <>
            <FragmentSourceSection hasError={sourceHasValidationError}>
              {sourceHasValidationError && (
                <ValidationMessage>
                  {sourceHasValidationError?.message}
                </ValidationMessage>
              )}

              <FragmentSectionBody
                style={{
                  border: isSourceSelected ? "solid 2px #009fd4" : "inherit",
                }}
              >
                <DropZone
                  accepts={["column"]}
                  onDrop={(draggable) => this.handleDrop(source, draggable)}
                  lastDroppedItem={this.getDroppedItem(source)}
                  zone={sourceZone}
                  columns={columns}
                  label={`${source.typeInformation[1].fragmentValue} ${sourceMod}`}
                  dataType={"column"}
                  removeZone={this.removeZone}
                  primarySourceId={this?.props?.sourceId}
                  isSource={true}
                  selectedDataSources={selectedDataSources}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.openModal(source);
                  }} // Pass `onClick` directly
                />
              </FragmentSectionBody>
            </FragmentSourceSection>
            <FragmentOperationSection hasError={operationHasValidationError}>
              {operationHasValidationError && (
                <ValidationMessage>
                  {operationHasValidationError?.message}
                </ValidationMessage>
              )}
              <FragmentSectionBody>
                <RenderOperation operationDetails={operation} isView={true} />
              </FragmentSectionBody>
            </FragmentOperationSection>
          </>
        )}

        {target ? (
          <FragmentTargetSection hasError={targetHasValidationError}>
            {targetHasValidationError && (
              <ValidationMessage>
                {targetHasValidationError?.message}
              </ValidationMessage>
            )}

            <FragmentSectionBody
              style={{
                border: isTargetSelected ? "solid 2px #009fd4" : "inherit",
              }}
            >
              {/* Render Column */}
              {target.typeInformation[1].typeValue === 1 ? (
                <DropZone
                  accepts={["column"]}
                  onDrop={(draggable) => this.handleDrop(target, draggable)}
                  lastDroppedItem={this.getDroppedItem(target)}
                  zone={targetZone}
                  sourceZone={sourceZone}
                  columns={columns}
                  isExistRule={isExistRule}
                  primarySourceId={this?.props?.sourceId}
                  label={`${target.typeInformation[1].fragmentValue} ${targetMod}`}
                  dataType={"column"}
                  removeZone={this.removeZone}
                  selectedDataSources={selectedDataSources}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.openModal(target);
                  }} // Pass `onClick` directly
                />
              ) : null}
              {/* Render Value */}
              {target.typeInformation[1].typeValue === 2 ? (
                businessOperation === 13 ? (
                  <>
                    {patterns.find(
                      (p) => p.value === target.typeInformation[1].fragmentValue
                    )?.label ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {
                          patterns.find(
                            (p) =>
                              p.value ===
                              target.typeInformation[1].fragmentValue
                          )?.label
                        }
                        <div
                          data-tip={
                            patterns.find(
                              (p) =>
                                p.value ===
                                target.typeInformation[1].fragmentValue
                            )?.description
                          }
                          data-for="patternHelper"
                        >
                          <MdInfoOutline />
                        </div>
                        <ReactTooltip id="patternHelper" type="info">
                          <span>
                            {
                              patterns.find(
                                (p) =>
                                  p.value ===
                                  target.typeInformation[1].fragmentValue
                              )?.description
                            }
                          </span>
                        </ReactTooltip>
                      </div>
                    ) : (
                      target.typeInformation[1].fragmentValue
                    )}
                  </>
                ) : (
                  <>
                    {isByAmount ? "By " : null} {isByPercentage ? "By " : null}
                    {target.typeInformation[1].fragmentValue}
                    {isByPercentage ? "%" : null}
                  </>
                )
              ) : null}

              {target.typeInformation[1].typeValue === 3
                ? BusinessRuleVariables.find(
                    (sv) =>
                      sv.value ===
                      Number(target.typeInformation[1].fragmentValue)
                  ).label
                : null}
              {target.typeInformation[1].typeValue === 3 ? (
                <>
                  {target.typeInformation[2].typeValue ? (
                    <div style={{ marginLeft: ".5rem", color: "orange" }}>
                      {
                        BusinessRuleVariableOperations[
                          target.typeInformation[2].typeValue
                        ].label
                      }
                    </div>
                  ) : null}

                  {target.typeInformation[2].typeValue !== 0 ? (
                    <div style={{ color: "orange" }}>
                      {target.typeInformation[2].fragmentValue}
                    </div>
                  ) : null}
                </>
              ) : null}

              {/* Render Calc */}

              {/* {target.typeInformation[1].typeValue === 4
                ? BusinessRuleTargetValues.find((tv) => tv.value === 4).label
                : null} */}

              {target.typeInformation[1].typeValue === 4 &&
              calcMatches?.length ? (
                <div>
                  <div style={{ marginBottom: ".5rem" }}>
                    {target.typeInformation[1].fragmentValue}
                  </div>
                  <div style={{ display: "flex" }}>
                    {calcMatches?.map((cm, i) => {
                      return (
                        <div
                          style={{
                            marginRight: ".5rem",
                            border:
                              calcSelected === i
                                ? "solid 2px #009fd4"
                                : "inherit",
                          }}
                        >
                          <DropZone
                            accepts={["column"]}
                            onDrop={(draggable) =>
                              this.handleCalcDrop(target, draggable, i)
                            }
                            lastDroppedItem={this.getDroppedCalcItem(target, i)}
                            zone={calculationMappings.find(
                              (cm) =>
                                cm?.businessRuleFragmentId === target?.id &&
                                cm.argument === i
                            )}
                            sourceZone={sourceZone}
                            columns={columns}
                            isExistRule={isExistRule}
                            primarySourceId={this?.props?.sourceId}
                            label={cm}
                            dataType={"column"}
                            removeZone={this.removeCalcZone}
                            selectedDataSources={selectedDataSources}
                            onClick={(e) => {
                              e.stopPropagation();
                              // Open modal with calculation details
                              this.openModal(target, true, i); // `true` indicates it's a calculation, `i` is the argument order
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}
            </FragmentSectionBody>
          </FragmentTargetSection>
        ) : null}
      </FragmentContainer>
    );
  };

  ConditionOperation = (fragment, i, template) => {
    const isLastFirstBracket =
      fragment.name === "(" && template[i + 1] && template[i + 1].name !== "(";

    const isLastLastBracket =
      fragment.name === ")" && template[i - 1] && template[i - 1].name !== ")";

    const isBlockedElement =
      fragment.name === "Then" ||
      fragment.name === "End If" ||
      fragment.name === "Else If";

    const isOrStatement = fragment.name === "Or";
    const isAndStatement = fragment.name === "And";

    const indent = isOrStatement || isAndStatement;

    return (
      <>
        {isBlockedElement ||
        isLastLastBracket ||
        isOrStatement ||
        isAndStatement ? (
          <Break />
        ) : null}
        <StandardElement isBlockedElement={isBlockedElement} indent={indent}>
          <ConditionalContainer>{fragment.name}</ConditionalContainer>
        </StandardElement>

        {isBlockedElement || isLastFirstBracket ? <Break /> : null}
      </>
    );
  };
}

function buildTemplate(template) {
  let newCards = [];

  const sorted = template.fragments.sort(function (a, b) {
    return a.executionOrder - b.executionOrder;
  });

  sorted.forEach((frag, i) => {
    const fragType = frag.typeInformation[0].typeValue;
    if (fragType === 1) {
      // Condition

      const conditionNames = ["If", "Else If", "Else", "End If", "Then"];
      const fragment = {
        ...frag,
        name: conditionNames[frag.typeInformation[1].typeValue - 1],
        def: "conditionOperators",
      };

      newCards.push(fragment);
    } else if (fragType === 2) {
      // Condition Operator
      const conditionOperatorNames = ["And", "Or", "(", ")"];
      const fragment = {
        ...frag,
        name: conditionOperatorNames[frag.typeInformation[1].typeValue - 1],
        def: "conditionOperators",
      };
      newCards.push(fragment);
    } else if (fragType === 4) {
      // Source
      //Map Source Dropdown to value for react-select
      const source = frag;
      const operation = sorted[i + 1];

      const isByAmountOrisByPercentage =
        operation.typeInformation[2]?.typeValue === 4 ||
        operation.typeInformation[2]?.typeValue === 5;

      const target =
        (operation.typeInformation[1].typeValue === 6 &&
          !isByAmountOrisByPercentage) ||
        (operation.typeInformation[1].typeValue === 14 &&
          !isByAmountOrisByPercentage) ||
        (operation.typeInformation[1].typeValue === 15 &&
          !isByAmountOrisByPercentage)
          ? null
          : sorted[i + 2];

      const fragment = {
        name: "Fragment",
        def: "fragment",
        source: source,
        operation: operation,
        target: target,
      };
      newCards.push(fragment);
    }
  });

  return newCards;
}
