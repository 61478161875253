import React from "react";
import styled from "styled-components/macro";
import DataSourceName from "../Card/DataSourceName";

const DashboardHeader = styled.div`
  color: ${(props) => props.theme.onBackground};
  margin-left: 10px;
  margin-right: 10px;
  font-family: "Source Sans Pro Semibold";
  font-size: 1.8em;
  display: flex;
  align-items: center;
`;

const DashboardActions = styled.div`
  font-size: 0.6em;
  display: flex;
  align-items: center;
`;

const DashboardBashName = styled.div`
  flex: 1;
  font-style: italic;
  font-size: 0.8rem;
`;

const DashboardHeaderComponent = ({
  actions: Actions,
  batchName,
  dataSource,
}) => {
  return (
    <DashboardHeader>
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <DataSourceName dataSource={dataSource} />
        {batchName && (
          <DashboardBashName>Batch Name: {batchName}</DashboardBashName>
        )}
      </div>

      {Actions ? (
        <DashboardActions>
          <Actions />
        </DashboardActions>
      ) : null}
    </DashboardHeader>
  );
};

export default DashboardHeaderComponent;
