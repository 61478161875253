import React, { useEffect, useState } from "react";
import { useApi } from "../../api/useApi";
import SelectTags from "./SelectTags";
import { setTagInstances } from "../../api/tagMutations";
import { FormActions } from "../Form/FormControls";
import Button from "../Button";
import Spinner from "../Loaders/Spinner";
import { ToastContainer, toast } from "react-toastify";
import ErrorMessages from "../Notifications/ErrorMessages";
import { businessRuleStandardTags } from "../../api/ruleQueries";
import { dataSourceTags } from "../../api/dataSourceQueries";
import { workflowByIdTags } from "../../api/workflowQueries";
import useTagUpdateComplete from "../../Hooks/useTagUpdateComplete";
import Modal from "../Modal";

const UpdateTags = ({ remoteObjectId, tagType = "DATA_SOURCE" }) => {
  const [currentTags, setCurrentTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);

  let query;
  let idKey;
  let instancesPath;
  let submitQuery = setTagInstances;
  switch (tagType) {
    case "WORKFLOW": {
      query = workflowByIdTags;
      idKey = "workflowId";
      instancesPath = "workflowById";
      break;
    }
    case "POLICY": {
      query = businessRuleStandardTags;
      idKey = "standardId";
      instancesPath = "businessRuleStandardById";
      break;
    }
    default: {
      query = dataSourceTags;
      idKey = "id";
      instancesPath = "dataSource";
    }
  }

  const [{ loading, errors, data }, fetchLatest] = useApi(query, {
    [idKey]: Number(remoteObjectId),
  });

  const [{ errors: updateTagsErrors, data: updateTagsData }, setTags] =
    useApi();

  const tagInstances = data?.[instancesPath]?.tagInstances ?? [];

  useEffect(() => {
    const tagInstances = data?.[instancesPath]?.tagInstances ?? [];
    if (tagInstances) {
      setCurrentTags(
        tagInstances.map((ti) => {
          const createTagOption = {
            label: ti?.tag?.name,
            value: ti?.tagId,
            ...ti,
          };
          return createTagOption;
        })
      );
    }
  }, [data, setCurrentTags, instancesPath]);

  const handleTagChange = React.useCallback(
    (tags) => {
      setCurrentTags(tags);
    },
    [setCurrentTags]
  );

  const callConfirm = () => {
    setShowConfirm(true);
  };

  const submitNewTags = React.useCallback(() => {
    setTags({
      query: submitQuery,
      variables: {
        remoteObjectId: Number(remoteObjectId),
        tagIds: currentTags?.map((t) => t?.tagId) ?? [],
        type: tagType,
      },
    });
    setIsLoading(true);
  }, [
    currentTags,
    remoteObjectId,
    tagType,
    setTags,
    submitQuery,
    setIsLoading,
  ]);

  useEffect(() => {
    if (updateTagsErrors?.length) {
      setIsLoading(false);
      setShowConfirm(false);
    }
  }, [updateTagsErrors, setIsLoading]);

  const { tagUpdateComplete, setTagUpdateComplete } = useTagUpdateComplete();

  useEffect(() => {
    if (tagUpdateComplete) {
      const payload = tagUpdateComplete?.payload;
      const matchLastRequest =
        updateTagsData?.setTagInstances === payload?.RequestId;
      if (matchLastRequest) {
        if (!payload?.ErrorMessage) {
          toast.info("Tags Saved", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          fetchLatest({
            query: query,
            variables: {
              [idKey]: Number(remoteObjectId),
            },
          });
          setIsLoading(false);
          setShowConfirm(false);
        } else {
          const errorMessage = [
            {
              message: payload?.ErrorMessage,
            },
          ];
          setErrorMessage(errorMessage);
          setIsLoading(false);
          setShowConfirm(false);
        }
      }
    }
  }, [
    tagUpdateComplete,
    setTagUpdateComplete,
    updateTagsData,
    remoteObjectId,
    query,
    idKey,
    fetchLatest,
  ]);

  // Check for a difference to warn the user of removing a tag.
  let arr1 = tagInstances?.map((ti) => ti?.tagId);
  let arr2 = currentTags?.map((ti) => ti?.tagId);
  let difference = arr1.filter((x) => arr2.indexOf(x) === -1);

  return (
    <>
      {showConfirm ? (
        <Modal title={`Confirm Tag Update`} hide={() => setShowConfirm(false)}>
          <p>
            we've noticed you are removing a tag from a source or policy.
            Removing these tags will cause the corresponding policy mapping's to
            be removed from the source(s).{" "}
          </p>
          <p>
            <b>Removing Tags:</b>
            {difference?.map((tagId) => {
              return (
                <div>
                  {tagInstances.find((ti) => ti?.tagId === tagId)?.tag?.name}
                </div>
              );
            })}
          </p>
          <div>
            <Button type="button" onClick={() => submitNewTags()}>
              {isLoading ? <Spinner /> : "Confirm Update"}
            </Button>
          </div>
        </Modal>
      ) : null}

      <h3>Tags</h3>
      <p>Select the appropriate tags below.</p>
      <SelectTags
        loadingIndicator={loading || isLoading}
        currentTags={tagInstances}
        updateTags={handleTagChange}
        tagType={tagType}
      />
      {errors || updateTagsErrors || errorMessage ? (
        <ErrorMessages errors={errors || updateTagsErrors || errorMessage} />
      ) : null}

      <div style={{ display: "flex", alignItems: "center" }}>
        {difference?.length ? (
          <div style={{ flex: 1 }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: ".8rem",
                color: "red",
                marginTop: "1.5rem",
              }}
            >
              When removing a tag from a source or policy, the corresponding
              policy mapping will be removed from the source.
            </div>
          </div>
        ) : null}

        <FormActions style={{ marginLeft: "auto" }}>
          <Button
            type="button"
            onClick={() =>
              difference?.length ? callConfirm() : submitNewTags()
            }
          >
            {isLoading ? <Spinner /> : "Update Tags"}
          </Button>
        </FormActions>
      </div>

      <ToastContainer />
    </>
  );
};

export default UpdateTags;
