// Set Pipeline On DataSource
export const createWorkflow = /* GraphQL */ `
  mutation ($workflow: WorkflowModelInput!) {
    createWorkflow(workflow: $workflow)
  }
`;

// Set Update Workflow
export const updateWorkflow = /* GraphQL */ `
  mutation ($workflow: WorkflowModelInput!) {
    updateWorkflow(workflow: $workflow)
  }
`;

// Set Workflow Enabled Flag
export const setWorkflowEnabledFlag = /* GraphQL */ `
  mutation ($id: Int!, $enabled: Boolean!) {
    setWorkflowEnabledFlag(id: $id, enabled: $enabled) {
      id
    }
  }
`;

// Cancel Workflow Run
export const cancelWorkflowRun = /* GraphQL */ `
  mutation ($id: UUID!) {
    cancelWorkflowRun(workflowRunId: $id)
  }
`;
