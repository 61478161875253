import React, { useEffect, useCallback } from "react";
import { Formik } from "formik";
import {
  FormControl,
  StyledField,
  FormActions,
  StyledSelect,
  ErrorMessage,
  Label,
} from "../../Form/FormControls";
import Button from "../../Button";
import Spinner from "../../Loaders/Spinner";
import { priorityLevelOptions } from "../../../common/formOptions";
import useGraphApi from "../../../api/useGraphApi";
import { setDataSourceMetadata } from "../../../api/dataSourceMutations";
import * as Yup from "yup";
import { dataSourceDetails } from "../../../api/dataSourceQueries";
import { useApi } from "../../../api/useApi";
import SplashLoader from "../../Loaders/SplashLoader";
import ErrorMessages from "../../Notifications/ErrorMessages";
import DataRefreshForm from "./DataRefreshForm";
import ReconciliationRuleSettings from "./ReconciliationRuleSettings";

import UpdateTags from "../../Tags/UpdateTags";
import RemoteInfoForm from "./RemoteInfoFormSection";

const SourceDetailsForm = ({ sourceId }) => {
  const [{ loading, errors, data }, getSource] = useApi(dataSourceDetails, {
    id: Number(sourceId),
  });

  const apiData = data?.dataSource;

  const getUpdatedSource = useCallback(() => {
    getSource({
      query: dataSourceDetails,
      variables: { id: Number(sourceId) },
    });
  }, [sourceId, getSource]);

  //remove rules state
  const updateMetaApi = useGraphApi();

  const description = (apiData && apiData.description) || "";
  const friendlyName = (apiData && apiData.friendlyName) || "";
  const qualityImpact = (apiData && apiData.qualityImpact) || "";
  const priorityLevelValue = (apiData && apiData.priorityLevel) || "NORMAL";
  const priorityLevel = priorityLevelOptions.find(
    (dt) => dt.value.toLowerCase() === priorityLevelValue.toLowerCase()
  );

  //callback
  const updateMeta = (values) => {
    const dataSourceId = (apiData && apiData.id) || null;

    const variables = {
      id: dataSourceId,
      description: values.description,
      friendlyName: values.friendlyName,
      priorityLevel: values.priorityLevel.value,
      qualityImpact: values.qualityImpact?.trim(),
    };

    updateMetaApi.doFetch(setDataSourceMetadata, variables);
  };

  useEffect(() => {
    if (updateMetaApi.apiData) {
      getUpdatedSource();
    }
  }, [updateMetaApi.apiData, getUpdatedSource]);

  if (loading) return <SplashLoader text={"Loading Settings"} />;
  if (errors) return <ErrorMessages errors={errors} />;

  return (
    <>
      <Formik
        initialValues={{
          description: description,
          friendlyName: friendlyName,
          priorityLevel: priorityLevel,
          qualityImpact: qualityImpact,
        }}
        enableReinitialize
        validationSchema={Yup.object().shape({
          description: Yup.string().required("Required"),
        })}
        onSubmit={(values, actions) => {
          updateMeta(values);
        }}
      >
        {(props) => {
          return (
            <form
              onSubmit={props.handleSubmit}
              data-testid="source-details-form"
            >
              <h3>GENERAL</h3>

              <FormControl>
                <StyledField
                  data-testid={"source-data-friendly"}
                  name={`friendlyName`}
                  label="Display Name"
                  placeholder={`Display Name`}
                />
                <ErrorMessage name={`friendlyName`} />
              </FormControl>

              <FormControl>
                <StyledField
                  data-testid={"source-data-desc"}
                  name={`description`}
                  label="Description"
                  placeholder={`Description`}
                />
                <ErrorMessage name={`description`} />
              </FormControl>

              <FormControl>
                <StyledField
                  data-testid={"data-quality-impact"}
                  name={`qualityImpact`}
                  label="Data Quality Impact"
                  placeholder={`Data Quality Impact`}
                />
                <ErrorMessage name={`qualityImpact`} />
              </FormControl>

              <FormControl>
                <Label htmlFor="priorityLevel">Data Impact Level</Label>
                <StyledSelect
                  data-testid={"data-priority-level"}
                  id={`data-priority-level`}
                  inputId={`data-priority-level-data-priority-levelSelect-input`}
                  instanceId={`data-priority-level-data-priority-levelSelect-instance`}
                  className={`react-select-container`}
                  classNamePrefix={`react-select`}
                  name={`priorityLevel`}
                  menuPortalTarget={document.body}
                  label="Data Priority Level"
                  options={priorityLevelOptions}
                  placeholder={`Data Priority Level`}
                  value={props.values.priorityLevel}
                  onChange={(e) => props.setFieldValue(`priorityLevel`, e)}
                />
              </FormControl>

              <FormActions>
                <Button
                  type="submit"
                  disabled={updateMetaApi.loading || !props.isValid}
                >
                  {updateMetaApi.loading ? <Spinner /> : "Update Details"}
                </Button>
              </FormActions>
            </form>
          );
        }}
      </Formik>
      <UpdateTags remoteObjectId={sourceId} tagType={"DATA_SOURCE"} />
      <DataRefreshForm apiData={apiData} getUpdatedSource={getUpdatedSource} />
      <ReconciliationRuleSettings
        sourceId={sourceId}
        modalAction={getUpdatedSource}
      />

      <RemoteInfoForm apiData={apiData} getUpdatedSource={getUpdatedSource} />
    </>
  );
};

export default SourceDetailsForm;
