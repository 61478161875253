import React, { useState, useEffect } from "react";
import { useApi } from "../../api/useApi";
import PagedTable from "../../components/Table/PagedTable";
import {
  StyledSelect,
  Label,
  FormControl,
  StyledInput,
  FormActions,
} from "../../components/Form/FormControls";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import { allUserDefinedCleanupsAsync } from "../../api/schedulerQueries";
import { createOrUpdateUserDefinedCleanup } from "../../api/schedulerMutations";
import TableButton from "../../components/Button/TableButton";
import Button from "../../components/Button";
import Modal from "../../components/Modal";

// needs to be kept in sync with new source types added for scheduling
const sourceTypes = [
  "DATA_SOURCE",
  "WORKFLOW",
  "PROVIDER",
  "PROVIDER_INSTANCE",
  "CONNECTION",
  "MANAGEMENT",
  "SERVICER_TRANSFER",
  "UNIQUE_VALUES",
  "PREDICTIVE_OCR",
  "TAGGING",
  "NOTIFICATION",
];

const CleanupForm = ({ isNew, existing, formData, updateCleanUpTime }) => {
  const [timeInHours, setTimeInHours] = useState(formData?.sourceTimeInHours);
  const [selectedSourceType, setSelectedSourceType] = useState();
  const [sourceDescription, setSourceDescription] = useState(
    formData?.description
  );
  const findExisting =
    existing?.map((e) => {
      return e?.node?.sourceType;
    }) ?? [];

  const buildOptions =
    sourceTypes
      .filter((x) => !findExisting?.includes(x))
      .map((bo) => {
        return { label: bo, value: bo };
      }) ?? [];

  return (
    <>
      <h4>{formData?.description}</h4>
      {isNew && (
        <>
          <FormControl>
            <Label>Source Type</Label>
            <StyledSelect
              className={`react-select-container`}
              classNamePrefix={`react-select`}
              menuPortalTarget={document.body}
              name={`${selectedSourceType}-selectedSourceType`}
              id={`${selectedSourceType}-selectedSourceType`}
              inputId={`${selectedSourceType}-selectedSourceType-input`}
              instanceId={`${selectedSourceType}-selectedSourceType-instance`}
              options={buildOptions}
              onChange={(e) => setSelectedSourceType(e)}
              placeholder={"Source Type"}
              value={selectedSourceType}
            />
          </FormControl>
        </>
      )}

      <FormControl style={{ marginTop: "1rem" }}>
        <StyledInput
          type="text"
          name="Description"
          label="Description"
          placeholder={`Enter Description`}
          value={sourceDescription}
          onChange={(input) => setSourceDescription(input.target.value)}
        />
      </FormControl>

      <FormControl style={{ marginTop: "1rem" }}>
        <StyledInput
          type="text"
          name="Time In Hours"
          label="Cleanup Time In Hours"
          placeholder={`Enter Time In Hours`}
          value={timeInHours}
          onChange={(input) => setTimeInHours(input.target.value)}
        />
      </FormControl>

      <FormActions>
        <Button
          type="button"
          disabled={!timeInHours}
          onClick={() =>
            updateCleanUpTime({
              ...formData,
              ...(isNew && { sourceType: selectedSourceType?.value }),
              description: sourceDescription,
              sourceTimeInHours: Number(timeInHours),
            })
          }
        >
          Update
        </Button>
      </FormActions>
    </>
  );
};

const ScheduledCleanup = () => {
  const [scheduleData, setScheduleCleanup] = useState([]);
  const [scheduleToEdit, setScheduleToEdit] = useState();
  const [createNew, setCreateNew] = useState();

  //TODO: Role UPDATE API is Broken currently, UI needs to indicate Loading and Completed Change
  const [
    { errors: scheduleUpdateErrors, data: scheduleUpdateData },
    doUpdateSchedule,
  ] = useApi();

  // createOrUpdateUserDefinedCleanup

  const updateCleanUpTime = React.useCallback(
    (cleanupData) => {
      const variables = {
        ...cleanupData,
      };

      doUpdateSchedule({ query: createOrUpdateUserDefinedCleanup, variables });
    },
    [doUpdateSchedule]
  );

  const columnsData = [
    {
      Header: "Source Type",
      id: "sourceType",
      sortable: false,
      Cell: ({ row: { original } }) => {
        return (
          <div title={original?.node?.sourceType}>
            {original?.node?.sourceType}
          </div>
        );
      },
    },
    {
      Header: "Description",
      id: "description",
      sortable: false,

      Cell: ({ row: { original } }) => {
        return (
          <div title={original?.node?.description}>
            {original?.node?.description}
          </div>
        );
      },
    },
    {
      Header: "Cleanup Time in Hours",
      id: "sourceTimeInHours",
      sortable: false,

      Cell: ({ row: { original } }) => {
        return (
          <div title={original?.node?.sourceTimeInHours}>
            {original?.node?.sourceTimeInHours}
          </div>
        );
      },
    },
    {
      Header: "",
      id: "actions",
      Cell: ({ row: { original } }) => {
        return (
          <>
            <TableButton
              list="true"
              type="button"
              onClick={() => setScheduleToEdit(original)}
            >
              Edit
            </TableButton>
          </>
        );
      },
    },
  ];

  //Init Data Fetch
  const [{ loading, data: apiData }, doFetch] = useApi();

  const totalCount = apiData?.allUserDefinedCleanupsAsync?.totalCount;
  const pageInfo = apiData?.allUserDefinedCleanupsAsync?.pageInfo;

  //Fetch for Table Paged
  const fetchData = React.useCallback(
    ({ pageSize, cursor }) => {
      doFetch({
        query: allUserDefinedCleanupsAsync,
        variables: {
          first: pageSize,
          after: cursor,
          order: {
            sourceType: "ASC",
          },
        },
      });
    },
    [doFetch]
  );

  useEffect(() => {
    if (scheduleUpdateData && !scheduleUpdateErrors) {
      doFetch({
        query: allUserDefinedCleanupsAsync,
        variables: {
          order: {
            sourceType: "ASC",
          },
        },
      });
      setScheduleToEdit(null);
      setCreateNew(null);
    }
  }, [scheduleUpdateData, scheduleUpdateErrors, doFetch]);

  useEffect(() => {
    if (apiData) {
      const cleanups = apiData?.allUserDefinedCleanupsAsync?.edges ?? [];
      setScheduleCleanup(cleanups);
    }
  }, [apiData]);

  return (
    <>
      {createNew ? (
        <Modal title={`Add Scheduled Cleanup`} hide={() => setCreateNew(null)}>
          <CleanupForm
            isNew={true}
            existing={scheduleData}
            formData={{}}
            updateCleanUpTime={updateCleanUpTime}
          />
          {scheduleUpdateErrors ? (
            <ErrorMessages errors={scheduleUpdateErrors} />
          ) : null}
        </Modal>
      ) : null}

      {scheduleToEdit ? (
        <Modal
          title={`Edit ${scheduleToEdit?.node?.sourceType}`}
          hide={() => setScheduleToEdit(null)}
        >
          <CleanupForm
            formData={scheduleToEdit?.node}
            updateCleanUpTime={updateCleanUpTime}
          />
          {scheduleUpdateErrors ? (
            <ErrorMessages errors={scheduleUpdateErrors} />
          ) : null}
        </Modal>
      ) : null}

      <Button list type="button" onClick={() => setCreateNew(true)}>
        Add New
      </Button>

      <PagedTable
        fetchData={fetchData}
        loading={loading}
        pageInfo={pageInfo}
        totalCount={totalCount}
        data={scheduleData}
        columns={columnsData}
        defaultPageSize={50}
      />
    </>
  );
};

export default ScheduledCleanup;
